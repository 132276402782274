import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, Checkbox, Col, Upload, notification, DatePicker, Tooltip, Icon, Select, InputNumber } from "antd";
import axios from "axios";
import moment from "moment";

const Dragger = Upload.Dragger;
const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerEditar = ({ ativo, visible, inscricaoId, form, onClose, acesso, disciplina }) => {
    const [data, setData] = useState(null);
    const [ordem, setOrdem] = useState(1);
    const [estado, setEstado] = useState("");
    const [fileList, setFileList] = useState([]);
    const [docGerado, setDocGerado] = useState("");
    const [documento, setDocumento] = useState(null);
    const [replicarDisciplinas, setReplicarDisciplinas] = useState(false);
    const [replicarInscritos, setReplicarInscritos] = useState(false);
    const [iconLoading, setIconLoading] = useState(false);

    const { getFieldDecorator } = form;

    const detalhe = () => {
        axios({
            method: "get",
            url: "/api/gestao-turma-matriculas/detalhe-matricula",
            params: {
                inscricaoId
            }
        })
            .then(response => {
                const dados = response.data;

                setData(moment(dados.data, "YYYY-MM-DD"));
                setOrdem(dados.ordem);
                //if (dados.anulado) {
                //    setData(moment(dados.dt_anulado, "YYYY-MM-DD"));
                //    setEstado("ANULADO");
                //}
                //else if (dados.transferido) {
                //    setData(moment(dados.dt_transferido, "YYYY-MM-DD"));
                //    setEstado("TRANSFERIDO");
                //}
                //else {
                //    setData(dados.dt_registo ? moment(dados.dt_registo, "YYYY-MM-DD") : null);
                //    setEstado(dados.transferencia ? "TRANSFERENCIA" : "MATRICULA");
                //}

                //if (dados.documento)
                //    setDocumento(dados.documento);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
            });
    }

    const confirma = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setIconLoading(true);
                var item = new FormData();
                item.append("id", inscricaoId);
                item.append("estado", estado);
                item.append("data", moment(data).format("YYYY-MM-DD"));
                item.append("ordem", ordem);
                item.append("anexo", fileList[0]);
                item.append("replicarDisciplinas", replicarDisciplinas);
                item.append("replicarInscritos", replicarInscritos);
                item.append("disciplina", disciplina);

                axios({
                    method: "post",
                    url: "/api/gestao-turma-matriculas/atualizar-matricula",
                    data: item
                })
                    .then(response => {
                        onClose();
                        setIconLoading(false);
                        openNotificationWithIcon("success", "Sucesso", response.data);
                    })
                    .catch(error => {
                        setIconLoading(false);
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        })
    }

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    const validarFormatoFicheiro = formato => {
        var formatos = ["pdf"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    const validar = (rule, value, callback) => {
        if (value.file) {
            if (value.file.size > 26214400)
                callback("Limite de 25 MB por upload!");
            if (!validarFormatoFicheiro(value.file.name.split(".").pop().toLowerCase()) && value.file.status !== "removed")
                callback("Ficheiro com formato inválido!");
        }

        callback();
    };

    const props = {
        accept: ".pdf",
        multiple: false,
        onRemove: file => {
            form.resetFields("ficheiro");
            setFileList(fileList => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);

                return newFileList;
            });
        },
        defaultFileList: fileList,
        beforeUpload: file => {

            setFileList([file]);

            return false;
        },
        fileList
    };

    const afterVisibleChange = visible => {
        form.resetFields();
        if (visible) {
            if (inscricaoId > 0)
                detalhe();
        }
        else {
            setData(null);
            setEstado("");
            setFileList([]);
            setDocGerado("");
            setDocumento(null);
            setIconLoading(false);
            setReplicarDisciplinas(false);
            setReplicarInscritos(false);
        }
    }

    return (
        <Drawer
            className="drawer-inscrever drawer-editar-matricula"
            //title=""
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-drawer-associar">
                <Form className="form-emitir-declaracao">
                    <div className="bloco">
                        <Form.Item label="Data">
                            {getFieldDecorator("data", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    }
                                ],
                                initialValue: data
                            })(
                                <DatePicker
                                    name="data"
                                    format="DD-MM-YYYY"
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                    disabled={!acesso?.update}
                                    onChange={(data) => setData(data)}
                                />
                            )}
                        </Form.Item>
                        {!disciplina && (
                            <Form.Item label="Ordem">
                                {getFieldDecorator("ordem", {
                                    initialValue: ordem,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(
                                    <InputNumber
                                        placeholder="Ordem"
                                        min={1}
                                        onChange={(ordem) => setOrdem(ordem)}
                                    />
                                )}
                            </Form.Item>)}
                    </div>
                    <div className="bloco">
                        <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>Documento</h3>
                        {documento &&
                            <Form.Item>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <span>{documento.nome}</span>
                                    <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 10 }}>
                                        <Link to="#" title="Visualizar" className="botao-icon-detalhe">
                                            <Icon type="eye" />
                                        </Link>
                                        <Link to="#" title="Descarregar" className="botao-icon-download">
                                            <Icon type="download" />
                                        </Link>
                                    </span>
                                </div>
                            </Form.Item>
                        }
                        <Form.Item>
                            <div className="anexos">
                                {getFieldDecorator("ficheiro", {
                                    rules: [
                                        {
                                            validator: validar
                                        }
                                    ],
                                    initialValue: fileList
                                })(
                                    <Dragger {...props} disabled={docGerado || !acesso?.update}>
                                        <p className="ant-upload-drag-icon">
                                            <i className="fas fa-upload" />
                                        </p>
                                        <p className="ant-upload-text">
                                            Adicionar anexo
                                            <Tooltip title="Formatos válidos: .pdf">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </p>
                                        <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                    </Dragger>
                                )}
                            </div>
                        </Form.Item>
                        {!disciplina && (
                            <Form.Item>
                                <Checkbox name="replicarDisciplinas" checked={replicarDisciplinas} onChange={e => setReplicarDisciplinas(e.target.checked)}>
                                    Replicar nas disciplinas
                                </Checkbox>
                            </Form.Item>)}
                        {ativo && (
                            <Form.Item>
                                <Checkbox name="replicarInscritos" checked={replicarInscritos} onChange={e => setReplicarInscritos(e.target.checked)}>
                                    Replicar em todos os inscritos as opções acima selecionadas
                                </Checkbox>
                            </Form.Item>)}
                    </div>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button
                    className="botao-secundario"
                    onClick={onClose}
                    style={{ marginRight: 20, display: "inline-block" }}
                >
                    Voltar
                </button>
                {acesso?.update && (
                    <button className="botao-principal" disabled={iconLoading} type="primary" onClick={confirma}>
                        {iconLoading ? <Icon type="loading" /> : null}
                        Guardar
                    </button>)}
            </div>
        </Drawer>
    );
}

const FormDrawerEditar = Form.create({ name: "drawer-editar" })(DrawerEditar);

export default FormDrawerEditar;
