import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import { Table, Switch, Select, Icon, notification, Modal } from "antd";
import { FaClone } from "react-icons/fa";
import Header from "../../../User/Header";
import BotaoProcurar from "../../../Geral/_Aux/BotaoProcurar";
import DrawerTurma from "./Drawer/DrawerTurma";
import DrawerDisciplina from "./Drawer/DrawerDisciplina";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";
import moment from "moment";

const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Turmas extends Component {
    static contextType = GlobalContext;
    state = {
        edicoes: [],
        niveis: [],
        //FILTROS
        filtroEdicao: undefined,
        filtroNivel: undefined,
        ativo: true,
        //TABELA
        loading_table: false,
        cursos: [],
        cursosMobile: [],
        //PAGINATION
        pagination: {
            pageSize: 10,
            current: 1,
            total: 0
        },
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER TURMA
        turmaId: 0,
        visibleTurma: false,
        //DRAWER DISCIPLINA
        disciplinaId: 0,

        disciplinaAtivo: false,
        visibleDisciplina: false
    };

    UNSAFE_componentWillMount() {
        //this.listarEdicoes();
        this.listarNiveis();
        this.totalCursos();
        this.listarCursos();
    }

    handleChangeFiltroEdicoes = value => {
        this.setState(
            {
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                },
                filtroEdicao: value
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    handleChangeFiltroNivel = value => {
        this.setState(
            {
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                },
                filtroNivel: value
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    listarEdicoes = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/lista-edicoes"
        })
            .then(response => {
                this.setState({
                    edicoes: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarNiveis = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/lista-categorias"
        })
            .then(response => {
                this.setState({
                    niveis: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //TOTAL CURSOS DA ENTIDADE
    totalCursos = () => {
        axios({
            method: "get",
            url: "/api/gestao-templates-turmas/total-turmas",
            params: {
                filtroEdicao: this.state.filtroEdicao,
                filtroNivel: this.state.filtroNivel,
                ativo: this.state.ativo
            }
        })
            .then(response => {
                var _pagination = this.state.pagination;
                _pagination.total = response.data;

                this.setState({
                    pagination: _pagination
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //LISTA TODOS OS CURSOS DA ENTIDADE
    listarCursos = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-templates-turmas/listar-turmas",
                    params: {
                        filtroEdicao: this.state.filtroEdicao,
                        filtroNivel: this.state.filtroNivel,
                        pageSize: this.state.pagination.pageSize,
                        page: this.state.pagination.current,
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var cursos = [];

                        response.data.map(curso => {
                            cursos.push({
                                key: curso.id,
                                id: curso.id,
                                edicao: curso.edicao,
                                curso: (
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">
                                            {curso.codigo} - {curso.ano}
                                        </span>
                                    </div>
                                ),
                                turno: curso.turno,
                                categoria: curso.nivel,
                                codigo: curso.codigo,
                                periodo: curso.periodo_letivo ? JSON.parse(curso.periodo_letivo).join(", ") : "",

                                opcoes: this.montarMenuCurso(curso),
                                subcursos: curso.subcursos ? curso.subcursos : []
                            });
                        });

                        this.setState({
                            cursos,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    listarSubCursos = (subcursos, cursoIndex, curso) => {
        var _subcursos = [];

        subcursos.map(subcurso => {
            _subcursos.push({
                key: subcurso.id,
                id: subcurso.id,
                edicao: (
                    <div>
                        <span>{subcurso.edicao}</span>
                        {subcurso.ano || subcurso.turma ? (
                            <span style={{ display: "block" }}>{`${subcurso.ano ? subcurso.ano : ""} ${
                                subcurso.turma ? subcurso.turma : ""
                            }`}</span>
                        ) : null}
                    </div>
                ),
                curso: (
                    <span className="titulo-curso">
                        {subcurso.codigo} - {subcurso.nome}
                    </span>
                ),
                periodo: (
                    <div>
                        <span>
                            {subcurso.dt_fim
                                ? `${moment(subcurso.dt_inicio).format("DD/MM/YYYY")} - ${moment(subcurso.dt_fim).format("DD/MM/YYYY")}`
                                : moment(subcurso.dt_inicio).format("DD/MM/YYYY")}
                        </span>
                        {subcurso.fechado ? (
                            <div className="tag-fechado">
                                Fechado (
                                {subcurso.dt_fechado && (
                                    <span style={{ fontSize: "12px" }}>{moment(subcurso.dt_fechado).format("DD/MM/YYYY")}</span>
                                )}
                                )
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                ),

                opcoes: this.montarMenuDisciplina(subcurso, cursoIndex, curso),
                ativo: subcurso.ativo
            });
        });

        return _subcursos;
    };

    atualizar = () => {
        this.totalCursos();
        this.listarCursos();
    };

    duplicar = turmaId => {
        confirm({
            title: "Pretende duplicar este plano de estudos?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-templates-turmas/duplicar",
                    params: {
                        turmaId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Plano duplicado");

                        this.totalCursos();
                        this.listarCursos();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível duplicar plano!");
                    });
            }
        });
    };

    ativarTurmas = turmaId => {
        confirm({
            title: this.state.ativo ? "Pretende inativar este plano de estudos?" : "Pretende ativar este plano de estudos?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-templates-turmas/ativar-turma",
                    params: {
                        turmaId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", this.state.ativo ? "Plano de estudos inativado!" : "Plano de estudos ativado!");

                        var cursos = this.state.cursos.filter(x => x.id !== turmaId);
                        var pagination = this.state.pagination;
                        pagination.total = pagination.total - 1;

                        this.setState({
                            cursos,
                            pagination
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            this.state.ativo ? "Não foi possível inativar o plano de estudos!" : "Não foi possível ativar o plano de estudos!"
                        );
                    });
            }
        });
    };

    excluirTurmas = turmaId => {
        confirm({
            title: "Pretende excluir esta plano de estudos?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-templates-turmas/excluir-turma",
                    params: {
                        turmaId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Plano de estudos excluida!");
                        var cursos = this.state.cursos.filter(x => x.id !== turmaId);
                        var pagination = this.state.pagination;
                        pagination.total = pagination.total - 1;

                        this.setState({
                            cursos,
                            pagination
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a plano de estudos ");
                    });
            }
        });
    };

    ativarDisciplina = (disciplinaId, ativo, cursoIndex) => {
        confirm({
            title: ativo ? "Pretende inativar esta Disciplina?" : "Pretende ativar esta Disciplina?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-templates-turmas/ativar-disciplina",
                    params: {
                        disciplinaId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Disciplina inativada!" : "Disciplina ativada!");

                        var cursos = this.state.cursos;
                        var subIndex = cursos[cursoIndex].subcursos.findIndex(x => x.id === disciplinaId);
                        cursos[cursoIndex].subcursos[subIndex].ativo = !cursos[cursoIndex].subcursos[subIndex].ativo;

                        this.setState({
                            cursos
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar a Disciplina!" : "Não foi possível ativar a Disciplina!"
                        );
                    });
            }
        });
    };

    excluirDisciplina = (disciplinaId, cursoIndex) => {
        confirm({
            title: "Pretende excluir esta disciplina?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-templates-turmas/excluir-disciplina",
                    params: {
                        disciplinaId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Disciplina excluida!");
                        var cursos = this.state.cursos;
                        cursos[cursoIndex].subcursos = cursos[cursoIndex].subcursos.filter(x => x.id !== disciplinaId);

                        this.setState({
                            cursos
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a disciplina");
                    });
            }
        });
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    montarMenuCurso = turma => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (this.state.ativo)
            return (
                <div style={divStyle}>
                    <Link
                        to="#"
                        className="botao-icon-adicionar"
                        title="Adicionar Disciplina"
                        onClick={event => {
                            event.stopPropagation();
                            this.setState({ visibleDisciplina: true, turmaId: turma.id, disciplinaId: 0 });
                        }}
                    >
                        <Icon type="plus" />
                    </Link>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => {
                            event.stopPropagation();
                            this.ativarTurmas(turma.id);
                        }}
                    >
                        <Icon type="close" />
                    </Link>
                    {/*<Link*/}
                    {/*    to="#"*/}
                    {/*    className="botao-icon-enviar"*/}
                    {/*    title="Duplicar"*/}
                    {/*    onClick={event => {*/}
                    {/*        event.stopPropagation();*/}
                    {/*        this.duplicar(turma.id);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <FaClone />*/}
                    {/*</Link>*/}
                </div>
            );

        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => {
                        event.stopPropagation();
                        this.ativarTurmas(turma.id);
                    }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => {
                        event.stopPropagation();
                        this.excluirTurmas(turma.id);
                    }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };

    montarMenuDisciplina = (disciplina, turmaIndex) => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (disciplina.ativo)
            return (
                <div style={divStyle}>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => {
                            event.stopPropagation();
                            this.ativarDisciplina(disciplina.id, disciplina.ativo, turmaIndex);
                        }}
                    >
                        <Icon type="close" />
                    </Link>
                </div>
            );

        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => {
                        event.stopPropagation();
                        this.ativarDisciplina(disciplina.id, disciplina.ativo, turmaIndex);
                    }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => {
                        event.stopPropagation();
                        this.excluirDisciplina(disciplina.id, turmaIndex);
                    }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };

    handleChangeTable = (pagination, filters, sorter) => {
        var _pagination = this.state.pagination;
        _pagination.current = pagination.current;

        this.setState(
            {
                pagination: _pagination
            },
            () => {
                this.listarCursos();
            }
        );
    };

    onRowClick = turmaId => {
        this.setState({
            turmaId,
            visibleTurma: true
        });
    };

    onLvl2RowClick = (turmaId, disciplinaId, disciplinaAtivo) => {
        this.setState({
            turmaId,
            disciplinaId,
            disciplinaAtivo,
            visibleDisciplina: true
        });
    };

    render() {
        const {
            edicoes,
            niveis,
            //FILTROS
            filtroEdicao,
            filtroNivel,
            ativo,
            //TABELA
            loading_table,
            cursos,
            cursosMobile,
            //PAGINATION
            pagination,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER TURMA
            turmaId,
            visibleTurma,
            //DRAWER DISCIPLINA
            disciplinaId,
            visibleDisciplina
        } = this.state;

        const columnsTurmas = [
            //{
            //    title: "Edição",
            //    dataIndex: "edicao",
            //    className: "td-50"
            //},
            {
                title: "Plano / Ano",
                dataIndex: "curso",
                className: "td-300"
            },
            {
                title: "Nível",
                dataIndex: "categoria",
                className: "td-155"
            },
            {
                title: "Turno",
                dataIndex: "turno",
                className: "td-155"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnsTurmasMobile = [
            //{
            //    title: "Edição",
            //    dataIndex: "edicao",
            //    className: "td-50"
            //},
            {
                title: "Plano / Ano",
                dataIndex: "curso",
                className: "td-300 theads-width p10"
            },
            {
                title: "Nível",
                dataIndex: "categoria",
                className: "td-155 p30 theads-width"
            },
            {
                title: "Turno",
                dataIndex: "turno",
                className: "td-155 p55 theads-width"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnsDisciplinas = [
            {
                title: "Disciplina",
                dataIndex: "curso",
                render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnsDisciplinasMobile = [
            {
                title: "Disciplina",
                dataIndex: "curso",
                className: "td-250",
                render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Planos de estudos" />
                <div className="container container-body">
                    <div className="bloco-v2 bloco-cursos">
                        <div className="bloco-tabela">
                            <>
                                <div className="filtros filtros-desktop" style={{ display: "block" }}>
                                    <div className="bloco-filtros-1" style={{ gap: 10 }}>
                                        <div>
                                            <button
                                                className="botao-principal botao-principal-redondo"
                                                title="Adicionar"
                                                onClick={() => this.setState({ turmaId: 0, visibleTurma: true })}
                                                disabled={!this.state.ativo}
                                            >
                                                +
                                            </button>
                                        </div>
                                        <div className="bloco-pesquisa bloco-filtros-2">
                                            <Select
                                                value={filtroNivel}
                                                className="filtro-selects"
                                                onChange={this.handleChangeFiltroNivel}
                                                placeholder="Nível"
                                                disabled={!niveis.length}
                                                allowClear={true}
                                            >
                                                {niveis.map(nivel => (
                                                    <Option key={nivel.id} value={nivel.id}>
                                                        {nivel.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <Switch
                                            checkedChildren="Ativos"
                                            unCheckedChildren="Inativos"
                                            defaultChecked
                                            checked={ativo}
                                            onChange={this.handleChangeSwitch}
                                        />
                                    </div>
                                </div>

                                <div className="filtros filtros-mobile">
                                    <div className="bloco-esquerda bloco-esquerda-cursos">
                                        <div
                                            className="controlos"
                                            style={{ display: "flex", justifyContent: "space-between", marginBottom: 0 }}
                                        >
                                            <div>
                                                <button
                                                    className="botao-principal botao-principal-redondo"
                                                    title="Adicionar"
                                                    onClick={() => this.setState({ turmaId: 0, visibleTurma: true })}
                                                >
                                                    +
                                                </button>
                                            </div>
                                            <div className="filtros" style={{ paddingTop: 8 }}>
                                                <Switch
                                                    checkedChildren="Ativos"
                                                    unCheckedChildren="Inativos"
                                                    defaultChecked
                                                    checked={ativo}
                                                    onChange={this.handleChangeSwitch}
                                                />
                                            </div>
                                        </div>
                                        <div className="bloco-filtros">
                                            <Select
                                                value={filtroNivel}
                                                className="filtro-selects"
                                                onChange={this.handleChangeFiltroNivel}
                                                placeholder="Nível"
                                                disabled={!niveis.length}
                                                allowClear={true}
                                            >
                                                {niveis.map(nivel => (
                                                    <Option key={nivel.id} value={nivel.id}>
                                                        {nivel.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </>
                            <Table
                                id="cursos-disciplinas"
                                className="curso-disciplinas-desktop"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columnsTurmas}
                                dataSource={cursos}
                                pagination={pagination}
                                rowClassName="clickeble-row"
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => this.onRowClick(record.id)
                                    };
                                }}
                                locale={{ emptyText: "Não existem dados!" }}
                                onChange={this.handleChangeTable}
                                expandedRowRender={(record, index) => (
                                    <Table
                                        id="disciplinas"
                                        columns={columnsDisciplinas}
                                        dataSource={this.listarSubCursos(record.subcursos, index, record)}
                                        pagination={false}
                                        rowClassName="clickeble-row"
                                        onRow={(record2, index) => {
                                            return {
                                                onClick: () => this.onLvl2RowClick(record.id, record2.id, record2.ativo)
                                            };
                                        }}
                                        locale={{ emptyText: "Não existem registos!" }}
                                    />
                                )}
                            />

                            <Table
                                id="cursos-disciplinas"
                                className="curso-disciplinas-mobile"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columnsTurmasMobile}
                                dataSource={cursos}
                                pagination={pagination}
                                locale={{ emptyText: "Não existem registos!" }}
                                onChange={this.handleChangeTable}
                                expandedRowRender={(record, index) => (
                                    <Table
                                        id="disciplinas"
                                        columns={columnsDisciplinas}
                                        dataSource={this.listarSubCursos(record.subcursos, index, record)}
                                        pagination={false}
                                        rowClassName="clickeble-row"
                                        onRow={(record2, index) => {
                                            return {
                                                onClick: () => this.onLvl2RowClick(record.id, record2.id, record2.ativo)
                                            };
                                        }}
                                        locale={{ emptyText: "Não existem dados!" }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>

                <DrawerTurma
                    ativo={this.state.ativo}
                    turmaId={turmaId}
                    visible={visibleTurma}
                    onClose={() => this.setState({ visibleTurma: false })}
                    atualizar={() => {
                        this.setState({ turmaId: 0, visibleTurma: false });
                        this.atualizar();
                    }}
                />
                <DrawerDisciplina
                    turmaId={turmaId}
                    disciplinaId={disciplinaId}
                    visible={visibleDisciplina}
                    onClose={() => this.setState({ visibleDisciplina: false })}
                    atualizar={() => {
                        this.setState({ disciplinaId: 0, visibleDisciplina: false });
                        this.atualizar();
                    }}
                />
            </div>
        );
    }
}

export default Turmas;
