import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Icon, notification, Modal, Button, Collapse, Select } from "antd";
import axios from "axios";

import ObservacaoAluno from "./_Aux/ObservacaoAluno";
import ObservacaoGeral from "./_Aux/ObservacaoGeral";
import ModalViewer from "../../Modals/ModalViewer";
import ModalLoading from "../../Modals/ModalLoading";

const { Option } = Select;
const { Panel } = Collapse;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ListaObservacoes extends Component {
    state = {
        //FILTROS
        disciplinas: [],
        disciplinaId: localStorage.getItem("vista") === "TURMA" ? null : localStorage.getItem("codigo_curso"),
        num: 1,
        total: 0,
        semPeriodos: false,
        periodos: [],
        //PESQUISA
        dt_inicio: "",
        dt_fim: "",
        //PERIODO
        mes: "",
        ano: "",
        titulo: "",
        aulasAlunos: [],
        aulas: [],
        participantes: [],
        //LOADING
        iconLoading: false,
        //LOADING
        visibleLoading: false,
        //VIEWER
        visibleViewer: false,
        docs: []
    }

    componentDidMount() {
        if (localStorage.getItem("vista") === "TURMA")
            this.listarDisciplinas();

        this.total();
    }

    handleSelect = (disciplinaId) => {
        if (disciplinaId) {
            this.setState({
                disciplinaId
            }, () => {
                this.listarObsAluno();
                this.listarObservacoes();
            })
        }
        else {
            this.setState({
                aulasAlunos: [],
                aulas: []
            })
        }
    }

    total = () => {
        this.setState({
            iconLoading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/aulas/total-periodos-observacoes",
                params: {
                    cursoId: localStorage.getItem("codigo_curso")
                }
            })
                .then(response => {
                    if (response.data.length > 0) {
                        this.setState({
                            total: response.data.length,
                            periodoId: response.data[0].id,
                            periodos: response.data,
                            titulo: response.data[0].titulo,
                            dt_inicio: response.data[0].dt_inicio,
                            dt_fim: response.data[0].dt_fim,
                        }, () => {
                            if (!localStorage.getItem("vista")) {
                                this.listarObsAluno();
                                this.listarObservacoes();
                            }
                        })
                    }
                    else {
                        this.setState({
                            semPeriodos: true,
                            iconLoading: false
                        })
                        openNotificationWithIcon("warning", "Atenção", "Não foram adicionados períodos ao curso!");
                    }
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
                });
        })
    }

    listarObsAluno = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-observacoes-alunos",
            params: {
                cursoId: this.state.disciplinaId,
                dt_inicio: this.state.dt_inicio,
                dt_fim: this.state.dt_fim
            }
        })
            .then(response => {
                this.setState({
                    aulasAlunos: response.data.aulas,
                    participantes: response.data.participantes,
                    iconLoading: false
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar as observações!");
            });
    }

    listarObservacoes = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-observacoes",
            params: {
                cursoId: this.state.disciplinaId,
                dt_inicio: this.state.dt_inicio,
                dt_fim: this.state.dt_fim
            }
        })
            .then(response => {
                this.setState({
                    aulas: response.data,
                    iconLoading: false
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar as observações!");
            });
    }

    listarDisciplinas = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-turma-disciplinas",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    disciplinas: response.data,
                    iconLoading: false
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar as disciplinas!");
            });
    }

    anteriorProximo = (num) => {
        var periodo = this.state.periodos[num - 1];
        this.setState({
            num,
            periodoId: periodo.id,
            titulo: periodo.titulo,
            dt_inicio: periodo.dt_inicio,
            dt_fim: periodo.dt_fim,
            iconLoading: true
        }, () => {
            this.listarObsAluno();
            this.listarObservacoes();
        })
    }

    exportarObservacoesPdf = () => {
        this.setState(
            {
                visibleLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas-exportar/observacoes-pdf",
                    params: {
                        cursoId: this.state.disciplinaId,
                        periodoId: this.state.periodoId
                    }
                })
                    .then(response => {
                        this.setState({
                            visibleLoading: false,
                            visibleViewer: true,
                            docs: [{ uri: response.data }]
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleLoading: false
                        });
                    });
            }
        );
    }

    exportarObservacoesGeraisPdf = () => {
        this.setState(
            {
                visibleLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas-exportar/observacoes-gerais-pdf",
                    params: {
                        cursoId: this.state.disciplinaId,
                        periodoId: this.state.periodoId
                    }
                })
                    .then(response => {
                        this.setState({
                            visibleLoading: false,
                            visibleViewer: true,
                            docs: [{ uri: response.data }]
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleLoading: false
                        });
                    });
            }
        );
    }

    render() {
        const {
            //FILTROS
            disciplinas,
            disciplinaId,
            num,
            semPeriodos,
            periodos,
            //PERIODO
            titulo,
            aulasAlunos,
            aulas,
            participantes,
            //LOADING
            iconLoading,
            //LOADING
            visibleLoading,
            //VIEWER
            visibleViewer,
            docs
        } = this.state;

        return (
            <>
                <div className="container container-body">
                    <div className="bloco-curso-diario-opcoes" style={{ gap: 10 }}>
                        <h3 className="titulo-bloco">
                            Observações
                        </h3>
                        <div className="bloco-opcoes-esquerda">
                            <Link
                                to={`/gerir-detalhe-turma/${this.props.match.params.turma}/${localStorage.getItem("vista") === "TURMA" ? "horario" : "horario-disciplina"}`}
                                className="botao-secundario botao-secundario-redondo"
                                title="Voltar"
                                style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                            >
                                <Icon type="arrow-left" />
                            </Link>
                        </div>
                    </div>
                    <div className="curso-diario">
                        <div className="bloco-controlos-frequencia">
                            <div className="filters">
                                <div>
                                    {localStorage.getItem("vista") === "TURMA" && (
                                        <Select
                                            className="filter"
                                            placeholder="Selecionar Disciplina"
                                            name="disciplinas"
                                            allowClear={true}
                                            onChange={this.handleSelect}
                                        >
                                            {disciplinas.map(disciplina => (
                                                <Option key={disciplina.id} value={disciplina.id}>{disciplina.nome}</Option>
                                            ))}
                                        </Select>)}
                                </div>
                                {!semPeriodos && (
                                    <div>
                                        <Button className='botao-periodo botao-periodo-anterior' disabled={num === 1 || localStorage.getItem("vista") === "TURMA" && !disciplinaId} style={{ color: "rgba(0, 0, 0, 0.65)" }} onClick={() => this.anteriorProximo(num - 1)}>
                                            <Icon type="left" />
                                        </Button>
                                        <span>{titulo}</span>
                                        <Button className='botao-periodo botao-periodo-proximo' disabled={num === periodos.length || localStorage.getItem("vista") === "TURMA" && !disciplinaId} style={{ color: "rgba(0, 0, 0, 0.65)" }} onClick={() => this.anteriorProximo(num + 1)}>
                                            <Icon type="right" />
                                        </Button>
                                    </div>)}
                            </div>
                        </div>
                        <Collapse defaultActiveKey={[]} className="collapse-diario-observacoes">
                            <Panel
                                header={<span style={{display: "inline-flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                    <label>
                                        Observações por Aluno
                                    </label>
                                    <span style={{ display: "flex", gap: 10 }}>
                                        <Link className={`botao-icon-detalhe ${localStorage.getItem("vista") === "TURMA" && !disciplinaId ? "botao-disabled" : ""}`} to="#" title="Visualizar" disabled={localStorage.getItem("vista") === "TURMA" && !disciplinaId} onClick={(event) => { event.stopPropagation(); this.exportarObservacoesPdf() }}>
                                            <Icon type="eye" />
                                        </Link>
                                    </span>
                                </span>}
                                key="1"
                            >
                                {!aulasAlunos.length ? "Sem dados..." : null}
                                {aulasAlunos.map((aula, index) => (
                                    <ObservacaoAluno
                                        key={index}
                                        aula={aula}
                                        participantes={participantes}
                                    />
                                ))}
                            </Panel>
                            <Panel
                                header={<span style={{ display: "inline-flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                    <label>
                                        Observações
                                    </label>
                                    <span style={{ display: "flex", gap: 10 }}>
                                        <Link className={`botao-icon-detalhe ${localStorage.getItem("vista") === "TURMA" && !disciplinaId ? "botao-disabled" : ""}`} to="#" title="Visualizar" disabled={localStorage.getItem("vista") === "TURMA" && !disciplinaId} onClick={(event) => { event.stopPropagation(); this.exportarObservacoesGeraisPdf() }}>
                                            <Icon type="eye" />
                                        </Link>
                                    </span>
                                </span>}
                                key="2"
                            >
                                {!aulas.length ? "Sem dados..." : null}
                                {aulas.map((aula, index) => (
                                    <ObservacaoGeral
                                        key={index}
                                        aula={aula}
                                    />
                                ))}
                            </Panel>
                        </Collapse>
                    </div>
                </div>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
                <ModalLoading
                    visible={visibleLoading}
                    text="A gerar documento..."
                />
                <ModalViewer
                    visible={visibleViewer}
                    docs={docs}
                    onCancel={() => this.setState({ visibleViewer: false })}
                />
            </>
        );
    }
}

export default ListaObservacoes;
