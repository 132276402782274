import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, Input, Checkbox, Select, notification, DatePicker, TimePicker, Tooltip, Icon } from "antd";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../../../GlobalState"
import noimage from "../../../images/noimage.png";
import DrawerPesquisar from "./DrawerPesquisar";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerVideoConf extends Component {
    static contextType = GlobalContext;
    state = {
        btnIniciar: false,
        assunto: "",
        dia: null,
        hora: null,
        duracao: null,
        gravarSessao: false,
        totalContratado: "",
        //LOADING
        iconLoading: false,
        //NOTIFICAR
        notificar: false,
        //PERMISSOES
        soGestoresVerDescarregar: false,
        //PARTICIANTES
        todos: false,
        participantes: [],
        //DRAWER PESQUISAR PARTICIPATES,
        visibleDrawerPesqusiar: false
    };

    handlerChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handlerChangeCheckbox = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    criar = iniciar => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && (this.state.todos || this.state.participantes.length > 0)) {
                if (iniciar)
                    this.props.prepararMeet(true);

                this.setState({
                    iconLoading: true
                });

                var item = new FormData()
                item.append("cursoID", localStorage.getItem("codigo_curso"));
                item.append("assunto", this.state.assunto);
                item.append("meetingDate", moment(this.state.dia).format("YYYY/MM/DD"));
                item.append("hora", this.state.hora.format("HH:mm"));
                item.append("duracao", this.state.duracao.format("HH:mm"));
                item.append("gravarSessao", this.state.gravarSessao);
                item.append("notificar", this.state.notificar);
                item.append("soGestoresVerDescarregar", this.state.soGestoresVerDescarregar);
                item.append("participantes", JSON.stringify(this.state.participantes));
                item.append("iniciar", iniciar);
                item.append("todos", this.state.todos);

                axios({
                    method: "post",
                    url: "/api/gerir-videoconferencia/criar-nova",
                    data: item
                })
                    .then(response => {
                        if (iniciar)
                            this.props.IniciarMeet(response.data);
                        else
                            openNotificationWithIcon("success", "Sucesso", "Criada nova Stepmeet");

                        this.setState({
                            iconLoading: false
                        });

                        this.props.onClose();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.props.prepararMeet(false);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
            else {
                if (!this.state.todos && this.state.participantes.length === 0)
                    openNotificationWithIcon("error", "Erro", "Não existem participantes para esta Stepmeet");
            }
        })
    };

    carregarTotalContratado = () => {
        axios({
            method: "get",
            url: "/api/gerir-videoconferencia/total-contratado",
            timeout: 60 * 10 * 1000
        })
            .then(response => {
                this.setState({
                    totalContratado: response.data
                });
            })
            .catch(() => {

            });
    }

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            const inicio = moment();
            const minutos = 15 - (inicio.minute() % 15);

            const hora = moment(inicio).add(minutos, "minutes");

            this.carregarTotalContratado();
            this.setState({
                assunto: "",
                dia: moment(),
                hora: hora,
                duracao: null,
                gravarSessao: false,
                //NOTIFICAR
                notificar: false,
                //PERMISSOES
                soGestoresVerDescarregar: false,
                //PARTICIANTES
                todos: false,
                participantes: [JSON.parse(this.props.utilizador)],
            }, () => this.validarVerBotao())
        }
    };

    validarVerBotao = () => {
        var dataHora = `${moment(this.state.data).format("MM/DD/YYYY")} ${moment(this.state.hora).format("HH:mm")}`
        const agora = moment();
        const inicio = moment(dataHora);

        const diff = inicio.diff(agora);

        const diffDuration = moment.duration(diff);

        this.setState({
            btnIniciar: diffDuration.hours() <= 0 && diffDuration.minutes() <= 15
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            btnIniciar,
            assunto,
            dia,
            hora,
            duracao,
            gravarSessao,
            totalContratado,
            //LOADING
            iconLoading,
            //NOTIFICAR
            notificar,
            //PERMISSOES
            soGestoresVerDescarregar,
            //PARTICIANTES
            todos,
            participantes,
            //DRAWER PESQUISAR PARTICIPATES,
            visibleDrawerPesqusiar
        } = this.state;

        const { videoConfId } = this.props;

        return (
            <>
                <Drawer
                    className="drawer-add-cursos drawer-videoconferencias"
                    //title={videoConfId ? "Alterar Stepmeet" : "Adicionar nova Stepmeet"}
                    width={720}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Assunto">
                                    {getFieldDecorator("assunto", {
                                        rules: [{ required: true, message: "Campo obrigatório" }],
                                        initialValue: assunto
                                    })(<Input name="assunto" onChange={this.handlerChange} />)}
                                </Form.Item>
                                <Form.Item label="Dia">
                                    {getFieldDecorator("dia", {
                                        rules: [{ required: true, message: "Campo obrigatório" }],
                                        initialValue: dia
                                    })(<DatePicker
                                        className="input-25"
                                        placeholder="Selecionar"
                                        format="DD-MM-YYYY"
                                        name="dia"
                                        onChange={dia => this.setState({ dia }, () => this.validarVerBotao())}
                                    />)}
                                </Form.Item>
                                <Form.Item label="Hora">
                                    {getFieldDecorator("hora", {
                                        rules: [{ required: true, message: "Campo obrigatório" }],
                                        initialValue: hora
                                    })(<TimePicker
                                        className="input-25"
                                        name="hora"
                                        defaultOpenValue={moment("00:00", "HH:mm")}
                                        format="HH:mm"
                                        onChange={hora => this.setState({ hora }, () => this.validarVerBotao())}
                                        placeholder="Selecionar"
                                        suffixIcon={false}
                                        clearIcon={false}
                                        minuteStep={15}
                                    />)}
                                </Form.Item>
                                <Form.Item label="Duração">
                                    {getFieldDecorator("duracao", {
                                        rules: [{ required: true, message: "Campo obrigatório" }],
                                        initialValue: duracao
                                    })(<TimePicker
                                        className="input-25"
                                        name="duracao"
                                        defaultOpenValue={moment("00:00", "HH:mm")}
                                        format="HH:mm"
                                        onChange={duracao => this.setState({ duracao })}
                                        placeholder="Selecionar"
                                        suffixIcon={false}
                                        clearIcon={false}
                                    />)}
                                </Form.Item>
                            </div>
                            <div className="bloco">
                                <h3 className="titulo-separador">Configurações</h3>
                                {this.context.conf_total_recorders > 0 &&
                                    <Form.Item className="form-item-checkbox">
                                        {getFieldDecorator("gravarSessao")(
                                            <Checkbox name="gravarSessao" checked={gravarSessao} onChange={this.handlerChangeCheckbox}>Gravar sessão</Checkbox>
                                        )}
                                    </Form.Item>}
                                <Form.Item className="form-item-checkbox">
                                    <Checkbox checked={soGestoresVerDescarregar} name="soGestoresVerDescarregar" onChange={this.handlerChangeCheckbox}>
                                        Só gestores podem ver e descarregar as gravações
                            </Checkbox>
                                </Form.Item>
                            </div>
                            <div className="bloco">
                                {/*<Form.Item>
                                    Nº máx. de participantes em simulâneo: {totalContratado}
                                    <Tooltip className="info-icon" title="Nº máximo de participantes contratualizado envolvido numa ou em mais Stepmeets  simultâneas">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Form.Item>*/}
                                <h3 className="titulo-separador titulo-com-botao">
                                    Participantes
                                <button className="botao-principal" onClick={() => this.setState({ visibleDrawerPesqusiar: true })}>
                                        <Icon type="plus" />
                                    </button>
                                </h3>
                                <Form.Item className="form-item-checkbox">
                                    <Checkbox className="marcar-todos" checked={todos} name="todos" onChange={this.handlerChangeCheckbox}>
                                        Todos
                                        <Tooltip className="info-icon" title="Ao selecionar todos, os alunos que indicar serão considerados exceções.">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </Checkbox>
                                </Form.Item>
                                <div className="lista-participantes">
                                    {participantes.map((participante, index) => (
                                        <div key={index} className="participante">
                                            <div className="info-participante">
                                                <div className="container-img">
                                                    <img src={participante.foto ? participante.foto : noimage} />
                                                </div>
                                                <div className="info">
                                                    <span className="nome">
                                                        {participante.nome}
                                                    </span>
                                                    <p className="numero">{participante.numero}</p>
                                                </div>
                                            </div>
                                            <div className="opcoes">
                                                <button className="botao-excluir" onClick={() => this.setState({ participantes: [...this.state.participantes.filter(x => x.id !== participante.id)] })}>
                                                    <Icon type="delete" />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <Form.Item className="form-item-checkbox">
                                    <Checkbox checked={notificar} name="notificar" onChange={this.handlerChangeCheckbox}>
                                        Notificar por e-mail
                                        <Tooltip className="info-icon" title="Notificar os participantes por e-mail">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <Link
                            to="#"
                            className="botao-secundario"
                            onClick={this.props.onClose}
                            style={{ marginRight: 20, display: "inline-block" }}
                        >
                            Voltar
                                </Link>
                        {btnIniciar ?
                            <>
                                <button className="botao-principal botao-principal-border" disabled={iconLoading} onClick={() => this.criar(false)} style={{ marginRight: 20, display: "inline-block" }}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Criar
                                </button>
                                <button className="botao-principal" disabled={iconLoading} onClick={() => this.criar(true)}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Iniciar
                                </button>
                            </>
                            :
                            <button className="botao-principal" disabled={iconLoading} onClick={() => this.criar(false)}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                Criar
                                </button>
                        }
                    </div>
                </Drawer>
                <DrawerPesquisar
                    onClose={() => this.setState({ visibleDrawerPesqusiar: false })}
                    participantes={participantes}
                    visibleDrawerPesqusiar={visibleDrawerPesqusiar}
                    selecionarParticipante={participante => this.setState({ participantes: [...participantes, participante] })}
                />
            </>
        );
    }
}
const FormDrawerVideoConf = Form.create({ name: "form-video-conf" })(DrawerVideoConf);

export default FormDrawerVideoConf;
