import React, { Component } from "react";
import { Drawer, Form, Input, DatePicker, TimePicker, Icon, notification, Checkbox } from "antd";
import { CirclePicker } from 'react-color';
import moment from "moment";
import axios from "axios";
import DrawerPesquisar from "./DrawerPesquisar"
import noimage from "../../images/noimage.png";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerAdicionar extends Component {
    state = {
        titulo: "",
        dt_inicio: "",
        dt_termino: "",
        hr_inicio: "",
        hr_termino: "",
        todo_dia: false,
        cor_fundo: "",
        descricao: "",
        participantes: [],
        //LOADING
        iconLoading: false,
        //DRAWER PARTICIPANTES
        visibleParticipantes: false
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeDataInicio = dt_inicio => {
        if (!this.state.dt_termino)
            this.setState({
                dt_inicio,
                dt_termino: dt_inicio
            });
        else
            this.setState({
                dt_inicio
            });
    };

    handleChangeDataTermino = dt_termino => {
        this.setState({ dt_termino });
    };

    handleChangeHoraInicio = hr_inicio => {
        this.setState({ hr_inicio });
    };

    handleChangeHoraTermino = hr_termino => {
        this.setState({ hr_termino });
    };

    handlerChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeColorPickerFundo = (color, event) => {
        this.setState({
            cor_fundo: color.hex
        })
    }

    criar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                let item = new FormData();
                item.append("titulo", this.state.titulo);
                item.append("dt_inicio", this.state.dt_inicio.format("YYYY/MM/DD"));
                item.append("dt_termino", this.state.dt_termino.format("YYYY/MM/DD"));
                if (this.state.hr_inicio)
                    item.append("hr_inicio", this.state.hr_inicio.format("HH:mm"));
                if (this.state.hr_termino)
                    item.append("hr_termino", this.state.hr_termino.format("HH:mm"));
                item.append("todo_dia", this.state.todo_dia);
                item.append("cor_fundo", this.state.cor_fundo);
                item.append("descricao", this.state.descricao);
                item.append("participantes", JSON.stringify(this.state.participantes));

                axios({
                    method: "post",
                    url: "/api/eventos/adicionar",
                    data: item
                })
                    .then(response => {
                        this.setState({ iconLoading: false });
                        this.props.adicionarEvento({
                            id: response.data,
                            title: this.state.titulo,
                            start: this.state.hr_inicio ? new Date(`${this.state.dt_inicio.format("YYYY/MM/DD")} ${this.state.hr_inicio.format("HH:mm")}`) : new Date(`${this.state.dt_inicio.format("YYYY/MM/DD")}`),
                            end: this.state.hr_termino ? new Date(`${this.state.dt_termino.format("YYYY/MM/DD")} ${this.state.hr_termino.format("HH:mm")}`) : new Date(`${this.state.dt_termino.format("YYYY/MM/DD")}`),
                            allDay: this.state.todo_dia,
                            cor: this.state.cor_fundo,
                            descricao: this.state.descricao,
                            autor: true
                        })
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível adicionar o evento!");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    }

    detalhe = eventoId => {
        axios({
            method: "get",
            url: "/api/eventos/detalhe",
            params: {
                eventoId
            }
        })
            .then(response => {
                let evento = response.data;
                this.setState({
                    titulo: evento.titulo,
                    dt_inicio: moment(evento.dt_inicio),
                    dt_termino: moment(evento.dt_termino),
                    todo_dia: response.data.todo_dia,
                    hr_inicio: evento.hr_inicio ? moment(evento.hr_inicio, "HH:mm") : "",
                    hr_termino: evento.hr_termino ? moment(evento.hr_termino, "HH:mm") : "",
                    cor_fundo: evento.cor_fundo,
                    descricao: evento.descricao ? evento.descricao : "",
                    participantes: evento.participantes
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar o evento!");
            });
    }

    alterar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                let item = new FormData();
                item.append("id", this.props.eventoId);
                item.append("titulo", this.state.titulo);
                item.append("dt_inicio", this.state.dt_inicio.format("YYYY/MM/DD"));
                item.append("dt_termino", this.state.dt_termino.format("YYYY/MM/DD"));
                if (this.state.hr_inicio)
                    item.append("hr_inicio", this.state.hr_inicio.format("HH:mm"));
                if (this.state.hr_termino)
                    item.append("hr_termino", this.state.hr_termino.format("HH:mm"));
                item.append("todo_dia", this.state.todo_dia);
                item.append("cor_fundo", this.state.cor_fundo);
                item.append("descricao", this.state.descricao);
                item.append("participantes", JSON.stringify(this.state.participantes));

                axios({
                    method: "put",
                    url: "/api/eventos/alterar",
                    data: item
                })
                    .then(response => {
                        this.setState({ iconLoading: false });
                        this.props.atualizarEvento({
                            id: this.props.eventoId,
                            title: this.state.titulo,
                            start: this.state.hr_inicio ? new Date(`${this.state.dt_inicio.format("YYYY/MM/DD")} ${this.state.hr_inicio.format("HH:mm")}`) : new Date(`${this.state.dt_inicio.format("YYYY/MM/DD")}`),
                            end: this.state.hr_termino ? new Date(`${this.state.dt_termino.format("YYYY/MM/DD")} ${this.state.hr_termino.format("HH:mm")}`) : new Date(`${this.state.dt_termino.format("YYYY/MM/DD")}`),
                            allDay: this.state.todo_dia,
                            cor: this.state.cor_fundo,
                            descricao: this.state.descricao,
                            autor: true
                        })
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível alterar o evento!");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    }

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            if (this.props.eventoId) {
                this.detalhe(this.props.eventoId)
            }
            else {
                this.setState({
                    titulo: "",
                    dt_inicio: "",
                    dt_termino: "",
                    hr_inicio: "",
                    hr_termino: "",
                    todo_dia: false,
                    cor_fundo: "",
                    descricao: "",
                    participantes: [],
                    //LOADING
                    iconLoading: false,
                    //DRAWER PARTICIPANTES
                    visibleParticipantes: false
                })
            }
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const {
            titulo,
            dt_inicio,
            dt_termino,
            hr_termino,
            hr_inicio,
            todo_dia,
            cor_fundo,
            descricao,
            participantes,
            //LOADING
            iconLoading,
            //DRAWER PARTICIPANTES
            visibleParticipantes
        } = this.state;
        const {
            visible,
            eventoId
        } = this.props;

        return (
            <>
                <Drawer
                    className="drawer-adicionar-evento"
                    //title={eventoId ? "Alterar" : "Adicionar"}
                    width={720}
                    onClose={this.onClose}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Titulo">
                                    {getFieldDecorator("titulo", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: titulo
                                    })(<Input name="titulo" onChange={this.handleChange} />)}
                                </Form.Item>
                                <Form.Item label="Inicio">
                                    {getFieldDecorator("dt_inicio", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: dt_inicio
                                    })(
                                        <DatePicker
                                            className="input-25"
                                            placeholder="Selecionar"
                                            format="DD-MM-YYYY"
                                            name="dt_fimAula"
                                            onChange={this.handleChangeDataInicio}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Término">
                                    {getFieldDecorator("dt_termino", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: dt_termino
                                    })(
                                        <DatePicker
                                            className="input-25"
                                            placeholder="Selecionar"
                                            format="DD-MM-YYYY"
                                            name="dt_fimAula"
                                            onChange={this.handleChangeDataTermino}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator("todo_dia", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: todo_dia,
                                        valuePropName: 'checked'
                                    })(
                                        <Checkbox name="todo_dia" onChange={this.handlerChangeCheckbox}>
                                            Todo o dia
                                                </Checkbox>
                                    )}
                                </Form.Item>
                                <Form.Item label="Hora Inicio">
                                    {getFieldDecorator("hr_inicio", {
                                        rules: [
                                            {
                                                required: !todo_dia,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: hr_inicio
                                    })(
                                        <TimePicker
                                            className="input-25"
                                            name="duracaoAula"
                                            defaultOpenValue={moment("00:00", "HH:mm")}
                                            format="HH:mm"
                                            onChange={this.handleChangeHoraInicio}
                                            placeholder="Selecionar"
                                            suffixIcon={false}
                                            clearIcon={false}
                                            disabled={todo_dia}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Hora Término">
                                    {getFieldDecorator("hr_termino", {
                                        rules: [
                                            {
                                                required: !todo_dia,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: hr_termino
                                    })(
                                        <TimePicker
                                            className="input-25"
                                            name="duracaoAula"
                                            defaultOpenValue={moment("00:00", "HH:mm")}
                                            format="HH:mm"
                                            onChange={this.handleChangeHoraTermino}
                                            placeholder="Selecionar"
                                            suffixIcon={false}
                                            clearIcon={false}
                                            disabled={todo_dia}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Cor de fundo">
                                    {getFieldDecorator("cor_fundo", {
                                        rules: [{
                                            required: true,
                                            message: "Campo obrigatório"
                                        }],
                                        initialValue: cor_fundo
                                    })(
                                        <CirclePicker
                                            color={cor_fundo}
                                            colors={['#29313E', '#696969', '#778899', '#C0C0C0', '#3A75AF', '#6495ED', '#87CEFA', '#5F9EA0', '#D0E5DE', '#2F4F4F', '#006400', '#3CB371', '#9FCD46', '#8B4513', '#A0522D', '#CD853F', '#800000', '#FF6347', '#D2691E', '#FFD700', '#8A2BE2', '#EE82EE', '#FAEBD7']}
                                            onChange={this.handleChangeColorPickerFundo}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Descrição">
                                    <TextArea
                                        name="descricao"
                                        rows={4}
                                        style={{ height: 100 }}
                                        value={descricao}
                                        onChange={this.handleChange}
                                    />
                                </Form.Item>
                            </div>
                            <div className="bloco">
                                <h3 className="titulo-separador titulo-com-botao">
                                    Participantes
                                    <button className="botao-principal" onClick={() => this.setState({ visibleParticipantes: true })}>
                                        <Icon type="plus" />
                                    </button>
                                </h3>
                                <Form.Item className="form-item-checkbox">
                                    <div className="lista-participantes" style={{ marginLeft: 50 }}>
                                        <Form.Item className="form-item-checkbox">
                                            {participantes.length > 0 ?
                                                participantes.map((participante, index) => (
                                                    <div key={index} className="participante">
                                                        <div className="info-participante">
                                                            <div className="container-img">
                                                                <img src={participante.foto ? participante.foto : noimage} />
                                                            </div>
                                                            <div className="info">
                                                                <span className="nome">
                                                                    {participante.nome}
                                                                </span>
                                                                <p className="numero">{participante.numero}</p>
                                                            </div>
                                                        </div>
                                                        <div className="opcoes">
                                                            <button className="botao-excluir" onClick={() => this.setState({ participantes: [...this.state.participantes.filter(x => x.id !== participante.id)] })}>
                                                                <Icon type="delete" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                <span className="sem-registos">Sem dados</span>
                                            }
                                        </Form.Item>
                                    </div>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        <button className="botao-principal" onClick={eventoId ? this.alterar : this.criar} disabled={iconLoading} type="primary">
                            {iconLoading ? <Icon type="loading" /> : null}
                            Guardar
                        </button>
                        {/*{eventoId ? (*/}
                        {/*    <button className="botao-principal" onClick={this.alterar} disabled={iconLoading} type="primary">*/}
                        {/*        {iconLoading ? <Icon type="loading" /> : null}*/}
                        {/*        Guardar*/}
                        {/*    </button>*/}
                        {/*) : (*/}
                        {/*    <button className="botao-principal" onClick={this.criar} disabled={iconLoading} type="primary">*/}
                        {/*        {iconLoading ? <Icon type="loading" /> : null}*/}
                        {/*        Criar*/}
                        {/*    </button>*/}
                        {/*)}*/}
                    </div>
                </Drawer>
                <DrawerPesquisar
                    onClose={() => this.setState({ visibleParticipantes: false })}
                    participantes={participantes}
                    visibleParticipantes={visibleParticipantes}
                    selecionarParticipante={participante => this.setState({ participantes: [...participantes, participante] })}
                />
            </>
        );
    }
}

const FormDrawerAdicionar = Form.create({ name: "form-drawer-adicionar" })(DrawerAdicionar);

export default FormDrawerAdicionar;