import React, { Component } from "react";
import { Drawer, Form, Icon, notification, Upload, Tooltip } from "antd";
import axios from "axios";
import noimage from "../../../images/noimage.png";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerCertificacao extends Component {
    state = {
        ficheiro: [],
        //LOADING
        iconLoading: false
    }

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoFicheiro = formato => {
        var formatos = ["pdf"];
        var valido = false;

        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) valido = true;
        }

        return valido;
    };

    //ATUALIZA O MODULO
    guardar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    iconLoading: true
                })

                var documento = "";
                var documento_nome = "";
                var item = new FormData();
                item.append("id_utilizador", this.props.utilizadorId);
                item.append("id_curso", this.props.cursoid);
                this.state.ficheiro.forEach(file => {
                    item.append("ficheiro", file);
                    documento = URL.createObjectURL(file);
                    documento_nome = file.name;
                });

                axios({
                    method: "put",
                    url: "/api/gestao-curso-resultados/alterar-certificacao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", this.props.documento ? "Certificado alterado!" : "Certificado adicionado!");
                        this.setState({ iconLoading: false });
                        this.props.onClose();
                        this.props.atualizar(documento, documento_nome)
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", this.props.documento ? "Não foi possível alterar o certificado!" : "Não foi possível adicionar o certificado!");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    afterVisibleChange = aberto => {
        this.props.form.resetFields();
        this.setState({
            ficheiro: []
        })
    }

    render() {
        const {
            ficheiro,
            iconLoading
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        const Dragger = Upload.Dragger;

        const props = {
            accept: ".pdf",
            multiple: false,
            onRemove: file => {
                this.setState({
                    ficheiro: []
                })
            },
            defaultFileList: ficheiro,
            beforeUpload: file => {
                if (!this.validarFormatoFicheiro(file.name.split(".").pop().toLowerCase())) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido")
                    return false;
                }

                if (file.size > 26214400) {
                    openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload")
                    return false;
                }

                this.setState(state => ({
                    ficheiro: [file]
                }));

                return false;
            },
            ficheiro
        };

        return (
            <Drawer
                className="drawer-certificado"
                //title="Certificado"
                width={720}
                onClose={this.props.onClose}
                visible={this.props.visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <div className="bloco">
                        <div className="participante">
                            <div className="info-participante">
                                <div className="container-img">
                                    <img src={this.props.utilizadorFoto ? this.props.utilizadorFoto : noimage} />
                                </div>
                                <div className="info">
                                    <span className="nome">
                                        {this.props.utilizadorNome}
                                    </span>
                                    <p className="numero">{this.props.utilizadorCodigo}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bloco">
                        <h3 className="titulo-separador">
                            Certificado
                            {this.props.documento &&
                                <a href={this.props.documento} className="botao-icon-download margin-left" download={this.props.documento_nome}>
                                    <Icon type="download" />
                                </a>}
                            </h3>
                        <Form className="form-aula" layout="horizontal">
                            <Form.Item>
                                {getFieldDecorator("documento", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<Dragger {...props} fileList={ficheiro}>
                                    <p className="ant-upload-drag-icon">
                                        <i className="fas fa-upload" />
                                    </p>
                                    <p className="ant-upload-text">
                                        Adicionar{" "}
                                        <Tooltip title="Formato válido: .pdf">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </p>
                                    <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                </Dragger>)}
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    <button className="botao-principal" type="primary" disabled={iconLoading} onClick={this.guardar}>
                        {iconLoading ? <Icon type="loading" /> : null}
                        Guardar
                        </button>
                </div>
            </Drawer>
        );
    }
}

const FormDrawerCertificacao = Form.create({ name: "drawer-certificacao" })(DrawerCertificacao);

export default FormDrawerCertificacao;
