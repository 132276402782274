import React, { Component } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { Upload, Tooltip, notification, Menu, Dropdown, Icon, Drawer, Form, Input, Checkbox, Select, Radio, Modal, Collapse, Tree, Button, Alert } from "antd";
import DrawerHTML from "../_Aux/FormPaginaHTML";
import noimage from "../../images/noimage.png";
import { GeralContext } from "../ContextProvider";
import axios from "axios";
import moment from "moment";
import DrawerPesquisar from "./_Aux/DrawerPesquisar"
import DrawerPasta from "./_Aux/DrawerPasta"
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";

const { TreeNode } = Tree;
const { Panel } = Collapse;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Ficheiros extends Component {
    static contextType = GeralContext;
    state = {
        documentos: [],
        pastas: [],
        //DRAWER
        visible: false,
        documentoId: 0,
        nome: "",
        tipo: "UPLOAD",
        tipo_partilha: "PRIVADO",
        fileList: [],
        ficheiros: [],
        link: "",
        texto: "",
        notificar: false,
        //DRAWER PASTA
        pastaId: 0,
        visiblePasta: false,
        niveis: "",
        //DRAWER HTML
        visibleHTML: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //PARTICIANTES
        todos: false,
        participantes: [],
        //DRAWER PESQUISAR PARTICIPATES,
        visibleDrawerPesqusiar: false,
        //FILTROS
        pesquisa: "",
        excluirTodos: false,
        //MODAL LISTA PARTICIPANTES
        visibleParticipantes: false,
        carregarLista: false,
        //DRAWER DETALHE
        visibleDetalhe: false,
        detalheAutor: "",
        detalhePermissao: "",
        //MODAL MOVER FICHEIROS
        visibleMover: false,
        pastaSelecionada: [],
        //LOADING
        iconLoading: false,
        loading: false
    };

    UNSAFE_componentWillMount() {
        this.listarFicheiros();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handlerChangeCheck = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    handlerChangeCheckExcluirTodos = event => {
        this.setState({
            [event.target.name]: event.target.checked
        }, () => {
            this.listarFicheiros();
        });
    };

    handlerChangeRadio = event => {
        this.setState({
            [event.target.name]: event.target.value,
            fileList: [],
            link: "",
            texto: ""
        });
    };

    handlerChangeRadioPartilha = event => {
        this.setState({
            [event.target.name]: event.target.value,
            notificar: event.target.value === "PESSOAL" ? false : this.state.notificar
        });
    };

    listarFicheiros = () => {
        this.setState({
            loading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/curso-fichs/listar",
                params: {
                    cursoId: localStorage.getItem("codigo_curso"),
                    pesquisa: this.state.pesquisa,
                    excluirTodos: this.state.excluirTodos
                }
            })
                .then(response => {
                    var documentos = this.montarListaFicheiros(response.data.ficheiros);
                    var pastas = response.data.pastas;

                    this.setState({
                        documentos,
                        pastas,
                        loading: false
                    });
                })
                .catch(error => {
                    if (error.response.status === 401)
                        this.setState({
                            redirect: true,
                            redirectLink: "/login"
                        });
                });
        })
    };

    montarListaFicheiros = (ficheiros) => {
        return ficheiros.map(documento => {
            return {
                id: documento.id,
                tipo: !documento.link && !documento.editor ? documento.nome.split(".").pop() : documento.link ? "LINK" : "EDITOR",
                tipo_partilha: documento.tipoPartilha,
                participante: documento.participante,
                totalParticipantes: documento.totalParticipantes,
                todos: documento.todos,
                nome: documento.nome,
                data: documento.dt_alterado
                    ? moment(documento.dt_alterado).format("DD/MM/YYYY")
                    : moment(documento.dt_criado).format("DD/MM/YYYY"),
                autor: <div className="info-autor">
                    <div className="container-img">
                        <img src={documento.autor.foto ? documento.autor.foto : noimage} />
                    </div>
                    <div className="info">
                        <span className="nome">
                            {documento.autor.nome_completo.split(" ").length > 1 ? `${documento.autor.nome_completo.split(" ")[0]} ${documento.autor.nome_completo.split(" ")[documento.autor.nome_completo.split(" ").length - 1]}` : documento.autor.nome_completo}
                        </span>
                        <p className="numero">{documento.autor.numero}</p>
                    </div>
                </div>,
                proprio: documento.proprio,
                ficheiro: documento.ficheiro,
                link: documento.link,
                editor: documento.editor
            };
        });
    }

    guardarFicheiro = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id_curso", localStorage.getItem("codigo_curso"));
                if (this.state.tipo === "UPLOAD") {
                    item.append("nome", this.state.nome + "." + this.state.fileList[0].name.split(".").pop());
                    item.append("ficheiro", this.state.fileList[0]);
                } else if (this.state.tipo === "LINK") {
                    item.append("nome", this.state.nome);
                    item.append("ficheiro", this.state.link);
                    item.append("link", true);
                } else {
                    item.append("nome", this.state.nome);
                    item.append("ficheiro", this.state.texto);
                    item.append("editor", true);
                }
                item.append("tipoPartilha", this.state.tipo_partilha);
                if (this.state.tipo_partilha === "PARTILHADO")
                    item.append("todos", this.state.todos);
                item.append("participantes", JSON.stringify(this.state.participantes));
                item.append("notificar", this.state.notificar);
                if (this.state.pastaId > 0)
                    item.append("id_pasta", this.state.pastaId);

                axios({
                    method: "post",
                    url: "/api/curso-fichs/adicionar-ficheiro",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!!");
                        this.listarFicheiros();
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    alterarFicheiro = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.state.documentoId);
                if (this.state.tipo === "UPLOAD") {
                    item.append("nome", this.state.nome + "." + this.state.fileList[0].name.split(".").pop());
                    item.append("ficheiro", this.state.fileList[0]);
                }
                else if (this.state.tipo === "LINK") {
                    item.append("nome", this.state.nome);
                    item.append("ficheiro", this.state.link);
                    item.append("link", true);
                }
                else {
                    item.append("nome", this.state.nome);
                    item.append("ficheiro", this.state.texto);
                    item.append("editor", true);
                }
                item.append("tipoPartilha", this.state.tipo_partilha);
                if (this.state.tipo_partilha === "PARTILHADO")
                    item.append("todos", this.state.todos);
                item.append("participantes", JSON.stringify(this.state.participantes));

                axios({
                    method: "put",
                    url: "/api/curso-fichs/alterar-ficheiro",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!!");
                        this.listarFicheiros();
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    carregarDetalhe = documentoId => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/curso-fichs/carregar-detalhe",
            params: {
                documentoId
            }
        })
            .then(response => {
                if (response.data.tipo === "UPLOAD")
                    this.setState({
                        documentoId,
                        nome: response.data.nome.replace("." + response.data.nome.split(".").pop(), ""),
                        tipo: response.data.tipo,
                        fileList: [],
                        ficheiros: [response.data.nome],
                        tipo_partilha: response.data.tipo_partilha,
                        todos: response.data.todos,
                        participantes: response.data.participantes,
                        visible: true
                    });
                else if (response.data.tipo === "LINK")
                    this.setState({
                        documentoId,
                        nome: response.data.nome.replace("." + response.data.nome.split(".").pop(), ""),
                        tipo: response.data.tipo,
                        link: response.data.ficheiro,
                        tipo_partilha: response.data.tipo_partilha,
                        todos: response.data.todos,
                        participantes: response.data.participantes,
                        visible: true
                    });
                else
                    this.setState({
                        documentoId,
                        nome: response.data.nome.replace("." + response.data.nome.split(".").pop(), ""),
                        tipo: response.data.tipo,
                        texto: response.data.ficheiro,
                        tipo_partilha: response.data.tipo_partilha,
                        todos: response.data.todos,
                        participantes: response.data.participantes,
                        visible: true
                    });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar o documento.");
                this.setState({ iconLoading: false });
            });
    };

    excluir = documentoId => {
        confirm({
            title: "Pretende excluir este registo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/curso-fichs/excluir-ficheiro",
                    params: {
                        documentoId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Registo excluido!");
                        this.listarFicheiros();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir este registo!");
                    });
            }
        });
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoFicheiro = formato => {
        var formatos = [
            "zip",
            "rar",
            "7z",
            "docx",
            "doc",
            "pdf",
            "odf",
            "txt",
            "rtf",
            "xlsx",
            "xls",
            "csv",
            "ods",
            "pptx",
            "ppt",
            "odp",
            "bmp",
            "gif",
            "png",
            "jpeg",
            "jpg"
        ];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    validar = (rule, value, callback) => {
        if (value.file) {
            if (value.file.size > 26214400) callback("Limite de 25 MB por upload!");
            if (!this.validarFormatoFicheiro(value.file.name.split(".").pop().toLowerCase()) && value.file.status !== "removed")
                callback("Ficheiro com formato inválido!");
            if (value.file.status === "removed") callback("Campo obrigatório");
        }

        callback();
    };

    //DRAWER
    onClose = () => {
        this.setState({
            visible: false
        });
    };

    onCloseDrawerHTML = () => {
        this.setState(
            {
                texto: localStorage.getItem("editor_conteudo") ? localStorage.getItem("editor_conteudo") : "",
                visibleHTML: false
            },
            () => localStorage.removeItem("editor_conteudo")
        );
    };

    HandleKeyPress = e => {
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa: e.target.value
                },
                () => {
                    this.listarFicheiros();
                }
            );
        }
    };

    //LIMPA A CAIXA DE PESQUISA E LISTA NOVAMENTE O TOTAL DE TICKETS
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: ""
            },
            () => {
                this.listarFicheiros();
            }
        );
    };

    adicionar = (pastaId) => {
        this.props.form.resetFields();
        this.setState({
            visible: true,
            documentoId: 0,
            pastaId,
            nome: "",
            tipo: "UPLOAD",
            tipo_partilha: "PESSOAL",
            fileList: [],
            ficheiros: [],
            link: "",
            texto: "",
            notificar: false,
            //PARTICIANTES
            todos: true,
            participantes: [],
        });
    }

    adicionarPasta = (niveis) => {
        this.setState({
            visiblePasta: true,
            pastaId: 0,
            niveis
        });
    }

    excluirPasta = pastaId => {
        confirm({
            title: "Pretende excluir esta pasta?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/curso-fichs/excluir-pasta",
                    params: {
                        pastaId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Pasta excluida!");
                        this.listarFicheiros();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir esta pasta!");
                    });
            }
        });
    };

    atualizarListagem = () => {
        this.setState({
            visiblePasta: false
        }, () => this.listarFicheiros())
    }

    listarParticipantes = documentoId => {
        this.setState({
            visibleParticipantes: true,
            carregarLista: true
        })
        axios({
            method: "get",
            url: "/api/curso-fichs/listar-participantes",
            params: {
                documentoId
            }
        })
            .then(response => {
                this.setState({
                    participantes: response.data,
                    carregarLista: false
                })
            })
            .catch(() => { });
    };

    abrirDetalhe = (autor, partilha) => {
        this.setState({
            visibleDetalhe: true,
            detalheAutor: autor,
            detalhePermissao: partilha
        })
    }

    downloadFicheiro = (documentoId) => {
        axios({
            method: "post",
            url: "/api/curso-fichs/download-ficheiro",
            params: {
                documentoId,
                cursoId: localStorage.getItem("codigo_curso"),
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute(
                    'download',
                    response.data.nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                if (error.response.data === "SEM_PERMISSAO")
                    this.setState({
                        redirect: true,
                        redirectLink: "/user"
                    });
                else if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                else
                    openNotificationWithIcon("error", "Erro", error.response.data);

                this.setState({ iconLoading: false });
            });
    }

    abrirMoverFicheiros = (documentoId) => {
        this.setState({
            documentoId,
            visibleMover: true
        })
    }

    moverFicheiro = () => {
        axios({
            method: "put",
            url: "/api/curso-fichs/mover-ficheiro",
            params: {
                pastaId: this.state.pastaSelecionada[0],
                documentoId: this.state.documentoId
            }
        })
            .then(response => {
                openNotificationWithIcon("success", "Sucesso", "Registo movido!");
                this.setState({
                    visibleMover: false
                })
                this.listarFicheiros();
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível mover este registo!");
            });
    }

    montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (<Link to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar} className="botao-principal" onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)} style={{ marginRight: 10 }}>
                Voltar
            </Link>);
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { Option } = Select;
        const Dragger = Upload.Dragger;
        const {
            documentos,
            pastas,
            //DRAWER
            documentoId,
            nome,
            tipo,
            tipo_partilha,
            fileList,
            ficheiros,
            link,
            texto,
            notificar,
            //DRAWER PASTA
            pastaId,
            visiblePasta,
            niveis,
            //DRAWER HTML
            visibleHTML,
            //REDIRECT
            redirect,
            redirectLink,
            //PARTICIANTES
            todos,
            participantes,
            //DRAWER PESQUISAR PARTICIPATES,
            visibleDrawerPesqusiar,
            //FILTROS
            pesquisa,
            excluirTodos,
            //MODAL LISTA PARTICIPANTES
            visibleParticipantes,
            carregarLista,
            //DRAWER DETALHE
            visibleDetalhe,
            detalheAutor,
            detalhePermissao,
            //MODAL MOVER FICHEIROS
            visibleMover,
            pastaSelecionada,
            //LOADING
            iconLoading,
            loading
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const props = {
            accept:
                ".zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg",
            multiple: false,
            onRemove: file => {
                this.props.form.resetFields("ficheiro");
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    return {
                        fileList: newFileList
                    };
                });
            },
            defaultFileList: this.state.fileList,
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file]
                }));

                return false;
            },
            fileList
        };

        const montar_tipo = tipo => {
            if (tipo === "UPLOAD") {
                return (
                    <Form.Item label="Ficheiro">
                        <div className="anexos">
                            {getFieldDecorator("ficheiro", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    },
                                    {
                                        validator: this.validar
                                    }
                                ],
                                initialValue: fileList
                            })(
                                <Dragger {...props}>
                                    <p className="ant-upload-drag-icon">
                                        <i className="fas fa-upload" />
                                    </p>
                                    <p className="ant-upload-text">
                                        {documentoId > 0 ? "Substituir anexo" : "Adicionar anexo"}{" "}
                                        <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </p>
                                    <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                </Dragger>
                            )}
                        </div>
                    </Form.Item>
                );
            } else if (tipo === "LINK") {
                return (
                    <Form.Item label="Link">
                        {getFieldDecorator("link", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: link
                        })(<Input name="link" onChange={this.handleChange} />)}
                    </Form.Item>
                );
            } else {
                return (
                    <Form.Item>
                        {getFieldDecorator("texto", {
                            rules: [
                                {
                                    required: this.state.texto === "",
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: this.state.texto
                        })(
                            <>
                                <h3 className="titulo-separador">Editor Web</h3>
                                {this.state.texto ? (
                                    <Link
                                        to="#"
                                        className="link-principal"
                                        style={{ paddingLeft: 0 }}
                                        onClick={() => this.setState({ visibleHTML: true })}
                                    >
                                        Editar
                                    </Link>
                                ) : (
                                    <Link
                                        to="#"
                                        className="link-principal"
                                        style={{ paddingLeft: 0 }}
                                        onClick={() => this.setState({ visibleHTML: true })}
                                    >
                                        Adicionar
                                    </Link>
                                )}
                            </>
                        )}
                    </Form.Item>
                );
            }
        };

        const montar_icon = tipo => {
            if (tipo === "LINK") {
                return (
                    <div className="start icon-tipo-ficheiro" title="Link">
                        <img
                            src={require("../../images/icons/link.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "EDITOR") {
                return (
                    <div className="start icon-tipo-ficheiro" title="Editor">
                        <img
                            src={require("../../images/icons/html.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "pdf") {
                return (
                    <div className="start icon-tipo-ficheiro" title="Editor">
                        <img
                            src={require("../../images/icons/pdf.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "zip" || tipo === "rar" || tipo === "7z") {
                return (
                    <div className="start icon-tipo-ficheiro" title="Ficheiro">
                        <img
                            src={require("../../images/icons/compress.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "docx" || tipo === "doc" || tipo === "odf") {
                return (
                    <div className="start icon-tipo-ficheiro" title="Word">
                        <img
                            src={require("../../images/icons/word.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "txt" || tipo === "rtf") {
                return (
                    <div className="start icon-tipo-ficheiro" title="Word">
                        <img
                            src={require("../../images/icons/text.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "xlsx" || tipo === "xls" || tipo === "csv" || tipo === "ods") {
                return (
                    <div className="start icon-tipo-ficheiro" title="Word">
                        <img
                            src={require("../../images/icons/excel.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "pptx" || tipo === "ppt" || tipo === "odp") {
                return (
                    <div className="start icon-tipo-ficheiro" title="Word">
                        <img
                            src={require("../../images/icons/powerpoint.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else {
                return (
                    <div className="start icon-tipo-ficheiro" title="Word">
                        <img
                            src={require("../../images/icons/images.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            }
        };

        const montarTipoPartilha = (documento) => {
            if (documento.tipo_partilha === "PESSOAL")
                return (<i className="fas fa-user icon-tipo-partilha pessoal" title="Pessoal"></i>);
            else if (documento.tipo_partilha === "INSTRUTORES")
                return (<i className="fas fa-user-friends icon-tipo-partilha partilhado-instrutor" title="Partilhado com os professores" ></i>);
            else {
                if (documento.totalParticipantes === 1 && !documento.todos) {
                    const utilizador = documento.participante;

                    return (<div className="info-autor">
                        <div className="container-img">
                            <img src={utilizador.foto ? utilizador.foto : noimage} />
                        </div>
                        <div className="info">
                            <span className="nome">
                                {utilizador.nome_completo.split(" ").length > 1 ? `${utilizador.nome_completo.split(" ")[0]} ${utilizador.nome_completo.split(" ")[utilizador.nome_completo.split(" ").length - 1]}` : utilizador.nome_completo}
                            </span>
                            <p className="numero">{utilizador.numero}</p>
                        </div>
                    </div>)
                }
                else
                    return (<Link to="#" className="link-model-participantes" onClick={() => this.listarParticipantes(documento.id)}>
                        <i className="fas fa-users icon-tipo-partilha partilhado" title="Partilhado com a turma"></i>
                    </Link>)
            }
        };

        const montarFicheiros = (documentos) => {
            return documentos.map((documento, index) => (
                <div key={index} className={`content ${pastas.length ? "content-separador" : ""}`}>
                    <span className="desc">{montar_icon(documento.tipo)}</span>
                    <span className="desc">{documento.nome}</span>
                    <span className="desc">{documento.data}</span>
                    <span className="desc">{documento.autor}</span>
                    <span className="desc">{(!documento.todos || documento.todos && documento.totalParticipantes > 0) && montarTipoPartilha(documento)}</span>
                    <span className="desc box-ficheiros-opcoes">
                        {documento.proprio && !this.context.fechado ?
                            <>
                                {montarBotao(documento)}
                                <Link className="botao-icon-editar" to="#" onClick={() => this.carregarDetalhe(documento.id)} title="Editar">
                                    <Icon type="edit" />
                                </Link>
                                <Link className="botao-icon-mover" to="#" onClick={() => this.abrirMoverFicheiros(documento.id)} title="Mover">
                                    <Icon type="drag" />
                                </Link>
                                <Link className="botao-icon-excluir" to="#" onClick={() => this.excluir(documento.id)} title="Excluir">
                                    <Icon type="delete" />
                                </Link>
                            </>
                            :
                            montarBotao(documento)
                        }
                    </span>
                </div>
            ))
        }

        const montarPastas = (pastas, paiId) => {
            const pastaNivel = pastas.filter(x => x.pastaPai === paiId)

            if (pastaNivel.length)
                return (<div className="content-collapse">
                    <Collapse className="bloco-collapse" defaultActiveKey={[]}>
                        {pastaNivel.map((pasta, index) => {
                            const pastasFilho = pastas.filter(x => x.pastaPai === pasta.id)
                            return (<Panel key={index} header={
                                <div className="bloco-panel">
                                    <div className="pasta-desc">
                                        <div className="icon-container" title="Link">
                                            <img
                                                className="icon"
                                                src={require("../../images/icons/pasta.png")}
                                                style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                                            />
                                        </div>
                                        <span className="titulo">{pasta.nome}</span>
                                    </div>
                                    <div className="pasta-controlos">
                                        <Link className="botao-icon-adicionar" to="#" title="Adicionar pasta" onClick={(e) => { this.adicionarPasta(pasta.niveis); e.stopPropagation(); }}>
                                            <Icon type="folder-add" />
                                        </Link>
                                        <Link className="botao-icon-adicionar" to="#" title="Adicionar ficheiro" onClick={(e) => { this.adicionar(pasta.id); e.stopPropagation(); }}>
                                            <Icon type="file-add" />
                                        </Link>
                                        {pasta.autor && !this.context.fechado ?
                                            <>
                                                <Link className="botao-icon-editar" to="#" title="Editar" onClick={(e) => { this.setState({ pastaId: pasta.id, visiblePasta: true }); e.stopPropagation(); }}>
                                                    <Icon type="edit" />
                                                </Link>
                                                <Link className="botao-icon-excluir" to="#" title="Excluir" onClick={(e) => { this.excluirPasta(pasta.id); e.stopPropagation(); }}>
                                                    <Icon type="delete" />
                                                </Link>
                                            </>
                                            : null}
                                    </div>
                                </div>
                            }>
                                {!pasta.ficheiros.length && !pastasFilho.length ?
                                    <div className="sem-registos">Não existem dados!</div>
                                    : null}
                                {pastasFilho.length ?
                                    montarPastas(pastas, pasta.id)
                                    : null}
                                {pasta.ficheiros.length ?
                                    montarFicheiros(this.montarListaFicheiros(pasta.ficheiros))
                                    : null}
                            </Panel>)
                        })}
                    </Collapse>
                </div>)
        }

        const montarPastasMover = (pastas, paiId) => {
            const pastaNivel = pastas.filter(x => x.pastaPai === paiId)

            if (pastaNivel.length)
                return pastaNivel.map((pasta, index) => {
                    const pastasFilho = pastas.filter(x => x.pastaPai === pasta.id)
                    if (pastasFilho.length)
                        return (<TreeNode
                            checkable={false}
                            title={<div className="pasta-desc">
                                <div className="icon-container" title="Link">
                                    <img
                                        className="icon"
                                        src={require("../../images/icons/pasta.png")}
                                        style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                                    />
                                </div>
                                <span className="titulo">{pasta.nome}</span>
                            </div>}
                            key={pasta.id}
                        >
                            {montarPastasMover(pastas, pasta.id)}
                        </TreeNode>)
                    else
                        return (<TreeNode
                            checkable={false}
                            title={<div className="pasta-desc">
                                <div className="icon-container" title="Link">
                                    <img
                                        className="icon"
                                        src={require("../../images/icons/pasta.png")}
                                        style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                                    />
                                </div>
                                <span className="titulo">{pasta.nome}</span>
                            </div>}
                            key={pasta.id}
                        />)
                })
        }

        const montarFicheirosMobile = (documentos) => {
            return documentos.map((documento, index) => (
                <div key={index} className={`content ${pastas.length ? "content-separador" : ""}`}>
                    <span className="desc">
                        <div className="box-ficheiro">
                            {montar_icon(documento.tipo)}
                            <div className="info-ficheiro">
                                <span className="nome-ficheiro">
                                    {documento.nome}
                                </span>
                                <div className="data-ficheiro">
                                    {documento.data}
                                </div>
                            </div>
                        </div>
                    </span>
                    <span className="desc box-ficheiros-opcoes">
                        {documento.proprio && !this.context.fechado ?
                            <Dropdown overlay={opcoesMobile(documento)} placement="bottomLeft">
                                <Link to="#" className="botao-icon-configs">
                                    <i className="fas fa-cog" />
                                </Link>
                            </Dropdown>
                            :
                            montarBotao(documento)
                        }
                    </span>
                </div>
            ))
        }

        const montarPastasMobile = (pastas, paiId) => {
            const pastaNivel = pastas.filter(x => x.pastaPai === paiId)

            if (pastaNivel.length)
                return (<div className="content-collapse">
                    <Collapse className="bloco-collapse" defaultActiveKey={[]}>
                        {pastaNivel.map((pasta, index) => {
                            const pastasFilho = pastas.filter(x => x.pastaPai === pasta.id)
                            return (<Panel key={index} header={
                                <div className="bloco-panel">
                                    <div className="pasta-desc">
                                        <div className="icon-container" title="Link">
                                            <img
                                                className="icon"
                                                src={require("../../images/icons/pasta.png")}
                                                style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                                            />
                                        </div>
                                        <span className="titulo">{pasta.nome}</span>
                                    </div>
                                    <div className="pasta-controlos">
                                        <Dropdown overlay={opcoesPastaMobile(pasta)} onClick={(e) => e.stopPropagation()} placement="bottomLeft">
                                            <Link to="#" className="botao-icon-configs">
                                                <i className="fas fa-cog" />
                                            </Link>
                                        </Dropdown>
                                    </div>
                                </div>
                            }>
                                {!pasta.ficheiros.length && !pastasFilho.length ?
                                    <div className="sem-registos">Não existem dados!</div>
                                    : null}
                                {pastasFilho.length ?
                                    montarPastasMobile(pastas, pasta.id)
                                    : null}
                                {pasta.ficheiros.length ?
                                    montarFicheirosMobile(this.montarListaFicheiros(pasta.ficheiros))
                                    : null}
                            </Panel>)
                        })}
                    </Collapse>
                </div>)
        }

        const montarBotao = (documento) => {
            if (!documento.link && !documento.editor) {
                return (<Link className="botao-icon-download" to="#" onClick={() => this.downloadFicheiro(documento.id)} title="Descarregar">
                    <Icon type="download" />
                </Link>)
            }
            else if (documento.link) {
                return (<a className="botao-icon-aceder" href={documento.ficheiro} target="_blank" rel="noopener noreferrer" title="Acessar">
                    <Icon type="login" />
                </a>)
            }
            else {
                return (<Link
                    className="botao-icon-aceder"
                    to={`/curso/${this.props.match.params.curso
                        .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                        .toLowerCase()}/${documento.nome
                            .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                            .toLowerCase()}/player-ficheiro`}
                    onClick={() => localStorage.setItem("codigo_documento", documento.id)}
                    title="Acessar"
                >
                    <Icon type="login" />
                </Link>)
            }
        }

        const opcoesMobile = documento => {
            return (
                <Menu>
                    {!documento.link && !documento.editor && (
                        <Menu.Item>
                            <Link to="#" onClick={() => this.downloadFicheiro(documento.id)}>
                                Descarregar
                                </Link>
                        </Menu.Item>
                    )}
                    {documento.link && (
                        <Menu.Item>
                            <a href={documento.ficheiro} target="_blank" rel="noopener noreferrer">
                                Acessar
                                </a>
                        </Menu.Item>
                    )}
                    {documento.editor && (
                        <Menu.Item>
                            <Link
                                to={`/gerir-curso/${this.props.match.params.curso
                                    .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                    .toLowerCase()}/${documento.nome
                                        .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                        .toLowerCase()}/player-ficheiro`}
                                onClick={() => localStorage.setItem("codigo_documento", documento.id)}
                            >
                                Acessar
                                </Link>
                        </Menu.Item>
                    )}
                    <Menu.Item>
                        <Link to="#" onClick={() => this.carregarDetalhe(documento.id)}>
                            Editar
                                </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirMoverFicheiros(documento.id)}>
                            Mover
                                </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.excluir(documento.id)}>
                            Excluir
                                </Link>
                    </Menu.Item>
                </Menu>
            );
        };

        const opcoesPastaMobile = pasta => {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={(e) => { this.adicionarPasta(pasta.niveis); e.stopPropagation(); }}>
                            Adicionar pasta
                                </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={(e) => { this.adicionar(pasta.id); e.stopPropagation(); }}>
                            Adicionar ficheiro
                                </Link>
                    </Menu.Item>
                    {pasta.autor && !this.context.fechado ?
                        <Menu.Item>
                            <Link to="#" onClick={(e) => { this.setState({ pastaId: pasta.id, visiblePasta: true }); e.stopPropagation(); }}>
                                Editar
                                </Link>
                        </Menu.Item>
                        : null}
                    <Menu.Item>
                        <Link to="#" onClick={(e) => { this.excluirPasta(pasta.id); e.stopPropagation(); }}>
                            Excluir
                                </Link>
                    </Menu.Item>
                </Menu>
            );
        };

        return (
            <div className="container container-body">
                <div className="curso-ficheiros">
                    <div className="controlos controlos-fundo controlos-desktop">
                        <div className="controlos-opcoes">
                            {this.montarBotaoVoltar()}
                            {this.context.fechado ?
                                <>
                                    <button className="botao-principal botao-disabled" disabled style={{ marginRight: 10 }}>
                                        Nova pasta
                                    </button>
                                    <button className="botao-principal botao-disabled" disabled style={{ marginRight: 10 }}>
                                        Novo documento
                                    </button>
                                </>
                                :
                                <>
                                    <button className="botao-principal" onClick={() => this.adicionarPasta("")} style={{ marginRight: 10 }}>
                                        Nova pasta
                                    </button>
                                    <button className="botao-principal" onClick={() => this.adicionar(0)} style={{ marginRight: 10 }}>
                                        Novo documento
                                    </button>
                                </>
                            }
                        </div>
                        <div className="controlos-opcoes controlo-search">
                            <BotaoProcurar
                                HandleKeyPress={this.HandleKeyPress}
                                resetCaixaProcura={this.resetCaixaProcura}
                                tem_placeholder
                                placeholder="Procurar no Nome ou Autor"
                            />
                        </div>
                        <div className="controlos-opcoes controlo-checkbox">
                            <Checkbox name="excluirTodos" checked={excluirTodos} onChange={this.handlerChangeCheckExcluirTodos}>
                                Excluir Partilha com Todos
                                    </Checkbox>
                        </div>
                    </div>
                    <div className="controlos controlos-fundo controlos-mobile">
                        <div className="bloco-controlos-cima">
                            <div className="controlos-opcoes controlo-search">
                                <BotaoProcurar
                                    HandleKeyPress={this.HandleKeyPress}
                                    resetCaixaProcura={this.resetCaixaProcura}
                                    tem_placeholder
                                    placeholder="Procurar no Nome ou Autor"
                                />
                            </div>
                        </div>
                        <div className="bloco-controlos-baixo">
                            <div className="controlos-opcoes">
                                {this.context.fechado ?
                                    <>
                                        <button className="botao-principal botao-disabled" disabled style={{ marginRight: 10 }}>
                                            Nova pasta
                                    </button>
                                        <button className="botao-principal botao-disabled" disabled style={{ marginRight: 10 }}>
                                            Novo documento
                                    </button>
                                    </>
                                    :
                                    <>
                                        <button className="botao-principal" onClick={() => this.adicionarPasta("")} style={{ marginRight: 10 }}>
                                            Nova pasta
                                    </button>
                                        <button className="botao-principal" onClick={() => this.adicionar(0)} style={{ marginRight: 10 }}>
                                            Novo documento
                                    </button>
                                    </>
                                }
                            </div>
                            <div className="controlos-opcoes controlo-checkbox">
                                <Checkbox name="excluirTodos" checked={excluirTodos} onChange={this.handlerChangeCheckExcluirTodos}>
                                    Excluir Partilha com Todos
                                    </Checkbox>
                            </div>
                        </div>
                    </div>
                    <div className="box-ficheiros box-ficheiros-pastas  box-ficheiros-desktop">
                        <div className="header" style={{ paddingLeft: 40 }}>
                            <span className="desc">Nome</span>
                            <span className="desc">Modificado</span>
                            <span className="desc">Autor</span>
                            <span className="desc">Partilha</span>
                            <span className="desc"></span>
                        </div>
                        {documentos.length || pastas.length ? (
                            <>
                                {montarPastas(pastas, 0)}
                                {montarFicheiros(documentos)}
                            </>
                        ) : (
                            <div className="sem-registos">Não existem dados!</div>
                        )}
                    </div>
                    <div className="box-ficheiros box-ficheiros-pastas box-ficheiros-mobile">
                        <div className="header">
                            <span className="desc">Nome</span>
                            <span className="desc"></span>
                        </div>
                        {documentos.length || pastas.length ? (
                            <>
                                {montarPastasMobile(pastas, 0)}
                                {montarFicheirosMobile(documentos)}
                            </>
                        ) : (
                            <div className="sem-registos">Não existem dados!</div>
                        )}
                    </div>
                </div>
                <Drawer
                    className="drawer-add-cursos drawer-videoconferencias drawer-drive"
                    //title={documentoId > 0 ? "Editar" : "Adicionar"}
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Nome">
                                    {getFieldDecorator("nome", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: nome
                                    })(<Input name="nome" onChange={this.handleChange} />)}
                                </Form.Item>
                                {documentoId === 0 && (
                                    <Form.Item className="form-item-checkbox">
                                        <Radio.Group name="tipo" onChange={this.handlerChangeRadio} value={tipo}>
                                            <Radio value="UPLOAD">Upload ficheiro</Radio>
                                            <Radio value="LINK">Link</Radio>
                                            <Radio value="EDITOR">Editor</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                )}
                                {montar_tipo(tipo)}
                                {ficheiros.length ?
                                    <Form.Item>
                                        <h3 className="titulo-separador">Anexos</h3>
                                        <div className="lista-videos">
                                            {ficheiros.map((ficheiro, index) => (
                                                <div key={index} className="item-video">
                                                    <span className="video-titulo">{ficheiro}</span>
                                                    <div className="video-controlos">
                                                        <Link to="#" className="botao-icon-download" title="Descarregar" onClick={() => this.downloadFicheiro(documentoId)}>
                                                            <Icon type="download" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Form.Item>
                                    : null}
                            </div>
                            <div className="bloco">
                                <h3 className="titulo-separador titulo-com-botao">
                                    Partilhado
                                </h3>
                                <Form.Item className="form-item-checkbox">
                                    <Radio.Group name="tipo_partilha" onChange={this.handlerChangeRadioPartilha} value={tipo_partilha}>
                                        <Radio value="PESSOAL">
                                            <i className="fas fa-user icon-tipo-partilha pessoal" title="Pessoal"></i>
                                            Pessoal</Radio>
                                        <Radio value="INSTRUTORES">
                                            <i className="fas fa-user-friends icon-tipo-partilha partilhado-instrutor" title="Partilhado com os professores" ></i>
                                            Com os professores</Radio>
                                        <Radio value="PARTILHADO">
                                            <i className="fas fa-users icon-tipo-partilha partilhado" title="Partilhado com a turma"></i>
                                            Com a turma </Radio>
                                    </Radio.Group>
                                    <span className="nota-info">
                                        {tipo_partilha === "PESSOAL" ? "Documento privado" : ""}
                                        {tipo_partilha === "INSTRUTORES" ? "Documento partilhado com todos os professores e coordenadores do curso" : ""}
                                        {tipo_partilha === "PARTILHADO" ? "Documento partilhado com o aluno" : ""}
                                    </span>
                                    {tipo_partilha === "PARTILHADO" &&
                                        <div className="lista-participantes" style={{ marginLeft: 50 }}>
                                            <Form.Item className="form-item-checkbox">
                                                <Checkbox className="todos" checked={todos} name="todos" onChange={this.handlerChangeCheck}>
                                                Todos
                                            <Tooltip className="info-icon" title="Ao selecionar todos, os alunos que indicar serão considerados exceções.">
                                                        <Icon type="question-circle-o" />
                                                    </Tooltip>
                                                </Checkbox>
                                            </Form.Item>
                                            <Form.Item className="form-item-checkbox">
                                                <label className="label-participantes">
                                                    {todos ? "Exceto os seguintes" : "Incluidos os seguintes"}
                                                    <button className="botao-principal" onClick={() => this.setState({ visibleDrawerPesqusiar: true })}>
                                                        <Icon type="plus" />
                                                    </button>
                                                </label>
                                                {participantes.length > 0 ?
                                                    participantes.map((participante, index) => (
                                                        <div key={index} className="participante">
                                                            <div className="info-participante">
                                                                <div className="container-img">
                                                                    <img src={participante.foto ? participante.foto : noimage} />
                                                                </div>
                                                                <div className="info">
                                                                    <span className="nome">
                                                                        {participante.nome}
                                                                    </span>
                                                                    <p className="numero">{participante.numero}</p>
                                                                </div>
                                                            </div>
                                                            <div className="opcoes">
                                                                <button className="botao-excluir" onClick={() => this.setState({ modulo: { ...this.state.modulo, participantes: [...this.state.modulo.participantes.filter(x => x.id !== participante.id)] } })}>
                                                                    <Icon type="delete" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    <span className="sem-registos">Sem dados</span>
                                                }
                                            </Form.Item>
                                        </div>}
                                </Form.Item>
                            </div>
                            <div className="bloco">
                                <Form.Item className="form-item-checkbox">
                                    <Checkbox name="notificar" checked={notificar} disabled={tipo_partilha === "PESSOAL"} onChange={this.handlerChangeCheck}>
                                        Notificar por e-mail
                                        <Tooltip className="info-icon" title="Notificar os participantes por e-mail">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        <button className="botao-principal" disabled={iconLoading} onClick={documentoId > 0 ? this.alterarFicheiro : this.guardarFicheiro}>
                            {iconLoading ? <Icon type="loading" /> : null}
                            Guardar
                        </button>
                        {/*{documentoId > 0 ? (*/}
                        {/*    <button className="botao-principal" disabled={iconLoading} onClick={this.alterarFicheiro}>*/}
                        {/*        {iconLoading ? <Icon type="loading" /> : null}*/}
                        {/*        Guardar*/}
                        {/*    </button>*/}
                        {/*) : (*/}
                        {/*        <button className="botao-principal" disabled={iconLoading} onClick={this.guardarFicheiro}>*/}
                        {/*            {iconLoading ? <Icon type="loading" /> : null}*/}
                        {/*        Guardar*/}
                        {/*        </button>*/}
                        {/*    )}*/}
                    </div>
                </Drawer>
                <DrawerHTML visible={visibleHTML} recurso={texto} onClose={this.onCloseDrawerHTML} />
                <DrawerPesquisar
                    onClose={() => this.setState({ visibleDrawerPesqusiar: false })}
                    participantes={participantes}
                    visibleDrawerPesqusiar={visibleDrawerPesqusiar}
                    selecionarParticipante={participante => this.setState({ participantes: [...participantes, participante] })}
                />
                <DrawerPasta
                    pastaId={pastaId}
                    niveis={niveis}
                    visiblePasta={visiblePasta}
                    onClose={() => this.setState({ visiblePasta: false })}
                    atualizarListagem={this.atualizarListagem}
                />
                <Modal
                    title={<>Participantes<br /><span style={{ fontSize: 13, color: "#8e9aa0" }}>Total: {participantes.length}</span></>}
                    visible={visibleParticipantes}
                    onCancel={() => this.setState({ visibleParticipantes: false })}
                    maskClosable={false}
                    className="modal-lista-participantes"
                    footer={null}
                >
                    <div className="bloco-lista-participantes">
                        {carregarLista ? (
                            <div className="bloco-loading">
                                <p>
                                    <Icon className="icon-loading" type="loading" />
                                </p>
                                <p className="texto">A carregar...</p>
                            </div>
                        ) : (
                                <>
                                    {participantes.map((participante, index) => (
                                        <div key={index} className="info-participante">
                                            <div className="bloco-img">
                                                <img src={participante.foto ? participante.foto : noimage} />
                                            </div>
                                            <div className="info">
                                                <span className="nome">
                                                    {participante.nome}
                                                </span>
                                                <p className="numero">{participante.numero}</p>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                    </div>
                </Modal>
                <Drawer
                    className="drawer-detalhe-drive"
                    //title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Autor">
                                    {detalheAutor}
                                </Form.Item>
                                <Form.Item label="Partilha">
                                    {detalhePermissao}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
                <Modal
                    visible={loading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
                <Modal
                    title={<Alert message="selecione a pasta para onde pretende mover o ficheiro" type="info" showIcon />}
                    visible={visibleMover}
                    onCancel={() => this.setState({ visibleMover: false })}
                    maskClosable={false}
                    closable={false}
                    className="modal-mover-ficheiros"
                    footer={[
                        <Link to="#" className="btn-cancelar" onClick={() => this.setState({ visibleMover: false })}>
                            Cancelar
                        </Link>,
                        <Link to="#" className={`btn-confirmar ${!pastaSelecionada.length ? "btn-confirmar-disabled" : ""}`} disabled={!pastaSelecionada.length} onClick={this.moverFicheiro}>
                            Confirmar
                        </Link>
                    ]}
                >
                    <Tree
                        checkable
                        switcherIcon={<Icon type="down" />}
                        onSelect={(pastaSelecionada) => { this.setState({ pastaSelecionada }); }}
                    >
                        {montarPastasMover(pastas, 0)}
                    </Tree>
                </Modal>
            </div>
        );
    }
}

const FicheirosForm = Form.create({ name: "form-ficheiro" })(Ficheiros);

export default withRouter(FicheirosForm);
