import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { notification, Form, Checkbox, Row, Col, Icon } from "antd";
import Controlos from "./_Aux/Controlos";
import ControlosSimples from "./_Aux/ControlosSimples";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../GlobalState";
import LazyLoad from "react-lazy-load";
import Oops from "../Geral/Oops.jsx";
import noimage from "../images/noimage.png";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Assiduidade extends Component {
    static contextType = GlobalContext;
    state = {
        curso: "",
        modulo: "",
        moduloIndex: 0,
        aulaid: 0,
        nome: "",
        tarefaPai: "",
        objetivos: "",
        aulaIndex: 0,
        fullscreen: false,
        current: "",
        updateModulos: false,
        dataHora: "",
        falta: false,
        presenca: false,
        presencas: [],
        participantes: [],
        //REDIRECT
        redirect: false,
        redirectLink: "",
        sameUrl: true,
        //NAO DISPONIVEL
        nao_disponivel: false,
        //LOADING
        loading: true,
        iconLoading: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.context.montarNavbar(false);
        var aulaId = localStorage.getItem("codigo_aula");

        this.setState({
            curso: this.props.match.params.curso,
            aulaid: aulaId
        });

        this.CarregarConteudo(aulaId);
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            var aulaId = localStorage.getItem("codigo_aula");

            this.CarregarConteudo(aulaId);
            this.setState(
                {
                    sameUrl: false
                },
                () =>
                    this.setState({
                        sameUrl: true
                    })
            );
        }
    }

    handlerChangeCheckboxPresencaColetiva = event => {
        this.props.form.resetFields();
        this.setState({
            [event.target.name]: event.target.checked,
            falta: false,
            presencas: event.target.checked ? this.state.participantes.map(participante => participante.id) : []
        });
    };

    handlerChangeCheckboxFaltaColetiva = event => {
        this.props.form.resetFields();
        this.setState({
            [event.target.name]: event.target.checked,
            presenca: false,
            presencas: []
        });
    };

    handlerChangeCheckboxPresencas = presencas => {
        this.setState({
            falta: false,
            presenca: false,
            presencas
        });
    };

    //CARREGA A AULA
    CarregarConteudo = aulaid => {
        axios({
            method: "get",
            url: "/api/gestao-player/conteudo5",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                if (response.data.recurso) {
                    var dados = JSON.parse(response.data.recurso)

                    this.setState({
                        nome: response.data.nome,
                        tarefaPai: response.data.tarefaPai,
                        objetivos: response.data.objetivos,
                        participantes: response.data.participantes,
                        presenca: dados.presenca_coletiva,
                        falta: dados.falta_coletiva,
                        presencas: dados.presencas,
                        dataHora: moment(response.data.dataHora).format("DD/MM/YYYY HH:mm"),
                        loading: false
                    });
                }
                else {
                    this.setState({
                        nome: response.data.nome,
                        objetivos: response.data.objetivos,
                        participantes: response.data.participantes,
                        loading: false
                    });
                }
            })
            .catch(error => {
                //if (error.response.data === "SEM_PERMISSAO")
                //    this.setState({
                //        redirect: true,
                //        redirectLink: "/user"
                //    });
                //else
                    if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                this.setState({
                    nao_disponivel: true
                });
            });
    };

    guardarPresencas = () => {
        if (this.state.presenca || this.state.falta || this.state.presencas.length) {
            this.setState({ iconLoading: true })

            var item = new FormData()
            var presencas = {
                presenca_coletiva: this.state.presenca,
                falta_coletiva: this.state.falta,
                presencas: this.state.presencas
            }

            item.append("tarefaId", this.state.aulaid)
            item.append("presencas", JSON.stringify(presencas))

            axios({
                method: "put",
                url: "/api/curso-aula/guardar-presencas",
                data: item,
                timeout: 60 * 30 * 1000
            })
                .then(() => {
                    openNotificationWithIcon("success", "Sucesso", "Assiduidade guardada!")
                    this.setState({ iconLoading: false })
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível guardar assiduidade!")
                    this.setState({ iconLoading: false })
                })
        }
        else {
            openNotificationWithIcon("info", "Informação", "Não existem dados a serem guardados")
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            curso,
            nome,
            tarefaPai,
            objetivos,
            falta,
            presenca,
            presencas,
            participantes,
            dataHora,
            //REDIRECT
            redirect,
            redirectLink,
            sameUrl,
            //NAO DISPONIVEL
            nao_disponivel,
            //LOADING
            loading,
            iconLoading
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const montarHoraTermino = (horaInicial, duracao) => {
            const hora = moment(horaInicial).add(duracao.hours(), "hours").add(duracao.minutes(), "minutes");
            return hora.format("HH:mm");
        }

        return loading ? (
            <div className="loading-data">
                <div className="loading" />
            </div>
        ) : (
            <div className="questionario">
                <div className="player-wrapper">
                    {/*<LazyLoad offsetVertical={200}>*/}
                    {/*    <img src={require("../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />*/}
                    {/*</LazyLoad>*/}
                    {!nao_disponivel ? (
                        <>
                            <div className="container">
                                <div className="bloco-presencial">
                                    <div className="bloco-download-link bloco-assiduidade">
                                        <div className="bloco-info-tarefa">
                                            <span className="icon-tarefa">
                                                <img src={require("../images/icons/presencas.png")} />
                                            </span>
                                            <span className="titulo-data-tarefa">
                                                <span className="titulo-tarefa">{nome}</span>
                                                <span className="data-tarefa">{moment(tarefaPai.data).format("DD/MM/YYYY")}
                                                    {tarefaPai.hora && <><i className="fas fa-circle separador-data-hora"></i> {tarefaPai.hora} -  {montarHoraTermino(moment(tarefaPai.hora, "HH:mm"), moment(tarefaPai.duracao, "HH:mm"))}</>}</span>
                                            </span>
                                        </div>
                                        <p className="descricao-tarefa" dangerouslySetInnerHTML={{ __html: objetivos?.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></p>
                                        <div className="listagem-participantes">
                                            <Form className="form-presencas" layout="horizontal">
                                                <div className="bloco">
                                                    <Form.Item className="form-item-checkbox">
                                                        <Checkbox name="presenca" checked={presenca} onChange={this.handlerChangeCheckboxPresencaColetiva}>
                                                            Presença coletiva
                                                        </Checkbox>
                                                        <Checkbox name="falta" checked={falta} onChange={this.handlerChangeCheckboxFaltaColetiva}>
                                                            Falta coletiva
                                                        </Checkbox>
                                                    </Form.Item>
                                                </div>
                                                <div className="bloco">
                                                    <h3 className="titulo-separador">Participantes</h3>
                                                    <Form.Item>
                                                        {getFieldDecorator("presencas", {
                                                            initialValue: presencas
                                                        })(<Checkbox.Group name="competenciasGerais" className="lista-presencas" onChange={this.handlerChangeCheckboxPresencas}>
                                                            <Row>
                                                                {participantes.map((participante, index) => (
                                                                    <div className="bloco-participante">
                                                                        <div className="participante">
                                                                            <div className="info-participante">
                                                                                <div className="container-img">
                                                                                    <img src={participante.foto ? participante.foto : noimage} />
                                                                                </div>
                                                                                <div className="info">
                                                                                    <span className="nome">{participante.nome}</span>
                                                                                    <p className="numero">{participante.numero}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <Checkbox key={index} value={participante.id}>
                                                                        </Checkbox>
                                                                    </div>
                                                                ))}
                                                            </Row>
                                                        </Checkbox.Group>)}
                                                    </Form.Item>
                                                </div>
                                            </Form>
                                        </div>
                                        <Link className="botao-principal" to="#" disabled={iconLoading} onClick={this.guardarPresencas}>
                                            {iconLoading ? <Icon type="loading" /> : null}
                                            Guardar
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <Controlos
                                    backUrl={localStorage.getItem("player_back_url") ? localStorage.getItem("player_back_url") : `/gerir-curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                                sameUrl={sameUrl}
                                temAnotacoes
                                temMensagens
                                nome={nome}
                                objetivos={objetivos}
                                dataHora={dataHora}
                                icon={require("../images/icons/presencas.png")}
                            />
                        </>
                    ) : (
                        <>
                            <div className="container">
                                <div className="bloco-nao-disponivel">
                                    <div className="bloco-info nao-disponivel">
                                        <Oops tem_texto texto={"Conteúdo não disponível"} />
                                    </div>
                                </div>
                            </div>
                            <ControlosSimples />
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const FormAssiduidade = Form.create({ name: "form-assiduidade" })(Assiduidade);

export default FormAssiduidade;
