import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { Form, Input, Icon, Drawer, Radio, Upload, Select, InputNumber, notification } from "antd";
import { GlobalContext } from "../../../../GlobalState";
import DrawerFicha from "./DrawerFicha";
import axios from "axios";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerDisciplina = ({ turmaId, disciplinaId, visible, onClose, atualizar, form }) => {
    const globalContext = useContext(GlobalContext);
    const [formData, setFormData] = useState({
        nome: "",
        codigo: "",
        creditos: 1,
        horas_trabalho: 1,
        carga_horaria: 1,
        horas_contacto: [],
        fasesEscolares: [],
        //FICHAS
        fichaId: undefined,
        fichaCampos: [],
        encerramento: "Manual",
        percentagemFaltas: 0,
        ordem: 1,
    });
    const [estruturaStatus, setEstruturaStatus] = useState({
        validateStatus: "",
        help: ""
    });
    const [tiposHoras, setTiposHoras] = useState([]);
    const [tiposDiario, setTiposDiario] = useState([]);
    const [fichas, setFichas] = useState([]);
    const [visibleFicha, setVisibleFicha] = useState(false);
    const [loading, setLoading] = useState(false);

    const { getFieldDecorator } = form;
    const Dragger = Upload.Dragger;
    const { Option } = Select;
    const { TextArea } = Input;

    const handleChangeHorasContacto = (value, index) => {
        let horas_contacto = formData.horas_contacto;
        horas_contacto[index].id = value
        setFormData({ ...formData, horas_contacto });
    };

    const handleChangeHorasContactoCarga = (value, index) => {
        let horas_contacto = formData.horas_contacto;
        horas_contacto[index].carga_horaria = value
        setFormData({ ...formData, horas_contacto });
    };

    const excluirHorasContacto = (index) => {
        form.resetFields();
        let horas_contacto = formData.horas_contacto;
        horas_contacto.splice(index, 1)
        setFormData({ ...formData, horas_contacto });
    }

    const handleChangeFase = (value, index) => {
        const fase = tiposDiario.find(x => x.id === value);

        let fasesEscolares = formData.fasesEscolares;

        fasesEscolares[index].tipo = value;
        //fasesEscolares[index].periodo = fase.periodo_letivo;
        fasesEscolares[index].periodo = fase.tipo;
        //fasesEscolares[index].genero = fase.tipo;

        setFormData({ ...formData, fasesEscolares });
    };

    const excluirCampo = (index) => {
        form.resetFields();
        let fasesEscolares = formData.fasesEscolares;
        fasesEscolares.splice(index, 1)

        setFormData({ ...formData, fasesEscolares });
    }

    const detalhe = () => {
        axios({
            method: "get",
            url: "/api/gestao-templates-turmas/detalhe-disciplina",
            params: {
                disciplinaId
            }
        })
            .then(response => {
                var info = response.data;
                let horas_contacto = [];

                info.horas_Contacto.forEach(hora_contacto => {
                    horas_contacto.push({
                        id: hora_contacto.id_tipo_hora,
                        carga_horaria: hora_contacto.carga_horaria
                    })
                });

                const fasesEscolares = info.fasesEscolares.map((fase, index) => {
                    return {
                        id: fase.id,
                        tipo: fase.tipo,
                        periodo: fase.periodo,
                        genero: fase.genero
                    }
                });

                setFormData({
                    nome: info.nome,
                    codigo: info.codigo,
                    creditos: info.creditos,
                    horas_trabalho: info.horas_trabalho,
                    carga_horaria: info.carga_horaria,
                    horas_contacto,
                    fasesEscolares,
                    //FICHAS
                    fichaId: info.id_ficha,
                    fichaCampos: JSON.parse(info.ficha_campos),
                    encerramento: info.encerramento,
                    percentagemFaltas: info.limite_percentagem_falta,
                    ordem: info.ordem,
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
            });
    }

    const validarDuplicados = () => {
        var valueArr = formData.fasesEscolares.map(function (item) { return item.tipo });
        var isDuplicate = valueArr.some(function (item, idx) {
            return valueArr.indexOf(item) != idx
        });

        return isDuplicate;
    }

    const adicionar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (err || formData.fasesEscolares.length === 0) {
                setEstruturaStatus({
                    validateStatus: "error",
                    help: "Campo obrigatório"
                })
                return null;
            }

            if (validarDuplicados()) {
                openNotificationWithIcon("error", "Erro", "Estruturas de avaliação selecionadas em duplicado");

                return null;
            }

            setLoading(true);

            var item = new FormData();
            item.append("id_template_turma", turmaId);
            item.append("nome", formData.nome);
            item.append("codigo", formData.codigo);
            item.append("creditos", formData.creditos);
            item.append("horas_trabalho", formData.horas_trabalho);
            item.append("carga_horaria", formData.carga_horaria);
            item.append("horas_contacto", JSON.stringify(formData.horas_contacto));
            item.append("fasesEscolares", JSON.stringify(formData.fasesEscolares));
            if (formData.fichaId) {
                item.append("fichaId", formData.fichaId);
                item.append("fichaCampos", JSON.stringify(formData.fichaCampos));
            }
            item.append("encerramento", formData.encerramento);
            item.append("percentagemFaltas", formData.percentagemFaltas);
            item.append("ordem", formData.ordem);

            axios({
                method: "post",
                url: "/api/gestao-templates-turmas/adicionar-disciplina",
                data: item
            })
                .then(() => {
                    openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!.");
                    setLoading(false)
                    atualizar();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", error.response.data);
                    setLoading(false)
                });
        })
    }

    const alterar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (err || formData.fasesEscolares.length === 0) {
                setEstruturaStatus({
                    validateStatus: "error",
                    help: "Campo obrigatório"
                })
                return null;
            }

            if (validarDuplicados()) {
                openNotificationWithIcon("error", "Erro", "Estruturas de avaliação selecionadas em duplicado");

                return null;
            }

            setLoading(true);

            var item = new FormData();
            item.append("id", disciplinaId);
            item.append("nome", formData.nome);
            item.append("codigo", formData.codigo);
            item.append("creditos", formData.creditos);
            item.append("horas_trabalho", formData.horas_trabalho);
            item.append("carga_horaria", formData.carga_horaria);
            item.append("horas_contacto", JSON.stringify(formData.horas_contacto));
            item.append("fasesEscolares", JSON.stringify(formData.fasesEscolares));
            if (formData.fichaId) {
                item.append("fichaId", formData.fichaId);
                item.append("fichaCampos", JSON.stringify(formData.fichaCampos));
            }
            item.append("encerramento", formData.encerramento);
            item.append("percentagemFaltas", formData.percentagemFaltas);
            item.append("ordem", formData.ordem);

            axios({
                method: "put",
                url: "/api/gestao-templates-turmas/alterar-disciplina",
                data: item
            })
                .then(() => {
                    openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!.");
                    setLoading(false)
                    atualizar();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", error.response.data);
                    setLoading(false)
                });
        })
    }

    const carregarTiposHoras = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/carregar-tipos-horas"
        })
            .then(response => {
                setTiposHoras(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar tipos de horas!");
            });
    };

    const carregarTiposDiario = (index) => {
        axios({
            method: "get",
            url: "/api/gestao-templates-turmas/lista-tipos-diario",
            params: {
                novo: disciplinaId > 0,
                disciplinaId: disciplinaId
            }
        })
            .then(response => {
                setTiposDiario(response.data);

                if (disciplinaId > 0)
                    detalhe();
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    const carregarFichas = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/listar-fichas"
        })
            .then(response => {
                setFichas(response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    const adicionarHorasContacto = () => {
        const registo = {
            id: undefined
        }

        setFormData({ ...formData, horas_contacto: [...formData.horas_contacto, registo] });
    }

    const adicionarFaseEscolar = () => {
        const periodos = formData.fasesEscolares.map(fase => fase.periodo)

        const fase = {
            id: "",
            periodos: tiposDiario.filter(x => !periodos.includes(x.periodo_letivo)),
            tipo: undefined,
            periodo: "",
            genero: "",
        }

        setEstruturaStatus({
            validateStatus: "",
            help: ""
        })
        setFormData({ ...formData, fasesEscolares: [...formData.fasesEscolares, fase] });
    }

    const montarPeriodos = (tipo) => {
        let periodos = formData.fasesEscolares.map(fase => fase.periodo)
        if (tipo)
            periodos = periodos.filter(x => x !== tipo);

        return tiposDiario.filter(x => !periodos.includes(x.periodo_letivo))
    }

    const afterVisibleChange = visible => {
        form.resetFields();
        if (visible) {
            //if (disciplinaId > 0)
            //    detalhe();

            carregarTiposDiario();
        }
        else {
            setFormData({
                nome: "",
                codigo: "",
                creditos: 1,
                horas_trabalho: 1,
                carga_horaria: 1,
                horas_contacto: [],
                fasesEscolares: [],
                //FICHAS
                fichaId: undefined,
                fichaCampos: [],
                encerramento: "Manual",
                percentagemFaltas: 0,
                ordem: 1,
            });
        }
    }

    const validarValor = (rule, value, callback) => {
        if (isNaN(value))
            callback("O valor deve ser um número")

        callback();
    }

    useEffect(() => {
        carregarTiposHoras();
        carregarFichas();
    }, [])

    return (
        <>
            <Drawer
                className="drawer-add-cursos"
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-add-curso" layout="horizontal">
                        <Form.Item label="Nome">
                            {getFieldDecorator("nome", {
                                initialValue: formData.nome,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(<Input name="nome" onChange={(e) => setFormData({ ...formData, nome: e.target.value })} />)}
                        </Form.Item>
                        <Form.Item label="Código">
                            {getFieldDecorator("codigo", {
                                initialValue: formData.codigo,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(<Input name="codigo" onChange={(e) => setFormData({ ...formData, codigo: e.target.value })} />)}
                        </Form.Item>
                        <Form.Item label="Créditos">
                            {getFieldDecorator("creditos", {
                                initialValue: formData.creditos,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(<InputNumber name="creditos" min={1} onChange={(creditos) => setFormData({ ...formData, creditos })} />)}
                        </Form.Item>
                        <Form.Item label="Carga Horária Total">
                            {getFieldDecorator("horas_trabalho", {
                                initialValue: formData.horas_trabalho,
                                //rules: [
                                //    {
                                //        required: true,
                                //        message: "Campo obrigatório"
                                //    }
                                //]
                            })(<InputNumber name="horas_trabalho" min={1} onChange={(horas_trabalho) => setFormData({ ...formData, horas_trabalho })} />)}
                        </Form.Item>
                        <Form.Item label="">
                            <div className="bloco-fases-escolares">
                                <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>
                                    Tipos de Horas / Carga Horária
                                    <button className="botao-principal" onClick={adicionarHorasContacto}>
                                        <Icon type="plus" />
                                    </button>
                                </h3>
                                <div className="curso-lista-fases">
                                    {!formData.horas_contacto.length ? <span>Sem dados</span> : null}
                                    {formData.horas_contacto.map((hora_contacto, index) => (
                                        <div key={index} className="item">
                                            <div className="item-inputs">
                                                <Form.Item>
                                                    {getFieldDecorator(`tipo${index}`, {
                                                        initialValue: hora_contacto.id,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Campo obrigatório"
                                                            }
                                                        ]
                                                    })(
                                                        <Select placeholder="Selecionar" allowClear={true} onChange={value => handleChangeHorasContacto(value, index)}>
                                                            {tiposHoras.map(tipo => (
                                                                <Option kye={tipo.id} value={tipo.id}>{tipo.titulo}</Option>
                                                            ))}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                                <Form.Item>
                                                    {getFieldDecorator(`horas${index}`, {
                                                        initialValue: hora_contacto.carga_horaria,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Campo obrigatório"
                                                            }
                                                        ]
                                                    })(<InputNumber name={`horas${index}`} min={1} onChange={value => handleChangeHorasContactoCarga(value, index)} />)}
                                                </Form.Item>
                                            </div>
                                            <Link className="botao-icon-excluir" to="#" title="Excluir" onClick={() => excluirHorasContacto(index)}>
                                                <Icon type="delete" />
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item validateStatus={estruturaStatus.validateStatus} help={estruturaStatus.help}>
                            <div className="bloco-fases-escolares">
                                <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>
                                    Estruturas de Avaliação
                                    <button className="botao-principal" onClick={adicionarFaseEscolar}>
                                        <Icon type="plus" />
                                    </button>
                                </h3>
                                <div className="curso-lista-fases">
                                    {!formData.fasesEscolares.length ? <span>Sem dados</span> : null}
                                    {formData.fasesEscolares.map((faseEscolar, index) => (
                                        <div key={index} className="item">
                                            <div className="item-inputs">
                                                <Form.Item>
                                                    {getFieldDecorator(`fase${index}`, {
                                                        initialValue: faseEscolar.tipo,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Campo obrigatório"
                                                            }
                                                        ]
                                                    })(
                                                        <Select
                                                            placeholder="Selecionar"
                                                            filterOption={false}
                                                            style={{ width: "100%" }}
                                                            onChange={value => handleChangeFase(value, index)}
                                                        >
                                                            {tiposDiario.map((tipo, index) => (
                                                                <Option key={index} value={tipo.id}>{tipo.nome}</Option>
                                                            ))}
                                                        </Select>
                                                    )}
                                                    {(faseEscolar.periodo && faseEscolar.genero) && (
                                                        <div style={{ borderTop: "1px solid #d9d9d9", marginTop: 10, paddingTop: 5, display: "flex", gap: 10 }}>
                                                            <span style={{ display: "block", lineHeight: "initial" }}>
                                                                Periodo Letivo: <strong>{faseEscolar.periodo}</strong>
                                                            </span>
                                                            <span style={{ display: "block", lineHeight: "initial" }}>
                                                                Tipo: <strong>{faseEscolar.genero}</strong>
                                                            </span>
                                                        </div>
                                                    )}
                                                </Form.Item>
                                            </div>
                                            <Link className="botao-icon-excluir" to="#" title="Excluir" onClick={() => excluirCampo(index)}>
                                                <Icon type="delete" />
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item label="Ficha">
                            {getFieldDecorator("ficha", {
                                initialValue: formData.fichaId
                            })(
                                <>
                                    <Select placeholder="Selecionar" value={formData.fichaId} allowClear={true} onChange={(fichaId) => setFormData({ ...formData, fichaId })}>
                                        {fichas.map((ficha, index) => (
                                            <Option key={index} value={ficha.id}>
                                                {ficha.nome}
                                            </Option>
                                        ))}
                                    </Select>
                                    {formData.fichaId ?
                                        <Link to="#" onClick={() => setVisibleFicha(true)}>Preencher ficha</Link>
                                        :
                                        null}
                                </>
                            )}
                        </Form.Item>
                        <Form.Item label="Encerramento">
                            {getFieldDecorator("encerramento", {
                                initialValue: formData.encerramento
                            })(
                                <Select placeholder="Selecionar" onChange={(encerramento) => setFormData({ ...formData, encerramento })}>
                                    <Option value="Manual">Manual</Option>
                                    <Option value="Automatico">Automático</Option>
                                    <Option value="FechoTurma">Fecho da turma</Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="% máxima de faltas">
                            {getFieldDecorator("percentagemFaltas", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    },
                                    {
                                        validator: validarValor
                                    }
                                ],
                                initialValue: formData.percentagemFaltas
                            })(<Input
                                className="input-50"
                                name="percentagemFaltas"
                                onChange={(e) => setFormData({ ...formData, percentagemFaltas: e.target.value })}
                            />)}
                        </Form.Item>
                        <Form.Item label="Ordem">
                            {getFieldDecorator("curso_ordem", {
                                initialValue: formData.ordem,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(<InputNumber className="input-25" name="curso_ordem" min={1} onChange={(ordem) => setFormData({ ...formData, ordem })} />)}
                        </Form.Item>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    <button
                        className="botao-principal"
                        disabled={loading}
                        onClick={disciplinaId > 0 ? alterar : adicionar} type="primary"
                    >
                        {loading ? <Icon type="loading" /> : null}
                        Guardar
                    </button>
                </div>
            </Drawer>
            <DrawerFicha
                fichaId={formData.fichaId}
                fichaCampos={formData.fichaCampos}
                visible={visibleFicha}
                onClose={() => setVisibleFicha(false)}
                atualizar={(fichaCampos) => { setVisibleFicha(false); setFormData({ ...formData, fichaCampos })} }
            />
        </>
    );
}

const FormDrawerDisciplina = Form.create({ name: "form-drawer-disciplina" })(DrawerDisciplina);

export default withRouter(FormDrawerDisciplina);