import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, Input, DatePicker, Tooltip, Icon, notification, Select, Checkbox } from "antd";
import axios from "axios";
import noimage from "../../../../images/noimage.png";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerAtribuirAvaliacaoCompetencia extends Component {
    state = {
        //CLASSIFICACAO
        classificacao: "",
        observacoes: "",
        medalha: false,
        notificar: false,
        //COMPETENCIAS
        tipos: [],
        competencias: [],
        //LOADING
        iconLoading: false
    };

    UNSAFE_componentWillUpdate() {
        this.props.form.resetFields();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeSelect = (value, tipo, competenciaId) => {
        var tipos = this.state.tipos
        var _competenciaIndex = this.state.tipos.findIndex(x => x.competenciaId === competenciaId)

        if (_competenciaIndex >= 0) {
            if (tipos[_competenciaIndex].tipos.length) {
                var _tipoIndex = tipos[_competenciaIndex].tipos.findIndex(x => x.tipo === tipo)
                if (_tipoIndex >= 0)
                    tipos[_competenciaIndex].tipos[_tipoIndex] = { tipo, valor: value }
                else
                    tipos[_competenciaIndex].tipos = [...tipos[_competenciaIndex].tipos, { tipo, valor: value }]
            }
            else {
                tipos[_competenciaIndex].tipos = [...tipos[_competenciaIndex].tipos, { tipo, valor: value }]
            }

            this.setState({
                tipos
            });
        }
        else
            this.setState({
                tipos: [
                    ...this.state.tipos,
                    {
                        competenciaId,
                        tipos: [{ tipo, valor: value }]
                    }
                ]
            });
    };

    carregar = () => {
        axios({
            method: "get",
            url: "/api/gestao-curso-resultados/carregar-avaliacao-competencia",
            params: {
                aulaId: this.props.aulaid,
                utilizadorId: this.props.utilizadorid
            }
        })
            .then(response => {
                this.props.form.resetFields()
                if (response.data.avaliacao)
                    this.setState({
                        tipos: response.data.avaliacao.tipos ? JSON.parse(response.data.avaliacao.tipos) : [],
                        classificacao: response.data.avaliacao.classificacao,
                        observacoes: response.data.avaliacao.observacoes,
                        medalha: response.data.avaliacao.medalha,
                        competencias: response.data.competencias,
                        iconLoading: false
                    });
                else
                    this.setState({
                        tipos: [],
                        competencias: response.data.competencias,
                        iconLoading: false
                    });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a avaliação!");
                this.setState({ iconLoading: false });
            });
    }

    guardar = event => {
        event.preventDefault();

        //this.props.form.validateFieldsAndScroll((err, values) => {
        //    if (!err) {
        this.setState({ iconLoading: true });
        var item = new FormData();
        item.append("id_modulo_aula", this.props.aulaid);
        item.append("id_utilizador", this.props.utilizadorid);
        if (this.state.classificacao)
            item.append("nota_final", this.state.classificacao);
        if (this.state.observacoes)
            item.append("nota_final_notas", this.state.observacoes);
        item.append("medalha", this.state.medalha);
        item.append("competencias", JSON.stringify(this.state.tipos));
        item.append("notificar", this.state.notificar);

        axios({
            method: "put",
            url: "/api/gestao-curso-resultados/atribuir-avaliacao",
            data: item
        })
            .then(response => {
                this.props.onCloseAulaAtribuirClassificacaoCompetencias(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível atribuir a avaliação!");
                this.setState({ iconLoading: false });
            });
        //    }
        //});
    };

    montarTipos = (competenciaId, tipos) => {
        return tipos.map((tipo, index) => {
            var selecionado = undefined
            if (this.state.tipos.length) {
                var competencia = this.state.tipos.find(x => x.competenciaId === competenciaId)
                if (competencia?.tipos.length)
                    selecionado = competencia.tipos.find(x => x.tipo === tipo)?.valor
            }

            return <Form.Item key={index} label={tipo}>
                <Select placeholder="Selecionar" defaultValue={selecionado} allowClear={true} onChange={value => this.handleChangeSelect(value, tipo, competenciaId)}>
                    <Option key="Competência satisfatória"><div className="tipo-competencia-dropdown"><span className="icon-competencia-dropdown icon-satisfatoria"></span><span>Competência satisfatória</span></div></Option>
                    <Option key="Competência não satisfatória"><div className="tipo-competencia-dropdown"><span className="icon-competencia-dropdown icon-nao-satisfatoria"></span><span>Competência não satisfatória</span></div></Option>
                    <Option key="Competência parcialmente satisfatória"><div className="tipo-competencia-dropdown"><span className="icon-competencia-dropdown icon-parcialmente-satisfatoria"></span><span>Competência parcialmente satisfatória</span></div></Option>
                </Select>
            </Form.Item>
        })
    }

    afterVisibleChange = aberto => {
        if (aberto) {
            this.carregar()
        }
    }

    render() {
        const { TextArea } = Input;
        const { getFieldDecorator } = this.props.form;
        const {
            //CLASSIFICACAO
            classificacao,
            observacoes,
            medalha,
            notificar,
            //COMPETENCIAS
            competencias,
            //LOADING
            iconLoading
        } = this.state;

        return (
            <Drawer
                className="drawer-from-outra-avaliacao"
                //title="Competências"
                width={720}
                onClose={this.props.onClose}
                visible={this.props.visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-modulo" layout="horizontal">
                        <div className="bloco">
                            <Form.Item label="Classificação">
                                {/*{getFieldDecorator("classificacao", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: classificacao
                                })(<Input name="classificacao" onChange={this.handleChange} />)}*/}
                                <Input value={classificacao} name="classificacao" onChange={this.handleChange} />
                            </Form.Item>
                            <Form.Item label="Observações">
                                <TextArea value={observacoes} name="observacoes" onChange={this.handleChange} rows="3" style={{ height: "100%" }} />
                                <span className="nota-info">Esta informação não será partilhada com o aluno nem com o seu responsável</span>
                            </Form.Item>
                            <Form.Item>
                                <Checkbox name="medalha" checked={medalha} onChange={this.handleChangeCheckbox}>
                                    Atribuir medalha
                            </Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Checkbox name="notificar" checked={notificar} onChange={this.handleChangeCheckbox}>
                                    Notificar por e-mail
                                    <Tooltip className="info-icon" title="Notificar os participantes por e-mail">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Checkbox>
                            </Form.Item>
                        </div>
                        <div className="bloco">
                            {competencias.map((competencia, index) => (
                                <div key={index} className="bloco">
                                    <h3 className="titulo-separador" style={{ borderBottom: 0, textTransform: "initial" }}>{competencia.nome}</h3>
                                    {this.montarTipos(competencia.id, JSON.parse(competencia.tipos))}
                                </div>
                            ))}
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    <button className="botao-principal" type="primary" disabled={iconLoading} onClick={this.guardar}>
                        {iconLoading ? <Icon type="loading" /> : null}
                            Guardar
                    </button>
                </div>
            </Drawer>
        );
    }
}
const FormDrawerAtribuirAvaliacaoCompetencia = Form.create({ name: "form-atribuir-avaliacao-competencia" })(DrawerAtribuirAvaliacaoCompetencia);

export default FormDrawerAtribuirAvaliacaoCompetencia;
