import React, { useState, useEffect, useContext } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Table, Icon, Modal, notification } from 'antd';
import DrawerDoc from "./Drawer/DrawerDoc";
import ModalViewer from "../../../Modals/ModalViewer";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";
import moment from "moment";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    })
}

const Tabela = ({ carregarAnosDocumentos, match }) => {
    const globalContext = useContext(GlobalContext);
    const [documentoId, setDocumentoId] = useState(0);
    const [documentos, setDocumentos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    const [visible, setVisible] = useState(false);
    const [docs, setDocs] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    const extensions = [".bmp", ".csv", ".odt", ".doc", ".docx", ".gif", ".htm", ".html", ".jpg", ".jpeg", ".pdf", ".png", ".ppt", ".pptx", ".tiff", ".txt", ".xls", ".xlsx"];

    const cols = [
        {
            title: "Data",
            dataIndex: "data"
        },
        {
            title: "Criado por",
            dataIndex: "criado"
        },
        {
            title: "Documento",
            dataIndex: "documento"
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    const download = (documentoId) => {
        axios({
            method: "post",
            url: "/api/documentos/download",
            params: {
                documentoId
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute(
                    'download',
                    response.data.nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
            });
    }

    const excluir = documentoId => {
        confirm({
            title: "Pretende excluir este ficheiro?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/documentos/excluir",
                    params: {
                        documentoId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Ficheiro excluido!");
                        //const _documentos = documentos.filter(x => x.key !== documentoId);
                        //setDocumentos(_documentos);

                        atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o ficheiro!");
                    });
            }
        });
    };

    const carregar = () => {
        setLoading(true);

        axios({
            method: "get",
            url: "/api/documentos/listar",
            params: {
                ano: globalContext[`ano${match.params.code}`]
            }
        })
            .then(response => {
                let documentos = [];

                response.data.map(documento => {
                    documentos.push({
                        key: documento.id,
                        data: documento.dt_alterado ? moment(documento.dt_alterado).format("DD/MM/YYYY") : moment(documento.dt_criado).format("DD/MM/YYYY"),
                        criado: documento.autorNome,
                        documento: documento.nome,
                        opcoes: <div className="documentos-opcoes">
                            {extensions.find(x => x === documento.extensao) &&
                                <Link to="#" title="Visualizar" className="botao-icon-detalhe" onClick={() => { setModalVisible(true); setDocs([{ uri: documento.ficheiro }]) }}>
                                    <Icon type="eye" />
                                </Link>}
                            {/*<Link to="#" title="Descarregar" className="botao-icon-download" onClick={() => download(documento.id)}>*/}
                            {/*    <Icon type="download" />*/}
                            {/*</Link>*/}
                            {(documento.autorDoc && !documento.tipo) && (
                                <>
                                    <Link className="botao-icon-editar" to="#" onClick={() => { setVisible(true); setDocumentoId(documento.id) }} title="Editar">
                                        <Icon type="edit" />
                                    </Link>
                                    <Link className="botao-icon-excluir" to="#" onClick={() => excluir(documento.id)} title="Excluir">
                                        <Icon type="delete" />
                                    </Link>
                                </>)}
                        </div>
                    });
                });

                setDocumentos(documentos);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }
                setLoading(false);
            });
    };

    const atualizar = () => {
        if (globalContext[`ano${match.params.code}`] === "Todos")
            carregar();
        else
            carregarAnosDocumentos();
    }

    //const data = [
    //    {
    //        key: 1,
    //        data: "13/02/2023",
    //        criado: "Gonçalo Gomes",
    //        documento: "doc1",
    //        opcoes: <div className="documentos-opcoes">
    //            <Link to="#" title="Visualizar" className="botao-icon-detalhe">
    //                <Icon type="eye" />
    //            </Link>
    //            <Link to="#" title="Descarregar" className="botao-icon-download">
    //                <Icon type="download" />
    //            </Link>
    //            <Link className="botao-icon-editar" to="#" title="Editar">
    //                <Icon type="edit" />
    //            </Link>
    //            <Link className="botao-icon-excluir" to="#" title="Excluir">
    //                <Icon type="delete" />
    //            </Link>
    //        </div>
    //    },
    //    {
    //        key: 1,
    //        data: "13/02/2023",
    //        criado: "Gonçalo Gomes",
    //        documento: "doc1",
    //        opcoes: <div className="documentos-opcoes">
    //            <Link to="#" title="Visualizar" className="botao-icon-detalhe">
    //                <Icon type="eye" />
    //            </Link>
    //            <Link to="#" title="Descarregar" className="botao-icon-download">
    //                <Icon type="download" />
    //            </Link>
    //            <Link className="botao-icon-editar" to="#" title="Editar">
    //                <Icon type="edit" />
    //            </Link>
    //            <Link className="botao-icon-excluir" to="#" title="Excluir">
    //                <Icon type="delete" />
    //            </Link>
    //        </div>
    //    }
    //]

    useEffect(() => {
        if (globalContext[`ano${match.params.code}`])
            carregar();
    }, [globalContext[`ano${match.params.code}`]])

    if (redirect) return <Redirect to={redirectLink} />;

    return (
        <>
            <Table
                className="tabela-documentos"
                columns={cols}
                pagination={false}
                dataSource={documentos}
                loading={{
                    spinning: loading,
                    indicator: (
                        <div className="loading-data-table">
                            <div className="loading" />
                        </div>
                    )
                }}
            />
            <div className="opcoes-meus-cursos">
                <Link to="#" className="btn-adicionar" title="Adicionar" onClick={() => { setVisible(true); setDocumentoId(0) }}>
                    +
                </Link>
            </div>
            <DrawerDoc
                download={download}
                documentoId={documentoId}
                visible={visible}
                onClose={() => setVisible(false)}
                atualizar={() => { setVisible(false); atualizar(); }}
            />
            <ModalViewer
                visible={modalVisible}
                docs={docs}
                onCancel={() => setModalVisible(false)}
            />
        </>
    );
}

export default withRouter(Tabela);