import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, Table, notification, Icon, Form, Input, Dropdown, Switch, Menu, Modal, Tooltip } from "antd";
import Header from "../../User/Header";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";
import Search from "../../Geral/_Aux/BotaoProcurar";
import DrawerCalendario from "./Drawers/DrawerCalendario";
import moment from "moment";

import ModalLoading from "../../Modals/ModalLoading";
import ModalViewer from "../../Modals/ModalViewer";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class CalendarioEventos extends Component {
    static contextType = GlobalContext;
    state = {
        calendarios: [],
        //FILTROS
        pesquisa: "",
        ativo: true,
        //LOADING
        loading_table: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER
        calendarioId: 0,
        visible: false,
        //MODAL LOADING
        visibleLoading: false,
        //MODAL VIEWER
        visibleViewer: false,
        docs: []
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listar();
    }

    //componentDidUpdate() {
    //    if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
    //        this.setState({
    //            redirect: true,
    //            redirectLink: "/user"
    //        });
    //}

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    //PROCURA ENTIDADES
    HandleKeyPress = e => {
        var pesquisa = e.target.value;
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa
                },
                () => {
                    this.listar();
                }
            );
        }
    };

    //LIMPA A PESQUISA APOR LIMPAR A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: ""
            },
            () => {
                this.listar();
            }
        );
    };

    //MONTA AS OPÇÕES DE MENU POR CADA CATEGORIA NA TABELA
    montarMenu = (calendarioId, code, ativo, exportar) => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (ativo)
            return (
                <div style={divStyle}>
                    {exportar && (
                        <Link
                            to="#"
                            className="botao-icon-download"
                            title="Exportar inscrições"
                            onClick={event => { event.stopPropagation(); this.gerarDoc(calendarioId) }}
                        >
                            <Icon type="download" />
                        </Link>)}
                    <Link
                        to={`/gestao-calendarios-eventos-detalhe/${code}`}
                        className="botao-icon-detalhe"
                        title="Inativar"
                    >
                        <Icon type="eye" />
                    </Link>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => { event.stopPropagation(); this.ativar(calendarioId, ativo) }}
                    >
                        <Icon type="close" />
                    </Link>
                </div>
            );

        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => { event.stopPropagation(); this.ativar(calendarioId, ativo) }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => { event.stopPropagation(); this.excluir(calendarioId) }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };

    //CARREGA TODAS AS CATEGORIA DA ENTIDADE PARA A TABELA
    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-calendario-eventos",
                    params: {
                        pesquisa: this.state.pesquisa,
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var calendarios = [];

                        response.data.map((calendario, index) => {
                            calendarios.push({
                                key: index,
                                id: calendario.id,
                                estabelecimentos: calendario.estabelecimentos,
                                inscritos: calendario.inscritos,
                                edicao: calendario.edicao,
                                nome: calendario.nome,
                                datas: `${moment(calendario.inicio).format("DD/MM/YYYY")} - ${moment(calendario.termino).format("DD/MM/YYYY")}`,
                                opcoes: this.montarMenu(calendario.id, calendario.code, calendario.ativo, calendario.exportar)
                            });
                        });

                        this.setState({
                            calendarios,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    excluir = calendarioId => {
        confirm({
            title: "Pretende excluir este calendário?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-calendario-eventos/excluir",
                    params: {
                        calendarioId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Calendário excluido!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não é possível excluir este registo!");
                    });
            }
        });
    };

    ativar = (calendarioId, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar este calendário?" : "Pretende ativar este calendário?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-calendario-eventos/ativar",
                    params: {
                        calendarioId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Calendário inativado!" : "Calendário ativado!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar o calendário!" : "Não foi possível ativar o calendário!"
                        );
                    });
            }
        });
    };

    gerarDoc = (eventoId) => {
        this.setState({
            visibleLoading: true
        })
        axios({
            method: "get",
            url: "/api/excel/lista-inscricoes",
            params: {
                eventoId
            }
        })
            .then(response => {
                this.setState({
                    visibleLoading: false
                })
                this.iniciarDownload(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível gerar documento.");
                this.setState({
                    visibleLoading: false
                })
            });
    }

    iniciarDownload = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = true;
        link.click();
    };

    showDrawer = () => {
        this.setState({
            calendarioId: 0,
            visible: true
        })
    }

    onClose = () => {
        this.setState({
            visible: false
        });
    };

    onRowClick = (calendarioId) => {
        this.setState({
            calendarioId,
            visible: true
        })
    }

    render() {
        const {
            ativo,
            calendarios,
            //LOADING
            loading_table,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER
            calendarioId,
            visible,
            //MODAL LOADING
            visibleLoading,
            //MODAL VIEWER
            visibleViewer,
            docs
        } = this.state;

        const columns = [
            {
                title: "Nome",
                dataIndex: "nome"
            },
            {
                title: "Inicio / Término",
                dataIndex: "datas"
            },
            {
                title: "Estabelecimentos",
                dataIndex: "estabelecimentos"
            },
            {
                title: "Inscritos",
                dataIndex: "inscritos"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Stepeventos" />
                <div className="container container-body">
                    <div className="bloco-categorias">
                        <div className="bloco-tabela">
                            <div className="filtros" style={{ justifyContent: "space-between" }}>
                                <div className="bloco-pesquisa">
                                    <button
                                        className="botao-principal botao-principal-redondo"
                                        title="Adicionar"
                                        onClick={this.showDrawer}
                                        disabled={!this.state.ativo}
                                    >
                                        +
                                    </button>
                                    <Search
                                        tem_placeholder
                                        placeholder="Procurar por nome"
                                        HandleKeyPress={this.HandleKeyPress}
                                        resetCaixaProcura={this.resetCaixaProcura}
                                    />
                                </div>
                                <Switch
                                    checkedChildren="Ativos"
                                    unCheckedChildren="Inativos"
                                    defaultChecked
                                    onChange={this.handleChangeSwitch}
                                />
                            </div>
                            <Table
                                id="tabela_calendarios"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={calendarios}
                                pagination={false}
                                rowClassName="clickeble-row"
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => this.onRowClick(record.id)
                                    };
                                }}
                                locale={{ emptyText: "Não existem dados!" }}
                            />
                        </div>
                    </div>
                </div>
                <DrawerCalendario
                    ativo={ativo}
                    calendarioId={calendarioId}
                    visible={visible}
                    onClose={() => this.setState({ visible: false })}
                    atualizar={() => { this.setState({ visible: false }); this.listar(); }}
                />
                <ModalLoading
                    visible={visibleLoading}
                    text="A gerar documento..."
                />
                <ModalViewer
                    visible={visibleViewer}
                    docs={docs}
                    onCancel={() => this.setState({ visibleViewer: false })}
                />
            </div>
        );
    }
}
export default CalendarioEventos;
