import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Form, Input, Icon, Drawer, Upload, Select, Tooltip, notification, Modal } from "antd";
import ModalViewer from "../../../../Modals/ModalViewer";
import axios from "axios";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerTurma = ({ ativo, turmaId, visible, onClose, atualizar, form }) => {
    const [formData, setFormData] = useState({
        ano: undefined,
        nivel: undefined,
        codigo: "",
        turno: undefined
    });
    const [niveis, setNiveis] = useState([]);
    const [anosLetivos, setAnosLetivos] = useState([]);
    const [turnos, setTurnos] = useState([]);
    const [planoEstudos, setPlanoEstudos] = useState({
        nome: "",
        url: ""
    });
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [docs, setDocs] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    const { getFieldDecorator } = form;
    const Dragger = Upload.Dragger;
    const { Option } = Select;

    const detalhe = () => {
        axios({
            method: "get",
            url: "/api/gestao-templates-turmas/detalhe-turma",
            params: {
                turmaId
            }
        })
            .then(response => {
                var info = response.data;
                setFormData({
                    ano: info.ano,
                    nivel: info.id_nivel,
                    turno: info.id_turno,
                    codigo: info.codigo
                })

                setPlanoEstudos({
                    nome: info.plano_estudos,
                    url: info.plano_estudos_caminho,
                    extensao: info.extensao
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
            });
    }

    const adicionar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);

                var item = new FormData();
                item.append("ano", formData.ano);
                item.append("nivel", formData.nivel);
                item.append("codigo", formData.codigo);
                if (formData.turno)
                    item.append("id_turno", formData.turno);
                if (fileList.length)
                    item.append("ficheiro", fileList[0]);

                axios({
                    method: "post",
                    url: "/api/gestao-templates-turmas/adicionar-turma",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!.");
                        setLoading(false)
                        atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false)
                    });
            }
        })
    }

    const alterar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);

                var item = new FormData();
                item.append("id", turmaId);
                item.append("ano", formData.ano);
                item.append("nivel", formData.nivel);
                item.append("codigo", formData.codigo);
                if (formData.turno)
                    item.append("id_turno", formData.turno);
                item.append("plano_estudos", planoEstudos);
                if (fileList.length)
                    item.append("ficheiro", fileList[0]);

                axios({
                    method: "put",
                    url: "/api/gestao-templates-turmas/alterar-turma",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!.");
                        setLoading(false)
                        atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false)
                    });
            }
        })
    }

    const carregarCategorias = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/lista-categorias",
            params: {
                cursoId: turmaId
            }
        })
            .then(response => {
                setNiveis(response.data);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar os níveis");
            });
    };

    const carregarAnosLetivos = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/lista-anos-letivos",
            params: {
                cursoId: turmaId
            }
        })
            .then(response => {
                setAnosLetivos(response.data);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar os anos letivos");
            });
    };

    const carregarTurnos = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/lista-turnos",
            params: {
                cursoId: turmaId
            }
        })
            .then(response => {
                setTurnos(response.data);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar os turnos");
            });
    };

    const validar = (rule, value, callback) => {
        if (value.file)
            if (!validarFormatoFicheiro(value.file.name.split(".").pop().toLowerCase()) && value.file.status !== "removed")
                callback("Ficheiro com formato inválido");
            else if (value.file.status === "removed")
                callback("Campo obrigatório");

        callback();
    };

    const validarFormatoFicheiro = formato => {
        let formatos = ["pdf"];
        let valido = false;
        for (let i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    const excluirDoc = () => {
        confirm({
            title: "Pretende excluir este documento?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                setPlanoEstudos("")
            }
        });
    }

    const afterVisibleChange = visible => {
        form.resetFields();
        if (visible) {
            if (turmaId > 0)
                detalhe();
        }
        else {
            setFormData({
                ano: undefined,
                nivel: undefined,
                turno: undefined,
                codigo: ""
            });
            setPlanoEstudos({
                nome: "",
                link: ""
            });
            setFileList([]);
        }
    }

    useEffect(() => {
        carregarCategorias();
        carregarAnosLetivos();
        carregarTurnos();
    }, [])

    const props = {
        multiple: false,
        onRemove: file => {
            form.resetFields("ficheiro");
            setFileList(fileList => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);

                return newFileList;
            });
        },
        defaultFileList: fileList,
        beforeUpload: file => {
            setFileList([file])

            return false;
        },
        fileList
    };

    return (
        <Drawer
            className="drawer-add-cursos"
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info">
                <Form className="form-add-curso" layout="horizontal">
                    <div className="bloco">
                        <Form.Item label="Ano">
                            {getFieldDecorator("ano", {
                                initialValue: formData.ano,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(
                                <Select placeholder="Selecionar" disabled={!ativo} allowClear={true} onChange={(ano) => setFormData({ ...formData, ano })}>
                                    {anosLetivos.map(anoLetivo => (
                                        <Option key={anoLetivo.id} value={anoLetivo.nome} >{anoLetivo.nome}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Código">
                            {getFieldDecorator("codigo", {
                                initialValue: formData.codigo,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(<Input name="codigo" disabled={!ativo} onChange={(e) => setFormData({ ...formData, codigo: e.target.value })} />)}
                        </Form.Item>
                        <Form.Item label="Nível">
                            {getFieldDecorator("nivel", {
                                initialValue: formData.nivel,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(
                                <Select placeholder="Selecionar" disabled={!ativo} allowClear={true} onChange={(nivel) => setFormData({ ...formData, nivel })}>
                                    {niveis.map(nivel => (
                                        <Option key={nivel.id} value={nivel.id}>
                                            {nivel.nome}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Turno">
                            <Select placeholder="Selecionar" disabled={!ativo} value={formData.turno} allowClear={true} onChange={(turno) => setFormData({ ...formData, turno })}>
                                {turnos.map(turno => (
                                    <Option key={turno.id} value={turno.id}>{turno.nome}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="bloco">
                        <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>Ficha do plano de estudos</h3>
                        <Form.Item>
                            {planoEstudos.nome &&
                                <Form.Item>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <span>{planoEstudos.nome}</span>
                                        {ativo && (
                                            <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 10 }}>
                                                <Link to="#" title="Visualizar" className="botao-icon-detalhe" onClick={() => { setModalVisible(true); setDocs([{ uri: planoEstudos.url }]) }}>
                                                    <Icon type="eye" />
                                                </Link>
                                                <Link
                                                    to="#"
                                                    title="Visualizar"
                                                    className="botao-icon-excluir"
                                                    onClick={excluirDoc}
                                                >
                                                    <Icon type="delete" />
                                                </Link>
                                            </span>
                                        )}
                                    </div>
                                </Form.Item>}
                            <div className="anexos">
                                {getFieldDecorator("ficheiro", {
                                    rules: [
                                        {
                                            validator: validar
                                        }
                                    ],
                                    initialValue: fileList
                                })(
                                    <Dragger {...props} disabled={!ativo}>
                                        <p className="ant-upload-drag-icon">
                                            <i className="fas fa-upload" />
                                        </p>
                                        <p className="ant-upload-text">
                                            Adicionar anexo{" "}
                                            <Tooltip title="Formatos válidos: .pdf">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </p>
                                        <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                    </Dragger>
                                )}
                            </div>
                        </Form.Item>
                    </div>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                    Voltar
                </button>
                {ativo && (
                    <button
                        className="botao-principal"
                        disabled={loading}
                        onClick={turmaId > 0 ? alterar : adicionar} type="primary"
                    >
                        {loading ? <Icon type="loading" /> : null}
                        Guardar
                    </button>
                )}
            </div>
            <ModalViewer
                visible={modalVisible}
                docs={docs}
                onCancel={() => setModalVisible(false)}
            />
        </Drawer>
    );
}

const FormDrawerTurma = Form.create({ name: "form-drawer-turma" })(DrawerTurma);

export default withRouter(FormDrawerTurma);