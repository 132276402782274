import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, notification, Icon, Form, Input, Row, Col, InputNumber, Checkbox, Modal, Select, DatePicker, Tooltip } from "antd";
import axios from "axios";
import { GlobalContext } from "../../../GlobalState";
import moment from "moment";

const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerCalendarioEscolar extends Component {
    static contextType = GlobalContext;
    state = {
        nome: "",
        estabelecimentos: [],
        selecionados: [],
        edicoes: [],
        edicaoId: undefined,
        tipo: [],
        nr_modulos: 1,
        inicio: "",
        termino: "",
        aprovado: moment(),
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listarEdicoes();
        this.listarEstabelecimentos();
    }

    componentDidUpdate() {
        //if (!this.context.is_authenticated || this.context.role === "UTILIZADOR") {
        //    if (!this.context.is_authenticated)
        //        this.setState({
        //            redirect: true,
        //            redirectLink: "/login"
        //        });
        //    else if (this.context.role === "UTILIZADOR")
        //        this.setState({
        //            redirect: true,
        //            redirectLink: "/user"
        //        });
        //}

        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeInicio = (date) => {
        this.setState({ inicio: date });
    };

    handleChangeTermino = (date) => {
        this.setState({ termino: date });
    };

    handleChangeAprovado = (date) => {
        this.setState({ aprovado: date });
    };

    listarEdicoes = () => {
        axios({
            method: "get",
            url: "api/gestao-calendario/listar-edicoes"
        })
            .then(response => {
                this.setState({
                    edicoes: response.data
                })
            })
            .catch(() => { });
    }

    listarEstabelecimentos = () => {
        axios({
            method: "get",
            url: "/api/gestao-organismos/listar-organismos"
        })
            .then(response => {
                this.setState({
                    estabelecimentos: response.data
                });
            })
            .catch(error => {

            });
    };

    //ABRE O DRAWER COM OS DADOS DA CATEGORIA
    carregarInfo = calendarioId => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/gestao-calendario/detalhe",
            params: {
                calendarioId
            }
        })
            .then(response => {
                const info = response.data;
                this.setState({
                    nome: info.nome,
                    edicaoId: info.id_edicao,
                    selecionados: info.estabelecimentos,
                    tipo: JSON.parse(info.tipo),
                    nr_modulos: info.nr_modulos,
                    inicio: moment(info.inicio, "YYYY-MM-DD"),
                    termino: moment(info.termino, "YYYY-MM-DD"),
                    aprovado: info.aprovado ? moment(info.aprovado, "YYYY-MM-DD") : "",
                });
        })
        .catch(() => { });
    };

    //CRIA UMA NOVA CATEGORIA
    criar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("nome", this.state.nome);
                if (this.state.edicaoId)
                    item.append("id_edicao", this.state.edicaoId);
                item.append("estabelecimentos", JSON.stringify(this.state.selecionados));
                item.append("tipo", JSON.stringify(this.state.tipo));
                if (this.state.tipo.find(x => x === "Módulos"))
                    item.append("nr_modulos", this.state.nr_modulos);
                item.append("dt_inicio", moment(this.state.inicio).format("YYYY/MM/DD"));
                item.append("dt_termino", moment(this.state.termino).format("YYYY/MM/DD"));
                if(this.state.aprovado)
                    item.append("dt_aprovado", moment(this.state.aprovado).format("YYYY/MM/DD"));

                axios({
                    method: "post",
                    url: "/api/gestao-calendario/adicionar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Adicionado novo calendário!");
                        this.setState({
                            iconLoading: false
                        });
                        this.props.atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //ATUALIZA A COMPETENCIA
    alterar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.calendarioId);
                item.append("nome", this.state.nome);
                if (this.state.edicaoId)
                    item.append("id_edicao", this.state.edicaoId);
                item.append("estabelecimentos", JSON.stringify(this.state.selecionados));
                item.append("tipo", JSON.stringify(this.state.tipo));
                if (this.state.tipo.find(x => x === "Módulos"))
                    item.append("nr_modulos", this.state.nr_modulos);
                item.append("dt_inicio", moment(this.state.inicio).format("YYYY/MM/DD"));
                item.append("dt_termino", moment(this.state.termino).format("YYYY/MM/DD"));
                if (this.state.aprovado)
                    item.append("dt_aprovado", moment(this.state.aprovado).format("YYYY/MM/DD"));

                axios({
                    method: "put",
                    url: "/api/gestao-calendario/alterar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Calendário alterada!");
                        this.setState({
                            iconLoading: false
                        });
                        this.props.atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    afterVisibleChange = aberto => {
        if (aberto) {

            if (this.props.calendarioId) {
                this.carregarInfo(this.props.calendarioId);
            }
            else {
                this.props.form.resetFields();
                this.setState({
                    nome: "",
                    edicaoId: undefined,
                    selecionados: [],
                    tipo: [],
                    nr_modulos: 1,
                    inicio: "",
                    termino: "",
                    aprovado: moment(),
                    //LOADING
                    iconLoading: false,
                });
            }
        }
    };

    render() {
        const {
            nome,
            edicoes,
            edicaoId,
            estabelecimentos,
            selecionados,
            tipo,
            nr_modulos,
            inicio,
            termino,
            aprovado,
            //LOADING
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        const {
            ativo,
            calendarioId,
            visible,
            onClose
        } = this.props;

        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <Drawer
                className="drawer-add-cursos"
                //title={`${calendarioId ? "Alterar" : "Adicionar"} calendário`}
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-competencias" layout="horizontal">
                        <div className="bloco">
                            <Form.Item label="Nome">
                                {getFieldDecorator("nome", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: nome
                                })(<Input name="nome" disabled={!ativo} maxLength={75} onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="Mostrar em">
                                {getFieldDecorator("selecionados", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: selecionados
                                })(
                                    <Checkbox.Group style={{ width: '100%' }} disabled={!ativo} onChange={(selecionados) => this.setState({ selecionados })}>
                                        <Row>
                                            {estabelecimentos.map(estabelecimento => (
                                                <Col key={estabelecimento.id} span={16}>
                                                    <Checkbox value={estabelecimento.id}>
                                                        {estabelecimento.nome}
                                                    </Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                )}
                            </Form.Item>
                            <Form.Item label="Edição">
                                <Select
                                    className="filtro-categorias"
                                    onChange={edicaoId => this.setState({ edicaoId })}
                                    placeholder="Selecionar"
                                    allowClear={true}
                                    value={edicaoId}
                                    disabled={calendarioId || !ativo}
                                >
                                    {edicoes.map(edicao => (
                                        <Option value={edicao.id} key={edicao.id}>{edicao.nome}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Periodos letivos">
                                {getFieldDecorator("tipo", {
                                    initialValue: tipo
                                })(
                                    <Checkbox.Group className="lista-estabelecimentos" disabled={calendarioId || !ativo} style={{ width: '100%' }} onChange={(tipo) => this.setState({ tipo })}>
                                        <Checkbox value="Módulos">Módulos</Checkbox>
                                        <Checkbox value="Bimestres">Bimestres</Checkbox>
                                        <Checkbox value="Trimestres">Trimestres</Checkbox>
                                        <Checkbox value="Semestres">Semestres</Checkbox>
                                        <Checkbox value="Anual">Anual</Checkbox>
                                    </Checkbox.Group>
                                )}
                            </Form.Item>
                            {tipo.find(x => x === "Módulos") &&
                                <Form.Item label="Quantidade de módulos">
                                    {getFieldDecorator("nr_modulos", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: nr_modulos
                                    })(<InputNumber name="nr_modulos" min={1} disabled={!ativo} onChange={(nr_modulos) => this.setState({ nr_modulos })} />)}
                                </Form.Item>}
                            <Form.Item label="Inicio">
                                {getFieldDecorator("inicio", {
                                    initialValue: inicio,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(
                                    <DatePicker
                                        name="inicio"
                                        format="DD-MM-YYYY"
                                        disabled={!ativo}
                                        onChange={this.handleChangeInicio}
                                        placeholder="Selecionar"
                                        suffixIcon={false}
                                        clearIcon={false}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="Término">
                                {getFieldDecorator("termino", {
                                    initialValue: termino,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(
                                    <DatePicker
                                        name="termino"
                                        format="DD-MM-YYYY"
                                        disabled={!ativo}
                                        onChange={this.handleChangeTermino}
                                        placeholder="Selecionar"
                                        suffixIcon={false}
                                        clearIcon={false}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="Aprovado em">
                                {getFieldDecorator("aprovado", {
                                    initialValue: aprovado
                                })(
                                    <DatePicker
                                        name="aprovado"
                                        format="DD-MM-YYYY"
                                        disabled={!ativo}
                                        onChange={this.handleChangeAprovado}
                                        placeholder="Selecionar"
                                        suffixIcon={false}
                                        clearIcon={false}
                                    />
                                )}
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    {ativo && (
                        <button className="botao-principal" onClick={calendarioId ? this.alterar : this.criar} disabled={iconLoading} type="primary">
                            {iconLoading ? <Icon type="loading" /> : null}
                            Guardar
                        </button>
                    )}
                </div>
            </Drawer>
        );
    }
}
const FormDrawerCalendarioEscolar = Form.create({ name: "form-drawer-calenario-escolar" })(DrawerCalendarioEscolar);

export default FormDrawerCalendarioEscolar;
