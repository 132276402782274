import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, notification, Modal } from "antd";
import axios from "axios";
import moment from "moment";

import ExcelTabelaRelatorios from "./_Aux/ExcelTabelaRelatorios";
import ModalViewer from "../../Modals/ModalViewer";
import ModalLoading from "../../Modals/ModalLoading";
import ModalHorarioRelatorio from "./_Aux/ModalHorarioRelatorio";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ListaConteudos extends Component {
    state = {
        disciplinaId: localStorage.getItem("vista") === "TURMA" ? null : localStorage.getItem("codigo_curso"),
        periodoId: 0,
        //LOADING
        visibleLoading: false,
        //VIEWER
        visibleViewer: false,
        isModalVisible: false,
        startDate: undefined,
        endDate: undefined,
        docs: [],
        dates: []
    };

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                disciplinaId: localStorage.getItem("vista") === "TURMA" ? null : localStorage.getItem("codigo_curso")
            });
        }
    }

    exportarPdf = (disciplina, option, startDate, endDate, dataEmissao, isCompleto) => {
        this.getMeses();
        this.setState(
            {
                visibleLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/pdf/exportar-notas",
                    params: {
                        cursoId: disciplina,
                        dt_inicio: moment(startDate).format("YYYY/MM/DD"),
                        dt_fim: moment(endDate).format("YYYY/MM/DD"),
                        isCompleto: isCompleto
                    }
                })
                    .then(response => {
                        let fileName = `${localStorage.getItem("header_codigo")} ${localStorage.getItem("header_nome")}`
                        if (localStorage.getItem("disciplina_nome"))
                            fileName = localStorage.getItem("disciplina_nome");

                        this.setState({
                            visibleLoading: false,
                            visibleViewer: true,
                            docs: [{ uri: response.data, fileName }]
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleLoading: false
                        });
                    });
            }
        );
    };

    getMeses = () => {
        axios({
            method: "get",
            url: "/api/aulas-exportar/get-meses",
            params: {
                id: this.state.disciplinaId == null ? localStorage.getItem("codigo_curso") : this.state.disciplinaId,
                periodoId: this.state.periodoId
            }
        })
            .then(response => {
                this.setState({
                    dates: response.data.map(e => e.dataHora)
                });
            })
            .catch(() => {});
    };

    toggleModal = () => {
        this.getMeses();
        this.setState(prevState => ({
            isModalVisible: !prevState.isModalVisible
        }));
    };

    render() {
        const {
            //LOADING
            visibleLoading,
            //VIEWER
            visibleViewer,
            startDate,
            endDate,
            docs,
            dates
        } = this.state;

        return (
            <>
                <div className="container container-body">
                    <div className="bloco-curso-diario-opcoes" style={{ gap: 10 }}>
                        <h3 className="titulo-bloco">Relatório</h3>
                        <div className="bloco-opcoes-esquerda">
                            <Link className={`botao-icon-detalhe botao-maior`} to="#" title="Visualizar" onClick={this.toggleModal}>
                                <Icon type="eye" />
                            </Link>
                            <Link
                                to={`/gerir-multisseriado/${this.props.match.params.turma}/${
                                    localStorage.getItem("vista") === "TURMA" ? "horario" : "horario-disciplina"
                                }`}
                                className="botao-secundario botao-secundario-redondo"
                                title="Voltar"
                                style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                            >
                                <Icon type="arrow-left" />
                            </Link>
                        </div>
                    </div>
                    <div className="curso-diario">
                        <ExcelTabelaRelatorios
                            carregarPeriodoId={periodoId => this.setState({ periodoId })}
                            setDisciplinaId={disciplinaId => this.setState({ disciplinaId })}
                        />
                    </div>
                </div>
                <ModalLoading visible={visibleLoading} text="A gerar documento..." />
                <ModalViewer visible={visibleViewer} docs={docs} onCancel={() => this.setState({ visibleViewer: false })} />
                <ModalHorarioRelatorio
                    datas={dates}
                    disciplina={this.state.disciplinaId}
                    visible={this.state.isModalVisible}
                    onClose={this.toggleModal}
                    onSelect={(option3, option1, option2, dataEmissao, isCompleto) => {
                        this.setState({
                            startDate: option2.start_date,
                            endDate: option2.end_date
                        });
                        this.exportarPdf(option3, option1, option2.start_date, option2.end_date, dataEmissao, isCompleto);
                    }}
                />
            </>
        );
    }
}

export default withRouter(ListaConteudos);

/**
 * 
 *                              <Link
                                className={`botao-icon-detalhe botao-maior ${
                                    localStorage.getItem("vista") === "TURMA" && !disciplinaId ? "botao-disabled" : ""
                                }`}
                                to="#"
                                title="Visualizar"
                                disabled={localStorage.getItem("vista") === "TURMA" && !disciplinaId}
                                onClick={this.exportarPdf}
                            >
                                <Icon type="eye" />
                            </Link>
 * 
 */
