import React, { useState, useEffect, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import { Icon, notification } from "antd";
import { GeralContext } from "../ContextProvider";
import Card from "../Participantes/Participante";
import ModalExportar from "../../Modals/ModalExportar";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const VisaoGeralDisciplina = ({ match }) => {
    const [professores, setProfessores] = useState([]);
    const [ficha, setFicha] = useState("");
    const [fichaValidada, setFichaValidada] = useState(false);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    const [visibleExport, setVisibleExport] = useState(false);
    const [buttonDownload, setButtonDownload] = useState(false);
    const [exportFile, setExportFile] = useState("");

    const context = useContext(GeralContext);

    const listarProfessores = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/turma/lista-professores",
            params: {
                id: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setProfessores(response.data);
                setLoading(false);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    };

    const carregarFicha = () => {
        axios({
            method: "get",
            url: "/api/turma/listar-ficha",
            params: {
                id: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                if (response.data) {
                    setFicha(response.data.ficha);
                    setFichaValidada(response.data.ficha_validada);
                }
            })
            .catch(() => {});
    };

    const montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (
                <Link
                    to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar}
                    className="botao-principal"
                    onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)}
                >
                    Voltar
                </Link>
            );
        }
    };

    const exportar = () => {
        setVisibleExport(true);
        setButtonDownload(true);
        axios({
            method: "get",
            url: "/api/pdf/ficha-curricular",
            params: {
                cursoId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setButtonDownload(false);
                setExportFile(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleExport(false);
            });
    };

    useEffect(() => {
        listarProfessores();
        carregarFicha();
    }, []);

    if (redirect) return <Redirect to={redirectLink} />;

    return (
        <div className="container container-body">
            {loading ? (
                <div className="loading-data">
                    <div className="loading" />
                </div>
            ) : (
                <div className="turma-visao-geral">
                    <div className="controlos controlos-fundo">
                        <div className="controlos-opcoes">{montarBotaoVoltar()}</div>
                    </div>
                    <div className="bloco esquerda">
                        <div className="item">
                            <div className="lista-professores">
                                {professores.map(professor => (
                                    <Card
                                        {...professor}
                                        key={professor.id}
                                        dt_fechado={context.dt_fechado}
                                        dt_inicio={context.dt_inicio}
                                        dt_fim={context.dt_fim}
                                        fechado={context.fechado}
                                        perfil={professor.coordenador ? "Coordenador" : "Professor"}
                                    />
                                ))}
                            </div>
                        </div>
                        {context.temFicha && (
                            <div className="bloco esquerda bloco-ficha">
                                <div className="item">
                                    <h2>
                                        <span>Ficha</span>
                                        <Link className="botao-icon-pdf" to="#" title="Descarregar" onClick={exportar}>
                                            <Icon type="file-pdf" />
                                        </Link>
                                    </h2>
                                    {!fichaValidada ? (
                                        <div>Aguarda valiadação...</div>
                                    ) : (
                                        <div className="lista-campos-ficha">
                                            {ficha?.estrutura?.map((campo, index) => (
                                                <div key={index} className="campo-ficha">
                                                    <h3>{campo.nome}</h3>
                                                    {campo.texto && (
                                                        <p dangerouslySetInnerHTML={{ __html: campo.texto.replace(/\n/g, "<br>") }}></p>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <ModalExportar
                visibleExportar={visibleExport}
                btnDescarregar={buttonDownload}
                ficheiroExportar={exportFile}
                onCancel={() => setVisibleExport(false)}
            />
        </div>
    );
};
export default VisaoGeralDisciplina;
