import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Drawer,
    Icon,
    Form,
    Input,
    DatePicker,
    notification
} from "antd";
import axios from "axios";
import moment from "moment";

const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerNota = ({ notaId, visible, onClose, atualizar, form }) => {
    const [data, setData] = useState(null);
    const [nota, setNota] = useState("");
    const [observacoes, setObservacoes] = useState("");
    const [iconLoading, setIconLoading] = useState(false)

    const { getFieldDecorator } = form;

    const detalhe = () => {

    }

    const alterar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err && (data || nota || observacoes)) {
                setIconLoading(true);

                var item = new FormData();
                item.append("id", notaId);
                if (data)
                    item.append("data", moment(data).format("YYYY/MM/DD"));
                if (nota)
                    item.append("nota", nota);
                if (observacoes)
                    item.append("observacoes", observacoes);

                axios({
                    method: "put",
                    url: "/api/aproveitamento/alterar-nota",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Registo alterado");
                        setIconLoading(false);
                        atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setIconLoading(false);
                    });
            }
            else {
                if (!data || !nota || !observacoes)
                    openNotificationWithIcon("error", "Erro", "Não existem dados para guardar.");
            }
        })
    }

    const afterVisibleChange = aberto => {
        if (!aberto) {
            form.resetFields();
            setData(null);
            setNota("");
            setObservacoes("");
        }
    }

    useEffect(() => {
        form.resetFields();

        if (notaId > 0)
            detalhe();

    }, [notaId]);

    return (
        <Drawer
            className="drawer-add-cursos"
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info">
                <Form className="form-categorias listagem-avaliacoes" layout="horizontal">
                    <div className="bloco">
                        <Form.Item label="Data">
                            {getFieldDecorator("data", {
                                initialValue: data
                            })(<DatePicker
                                placeholder="Selecionar"
                                format="DD/MM/YYYY"
                                onChange={(date) => setData(date)}
                            />
                            )}
                        </Form.Item>
                        <Form.Item label="Nota">
                            {getFieldDecorator("nota", {
                                initialValue: nota
                            })(<Input name="nota" onChange={(e) => setNota(e.target.value)} />)}
                        </Form.Item>
                        <Form.Item label="Observações">
                            {getFieldDecorator("observacoes", {
                                initialValue: observacoes
                            })(<TextArea name="observacoes" onChange={(e) => setObservacoes(e.target.value)} rows={4} style={{ height: 100 }} />)}
                        </Form.Item>
                    </div>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                    Voltar
                </button>
                <button className="botao-principal" onClick={alterar} disabled={iconLoading} type="primary">
                    {iconLoading ? <Icon type="loading" /> : null}
                    Guardar
                </button>
            </div>
        </Drawer>
    );
}

const FormDrawerNota = Form.create({ name: "drawer-nota" })(DrawerNota);

export default FormDrawerNota;