import React, { useState, useEffect } from "react";
import { Drawer, Form, Checkbox, Icon, Upload, notification, DatePicker, InputNumber } from "antd";
import axios from "axios";
import moment from "moment";

const Dragger = Upload.Dragger;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerTransferenciaListaDisciplinas = ({ visible, cursos, utilizador, utilizadores, onClose, onConfirm, onConfirmImport, importacao, turmaId, transferencia, form }) => {
    const [listaDisciplinas, setListaDisciplinas] = useState([]);
    const [selecionados, setSelecionados] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [todos, setTodos] = useState(false);
    const [data, setData] = useState(moment());
    const [ordem, setOrdem] = useState(1);
    const [fileList, setFileList] = useState([]);
    const [iconLoading, setIconLoading] = useState(false);
    const [docGerado, setDocGerado] = useState("");

    const { getFieldDecorator } = form;

    const onChange = selecionados => {
        setSelecionados(selecionados);
        setIndeterminate(!!selecionados.length && selecionados.length < listaDisciplinas.length);
        setTodos(selecionados.length === listaDisciplinas.length);
    };

    const handleChange = (date) => {
        setData(date);
    };

    const handleChangeOrdem = (ordem) => {
        setOrdem(ordem);
    };

    const selecionarTodos = (event) => {
        setSelecionados(event.target.checked ?
            listaDisciplinas.map(item => item.id) : []
        );
        setIndeterminate(false);
        setTodos(event.target.checked);
    }

    const confirma = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setIconLoading(true);

                if (!importacao) {
                    var item = new FormData();
                    item.append("cursoId", localStorage.getItem("codigo_curso"));
                    item.append("utilizadorId", utilizador.id);
                    item.append("selecionados", JSON.stringify(selecionados));
                    item.append("data", moment(data).format("YYYY-MM-DD"));
                    item.append("ordem", ordem);
                    //if (turmaId)
                    //    item.append("turmaId", turmaId);
                    if (fileList.length)
                        item.append("anexo", fileList[0]);
                    if (transferencia)
                        item.append("transferencia", true);

                    axios({
                        method: "post",
                        url: "/api/gestao-turma-matriculas/inscrever-utilizador-transferencia-selecionados",
                        data: item
                    })
                        .then(() => {
                            onConfirm(selecionados);
                            setIconLoading(false);

                            openNotificationWithIcon("success", "Sucesso", "Aluno matrículado nas disciplinas selecionadas!");
                        })
                        .catch(error => {
                            openNotificationWithIcon("error", "Erro", error.response.data);
                        });
                }
                else {
                    var item = new FormData();
                    item.append("cursoId", localStorage.getItem("codigo_curso"));
                    item.append("utilizadores", JSON.stringify(utilizadores.map(x => x.id)));
                    item.append("selecionados", JSON.stringify(selecionados));
                    item.append("data", moment(data).format("YYYY-MM-DD"));
                    item.append("ordem", ordem);
                    if (turmaId)
                        item.append("turmaId", turmaId);
                    if (transferencia)
                        item.append("transferencia", true);

                    axios({
                        method: "post",
                        url: "/api/gestao-turma-matriculas/inscrever-utilizadores-transferencia-selecionados",
                        data: item
                    })
                        .then(() => {
                            onConfirmImport();
                            setIconLoading(false);

                            openNotificationWithIcon("success", "Sucesso", utilizadores.length > 1 ? "Alunos matrículados nas disciplinas selecionadas!" : "Aluno matrículado nas disciplinas selecionadas!");
                        })
                        .catch(error => {
                            openNotificationWithIcon("error", "Erro", error.response.data);
                        });
                }
            }
        })
    }

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    const validarFormatoFicheiro = formato => {
        var formatos = ["pdf"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    const validar = (rule, value, callback) => {
        if (value.file) {
            if (value.file.size > 26214400) callback("Limite de 25 MB por upload!");
            if (!validarFormatoFicheiro(value.file.name.split(".").pop().toLowerCase()) && value.file.status !== "removed")
                callback("Ficheiro com formato inválido!");
        }

        callback();
    };

    const props = {
        accept:
            ".pdf",
        multiple: false,
        onRemove: file => {
            form.resetFields("ficheiro");
            setFileList(fileList => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);

                return newFileList;
            });
        },
        defaultFileList: fileList,
        beforeUpload: file => {
            setFileList([file]);

            return false;
        },
        fileList
    };

    const afterVisibleChange = visible => {
        if (!visible) {
            setSelecionados([]);
            setIndeterminate(false);
            setTodos(false);
            setData(moment());
            setFileList([]);
        }
    }

    useEffect(() => {
        setListaDisciplinas(cursos);
    }, [cursos])

    useEffect(() => {
        if (!importacao) {
            const inscricoes = utilizador.inscricoesDisciplina?.map(x => x.id);

            setSelecionados(inscricoes);
            setIndeterminate(!!inscricoes?.length && inscricoes?.length < listaDisciplinas.length);
            setTodos(inscricoes?.length === listaDisciplinas.length);
        }
    }, [utilizador])

    return (
        <Drawer
            className="drawer-inscrever"
            //title="Informações da transferência"
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-drawer-associar">
                <Form className="form-emitir-declaracao">
                    <Form.Item label="Data">
                        {getFieldDecorator("data", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                }
                            ],
                            initialValue: data
                        })(
                                <DatePicker
                                    name="data"
                                    format="DD-MM-YYYY"
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                    onChange={handleChange}
                                />
                        )}
                    </Form.Item>
                    <Form.Item label="Ordem">
                        {getFieldDecorator("ordem", {
                            initialValue: ordem,
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório"
                                }
                            ]
                        })(
                            <InputNumber
                                placeholder="Ordem"
                                min={1}
                                onChange={handleChangeOrdem}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Disciplinas">
                        <div style={{ marginBottom: "20px" }}>
                            <Checkbox
                                indeterminate={indeterminate}
                                onChange={selecionarTodos}
                                checked={todos}
                                disabled={utilizador.inscricoesDisciplina?.length > 0}
                            >
                                Todas
                            </Checkbox>
                        </div>
                        <Checkbox.Group style={{ width: '100%' }} value={selecionados} onChange={onChange}>
                            {listaDisciplinas.map((disciplina, index) => (
                                <div key={disciplina.id} style={{ marginBottom: "20px" }}>
                                    <Checkbox value={disciplina.id} disabled={utilizador.inscricoesDisciplina?.find(x => x.id === disciplina.id)}>
                                        {disciplina.nome}
                                    </Checkbox>
                                </div>
                            ))}
                        </Checkbox.Group>
                    </Form.Item>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button
                    className="botao-secundario"
                    onClick={onClose}
                    style={{ marginRight: 20, display: "inline-block" }}
                >
                    Voltar
                </button>
                <button className="botao-principal" type="primary" disabled={iconLoading} onClick={confirma}>
                    {iconLoading ? <Icon type="loading" /> : null}
                    Guardar
                </button>
            </div>
        </Drawer>
    );
}

const FormDrawerTransferenciaListaDisciplinas = Form.create({ name: "drawer-transferencia-lista-disciplinas" })(DrawerTransferenciaListaDisciplinas);

export default FormDrawerTransferenciaListaDisciplinas;
