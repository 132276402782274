import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Icon, notification, Modal, Upload, Tooltip, Menu, Dropdown } from "antd";
import axios from "axios";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Anotacao extends Component {
    state = {
        anotacaoId: 0,
        cursoId: 0,
        texto: "",
        //anexo: "",
        ficheiros: [],
        fileList: [],
        //VALIDACOES
        validateStatus: "",
        help: "",
        totalBytes: 0,
        formatoValido: true,
        //LOADING
        loading: false,
        iconLoading: false,
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    excluirAnotacao = anotacaoId => {
        confirm({
            title: "Pretende excluir esta anotação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/anotacoes/excluir-anotacao",
                    params: {
                        anotacaoId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Anotação excluida!");
                        let anotacoes = this.props.anotacoes.filter(x => x.id !== anotacaoId);
                        this.props.atualizarListagem(anotacoes);
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a anotação!");
                    });
            }
        });
    };

    carregaAnotacao = anotacaoId => {
        axios({
            method: "get",
            url: "/api/anotacoes/carregar-anotacao",
            params: {
                anotacaoId
            }
        })
            .then(response => {
                this.setState({
                    anotacaoId,
                    cursoId: localStorage.getItem("codigo_curso"),
                    texto: response.data.texto,
                    info: response.data.info,
                    ficheiros: response.data.anexos
                });
                this.props.esconderBotao(false)
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    carregaAnexos = anotacaoId => {
        axios({
            method: "get",
            url: "/api/anotacoes/carregar-anexos",
            params: {
                anotacaoId
            }
        })
            .then(response => {
                let anotacoes = this.props.anotacoes;
                const anotacaoIndex = anotacoes.findIndex(x => x.id === this.state.anotacaoId)
                var anexos = response.data.map(anexo => {
                    return {
                        Nome: anexo,
                        Caminho: ""
                    }
                })
                anotacoes[anotacaoIndex].anexo = JSON.stringify(anexos)

                this.setState({
                    ficheiros: response.data,
                    fileList: [],
                    anotacoes
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    downloadFicheiro = (index, nome, anotacaoId) => {
        axios({
            method: "post",
            url: "/api/anotacoes/download-ficheiro",
            params: {
                anotacaoId,
                index
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data;
                link.setAttribute(
                    'download',
                    nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                else
                    openNotificationWithIcon("error", "Erro", error.response.data);

                this.setState({ iconLoading: false });
            });
    }

    montarMenuAnexos = (ficheiros, anotacaoId) => {
        var montar = JSON.parse(ficheiros).map((ficheiro, index) => (
            <Menu.Item>
                <Link to="#" onClick={() => this.downloadFicheiro(index, ficheiro.Nome, anotacaoId)}>
                    {ficheiro.Nome}
                </Link>
            </Menu.Item>
        ));

        return (
            <Menu>
                {montar}
            </Menu>
        );
    };

    excluirFicheiro = (index) => {
        confirm({
            title: "Pretende excluir este ficheiro?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/anotacoes/excluir-ficheiro",
                    params: {
                        anotacaoId: this.state.anotacaoId,
                        index
                    }
                })
                    .then(response => {
                        var ficheiros = this.state.ficheiros;
                        ficheiros.splice(index, 1);

                        let anotacoes = this.props.anotacoes;
                        const anotacaoIndex = anotacoes.findIndex(x => x.id === this.state.anotacaoId)
                        let anexos = JSON.parse(anotacoes[anotacaoIndex].anexo);
                        anexos.splice(index, 1);
                        anotacoes[anotacaoIndex].anexo = JSON.stringify(anexos)

                        this.setState({
                            ficheiros,
                            anotacoes
                        })
                    })
                    .catch(() => {
                        this.setState({ iconLoading: false })
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o ficheiro!")
                    })
            }
        });
    }

    alterarAnotacao = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 26214400 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.state.anotacaoId);
                item.append("id_curso", this.state.cursoId);
                item.append("texto", this.state.texto);

                this.state.fileList.forEach(file => {
                    item.append("anexo[]", file);
                });

                axios({
                    method: "post",
                    url: "/api/anotacoes/alterar-anotacao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Anotação alterada!");

                        let anotacoes = this.props.anotacoes;
                        const anotacaoIndex = anotacoes.findIndex(x => x.id === this.state.anotacaoId)
                        anotacoes[anotacaoIndex].texto = this.state.texto

                        this.setState({
                            iconLoading: false,
                            anotacoes
                        });

                        this.carregaAnexos(this.state.anotacaoId);
                    })
                    .catch(error => {
                        this.setState({
                            iconLoading: false
                        });
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        })
    };

    voltar = () => {
        this.setState({
            anotacaoId: 0
        })
        this.props.esconderBotao(true)
    }

    validarFormato = formato => {
        var formatos = ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const Dragger = Upload.Dragger;
        const {
            anotacaoId,
            texto,
            //anexo: "",
            ficheiros,
            fileList,
            //VALIDACOES
            validateStatus,
            help,
            totalBytes,
            formatoValido,
            //LOADING
            loading,
            iconLoading,
        } = this.state;

        const props = {
            accept: ".zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg",
            multiple: true,
            onChange: info => {
                var tamanhoTotal = 0;
                var ficheirosValidos = true;
                info.fileList.forEach(file => {
                    tamanhoTotal += file.size;

                    if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                        ficheirosValidos = false;
                    }
                });

                if (tamanhoTotal > 26214400)
                    this.setState({
                        totalBytes: tamanhoTotal,
                        validateStatus: "error",
                        help: "Limite de 25 MB por upload."
                    });
                else {
                    if (!ficheirosValidos)
                        this.setState({
                            formatoValido: false,
                            validateStatus: "error",
                            help: "Ficheiro(s) com formato inválido"
                        });
                }
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    var tamanhoTotal = 0;
                    var ficheirosValidos = true;
                    newFileList.forEach(file => {
                        tamanhoTotal += file.size;

                        if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                            ficheirosValidos = false;
                        }
                    });

                    if (tamanhoTotal > 26214400 || !ficheirosValidos)
                        return {
                            totalBytes: tamanhoTotal,
                            fileList: newFileList
                        };
                    else
                        return {
                            totalBytes: tamanhoTotal,
                            formatoValido: true,
                            validateStatus: "",
                            help: "",
                            fileList: newFileList
                        };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));
                return false;
            },
            fileList
        };

        return (
            <div className="anotacao">
                <div className="anotacao-content">
                    {anotacaoId === 0 ? (
                        <div className="info-anotacao">
                            <ul>
                                {this.props.anotacoes.map((anotacao, index) => (
                                    <li key={index}>
                                        <div className="bloco-anotacao-header">
                                            <span className="anotacao-data-hora">{anotacao.data}</span>
                                        </div>
                                        <div className="bloco-anotacao-body">
                                            <span className="texto-anotacao">
                                                <Link
                                                    to="#"
                                                    className="texto"
                                                    onClick={() => this.carregaAnotacao(anotacao.id)}
                                                    dangerouslySetInnerHTML={{ __html: anotacao.texto.replace(/(?:\r\n|\r|\n)/g, "<br>") }}
                                                >
                                                </Link>
                                                <div className="anotacao-footer">
                                                    {JSON.parse(anotacao.anexo).length ?
                                                        <Dropdown
                                                            overlay={this.montarMenuAnexos(anotacao.anexo, anotacao.id)}
                                                            placement="bottomLeft"
                                                        >
                                                            <Link to="#" className="botao-icon-clip" title="Anexos">
                                                                <Icon type="paper-clip" />
                                                            </Link>
                                                        </Dropdown> : null}
                                                    <Link
                                                        to="#"
                                                        className="botao-icon-excluir"
                                                        title="Descarregar"
                                                        onClick={() => this.excluirAnotacao(anotacao.id)}
                                                    >
                                                        <Icon type="delete" />
                                                    </Link>
                                                </div>
                                            </span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <div className="info-anotacao-detalhe">
                            <span className="texto-anotacao">
                                <Form className="form-nova-pergunta">
                                    <Form.Item label="Anotação">
                                        {getFieldDecorator("texto", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ],
                                            initialValue: this.state.texto
                                        })(<TextArea name="texto" onChange={this.handleChange} style={{ height: "100%" }} rows={10} />)}
                                    </Form.Item>
                                    <Form.Item validateStatus={validateStatus} help={help}>
                                        <div className="anexos">
                                            <Dragger {...props}>
                                                <p className="ant-upload-drag-icon">
                                                    <i className="fas fa-upload" />
                                                </p>
                                                <p className="ant-upload-text">
                                                    Adicionar anexos{" "}
                                                    <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                        <Icon type="question-circle-o" />
                                                    </Tooltip>
                                                </p>
                                                <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                            </Dragger>
                                        </div>
                                    </Form.Item>
                                    <Form.Item>
                                        <h3 className="titulo-separador">Anexos</h3>
                                        {loading ?
                                            <div className="loading-data">
                                                <div className="loading" />
                                            </div>
                                            :
                                            <div className="lista-videos">
                                                {!ficheiros.length ? <span>Sem dados</span> : null}
                                                {ficheiros.map((ficheiro, index) => (
                                                    <div key={index} className="item-video">
                                                        <span className="video-titulo">{ficheiro}</span>
                                                        <div className="video-controlos">
                                                            <Link to="#" className="botao-icon-download" title="Descarregar" onClick={() => this.downloadFicheiro(index, ficheiro, this.state.anotacaoId)}>
                                                                <Icon type="download" />
                                                            </Link>
                                                            <Link to="#" className="botao-icon-excluir" title="Excluir" onClick={() => this.excluirFicheiro(index)}>
                                                                <Icon type="delete" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </Form.Item>
                                </Form>
                            </span>
                            <div className="anotacao-controlos">
                                <Link
                                    to="#"
                                    className="botao-secundario"
                                    onClick={this.voltar}
                                >
                                    Voltar
                            </Link>
                                <button
                                    onClick={this.alterarAnotacao}
                                    className="botao-principal"
                                    style={{ marginLeft: 20 }}
                                >
                                    {iconLoading ? <Icon type="loading" /> : null}
                                                Guardar
                            </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };
}

const FormAnotacao = Form.create({ name: "anotacoes" })(Anotacao);

export default FormAnotacao;
