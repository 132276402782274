import React, { Component } from "react";
import { Drawer, Form, Input, Icon, notification, Checkbox, Tooltip } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerAdicionarAvaliacao extends Component {
    state = {
        titulo: "",
        /*peso: "",*/
        recuperacao: false,
        ordem: "",
        //LOADING
        iconLoading: false,
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({
            [event.target.name]: event.target.checked,
            /*peso: 0*/
        });
    };

    criar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                var _avalicoes = this.props.avalicoesNoPeriodo.filter(x => !x.recuperacao)
                var recuperacao = this.props.avalicoesNoPeriodo.find(x => x.recuperacao)

                if (_avalicoes.length >= 10 && !this.state.recuperacao) {
                    openNotificationWithIcon("warning", "Atenção", "Limite de 10 avaliações por periodo");
                    return false
                }

                if (recuperacao && this.state.recuperacao) {
                    openNotificationWithIcon("warning", "Atenção", "Limite de 1 recuperação por periodo");
                    return false
                }

                if (this.props.existeRecuperacao && this.state.recuperacao) {
                    openNotificationWithIcon("warning", "Atenção", "Já existe uma avaliação de recuperação.");
                    return false
                }

                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id_periodo", this.props.periodoId);
                item.append("titulo", this.state.titulo);
                /*item.append("peso", this.state.peso);*/
                item.append("recuperacao", this.state.recuperacao);
                item.append("ordem", this.state.ordem);

                axios({
                    method: "post",
                    url: "/api/aulas/adicionar-avaliacao",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "sucesso", "Avaliação adicionada!");
                        this.setState({ iconLoading: false });
                        this.props.avaliacaoAdicionada(response.data)
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível adicionar a avaliação!");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    }

    detalhe = avaliacaoId => {
        axios({
            method: "get",
            url: "/api/aulas/detalhe-avaliacao",
            params: {
                avaliacaoId
            }
        })
            .then(response => {
                let evento = response.data;
                this.setState({
                    titulo: evento.titulo,
                    /*peso: evento.peso,*/
                    recuperacao: evento.recuperacao,
                    ordem: evento.ordem,
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    }

    alterar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (this.props.existeRecuperacao && this.state.recuperacao) {
                    openNotificationWithIcon("warning", "Atenção", "Já existe uma avaliação de recuperação.");
                    return false
                }

                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.avaliacaoId);
                item.append("titulo", this.state.titulo);
                /*item.append("peso", this.state.peso);*/
                item.append("recuperacao", this.state.recuperacao);
                item.append("ordem", this.state.ordem);

                axios({
                    method: "put",
                    url: "/api/aulas/alterar-avaliacao",
                    data: item
                })
                    .then(response => {
                        this.setState({ iconLoading: false });
                        this.props.avaliacaoAlterada(response.data)
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível alterar a aula!");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    }

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            if (this.props.avaliacaoId) {
                this.detalhe(this.props.avaliacaoId)
            }
            else {
                this.setState({
                    titulo: "",
                    /*peso: "",*/
                    recuperacao: false,
                    ordem: this.props.ordem,
                    //LOADING
                    iconLoading: false
                })
            }
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            titulo,
            /*peso,*/
            recuperacao,
            ordem,
            //LOADING
            iconLoading
        } = this.state;

        const {
            visible,
            avaliacaoId
        } = this.props;

        return (
            <>
                <Drawer
                    className="drawer-adicionar-evento"
                    //title={avaliacaoId ? "Alterar" : "Adicionar"}
                    width={720}
                    onClose={this.props.onClose}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Instrumento avaliativo">
                                    {getFieldDecorator("titulo", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: titulo
                                    })(<Input name="titulo" onChange={this.handleChange} />)}
                                </Form.Item>
                                {/*<Form.Item label="Percentual da nota (%)">
                                    {getFieldDecorator("peso", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: peso
                                    })(<Input name="peso" onChange={this.handleChange} disabled={recuperacao} />)}
                                </Form.Item>*/}
                                <Form.Item>
                                    <Checkbox name="recuperacao" checked={recuperacao} onChange={this.handleChangeCheckbox}>
                                        Recuperação
                                        <Tooltip className="info-icon" title="Marcar como avaliação de recuperação">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                        </Checkbox>
                                </Form.Item>
                                <Form.Item label="Ordem">
                                    {getFieldDecorator("ordem", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: ordem
                                    })(<Input name="ordem" onChange={this.handleChange} />)}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        <button className="botao-principal" onClick={avaliacaoId ? this.alterar : this.criar} disabled={iconLoading} type="primary">
                            {iconLoading ? <Icon type="loading" /> : null}
                            Guardar
                        </button>
                        {/*{avaliacaoId ? (*/}
                        {/*    <button className="botao-principal" onClick={this.alterar} disabled={iconLoading} type="primary">*/}
                        {/*        {iconLoading ? <Icon type="loading" /> : null}*/}
                        {/*        Guardar*/}
                        {/*    </button>*/}
                        {/*) : (*/}
                        {/*    <button className="botao-principal" onClick={this.criar} disabled={iconLoading} type="primary">*/}
                        {/*        {iconLoading ? <Icon type="loading" /> : null}*/}
                        {/*        Criar*/}
                        {/*    </button>*/}
                        {/*)}*/}
                    </div>
                </Drawer>
            </>
        );
    }
}

const FormDrawerAdicionar = Form.create({ name: "form-drawer-adicionar" })(DrawerAdicionarAvaliacao);

export default FormDrawerAdicionar;