import React, { useState, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, notification } from 'antd';
import UserInfo from "../UserInfo/UserInfo";
import Tabela from "./Tabela";
import ModalLoading from "../../../Modals/ModalLoading";
import ModalViewer from "../../../Modals/ModalViewer";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Documentos = ({ menuOptionSelected, match }) => {
    const globalContext = useContext(GlobalContext);
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [visibleViewer, setVisibleViewer] = useState(false);
    const [docs, setDocs] = useState([]);

    const gerarDoc = () => {
        setVisibleLoading(true);
        axios({
            method: "get",
            url: "/api/pdf/dados-gerais-documentacao",
            params: {
                code: match.params.code,
                ano: globalContext[`ano${match.params.code}`]
            }
        })
            .then(response => {
                setVisibleLoading(false);
                setVisibleViewer(true)
                setDocs([{ uri: response.data }])
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível gerar documento!");
                setVisibleLoading(false);
            });
    }

    const carregarAnosDocumentos = () => {
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-anos-documentos",
            params: {
                code: match.params.code
            }

        })
            .then(response => {
                globalContext.atualizarState({
                    [`ano${match.params.code}`]: ""
                });
                globalContext.atualizarState({
                    [`listaAnos${match.params.code}`]: response.data
                });
                if (response.data.length) {
                    globalContext.atualizarState({
                        [`ano${match.params.code}`]: response.data[0]
                    });
                }
                else
                    globalContext.atualizarState({
                        [`ano${match.params.code}`]: "Todos"
                    });
            })
            .catch(error => {

            });
    };

    return (
        <>
            <div className="page-container">
                <UserInfo
                    documentos
                    carregarAnosDocumentos={carregarAnosDocumentos}
                />
                <div className="user-info-detail">
                    <div className="page-info">
                        <div>
                            <h1 className="page-title" style={{ margin: "20px 20px 10px 20px" }}>Documentação</h1>
                        </div>
                        <span className="page-controls">
                            <Link className="botao-icon-detalhe botao-maior" to="#" title="Descarregar" onClick={gerarDoc}>
                                <Icon type="eye" />
                            </Link>
                        </span>
                    </div>
                    <div className="block-table">
                        <Tabela carregarAnosDocumentos={carregarAnosDocumentos} />
                    </div>
                </div>
                <ModalLoading
                    visible={visibleLoading}
                    text="A gerar documento..."
                />
                <ModalViewer
                    visible={visibleViewer}
                    docs={docs}
                    onCancel={() => setVisibleViewer(false)}
                />
            </div>
        </>
    );
}

export default withRouter(Documentos);