import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
    Drawer,
    Table,
    Form,
    Icon,
    Input,
    Switch,
    Select,
    Spin,
    Dropdown,
    Menu,
    Checkbox,
    notification,
    Modal,
    DatePicker,
    Tooltip,
    Upload,
    Radio,
    InputNumber,
    Collapse
} from "antd";
import Header from "../../User/Header";
import DrawerInformacoes from "../../GerirCursos/_Aux/FormInformacoes";
//import noimage from "../../images/noimage.png";
import axios from "axios";
import moment from "moment";
//import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";
import DrawerHorarios from "./_Aux/DrawerHorarios";
import DrawerFicha from "./_Aux/DrawerFicha";
import DrawerMultisseriado from "./Multisseriado/Drawer";
import ModalMover from "./_Aux/ModalMover";
//import ModalDuplicarChecklist from "./_Aux/ModalDuplicarChecklist";
import ModalExportar from "../../Modals/ModalExportar";
import ModalViewer from "../../Modals/ModalViewer";
//import DrawerAgrupar from "./_Aux/DrawerAgrupar";
import { GlobalContext } from "../../GlobalState";
import Acessos from "../../Geral/Acessos/Acessos";
import ProcurarTurma from "./_Aux/ProcurarTurma";
import { FaClone } from "react-icons/fa";

//const { Panel } = Collapse;
const confirm = Modal.confirm;
const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class GestaoCursosOrganismos extends Component {
    static contextType = GlobalContext;
    state = {
        escolas: [],
        edicoes: [],
        anosLetivos: [],
        turnos: [],
        categorias: [],
        escolasCarregadas: false,
        //FILTROS
        pesquisa: "",
        filtro: 2,
        filtroEdicao: parseInt(localStorage.getItem("filtroEdicao")) || undefined, // Retrieve from localStorage
        filtroCategoria: parseInt(localStorage.getItem("filtroCategoria")) || undefined, // Retrieve from localStorage
        filtroOrganismo: undefined,
        ativo: true,
        //TABELA
        loading_table: false,
        cursos: [],
        cursosMobile: [],
        //PAGINATION
        pagination: {
            pageSize: 10,
            current: 1,
            total: 0
        },
        //DRAWER
        templates: [],
        templateId: 0,
        cursoid: 0,
        disciplinaid: 0,
        escolaId: undefined,
        capa: "",
        preview: "",
        titulo: "",
        breveDescricao: "",
        categoriaId: "",
        idiomas: [],
        idiomasTrascricao: [],
        descricao: "",
        certificacao: "NAO_APLICAVEL",
        portaria: "",
        componente_formacao: "",
        codigo: "",
        nr_certificacao: "",
        infos: [],
        questionario_ag: "",
        questionario_ac: "",
        questionario_ai: "",
        questionario_ig: "",
        questionario_ic: "",
        questionario_ia: "",
        formadores: [],
        dt_inicio: null,
        dt_inicio_limite: null,
        dt_disponivel: null,
        dt_fim: null,
        dt_fim_limite: null,
        atualizarEstruturasDisciplinas: false,
        pareceresPorAula: false,
        //GRUPO PERIODOS
        periodos: [],
        precedencia: undefined,
        obrigatorio: false,
        precedencias: [],
        creditos: 0,
        horas_trabalho: 1,
        carga_horaria: 1,
        horas_contacto: [],
        tipo_horas: [],
        editavel_formadores: true,
        fechado: false,
        habilitar_competencias: false,
        editavel_gestao: false,
        curso_visivel: false,
        conteudos_visiveis: false,
        tipo_inscricao: "MANUAL",
        inscricao: true,
        visivel_inscritos: true,
        esconderListaParticipantes: false,
        habilitarCompetencias: false,
        habilitarGamificacao: false,
        valorCurso: 0,
        valorCertificado: 0,
        subscricao: false,
        dt_fecho_inscricoes: null,
        tipologia: "NAO_APLICAVEL",
        horarios: [],
        horario: "",
        horarioIndex: "",
        dedicacao: "NAO_APLICAVEL",
        paginaExterna: "",
        calendarioId: undefined,
        profTitularId: undefined,
        discComAulas: false,
        ano: undefined,
        turma: "",
        turno: undefined,
        habilitarProfessorPolivalente: false,
        diario: false,
        fasesEscolares: [],
        habilitarConteudosStepforma: false,
        atividadeConteudosStepforma: undefined,
        listaAtividadesConteudosStepforma: [],
        curso_eja: false,
        percentagemFaltas: 0,
        curso_ordem: 1,
        curso_grupo: false,
        disciplina_grupo: false,
        professor_submeter: false,
        plano_estudos: "",
        plano_estudos_caminho: "",
        fileList: [],
        //FICHAS
        listaFichas: [],
        fichaId: undefined,
        fichaCampos: [],
        encerramento: "Manual",
        //MULTISSERIADO
        turmaId: 0,
        multisseriadoId: 0,
        visibleDrawerMultisseriado: false,
        //DRAWER
        visible: false,
        visibleDisciplina: false,
        visibleGrupoDrawer: false,
        visibleGrupoDisciplinaDrawer: false,
        procurar: false,
        iconLoading: false,
        //SELECTS
        linguas: [],
        listaTranscricaoLinguas: [],
        listaTipos: [],
        listaCalendarios: [],
        listaTiposDiario: [],
        listaCategorias: [],
        listaOrganismos: [],
        listaQuestionariosAG: [],
        listaQuestionariosAC: [],
        listaQuestionariosAI: [],
        listaQuestionariosIG: [],
        listaQuestionariosIC: [],
        listaQuestionariosIA: [],
        listaProcurarCoordenadores: [],
        listaCoordenadores: [],
        listaProcurarFormadores: [],
        listaFormadores: [],
        listaProcurarPrecedencia: [],
        listaPrecedencia: [],
        listaProfs: [],
        //UPLOAD IMAGEM VALIDATE
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
        //DRAWER INFORMACOES
        index: "",
        dataInfo: null,
        descricaoInfo: "",
        detalhe: false,
        visibleInformacoes: false,
        //VALIDAÇÔES INFO CURSO
        info_status: "",
        info_help: "",
        fichaCurso: [],
        fichaCursoEliminado: false,
        fichaInscricao: [],
        fichaInscricaoEliminado: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //MODAL QUESTIONARIO FORMANDO
        questoesQuestionarioFormandos: [],
        visibleQuestionarioFormandos: false,
        dt_inicioQuestFormando: null,
        dt_fimQuestFormando: null,
        botaoQuestFormando: true,
        //MODAL QUESTIONARIO FORMADOR
        questoesQuestionarioFormadores: [],
        visibleQuestionarioFormadores: false,
        dt_inicioQuestFormador: null,
        dt_fimQuestFormador: null,
        botaoQuestFormador: true,
        //MODAL DUPLICAR CHECKLIST
        cursoIdDuplicar: 0,
        tipoCurso: "",
        visibleDuplicarChecklist: false,
        duplicarOpcoesSelecionadas: [],
        //MODAL DUPLICAR
        duplicarDisciplina: false,
        visibleDuplicar: false,
        textoModal: "",
        //COPIAR/COLAR
        id_copia: 0,
        //DRAWER DETALHE
        visibleDetalhe: false,
        detalheCategoria: "",
        detalhePeriodo: "",
        detalheEstado: "",
        detalheInscritos: "",
        //DRAWER HORARIOS,
        visibleDrawerHorarios: false,
        detalheHorario: false,
        //MODAL MOVER
        visibleMover: false,
        cursoPai: false,
        //EXPORT
        visibleExport: false,
        buttonDownload: false,
        exportFile: "",
        //DRAWER FICHA
        visibleDrawerFicha: false,
        //VIEWER
        docs: [],
        visibleViewer: false,
        //VALIDATE
        coordenadoresStatus: {
            validateStatus: "",
            help: ""
        },
        professoresStatus: {
            validateStatus: "",
            help: ""
        },
        estruturaStatus: {
            validateStatus: "",
            help: ""
        }
    };

    UNSAFE_componentWillMount() {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));

            this.setState(
                {
                    pesquisa: opcaoInfo.pesquisa,
                    filtroCalendario: opcaoInfo.calendario ? opcaoInfo.calendario : undefined,
                    filtroOrganismo: opcaoInfo.organismo ? opcaoInfo.organismo : undefined,
                    ativo: opcaoInfo.ativo,
                    pagination: opcaoInfo.pagination
                },
                () => this.chamarListagens()
            );
        } else {
            this.chamarListagens();
        }
    }

    chamarListagens = () => {
        this.listarEdicoes();
        this.listarAnosLetivos();
        this.listarTurnos();
        this.listarTemplates();
        this.listarCategorias();
        this.totalCursos();
        this.listarCursos();
        this.carregarTiposHoras();
        this.carregarFormadores();
        this.carregarCoordenador();
        this.listarEscolasDrawer();
        localStorage.removeItem("modulo_aberto");
    };

    //VALIDA SE O UTILIZADOR ESTA AUTENTICADO
    componentDidUpdate() {
        const { estabelecimento } = this.props;
        const { filtroOrganismo } = this.state;

        if (estabelecimento?.id && estabelecimento.id !== filtroOrganismo) {
            this.setState(
                {
                    filtroOrganismo: estabelecimento.id
                },
                () => {
                    this.totalCursos();
                    this.listarCursos();
                    this.carregarTiposHoras();
                    this.carregarFormadores();
                    this.carregarCoordenador();
                }
            );
        }

        //if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
        //    this.setState({
        //        redirect: true,
        //        redirectLink: "/user"
        //    });

        if (this.context.escolas_entidade && !this.state.escolasCarregadas) {
            this.setState(
                {
                    escolasCarregadas: true
                },
                () => {
                    this.totalCursos();
                    this.listarCursos();
                }
            );
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangePeriodo = (event, index) => {
        let periodos = this.state.periodos;
        periodos[index].titulo = event.target.value;
        this.setState({ periodos });
    };

    handleChangePeso = (event, index) => {
        let periodos = this.state.periodos;
        periodos[index].peso = event.target.value;
        this.setState({ periodos });
    };

    handleChangeOrdem = (value, index) => {
        let periodos = this.state.periodos;
        periodos[index].ordem = value;
        this.setState({ periodos });
    };

    handleChangePeriodoDataInicio = (date, index) => {
        let periodos = this.state.periodos;
        periodos[index].dt_inicio = date;
        this.setState({ periodos });
    };

    handleChangePeriodoDataFim = (date, index) => {
        let periodos = this.state.periodos;
        periodos[index].dt_fim = date;
        this.setState({ periodos });
    };

    handleChangeCarga = value => {
        this.setState({ carga_horaria: value });
    };

    handleChangeInicio = date => {
        if (this.state.dt_disponivel)
            this.setState({
                dt_inicio: date
            });
        else
            this.setState({
                dt_inicio: date,
                dt_disponivel: date,
                dt_fecho_inscricoes: date
            });
    };

    handleChangeFim = date => {
        this.setState({ dt_fim: date });
    };

    handleChangeDis = date => {
        this.setState({ dt_disponivel: date });
    };

    handleChangeFechoInscricoes = date => {
        this.setState({ dt_fecho_inscricoes: date });
    };

    handleChangeData = date => {
        this.setState({ dataInfo: date });
    };

    handlerChangeSelectCategoria = value => {
        this.setState({ categoriaId: value });
    };

    handleChangeSelectCalendario = calendarioId => {
        const cal = this.state.listaCalendarios.find(x => x.id === calendarioId);
        this.setState({
            calendarioId,
            dt_inicio: moment(cal.dt_inicio, "YYYY-MM-DD"),
            dt_fim: moment(cal.dt_termino, "YYYY-MM-DD")
        });

        //this.carregarParametros(calendarioId);
    };

    handleChangeSelectProfTitular = profTitularId => {
        this.setState({
            profTitularId
        });
    };

    //handleChangeSelectOrganismo = escolaId => {
    //    this.setState({
    //        escolaId
    //    });
    //};

    handlerChangeSelectPrecedencia = value => {
        this.setState({ precedencia: value ? value.key : undefined });
    };

    handlerChangeCheck = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeValorCurso = value => {
        this.setState({ valorCurso: value });
    };

    handleChangeValorCertificado = value => {
        this.setState({ valorCertificado: value });
    };

    handleChangeOrdem = value => {
        this.setState({ curso_ordem: value });
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    handleChangeTable = (pagination, filters, sorter) => {
        var _pagination = this.state.pagination;
        _pagination.current = pagination.current;

        this.setState(
            {
                pagination: _pagination
            },
            () => {
                this.listarCursos();
            }
        );
    };

    handleChangeFiltroCalendarios = value => {
        this.setState(
            {
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                },
                filtroCalendario: value
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    handleChangeFiltroEdicoes = value => {
        this.setState(
            {
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                },
                filtroEdicao: value
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
        localStorage.setItem("filtroEdicao", value);
    };

    handleChangeFiltroCategorias = value => {
        this.setState(
            {
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                },
                filtroCategoria: value
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
        localStorage.setItem("filtroCategoria", value);
    };

    handleChangeFiltroOrganismos = value => {
        this.setState(
            {
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                },
                filtroOrganismo: value
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    handleChangeTituloFase = (event, index) => {
        let fasesEscolares = this.state.fasesEscolares;
        fasesEscolares[index].titulo = event.target.value;
        this.setState({ fasesEscolares });
    };

    handleChangeFase = (value, index) => {
        const fase = this.state.listaTiposDiario.find(x => x.id === value);
        let fasesEscolares = this.state.fasesEscolares;

        fasesEscolares[index].tipo = value;
        fasesEscolares[index].periodo = fase.periodo_letivo;
        fasesEscolares[index].genero = fase.tipo;

        this.setState({ fasesEscolares });
    };

    handleChangeFaseOrdem = (value, index) => {
        let fasesEscolares = this.state.fasesEscolares;

        fasesEscolares[index].ordem = value;

        this.setState({ fasesEscolares });
    };

    handlerChangeCheckFase = (event, index) => {
        let fasesEscolares = this.state.fasesEscolares;
        fasesEscolares[index].principal = event.target.checked;
        this.setState({ fasesEscolares });
    };

    handleChangeHorasContacto = (value, index) => {
        let horas_contacto = this.state.horas_contacto;
        horas_contacto[index].id = value;
        this.setState({ horas_contacto });
    };

    handleChangeHorasContactoCarga = (value, index) => {
        let horas_contacto = this.state.horas_contacto;
        horas_contacto[index].carga_horaria = value;
        this.setState({ horas_contacto });
    };

    handleChangeCoordenador = (value, index) => {
        let listaProcurarCoordenadores = this.state.listaProcurarCoordenadores;
        listaProcurarCoordenadores[index].id = value;
        this.setState({ listaProcurarCoordenadores });
    };

    handleChangeCoordenadorCarga = (value, index) => {
        let listaProcurarCoordenadores = this.state.listaProcurarCoordenadores;
        listaProcurarCoordenadores[index].carga_horaria = value;
        this.setState({ listaProcurarCoordenadores });
    };

    handleChangeCoordenadorReplicar = (value, index) => {
        let listaProcurarCoordenadores = this.state.listaProcurarCoordenadores;
        listaProcurarCoordenadores[index].replicar = value;
        this.setState({ listaProcurarCoordenadores });
    };

    handleChangeProfessor = (value, index) => {
        let listaProcurarFormadores = this.state.listaProcurarFormadores;
        listaProcurarFormadores[index].id = value;
        this.setState({ listaProcurarFormadores });
    };

    handleChangeProfessorCarga = (value, index) => {
        let listaProcurarFormadores = this.state.listaProcurarFormadores;
        listaProcurarFormadores[index].carga_horaria = value;
        this.setState({ listaProcurarFormadores });
    };

    handleChangeProfessorCarga = (value, index) => {
        let listaProcurarFormadores = this.state.listaProcurarFormadores;
        listaProcurarFormadores[index].carga_horaria = value;
        this.setState({ listaProcurarFormadores });
    };

    handlerChangeTemplate = turmaId => {
        axios({
            method: "get",
            url: "/api/gestao-templates-turmas/detalhe-turma",
            params: {
                turmaId
            }
        })
            .then(response => {
                this.props.form.resetFields();
                const info = response.data;

                this.setState({
                    templateId: turmaId,
                    ano: info.ano,
                    categoriaId: info.id_nivel,
                    turno: info.id_turno ? info.id_turno : undefined,
                    codigo: info.codigo,
                    plano_estudos: info.plano_estudos,
                    plano_estudos_caminho: info.plano_estudos_caminho,
                    fasesEscolares: [{ tipo: info.id_diario }]
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    handleChangeAtualizarEstruturasDisciplinas = atualizarEstruturasDisciplinas => {
        this.setState({
            atualizarEstruturasDisciplinas
        });
    };

    handleChangePareceresPorAula = pareceresPorAula => {
        this.setState({
            pareceresPorAula
        });
    };

    //PROCURA ENTIDADES
    HandleKeyPress = e => {
        var pesquisa = e.target.value;
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa,
                    pagination: {
                        pageSize: 10,
                        current: 1,
                        total: 0
                    }
                },
                () => {
                    this.totalCursos();
                    this.listarCursos();
                }
            );
        }
    };

    //LIMPA A PESQUISA APOR LIMPAR A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: "",
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                }
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    onClick = () => {
        this.context.atualizarState({
            lista_modulos: [],
            lista_disciplinas: []
        });
    };

    //listarEscolas = () => {
    //    axios({
    //        method: "get",
    //        url: "/api/gestao-organismos/listar-organismos"
    //    })
    //        .then(response => {
    //            this.setState({
    //                escolas: response.data
    //            });
    //        })
    //        .catch(error => {
    //            if (error.response.status === 401)
    //                this.setState({
    //                    redirect: true,
    //                    redirectLink: "/login"
    //                });
    //        });
    //};

    listarEscolasDrawer = () => {
        axios({
            method: "get",
            url: "/api/gestao-organismos/listar-organismos"
        })
            .then(response => {
                this.setState({
                    listaOrganismos: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    abrirDetalhe = curso => {
        const detalhePeriodo = (
            <div>
                <span>
                    {curso.dt_fim
                        ? `${moment(curso.dt_inicio).format("DD/MM/YYYY")} - ${moment(curso.dt_fim).format("DD/MM/YYYY")}`
                        : moment(curso.dt_inicio).format("DD/MM/YYYY")}
                </span>
                {curso.fechado ? (
                    <div className="tag-fechado">
                        Fechado (
                        {curso.dt_fechado && <span style={{ fontSize: "12px" }}>{moment(curso.dt_fechado).format("DD/MM/YYYY")})</span>}
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
        const detalheInscritos = curso.inscritos;

        this.setState({
            visibleDetalhe: true,
            detalheCategoria: curso.categoria,
            detalhePeriodo,
            detalheInscritos
        });
    };

    // MONTA O MENU DE OPÇÕES POR CADA CURSO COM DISCIPLINAS
    montarMenuCurso = curso => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };
        const acesso = this.context.acessos?.find(x => x.func === 1);
        const acessoP = this.context.acessos?.find(x => x.func === 26);

        if (this.state.ativo)
            return (
                <div style={divStyle}>
                    {acesso?.create && (
                        <Link
                            to="#"
                            className="botao-icon-adicionar"
                            title="Adicionar Disciplina"
                            onClick={event => {
                                event.stopPropagation();
                                this.showDrawerDisciplina(curso.id, curso.dt_inicio, curso.dt_fim, curso.organismo);
                            }}
                        >
                            <Icon type="plus" />
                        </Link>
                    )}
                    {acesso?.update && (
                        <Link
                            to="#"
                            className="botao-icon-editar"
                            title="Editar"
                            onClick={
                                !curso.grupo
                                    ? event => {
                                          event.stopPropagation();
                                          this.editar(curso.id, 0, false);
                                      }
                                    : event => {
                                          event.stopPropagation();
                                          this.editarGrupo(curso.id, 0, false);
                                      }
                            }
                        >
                            <Icon type="edit" />
                        </Link>
                    )}
                    {acesso?.update && (
                        <Link
                            to="#"
                            className="botao-icon-inativar"
                            title="Inativar"
                            onClick={event => {
                                event.stopPropagation();
                                this.ativarCursosDisciplinas(curso.id);
                            }}
                        >
                            <Icon type="close" />
                        </Link>
                    )}
                    {(!curso.grupo && acessoP) && (
                        <Link
                            to="#"
                            className="botao-icon-enviar"
                            title="Duplicar"
                            onClick={event => {
                                event.stopPropagation();
                                this.duplicarTurma(curso.id);
                            }}
                        >
                            <FaClone />
                        </Link>
                    )}
                    <Dropdown
                            overlay={this.montarMenuDropdown(curso)}
                            placement="bottomLeft"
                            onClick={event => event.stopPropagation()}
                            overlayStyle={{
                                zIndex: "9"
                            }}
                        >
                            <Link to="#" className="botao-icon-configs">
                                <i className="fas fa-cog" />
                            </Link>
                        </Dropdown>
                </div>
            );

        return (
            <div style={divStyle}>
                {acesso?.update && (
                    <Link
                        to="#"
                        className="botao-icon-ativar"
                        title="Ativar"
                        onClick={event => {
                            event.stopPropagation();
                            this.ativarCursosDisciplinas(curso.id);
                        }}
                    >
                        <Icon type="check" />
                    </Link>
                )}
                {acesso?.delete && (
                    <Link
                        to="#"
                        className="botao-icon-excluir"
                        title="Excluir"
                        onClick={event => {
                            event.stopPropagation();
                            this.excluirCursoDisciplina(curso.id);
                        }}
                    >
                        <Icon type="delete" />
                    </Link>
                )}
            </div>
        );
    };

    montarMenuDropdown = curso => {
        const acesso = this.context.acessos?.find(x => x.func === 1);
        const acessoM = this.context.acessos?.find(x => x.func === 13);
        const acessoMultisseriado = this.context.acessos?.find(x => x.func === 27);

        return (
            <Menu>
                {acesso?.update && (
                    <Menu.Item>
                        {curso.fechado ? (
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.fechar(curso.id, curso.fechado);
                                }}
                            >
                                Reabrir
                            </Link>
                        ) : (
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.fechar(curso.id, curso.fechado);
                                }}
                            >
                                Fechar
                            </Link>
                        )}
                    </Menu.Item>
                )}
                {/*{!curso.grupo ? (*/}
                {/*    <Menu.Item>*/}
                {/*        <Link to={`/gestao-agrupar-turmas/${curso.code}`}>Agrupar</Link>*/}
                {/*    </Menu.Item>*/}
                {/*) : (*/}
                {/*    <Menu.Item>*/}
                {/*        <Link*/}
                {/*            to="#"*/}
                {/*            onClick={event => {*/}
                {/*                event.stopPropagation();*/}
                {/*                this.desagrupar(curso.id, curso.nome);*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Desagrupar*/}
                {/*        </Link>*/}
                {/*    </Menu.Item>*/}
                {/*)}*/}
                {acessoM && (
                    <Menu.Item>
                        <Link
                            to={`/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-turma-inscricoes`}
                            onClick={() => {
                                localStorage.setItem("cod_curso", curso.codigo ? curso.codigo : "");
                                localStorage.setItem("nome_curso", curso.nome);
                                localStorage.setItem("codigo_curso", curso.id);
                                localStorage.setItem("escola_id", curso.organismo);
                                localStorage.setItem("return_link", "/gestao-turmas");
                                localStorage.removeItem("nome_curso_pai");
                            }}
                        >
                            Matrículas
                        </Link>
                    </Menu.Item>
                )}
                {acessoMultisseriado && (
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.setState({
                                    turmaId: curso.id,
                                    multisseriadoId: curso.multisseriado?.id,
                                    visibleDrawerMultisseriado: true
                                })
                            }}
                        >
                            Multisseriado
                        </Link>
                    </Menu.Item>)}
            </Menu>
        );
    };

    exportApoio = (cursoId, tipo) => {
        this.setState(
            {
                visibleExport: true,
                buttonDownload: true
            },
            () => {
                axios({
                    method: "get",
                    url: `/api/concelho-classe/${tipo}`,
                    params: {
                        cursoId
                    },
                    timeout: 60 * 30 * 1000
                })
                    .then(response => {
                        this.setState({
                            buttonDownload: false,
                            exportFile: response.data
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 400) openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        else if (error.response?.data != "") openNotificationWithIcon("error", "Erro", error.response.data);
                        else openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");

                        this.setState({
                            visibleExport: false,
                            buttonDownload: false
                        });
                    });
            }
        );
    };

    montarMenuCursoMobile = curso => {
        const acesso = this.context.acessos?.find(x => x.func === 1);
        const acessoM = this.context.acessos?.find(x => x.func === 13);

        if (this.state.ativo) {
            return (
                <Menu>
                    {!curso.grupo && acesso?.create && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.showDrawerDisciplina(curso.id, curso.dt_inicio, curso.dt_fim, curso.organismo);
                                }}
                            >
                                Adicionar Disciplina
                            </Link>
                        </Menu.Item>
                    )}
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.abrirDetalhe(curso);
                            }}
                        >
                            Detalhe
                        </Link>
                    </Menu.Item>
                    {acesso?.update && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.editar(curso.id, 0, false);
                                }}
                            >
                                Editar
                            </Link>
                        </Menu.Item>
                    )}
                    {acesso?.update && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.ativarCursosDisciplinas(curso.id);
                                }}
                            >
                                Inativar
                            </Link>
                        </Menu.Item>
                    )}
                    {acesso?.update && (
                        <Menu.Item>
                            {curso.fechado ? (
                                <Link
                                    to="#"
                                    onClick={event => {
                                        event.stopPropagation();
                                        this.fechar(curso.id, curso.fechado);
                                    }}
                                >
                                    Reabrir
                                </Link>
                            ) : (
                                <Link
                                    to="#"
                                    onClick={event => {
                                        event.stopPropagation();
                                        this.fechar(curso.id, curso.fechado);
                                    }}
                                >
                                    Fechar
                                </Link>
                            )}
                        </Menu.Item>
                    )}
                    {!curso.grupo && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.duplicarTurma(curso.id);
                                }}
                            >
                                Duplicar
                            </Link>
                        </Menu.Item>
                    )}
                    {/*{!curso.grupo ? (*/}
                    {/*    <Menu.Item>*/}
                    {/*        <Link to={`/gestao-agrupar-turmas/${curso.code}`}>Agrupar</Link>*/}
                    {/*    </Menu.Item>*/}
                    {/*) : (*/}
                    {/*    <Menu.Item>*/}
                    {/*        <Link*/}
                    {/*            to="#"*/}
                    {/*            onClick={event => {*/}
                    {/*                event.stopPropagation();*/}
                    {/*                this.desagrupar(curso.id, curso.nome);*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            Desagrupar*/}
                    {/*        </Link>*/}
                    {/*    </Menu.Item>*/}
                    {/*)}*/}
                    {acessoM && (
                        <Menu.Item>
                            <Link
                                to={`/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-turma-inscricoes`}
                                onClick={() => {
                                    localStorage.setItem("tipo_inscricao", curso.tipo_inscricao);
                                    localStorage.setItem("cod_curso", curso.codigo ? curso.codigo : "");
                                    localStorage.setItem("nome_curso", curso.nome);
                                    localStorage.setItem("codigo_curso", curso.id);
                                    localStorage.setItem("escola_id", curso.organismo);
                                    localStorage.removeItem("nome_curso_pai");
                                }}
                            >
                                Matrículas
                            </Link>
                        </Menu.Item>
                    )}
                    {/*<Menu.Item>*/}
                    {/*    <Link to="#" onClick={() => { }}>*/}
                    {/*        Estatísticas*/}
                    {/*    </Link>*/}
                    {/*</Menu.Item>*/}
                </Menu>
            );
        } else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.abrirDetalhe(curso);
                            }}
                        >
                            Detalhe
                        </Link>
                    </Menu.Item>
                    {acesso?.update && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.ativarCursosDisciplinas(curso.id);
                                }}
                            >
                                Ativar
                            </Link>
                        </Menu.Item>
                    )}
                    {acesso?.delete && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.excluirCursoDisciplina(curso.id);
                                }}
                            >
                                Excluir
                            </Link>
                        </Menu.Item>
                    )}
                    {/*<Menu.Item>*/}
                    {/*    <Link to="#" onClick={() => { }}>*/}
                    {/*        Estatísticas*/}
                    {/*    </Link>*/}
                    {/*</Menu.Item>*/}
                </Menu>
            );
        }
    };

    // MONTA O MENU DE OPÇÕES POR CADA DISCIPLINA
    montarMenuDisciplina = (subcurso, cursoIndex, curso) => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };
        const acesso = this.context.acessos?.find(x => x.func === 1);

        if (subcurso.ativo)
            return (
                <div style={divStyle}>
                    {acesso?.update && (
                        <Link
                            to="#"
                            className="botao-icon-editar"
                            title="Editar"
                            onClick={
                                !subcurso.agrupado
                                    ? event => {
                                          event.stopPropagation();
                                          this.editar(curso.id, subcurso.id, true, curso.grupo);
                                          this.setState({ dt_inicio_limite: curso.dt_inicio, dt_fim_limite: curso.dt_fim });
                                      }
                                    : event => {
                                          event.stopPropagation();
                                          this.editarGrupoDisciplina(curso.id, subcurso.id);
                                      }
                            }
                        >
                            <Icon type="edit" />
                        </Link>
                    )}
                    {acesso?.update && (
                        <Link
                            to="#"
                            className="botao-icon-inativar"
                            title="Inativar"
                            onClick={event => {
                                event.stopPropagation();
                                this.ativarDisciplina(subcurso.id, subcurso.ativo, cursoIndex);
                            }}
                        >
                            <Icon type="close" />
                        </Link>
                    )}
                    {!curso.grupo && (
                        <Link
                            to="#"
                            className="botao-icon-enviar"
                            title="Duplicar"
                            onClick={event => {
                                event.stopPropagation();
                                this.duplicarDisciplina(subcurso.id);
                            }}
                        >
                            <FaClone />
                        </Link>
                    )}
                    {acesso?.update && (
                        <Dropdown
                            overlay={this.montarMenuDropdownDisciplina(subcurso)}
                            placement="bottomLeft"
                            onClick={event => event.stopPropagation()}
                            overlayStyle={{
                                zIndex: "9"
                            }}
                        >
                            <Link to="#" className="botao-icon-configs">
                                <i className="fas fa-cog" />
                            </Link>
                        </Dropdown>
                    )}
                </div>
            );

        return (
            <div style={divStyle}>
                {acesso?.update && (
                    <Link
                        to="#"
                        className="botao-icon-ativar"
                        title="Ativar"
                        onClick={event => {
                            event.stopPropagation();
                            this.ativarDisciplina(subcurso.id, subcurso.ativo, cursoIndex);
                        }}
                    >
                        <Icon type="check" />
                    </Link>
                )}
                {acesso?.delete && (
                    <Link
                        to="#"
                        className="botao-icon-excluir"
                        title="Excluir"
                        onClick={event => {
                            event.stopPropagation();
                            this.excluirDisciplina(subcurso.id, cursoIndex);
                        }}
                    >
                        <Icon type="delete" />
                    </Link>
                )}
            </div>
        );
    };

    montarMenuDropdownDisciplina = subcurso => {
        return (
            <Menu>
                <Menu.Item>
                    {subcurso.fechado ? (
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.fechar(subcurso.id, subcurso.fechado);
                            }}
                        >
                            Reabrir
                        </Link>
                    ) : (
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.fechar(subcurso.id, subcurso.fechado);
                            }}
                        >
                            Fechar
                        </Link>
                    )}
                </Menu.Item>
                {/*<Menu.Item>*/}
                {/*    <Link*/}
                {/*        to="#"*/}
                {/*        onClick={event => {*/}
                {/*            event.stopPropagation();*/}
                {/*            this.setState({ cursoid: subcurso.id, cursoNome: subcurso.nome, visibleMover: true, cursoPai: false });*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Mover*/}
                {/*    </Link>*/}
                {/*</Menu.Item>*/}
            </Menu>
        );
    };

    montarMenuDisciplinaMobile = (subcurso, cursoIndex, curso) => {
        const acesso = this.context.acessos?.find(x => x.func === 1);

        if (subcurso.ativo) {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.abrirDetalhe(subcurso);
                            }}
                        >
                            Detalhe
                        </Link>
                    </Menu.Item>
                    {acesso?.update && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.editar(curso.id, subcurso.id, true);
                                    this.setState({ dt_inicio_limite: curso.dt_inicio, dt_fim_limite: curso.dt_fim });
                                }}
                            >
                                Editar
                            </Link>
                        </Menu.Item>
                    )}
                    {acesso?.update && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.ativarDisciplina(subcurso.id, subcurso.ativo, cursoIndex);
                                }}
                            >
                                Inativar
                            </Link>
                        </Menu.Item>
                    )}
                    {acesso?.update && (
                        <Menu.Item>
                            {subcurso.fechado ? (
                                <Link
                                    to="#"
                                    onClick={event => {
                                        event.stopPropagation();
                                        this.fechar(subcurso.id, subcurso.fechado);
                                    }}
                                >
                                    Reabrir
                                </Link>
                            ) : (
                                <Link
                                    to="#"
                                    onClick={event => {
                                        event.stopPropagation();
                                        this.fechar(subcurso.id, subcurso.fechado);
                                    }}
                                >
                                    Fechar
                                </Link>
                            )}
                        </Menu.Item>
                    )}
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.duplicarDisciplina(subcurso.id);
                            }}
                        >
                            Duplicar
                        </Link>
                    </Menu.Item>
                    {/*<Menu.Item>*/}
                    {/*    <Link*/}
                    {/*        to={`/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-turmas-estatisticas`}*/}
                    {/*        onClick={() => {*/}
                    {/*            localStorage.setItem("cod_curso_pai", curso.codigo);*/}
                    {/*            localStorage.setItem("nome_curso_pai", curso.nome);*/}
                    {/*            localStorage.setItem("cod_subcurso", subcurso.codigo);*/}
                    {/*            localStorage.setItem("nome_subcurso", subcurso.nome);*/}
                    {/*            localStorage.setItem("codigo_curso", subcurso.id);*/}
                    {/*            if (subcurso.id_organismo)*/}
                    {/*                localStorage.setItem("escola_nome", this.state.escolas.find(x => x.id === subcurso.id_organismo).nome);*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Estatísticas*/}
                    {/*</Link>*/}
                    {/*</Menu.Item>*/}
                    {/*<Menu.Item>*/}
                    {/*    <Link*/}
                    {/*        to="#"*/}
                    {/*        onClick={event => {*/}
                    {/*            event.stopPropagation();*/}
                    {/*            this.setState({ cursoid: subcurso.id, cursoNome: subcurso.nome, visibleMover: true, cursoPai: false });*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Mover*/}
                    {/*    </Link>*/}
                    {/*</Menu.Item>*/}
                </Menu>
            );
        } else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.abrirDetalhe(subcurso);
                            }}
                        >
                            Detalhe
                        </Link>
                    </Menu.Item>
                    {acesso?.update && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.ativarDisciplina(subcurso.id, subcurso.ativo, cursoIndex);
                                }}
                            >
                                Ativar
                            </Link>
                        </Menu.Item>
                    )}
                    {acesso?.delete && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.excluirDisciplina(subcurso.id, cursoIndex);
                                }}
                            >
                                Excluir
                            </Link>
                        </Menu.Item>
                    )}
                    {/*<Menu.Item>*/}
                    {/*    <Link*/}
                    {/*        to={`/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-turmas-estatisticas`}*/}
                    {/*        onClick={() => {*/}
                    {/*            localStorage.setItem("cod_curso_pai", curso.codigo);*/}
                    {/*            localStorage.setItem("nome_curso_pai", curso.nome);*/}
                    {/*            localStorage.setItem("cod_subcurso", subcurso.codigo);*/}
                    {/*            localStorage.setItem("nome_subcurso", subcurso.nome);*/}
                    {/*            localStorage.setItem("codigo_curso", subcurso.id);*/}
                    {/*            if (subcurso.id_organismo)*/}
                    {/*                localStorage.setItem("escola_nome", this.state.escolas.find(x => x.id === subcurso.id_organismo).nome);*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Estatísticas*/}
                    {/*</Link>*/}
                    {/*</Menu.Item>*/}
                </Menu>
            );
        }
    };

    duplicarTurma = turmaId => {
        confirm({
            title: `Pretende duplicar esta Turma?`,
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                this.setState(
                    {
                        visibleDuplicarChecklist: false,
                        visibleDuplicar: true,
                        textoModal: "duplicar"
                    },
                    () => {
                        axios({
                            method: "post",
                            url: "/api/gestao-turmas/duplicar-turma",
                            params: {
                                turmaId
                            }
                        })
                            .then(() => {
                                openNotificationWithIcon("success", "Sucesso", "Turma duplicada!");
                                this.totalCursos();
                                this.listarCursos();
                                this.setState({
                                    visibleDuplicar: false
                                });
                            })
                            .catch(() => {
                                openNotificationWithIcon("error", "Erro", "Não foi possível duplicar turma");
                                this.setState({
                                    visibleDuplicar: false
                                });
                            });
                    }
                );
            }
        });
    };

    duplicarDisciplina = disciplinaId => {
        confirm({
            title: `Pretende duplicar esta disciplina?`,
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                this.setState(
                    {
                        visibleDuplicarChecklist: false,
                        visibleDuplicar: true,
                        textoModal: "duplicar"
                    },
                    () => {
                        axios({
                            method: "post",
                            url: "/api/gestao-turmas/duplicar-disciplina",
                            params: {
                                disciplinaId
                            }
                        })
                            .then(() => {
                                openNotificationWithIcon("success", "Sucesso", "Disciplina duplicada!");
                                this.totalCursos();
                                this.listarCursos();
                                this.setState({
                                    visibleDuplicar: false
                                });
                            })
                            .catch(() => {
                                openNotificationWithIcon("error", "Erro", "Não foi possível duplicar disciplina");
                                this.setState({
                                    visibleDuplicar: false
                                });
                            });
                    }
                );
            }
        });
    };

    listarCalendarios = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/lista-calendarios"
        })
            .then(response => {
                this.setState({
                    calendarios: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarCalendariosDrawer = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/lista-calendarios",
            params: {
                cursoId: this.state.cursoid
            }
        })
            .then(response => {
                this.setState({
                    listaCalendarios: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarTemplates = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/carregar-templates"
        })
            .then(response => {
                this.setState({
                    templates: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //CARREGA A LISTA DE CATEGORIAS EXISTENTES NO STEPFORMA
    listarCategorias = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/lista-categorias"
        })
            .then(response => {
                this.setState({
                    categorias: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarCategoriasDrawer = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/lista-categorias",
            params: {
                cursoId: this.state.cursoid
            }
        })
            .then(response => {
                this.setState({
                    listaCategorias: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarEdicoes = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/lista-edicoes"
        })
            .then(response => {
                this.setState({
                    edicoes: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarAnosLetivos = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/lista-anos-letivos"
        })
            .then(response => {
                this.setState({
                    anosLetivos: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarTurnos = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/lista-turnos"
        })
            .then(response => {
                this.setState({
                    turnos: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarEdicoesDrawer = novo => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/lista-edicoes-drawer",
            params: {
                cursoId: this.state.cursoid,
                novo
            }
        })
            .then(response => {
                this.setState({
                    listaEdicoes: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarTiposDiarioDrawer = (novo, cursoId) => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/lista-tipos-diario",
            params: {
                novo,
                cursoId
            }
        })
            .then(response => {
                this.setState({
                    listaTiposDiario: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarFichas = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/listar-fichas"
        })
            .then(response => {
                this.setState({
                    listaFichas: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //TOTAL CURSOS DA ENTIDADE
    totalCursos = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/total-turmas",
            params: {
                pesquisa: this.state.pesquisa,
                filtroEdicao: this.state.filtroEdicao,
                filtroCategoria: this.state.filtroCategoria,
                filtroOrganismo: this.state.filtroOrganismo,
                ativo: this.state.ativo
            }
        })
            .then(response => {
                var _pagination = this.state.pagination;
                _pagination.total = response.data;

                this.setState({
                    pagination: _pagination
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //LISTA TODOS OS CURSOS DA ENTIDADE
    listarCursos = () => {
        if (this.state.filtroOrganismo)
            this.setState(
                {
                    loading_table: true
                },
                () => {
                    axios({
                        method: "get",
                        url: "/api/gestao-turmas/listar-turmas",
                        params: {
                            pesquisa: this.state.pesquisa,
                            pageSize: this.state.pagination ? this.state.pagination.pageSize : 10,
                            page: this.state.pagination ? this.state.pagination.current : 1,
                            filtroEdicao: this.state.filtroEdicao,
                            filtroCategoria: this.state.filtroCategoria,
                            filtroOrganismo: this.state.filtroOrganismo,
                            ativo: this.state.ativo
                        }
                    })
                        .then(response => {
                            const acesso = this.context.acessos?.find(x => x.func === 1);
                            const acessoM = this.context.acessos?.find(x => x.func === 13);
                            var cursos = [];
                            var cursosMobile = [];

                            response.data.map(curso => {
                                cursos.push({
                                    key: curso.id,
                                    id: curso.id,
                                    edicao: curso.edicao,
                                    curso: (
                                        <div className="nome-colaborador" style={{ marginLeft: 0 }}>
                                            <span className="titulo-curso">
                                                {curso.codigo} - {curso.nome}
                                            </span>
                                            {(curso.multisseriado != null || curso.fechado) && (
                                                <div style={{ display: "flex", gap: 5 }}>
                                                    {curso.multisseriado != null && (
                                                        <span className="tag-multisseriado" title="Multisseriado">{curso.multisseriado?.nome}</span>)}
                                                    {curso.fechado && (<span className="tag-fechado">Fechado</span>)}
                                                </div>
                                            )}
                                        </div>
                                    ),
                                    categoria: curso.categoria,
                                    codigo: curso.codigo,
                                    nome: curso.nome,
                                    periodo: curso.periodo_letivo ? JSON.parse(curso.periodo_letivo).join(", ") : "",
                                    dt_inicio: curso.dt_inicio,
                                    dt_fim: curso.dt_fim,
                                    dt_disponivel: curso.dt_disponivel,
                                    coordenadores: curso.coordenadores,
                                    inscritos: curso.inscritos,
                                    grupo: curso.grupo,
                                    multisseriado: curso.multisseriado,
                                    opcoes: this.montarMenuCurso(curso),
                                    subcursos: []//curso.subcursos ? curso.subcursos : []
                                });

                                cursosMobile.push({
                                    key: curso.id,
                                    id: curso.id,
                                    curso: (
                                        <div className="nome-colaborador" style={{ marginLeft: 0 }}>
                                            <span className="titulo-curso">
                                                {curso.codigo} - {curso.nome}
                                            </span>
                                            {curso.multisseriado != "" || curso.fechado && (
                                                <div style={{ display: "flex", gap: 5 }}>
                                                    {curso.multisseriado != "" && (
                                                        <span className="tag-multisseriado" title="Multisseriado">{curso.multisseriado}</span>)}
                                                    {curso.fechado && (<span className="tag-fechado">Fechado</span>)}
                                                </div>
                                            )}
                                        </div>
                                    ),
                                    codigo: curso.codigo,
                                    nome: curso.nome,
                                    periodo: (
                                        <div>
                                            <span>
                                                {curso.dt_fim
                                                    ? `${moment(curso.dt_inicio).format("DD/MM/YYYY")} - ${moment(curso.dt_fim).format(
                                                          "DD/MM/YYYY"
                                                      )}`
                                                    : moment(curso.dt_inicio).format("DD/MM/YYYY")}
                                            </span>
                                            {curso.fechado ? (
                                                <div className="tag-fechado">
                                                    Fechado (
                                                    {curso.dt_fechado && (
                                                        <span style={{ fontSize: "12px" }}>
                                                            {moment(curso.dt_fechado).format("DD/MM/YYYY")})
                                                        </span>
                                                    )}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    ),
                                    dt_inicio: curso.dt_inicio,
                                    dt_fim: curso.dt_fim,
                                    coordenadores: curso.coordenadores,
                                    multisseriado: curso.multisseriado,
                                    opcoes: (acesso?.create || acesso?.update || acesso?.delete || acessoM) && (
                                        <Dropdown
                                            overlay={this.montarMenuCursoMobile(curso)}
                                            placement="bottomLeft"
                                            onClick={event => event.stopPropagation()}
                                            overlayStyle={{
                                                zIndex: "9"
                                            }}
                                            trigger={["click"]}
                                        >
                                            <Link to="#" className="botao-icon-configs">
                                                <i className="fas fa-cog" />
                                            </Link>
                                        </Dropdown>
                                    ),
                                    subcursos: []//curso.subcursos ? curso.subcursos : []
                                });
                            });

                            this.setState({
                                cursos,
                                cursosMobile,
                                loading_table: false
                            });
                        })
                        .catch(error => {
                            if (error.response.status === 401)
                                this.setState({
                                    redirect: true,
                                    redirectLink: "/login"
                                });
                        });
                }
            );
    };

    //LISTA TODOS OS CURSOS DA ENTIDADE
    listarSubCursos = (subcursos, cursoIndex, curso) => {
        var _subcursos = [];

        subcursos.map(subcurso => {
            _subcursos.push({
                key: subcurso.id,
                id: subcurso.id,
                edicao: (
                    <div>
                        <span>{subcurso.edicao}</span>
                        {subcurso.ano || subcurso.turma ? (
                            <span style={{ display: "block" }}>{`${subcurso.ano ? subcurso.ano : ""} ${
                                subcurso.turma ? subcurso.turma : ""
                            }`}</span>
                        ) : null}
                    </div>
                ),
                curso: (
                    <div className="nome-colaborador">
                        {!subcurso.agrupado ? (
                            <span className="titulo-curso">
                                {subcurso.codigo} - {subcurso.nome}
                            </span>
                        ) : (
                            <span className="titulo-curso">{subcurso.nome}</span>
                        )}
                        {subcurso.fechado && <span className="tag-fechado">Fechado</span>}
                    </div>
                ),
                nome: subcurso.nome,
                periodo: (
                    <div>
                        <span>
                            {subcurso.dt_fim
                                ? `${moment(subcurso.dt_inicio).format("DD/MM/YYYY")} - ${moment(subcurso.dt_fim).format("DD/MM/YYYY")}`
                                : moment(subcurso.dt_inicio).format("DD/MM/YYYY")}
                        </span>
                        {subcurso.fechado ? (
                            <div className="tag-fechado">
                                Fechado (
                                {subcurso.dt_fechado && (
                                    <span style={{ fontSize: "12px" }}>{moment(subcurso.dt_fechado).format("DD/MM/YYYY")}</span>
                                )}
                                )
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                ),
                professores: subcurso.professores,
                inscritos: subcurso.inscritos,
                opcoes: this.montarMenuDisciplina(subcurso, cursoIndex, curso),
                ativo: subcurso.ativo
            });
        });

        return _subcursos;
    };

    listarSubCursosMobile = (subcursos, cursoIndex, curso) => {
        var _subcursos = [];

        subcursos.map(subcurso => {
            _subcursos.push({
                key: subcurso.id,
                id: subcurso.id,
                curso: (
                    <div className="nome-colaborador">
                        {!subcurso.agrupado ? (
                            <span className="titulo-curso">
                                {subcurso.codigo} - {subcurso.nome}
                            </span>
                        ) : (
                            <span className="titulo-curso">{subcurso.nome}</span>
                        )}
                        {subcurso.fechado && <span className="tag-fechado">Fechado</span>}
                    </div>
                ),
                nome: subcurso.nome,
                opcoes: (
                    <Dropdown
                        overlay={this.montarMenuDisciplinaMobile(subcurso, cursoIndex, curso)}
                        placement="bottomLeft"
                        onClick={event => event.stopPropagation()}
                        overlayStyle={{
                            zIndex: "9"
                        }}
                        trigger={["click"]}
                    >
                        <Link to="#" className="botao-icon-configs">
                            <i className="fas fa-cog" />
                        </Link>
                    </Dropdown>
                ),
                ativo: subcurso.ativo
            });
        });

        return _subcursos;
    };

    //ABRE O DRAWER E CARREGA COM OS DADOS DO CURSO PARA EDIÇÃO
    editar = (cursoId, disciplinaId, disciplina, grupo) => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/gestao-turmas/detalhe",
            params: {
                cursoid: !disciplina ? cursoId : disciplinaId
            }
        })
            .then(response => {
                const data = response.data;
                let horas_contacto = [];
                let coordenadores = [];
                let formadores = [];
                let listaProcurarCoordenadores = [];
                let listaProcurarFormadores = [];
                let fichaCurso = [];
                let fichaInscricao = [];

                //ADICIONA AO ARRAY TODAS AS HORAS DE CONTACTO
                data.horas_Contacto.forEach(hora_contacto => {
                    horas_contacto.push({
                        id: hora_contacto.id_tipo_hora,
                        carga_horaria: hora_contacto.carga_horaria
                    });
                });

                //ADICIONA AO ARRAY TODOS OS COORDENADORES DO CURSO
                data.curso_Formadores.forEach(coordenador => {
                    if (coordenador.responsavel) {
                        coordenadores.push(coordenador.id);
                        listaProcurarCoordenadores.push({
                            id: coordenador.id,
                            carga_horaria: coordenador.carga_horaria,
                            replicar: coordenador.replicar
                        });
                    }
                });

                //ADICIONA AO ARRAY TODOS OS FORMADORES DO CURSO
                data.curso_Formadores.forEach(formador => {
                    if (!formador.responsavel) {
                        formadores.push(formador.id);
                        listaProcurarFormadores.push({
                            id: formador.id,
                            carga_horaria: formador.carga_horaria
                        });
                    }
                });

                //ADICIONA AO ARRAY TODAS AS INFORMAÇÕES DO CURSO
                const infos = data.curso_Informacoes.map(info => {
                    return {
                        id: info.id,
                        data: moment(info.dt_criado),
                        descricao: info.texto
                    };
                });

                //ADICIONA AO ARRAY TODOS OS HORARIOS
                const horarios = data.cursos_Aulas.map(horario => {
                    return {
                        id: horario.id,
                        data: horario.data,
                        hora_ini: horario.hora_ini,
                        hora_fim: horario.hora_fim,
                        sala: horario.sala,
                        morada: horario.morada,
                        localidade: horario.localidade
                    };
                });

                if (data.ficha)
                    fichaCurso.push({
                        uid: !disciplina ? cursoId : disciplinaId,
                        name: data.ficha_nome,
                        status: "done",
                        url: data.ficha
                    });

                if (data.ficha_inscricao)
                    fichaInscricao.push({
                        uid: !disciplina ? cursoId : disciplinaId,
                        name: data.ficha_inscricao_nome,
                        status: "done",
                        url: data.ficha_inscricao
                    });

                const fasesEscolares = data.fasesEscolares.map(fase => {
                    return {
                        id: fase.id,
                        titulo: fase.titulo,
                        tipo: fase.tipo,
                        ordem: fase.ordem,
                        principal: fase.principal
                    };
                });

                this.setState(
                    {
                        templateId: 0,
                        cursoid: cursoId,
                        disciplinaid: disciplinaId,
                        categoriaId: data.id_turma_categoria > 0 ? data.id_turma_categoria : undefined,
                        //escolaId: data.id_organismo > 0 ? data.id_organismo : undefined,
                        capa: data.imagem,
                        preview: data.imagem,
                        titulo: data.nome,
                        codigo: data.codigo ? data.codigo : "",
                        breveDescricao: data.objectivo,
                        descricao: data.descricao,
                        creditos: data.creditos,
                        horas_trabalho: data.horas_trabalho,
                        carga_horaria: data.carga_horaria,
                        horas_contacto,
                        formadores,
                        coordenadores,
                        listaProcurarCoordenadores,
                        listaProcurarFormadores,
                        infos,
                        dt_inicio: moment(data.calendario.dt_inicio),
                        dt_fim: moment(data.calendario.dt_termino),
                        fechado: data.fechado,
                        curso_visivel: data.curso_visivel,
                        conteudos_visiveis: data.conteudos_visiveis,
                        tipo_inscricao: data.tipo_inscricao,
                        inscricao: data.inscricao,
                        visivel_inscritos: data.visivel_inscritos,
                        esconderListaParticipantes: response.data.esconderListaParticipantes,
                        habilitarCompetencias: response.data.habilitar_competencias,
                        habilitarGamificacao: response.data.habilitar_gamificacao,
                        horarios,
                        paginaExterna: response.data.pagina_externa ? response.data.pagina_externa : "",
                        visible: !disciplina && !grupo,
                        visibleDisciplina: disciplina && !grupo,
                        visibleGrupoDisciplinaDrawer: disciplina && grupo,
                        disciplina_grupo: disciplina && grupo,
                        fichaCurso,
                        fichaInscricao,
                        encerramento: response.data.encerramento,
                        calendarioId: data.calendario.id > 0 ? data.calendario.id : undefined,
                        discComAulas: response.data.discComAulas,
                        ano: response.data.ano ? response.data.ano : "",
                        turma: response.data.turma ? response.data.turma : "",
                        turno: response.data.id_turno ? response.data.id_turno : undefined,
                        habilitarProfessorPolivalente: response.data.professor_polivalente,
                        diario: response.data.diario,
                        fasesEscolares,
                        habilitarConteudosStepforma: response.data.conteudos_stepforma,
                        atividadeConteudosStepforma: response.data.id_atividade_conteudos_stepforma != null ? response.data.id_atividade_conteudos_stepforma : undefined,
                        curso_eja: response.data.eja,
                        percentagemFaltas: response.data.limite_percentagem_falta,
                        curso_ordem: response.data.ordem ? response.data.ordem : 1,
                        fichaId: response.data.id_ficha,
                        fichaCampos: response.data.ficha_campos ? JSON.parse(response.data.ficha_campos) : [],
                        plano_estudos: response.data.plano_estudos,
                        pareceresPorAula: response.data.pareceres_por_aula,
                        listaProfs: response.data.professores,
                        profTitularId: response.data.id_prof_titular ? response.data.id_prof_titular : undefined
                    },
                    () => {
                        this.listarCategoriasDrawer();
                        this.listarCalendariosDrawer();
                        this.listarTiposDiarioDrawer(false, cursoId);
                        this.listarFichas();
                        this.listarAtividadesConteudosStepforma(false);
                    }
                );
            })
            .catch(() => {});
    };

    //ABRE O DRAWER E CARREGA COM OS DADOS DO CURSO PARA EDIÇÃO
    editarGrupo = cursoId => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/gestao-turmas/detalhe-grupo",
            params: {
                cursoId
            }
        })
            .then(response => {
                const data = response.data;
                let coordenadores = [];
                let listaProcurarCoordenadores = [];

                //ADICIONA AO ARRAY TODOS OS COORDENADORES DO CURSO
                data.curso_Formadores.forEach(coordenador => {
                    coordenadores.push(coordenador.id);
                    listaProcurarCoordenadores.push({
                        key: coordenador.id,
                        label: coordenador.nome
                    });
                });

                this.setState(
                    {
                        templateId: 0,
                        cursoid: cursoId,
                        categoriaId: data.id_turma_categoria > 0 ? data.id_turma_categoria : undefined,
                        //escolaId: data.id_organismo > 0 ? data.id_organismo : undefined,
                        calendarioId: data.calendario.id > 0 ? data.calendario.id : undefined,
                        capa: data.imagem,
                        preview: data.imagem,
                        titulo: data.nome,
                        coordenadores,
                        listaProcurarCoordenadores,
                        curso_eja: response.data.eja,
                        curso_grupo: data.grupo,
                        visibleGrupoDrawer: true
                    },
                    () => {
                        this.listarCategoriasDrawer();
                        this.listarCalendariosDrawer();
                        this.listarTiposDiarioDrawer(false, cursoId);
                        this.listarFichas();
                    }
                );
            })
            .catch(() => {});
    };

    editarGrupoDisciplina = (cursoId, disciplinaId) => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/gestao-turmas/detalhe-grupo-disciplina",
            params: {
                disciplinaId
            }
        })
            .then(response => {
                const data = response.data;
                let coordenadores = [];
                let formadores = [];
                let listaProcurarCoordenadores = [];
                let listaProcurarFormadores = [];

                //ADICIONA AO ARRAY TODOS OS COORDENADORES DO CURSO
                data.curso_Formadores.forEach(coordenador => {
                    if (coordenador.responsavel) {
                        coordenadores.push(coordenador.id);
                        listaProcurarCoordenadores.push({
                            key: coordenador.id,
                            label: coordenador.nome
                        });
                    }
                });

                //ADICIONA AO ARRAY TODOS OS FORMADORES DO CURSO
                data.curso_Formadores.forEach(formador => {
                    if (!formador.responsavel) {
                        formadores.push(formador.id);
                        listaProcurarFormadores.push({
                            key: formador.id,
                            label: formador.nome
                        });
                    }
                });

                const fasesEscolares = data.fasesEscolares.map(fase => {
                    return {
                        id: fase.id,
                        titulo: fase.titulo,
                        tipo: fase.tipo,
                        ordem: fase.ordem,
                        principal: fase.principal
                    };
                });

                this.setState(
                    {
                        templateId: 0,
                        disciplinaid: disciplinaId,
                        capa: data.imagem,
                        preview: data.imagem,
                        titulo: data.nome,
                        formadores,
                        coordenadores,
                        listaProcurarCoordenadores,
                        listaProcurarFormadores,
                        fasesEscolares,
                        curso_ordem: response.data.ordem ? response.data.ordem : 1,
                        disciplina_grupo: true,
                        visibleGrupoDisciplinaDrawer: true
                    },
                    () => {
                        this.listarTiposDiarioDrawer(false, cursoId);
                        this.listarFichas();
                    }
                );
            })
            .catch(() => {});
    };

    //FECHAR O CURSO SELECIONADO
    fechar = (cursoid, estado) => {
        confirm({
            title: `Pretende ${estado ? "reabrir" : "fechar"} este curso ?`,
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-turmas/fechar-curso",
                    params: {
                        cursoid: cursoid
                    }
                })
                    .then(() => {
                        if (estado) openNotificationWithIcon("success", "Sucesso", "Curso reaberto!");
                        else openNotificationWithIcon("success", "Sucesso", "Curso fechado!");

                        this.listarCursos();
                    })
                    .catch(() => {
                        if (estado) openNotificationWithIcon("error", "Erro", "Não foi possível reabrir o curso");
                        else openNotificationWithIcon("error", "Erro", "Não foi possível fechar o curso");
                    });
            }
        });
    };

    //EXCLUIR O CURSO SELECIONADO
    excluir = cursoid => {
        confirm({
            title: "Pretende excluir este curso (ALERTA: Esta opção remove tudo, conteúdos, inscrições e professores) ?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-turmas/excluir-curso",
                    params: {
                        cursoid: cursoid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Curso excluido!");
                        var cursos = this.state.cursos.filter(x => x.id !== cursoid);
                        var pagination = this.state.pagination;
                        pagination.total = pagination.total - 1;

                        this.setState({
                            cursos,
                            pagination
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o curso");
                    });
            }
        });
    };

    //EXCLUIR O CURSO SELECIONADO
    excluirCursoDisciplina = cursoid => {
        confirm({
            title: "Pretende excluir esta turma (ALERTA: Esta opção remove tudo, conteúdos, inscrições e professores)?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-turmas/excluir-curso-disciplina",
                    params: {
                        cursoid: cursoid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Turma excluida!");
                        var cursos = this.state.cursos.filter(x => x.id !== cursoid);
                        var cursosMobile = this.state.cursosMobile.filter(x => x.id !== cursoid);
                        var pagination = this.state.pagination;
                        pagination.total = pagination.total - 1;

                        this.setState({
                            cursos,
                            cursosMobile,
                            pagination
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a turma");
                    });
            }
        });
    };

    //EXCLUIR O CURSO SELECIONADO
    excluirDisciplina = (cursoid, cursoIndex) => {
        confirm({
            title: "Pretende excluir esta disciplina (ALERTA: Esta opção remove tudo, conteúdos, inscrições e professores)?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-turmas/excluir-curso",
                    params: {
                        cursoid: cursoid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Disciplina excluida!");
                        var cursos = this.state.cursos;
                        cursos[cursoIndex].subcursos = cursos[cursoIndex].subcursos.filter(x => x.id !== cursoid);

                        this.setState({
                            cursos
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a disciplina");
                    });
            }
        });
    };

    ativarCursosDisciplinas = cursoid => {
        confirm({
            title: this.state.ativo ? "Pretende inativar esta disciplina?" : "Pretende ativar esta disciplina?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-turmas/ativar-curso-disciplinas",
                    params: {
                        cursoid: cursoid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", this.state.ativo ? "Disciplina inativada!" : "Disciplina ativada!");

                        var cursos = this.state.cursos.filter(x => x.id !== cursoid);
                        var cursosMobile = this.state.cursosMobile.filter(x => x.id !== cursoid);
                        var pagination = this.state.pagination;
                        pagination.total = pagination.total - 1;

                        this.setState({
                            cursos,
                            cursosMobile,
                            pagination
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            this.state.ativo ? "Não foi possível inativar a disciplina!" : "Não foi possível ativar a disciplina!"
                        );
                    });
            }
        });
    };

    //ATIVAR E INATIVAR O CURSO
    ativarDisciplina = (id, ativo, cursoIndex) => {
        confirm({
            title: ativo ? "Pretende inativar esta Disciplina?" : "Pretende ativar esta Disciplina?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-turmas/ativar-curso-disciplinas",
                    params: {
                        cursoId: id
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Disciplina inativada!" : "Disciplina ativada!");

                        var cursos = this.state.cursos;
                        var subIndex = cursos[cursoIndex].subcursos.findIndex(x => x.id === id);
                        cursos[cursoIndex].subcursos[subIndex].ativo = !cursos[cursoIndex].subcursos[subIndex].ativo;

                        this.setState({
                            cursos
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar a Disciplina!" : "Não foi possível ativar a Disciplina!"
                        );
                    });
            }
        });
    };

    //UPLOAD DA FOTO/IMAGEM DO CURSO
    uploadFoto = event => {
        if (event.target.files[0].size < 100 * 1024) {
            if (this.validarFormatoImagem(event.target.files[0].name.split(".").pop())) {
                this.setState({
                    totalBytes: event.target.files[0].size,
                    formatoValido: true,
                    validateStatus: "",
                    help: "",
                    capa: event.target.files[0],
                    preview: URL.createObjectURL(event.target.files[0])
                });
            } else {
                document.getElementById("input-foto").value = "";
                openNotificationWithIcon("error", "Erro", "Imagem com formato inválido");
            }
        } else {
            document.getElementById("input-foto").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 100 KB para a imagem.");
        }
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoImagem = formato => {
        var formatos = ["jpg", "jpeg", "png", "gif"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //ABRE O FILE BROWSER
    ativarUploadFoto = () => {
        document.getElementById("input-foto").click();
    };

    //REMOVE A FOTO
    removerFoto = () => {
        document.getElementById("input-foto").value = "";
        this.setState({
            capa: "",
            preview: ""
        });
    };

    //CRIA UM NOVO CURSO
    criarCurso = event => {
        event.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (
                !err &&
                this.state.totalBytes <= 100 * 1024 &&
                this.state.formatoValido &&
                this.state.listaProcurarCoordenadores.length > 0
            ) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                if (this.state.templateId)
                    item.append("templateId", this.state.templateId);
                item.append("capa", this.state.capa);
                item.append("ano", this.state.ano);
                item.append("turma", this.state.turma);
                item.append("id_turma_categoria", this.state.categoriaId);
                item.append("codigo", this.state.codigo);
                if (this.state.breveDescricao)
                    item.append("breveDescricao", this.state.breveDescricao);
                if (this.state.descricao)
                    item.append("descricao", this.state.descricao);
                item.append("id_calendario", this.state.calendarioId);
                item.append("fasesEscolares", JSON.stringify(this.state.fasesEscolares));
                item.append("id_organismo", this.state.filtroOrganismo);
                if (this.state.horas_trabalho)
                    item.append("horas_trabalho", this.state.horas_trabalho);
                item.append("coordenadores", JSON.stringify(this.state.listaProcurarCoordenadores));
                if (this.state.turno)
                    item.append("id_turno", this.state.turno);
                if (this.state.fileList.length)
                    item.append("ficheiro", this.state.fileList[0]);
                item.append("pareceres_por_aula", this.state.pareceresPorAula);

                axios({
                    method: "post",
                    url: "/api/gestao-turmas/adicionar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Adicionada nova turma!");
                        this.listarCursos();
                        this.setState({
                            visible: false,
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            } else {
                if (this.state.listaProcurarCoordenadores.length === 0) {
                    this.setState({
                        coordenadoresStatus: {
                            validateStatus: "error",
                            help: "Campo obrigatório"
                        }
                    });
                }
            }
        });
    };

    validarDuplicados = () => {
        var valueArr = this.state.fasesEscolares.map(function (item) {
            return item.tipo;
        });
        var isDuplicate = valueArr.some(function (item, idx) {
            return valueArr.indexOf(item) != idx;
        });

        return isDuplicate;
    };

    //CRIA UM NOVO CURSO
    criarDisciplina = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (
                !err &&
                this.state.totalBytes <= 100 * 1024 &&
                this.state.formatoValido &&
                this.state.listaProcurarFormadores.length > 0 &&
                this.state.fasesEscolares.length > 0
            ) {
                if (this.validarDuplicados()) {
                    openNotificationWithIcon("error", "Erro", "Estruturas de avaliação selecionadas em duplicado");

                    return null;
                }

                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("cursoid", this.state.cursoid);
                item.append("capa", this.state.capa);
                item.append("titulo", this.state.titulo.trim());
                item.append("codigo", this.state.codigo);
                if (this.state.breveDescricao)
                    item.append("breveDescricao", this.state.breveDescricao);
                if (this.state.descricao)
                    item.append("descricao", this.state.descricao);
                item.append("creditos", this.state.creditos);
                if (this.state.horas_trabalho)
                    item.append("horas_trabalho", this.state.horas_trabalho);
                if (this.state.horas_contacto)
                    item.append("horas_contacto", JSON.stringify(this.state.horas_contacto));
                item.append("coordenadores", JSON.stringify(this.state.listaProcurarCoordenadores));
                item.append("formadores", JSON.stringify(this.state.listaProcurarFormadores));
                item.append("fasesEscolares", JSON.stringify(this.state.fasesEscolares));
                item.append("professor_submeter", this.state.professor_submeter);
                item.append("limite_percentagem_falta", this.state.percentagemFaltas);
                item.append("ordem", this.state.curso_ordem);
                if (this.state.fichaId) {
                    item.append("id_ficha", this.state.fichaId);
                    if (this.state.fichaCampos.length)
                        item.append("ficha_campos", JSON.stringify(this.state.fichaCampos));
                }
                item.append("conteudos_stepforma", this.state.habilitarConteudosStepforma);
                if (this.state.atividadeConteudosStepforma)
                    item.append("id_atividade_conteudos_stepforma", this.state.atividadeConteudosStepforma);
                item.append("encerramento", this.state.encerramento);

                

                axios({
                    method: "post",
                    url: "/api/gestao-turmas/adicionar-disciplina",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Adicionada nova disciplina!");
                        this.listarCursos();
                        this.setState({
                            visibleDisciplina: false,
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            } else {
                if (this.state.listaProcurarFormadores.length === 0) {
                    this.setState({
                        professoresStatus: {
                            validateStatus: "error",
                            help: "Campo obrigatório"
                        }
                    });
                }

                if (this.state.fasesEscolares.length === 0) {
                    this.setState({
                        estruturaStatus: {
                            validateStatus: "error",
                            help: "Campo obrigatório"
                        }
                    });
                }
            }
        });
    };

    //ATUALIZA O CURSO
    guardarTurma = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (
                !err &&
                this.state.totalBytes <= 100 * 1024 &&
                this.state.formatoValido &&
                this.state.listaProcurarCoordenadores.length > 0
            ) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("cursoId", this.state.cursoid);
                item.append("capa", this.state.capa);
                item.append("imagem", this.state.capa);
                item.append("ano", this.state.ano);
                item.append("turma", this.state.turma);
                if (this.state.turno)
                    item.append("id_turno", this.state.turno);
                item.append("id_turma_categoria", this.state.categoriaId);
                item.append("codigo", this.state.codigo);
                if (this.state.breveDescricao)
                    item.append("breveDescricao", this.state.breveDescricao);
                if (this.state.descricao)
                    item.append("descricao", this.state.descricao);
                if (this.state.horas_trabalho)
                    item.append("horas_trabalho", this.state.horas_trabalho);
                if (this.state.carga_horaria)
                    item.append("carga_horaria", this.state.carga_horaria);
                item.append("id_calendario", this.state.calendarioId);
                item.append("fasesEscolares", JSON.stringify(this.state.fasesEscolares));
                item.append("coordenadores", JSON.stringify(this.state.listaProcurarCoordenadores));
                item.append("plano_estudos", this.state.plano_estudos);
                if (this.state.fileList.length)
                    item.append("ficheiro", this.state.fileList[0]);
                item.append("atualizarEstruturasDisciplinas", this.state.atualizarEstruturasDisciplinas);
                item.append("pareceres_por_aula", this.state.pareceresPorAula);
                if (this.state.profTitularId)
                    item.append("id_prof_titular", this.state.profTitularId);

                axios({
                    method: "post",
                    url: "/api/gestao-turmas/alterar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Turma editada com sucesso!");
                        this.listarCursos();
                        this.setState({
                            visible: false,
                            visibleDisciplina: false,
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            } else {
                if (this.state.listaProcurarCoordenadores.length === 0) {
                    this.setState({
                        coordenadoresStatus: {
                            validateStatus: "error",
                            help: "Campo obrigatório"
                        }
                    });
                }
            }
        });
    };

    //ATUALIZA O CURSO
    guardarGrupo = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 100 * 1024 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("cursoId", this.state.cursoid);
                item.append("capa", this.state.capa);
                item.append("imagem", this.state.capa);
                item.append("titulo", this.state.titulo.trim());

                axios({
                    method: "post",
                    url: "/api/gestao-turmas/alterar-grupo",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Turma editada com sucesso!");
                        this.listarCursos();
                        this.setState({
                            visibleGrupoDrawer: false,
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    guardarDisciplina = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (
                !err &&
                this.state.totalBytes <= 100 * 1024 &&
                this.state.formatoValido &&
                this.state.listaProcurarFormadores.length > 0 &&
                this.state.fasesEscolares.length > 0
            ) {
                if (this.validarDuplicados()) {
                    openNotificationWithIcon("error", "Erro", "Estruturas de avaliação selecionadas em duplicado");

                    return null;
                }

                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("disciplinaId", this.state.disciplinaid);
                item.append("capa", this.state.capa);
                item.append("titulo", this.state.titulo.trim());
                item.append("codigo", this.state.codigo);
                if (this.state.breveDescricao)
                    item.append("breveDescricao", this.state.breveDescricao);
                if (this.state.descricao)
                    item.append("descricao", this.state.descricao);
                item.append("creditos", this.state.creditos);
                if (this.state.horas_trabalho)
                    item.append("horas_trabalho", this.state.horas_trabalho);
                if (this.state.horas_contacto)
                    item.append("horas_contacto", JSON.stringify(this.state.horas_contacto));
                item.append("coordenadores", JSON.stringify(this.state.listaProcurarCoordenadores));
                item.append("formadores", JSON.stringify(this.state.listaProcurarFormadores));
                item.append("fasesEscolares", JSON.stringify(this.state.fasesEscolares));
                item.append("professor_submeter", this.state.professor_submeter);
                item.append("limite_percentagem_falta", this.state.percentagemFaltas);
                item.append("ordem", this.state.curso_ordem);
                if (this.state.fichaId) {
                    item.append("id_ficha", this.state.fichaId);
                    if (this.state.fichaCampos)
                        item.append("ficha_campos", JSON.stringify(this.state.fichaCampos));
                }
                item.append("encerramento", this.state.encerramento);
                item.append("conteudos_stepforma", this.state.habilitarConteudosStepforma);
                if (this.state.atividadeConteudosStepforma)
                    item.append("id_atividade_conteudos_stepforma", this.state.atividadeConteudosStepforma);

                axios({
                    method: "post",
                    url: "/api/gestao-turmas/alterar-disciplina",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Disciplina editada com sucesso!");
                        this.listarDisciplinas(null, { id: this.state.cursoid });
                        this.setState({
                            visible: false,
                            visibleDisciplina: false,
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            } else {
                if (this.state.listaProcurarFormadores.length === 0) {
                    this.setState({
                        professoresStatus: {
                            validateStatus: "error",
                            help: "Campo obrigatório"
                        }
                    });
                }

                if (this.state.fasesEscolares.length === 0) {
                    this.setState({
                        estruturaStatus: {
                            validateStatus: "error",
                            help: "Campo obrigatório"
                        }
                    });
                }
            }
        });
    };

    guardarDisciplinaGrupo = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 100 * 1024 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("disciplinaId", this.state.disciplinaid);
                item.append("capa", this.state.capa);
                item.append("titulo", this.state.titulo.trim());
                item.append("professor_submeter", this.state.professor_submeter);
                item.append("limite_percentagem_falta", this.state.percentagemFaltas);
                item.append("ordem", this.state.curso_ordem);
                if (this.state.fichaId) {
                    item.append("id_ficha", this.state.fichaId);
                    if (this.state.fichaCampos.length) item.append("ficha_campos", JSON.stringify(this.state.fichaCampos));
                }

                axios({
                    method: "post",
                    url: "/api/gestao-turmas/alterar-disciplina-grupo",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Disciplina editada com sucesso!");
                        this.listarCursos();
                        this.setState({
                            visibleGrupoDisciplinaDrawer: false,
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    //ADICIONA UMA NOVA INFORMAÇÃO AO CURSO DURANTE A EDIÇÃO OU ADIÇÃO
    adicionarInfo = () => {
        var infos = this.state.infos;

        infos.push({
            id: 0,
            data: this.state.dataInfo,
            descricao: this.state.descricaoInfo
        });

        this.setState({
            infos,
            visibleInformacoes: false,
            info_status: "",
            info_help: ""
        });
    };

    //ABRE A INFORMAÇÃO DO CURSO
    detalheInfo = index => {
        var infos = this.state.infos;

        this.setState({
            index,
            dataInfo: infos[index].data,
            descricaoInfo: infos[index].descricao,
            detalhe: true,
            visibleInformacoes: true
        });
    };

    //ATUALIZA A INFORMAÇÃO DO CURSO
    editarInfo = index => {
        var infos = this.state.infos;

        infos[index].data = this.state.dataInfo;
        infos[index].descricao = this.state.descricaoInfo;

        this.setState({
            infos,
            visibleInformacoes: false
        });
    };

    //EXCLUI A INFORMAÇÃO
    excluirInfo = index => {
        confirm({
            title: "Pretende excluir esta informação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                var infos = this.state.infos;

                infos.splice(index, 1);

                this.setState({
                    infos
                });
            }
        });
    };

    //MONTA AS OPÇÕES DE MENU POR CADA INFORMAÇÃO NUM CURSO
    montarMenu = index => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.detalheInfo(index)}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.excluirInfo(index)}>
                        Excluir
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    montarMenuHorario = index => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.detalheHorario(index)}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.excluirHorario(index)}>
                        Excluir
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    //PROCURA OS COORDENADORES DA ENTIDADE COM BASE NO INSERT DO UTILIZADOR
    procurarCoordenadores = value => {
        if (value.length === 4) {
            this.setState(
                {
                    listaCoordenadores: [],
                    procurar: true
                },
                () => {
                    axios({
                        method: "get",
                        url: "/api/gestao-turmas/procurar-formadores",
                        params: {
                            nome: value
                        }
                    })
                        .then(response => {
                            this.setState({
                                listaCoordenadores: response.data,
                                listaCoordenadoresBackUp: response.data,
                                procurar: false
                            });
                        })
                        .catch(() => {
                            openNotificationWithIcon("error", "Erro", "Não foi possível pesquisar Coordenadores!");
                        });
                }
            );
        } else if (value.length > 4) {
            if (this.state.listaCoordenadoresBackUp) {
                var _listaCoordenadores = this.state.listaCoordenadoresBackUp;

                this.setState({
                    listaCoordenadores: _listaCoordenadores.filter(x => x.nome_completo.toLowerCase().includes(value.toLowerCase()))
                });
            }
        } else {
            this.setState({
                listaCoordenadores: []
            });
        }
    };

    //SELECIONA O COORDENADOR
    checkCoordenadores = value => {
        var coordenadores = [];
        for (var i = 0; i < value.length; i++) {
            coordenadores.push(value[i].key);
        }

        this.setState({
            coordenadores,
            listaProcurarCoordenadores: value,
            listaCoordenadores: []
        });
    };

    carregarTiposHoras = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/carregar-tipos-horas"
        })
            .then(response => {
                this.setState({
                    tipo_horas: response.data
                });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar tipos de horas!");
            });
    };

    //PROCURA OS FORMADORES DA ENTIDADE COM BASE NO INSERT DO UTILZIADOR
    carregarFormadores = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/carregar-formadores",
            params: {
                escolaId: this.state.filtroOrganismo,
                perfilId: 6
            }
        })
            .then(response => {
                this.setState({
                    listaFormadores: response.data
                });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar formadores!");
            });
    };

    carregarCoordenador = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/carregar-formadores",
            params: {
                escolaId: this.state.filtroOrganismo,
                perfilId: 5
            }
        })
            .then(response => {
                this.setState({
                    listaCoordenadores: response.data
                });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar coordenadores!");
            });
    };

    //PROCURA OS FORMADORES DA ENTIDADE COM BASE NO INSERT DO UTILZIADOR
    procurarFormadores = value => {
        if (value.length === 4) {
            this.setState(
                {
                    listaFormadores: [],
                    procurar: true
                },
                () => {
                    axios({
                        method: "get",
                        url: "/api/gestao-turmas/procurar-formadores",
                        params: {
                            nome: value
                        }
                    })
                        .then(response => {
                            this.setState({
                                listaFormadores: response.data,
                                listaFormadoresBackUp: response.data,
                                procurar: false
                            });
                        })
                        .catch(() => {
                            openNotificationWithIcon("error", "Erro", "Não foi possível pesquisar formadores!");
                        });
                }
            );
        } else if (value.length > 4) {
            if (this.state.listaFormadoresBackUp) {
                var _listaFormadores = this.state.listaFormadoresBackUp;

                this.setState({
                    listaFormadores: _listaFormadores.filter(x => x.nome_completo.toLowerCase().includes(value.toLowerCase()))
                });
            }
        } else {
            this.setState({
                listaFormadores: []
            });
        }
    };

    //SELECIONA O FORMADOR
    checkFormadores = value => {
        var formadores = [];
        for (var i = 0; i < value.length; i++) {
            formadores.push(value[i].key);
        }

        this.setState({
            formadores,
            listaProcurarFormadores: value,
            listaFormadores: []
        });
    };

    //PROCURA OS COORDENADORES DA ENTIDADE COM BASE NO INSERT DO UTILIZADOR
    procurarPrecedencia = value => {
        if (value.length > 3)
            this.setState(
                {
                    listaPrecedencia: [],
                    procurar: true
                },
                () => {
                    axios({
                        method: "get",
                        url: "/api/gestao-turmas/procurar-precedencia",
                        params: {
                            nome: value,
                            cursoId: this.state.cursoid
                        }
                    })
                        .then(response => {
                            this.setState({
                                listaPrecedencia: response.data,
                                procurar: false
                            });
                        })
                        .catch(() => {
                            openNotificationWithIcon("error", "Erro", "Não foi possível pesquisar precedências!");
                        });
                }
            );
    };

    //PROCURA OS COORDENADORES DA ENTIDADE COM BASE NO INSERT DO UTILIZADOR
    procurarPrecedenciaDisciplinas = value => {
        if (value.length > 3)
            this.setState(
                {
                    listaPrecedencia: [],
                    procurar: true
                },
                () => {
                    axios({
                        method: "get",
                        url: "/api/gestao-turmas/procurar-precedencia-disciplinas",
                        params: {
                            nome: value,
                            cursoId: this.state.cursoid
                        }
                    })
                        .then(response => {
                            this.setState({
                                listaPrecedencia: response.data,
                                procurar: false
                            });
                        })
                        .catch(() => {
                            openNotificationWithIcon("error", "Erro", "Não foi possível pesquisar precedências!");
                        });
                }
            );
    };

    //CARREGA LISTA DE TODAS AS ATIVIDADES DO CURSO CONTEUDOS STEPFORMA
    listarAtividadesConteudosStepforma = (novo) => {
        axios({
            method: "get",
            url: "/api/gestao-cursos/listar-atividades-conteudos-stepforma",
            params: {
                cursoId: this.state.cursoid,
                novo
            }
        })
            .then(response => {
                this.setState({
                    listaAtividadesConteudosStepforma: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //ABRE O DRAWER COM O FORM DE UM CURSO VAZIO
    showDrawer = () => {
        this.props.form.resetFields();
        this.setState(
            {
                cursoid: 0,
                templateId: undefined,
                escolaId: undefined,
                capa: "",
                preview: null,
                titulo: "",
                codigo: "",
                breveDescricao: "",
                categoriaId: undefined,
                descricao: "",
                credito: 0,
                horas_trabalho: 1,
                carga_horaria: 1,
                horas_contacto: [],
                infos: [],
                //FORMADORES
                coordenadores: [],
                formadores: [],
                dt_inicio: null,
                dt_inicio_limite: null,
                dt_disponivel: null,
                dt_fim: null,
                dt_fim_limite: null,
                atualizarEstruturasDisciplinas: false,
                pareceresPorAula: false,
                curso_visivel: false,
                conteudos_visiveis: false,
                inscricao: true,
                visivel_inscritos: true,
                esconderListaParticipantes: false,
                habilitarCompetencias: false,
                habilitarGamificacao: false,
                paginaExterna: "",
                calendarioId: undefined,
                profTitularId: undefined,
                discComAulas: false,
                ano: undefined,
                turma: "",
                turno: undefined,
                habilitarProfessorPolivalente: false,
                diario: false,
                fasesEscolares: [],
                habilitarConteudosStepforma: false,
                atividadeConteudosStepforma: undefined,
                listaAtividadesConteudosStepforma: [],
                curso_eja: false,
                percentagemFaltas: 0,
                curso_ordem: 0,
                curso_grupo: false,
                professor_submeter: false,
                plano_estudos: "",
                plano_estudos_caminho: "",
                fileList: [],
                //GERAL
                listaProcurarCoordenadores: [],
                //listaCoordenadores: [],
                listaProcurarFormadores: [],
                //listaFormadores: [],
                listaCalendarios: [],
                visible: true,
                fasesEscolares: [
                    {
                        id: "",
                        titulo: "",
                        tipo: "",
                        ordem: 1,
                    }
                ],
                fichaCurso: [],
                fichaInscricao: [],
                //VIEWER
                docs: [],
                visibleViewer: false,
                //VALIDATE
                coordenadoresStatus: {
                    validateStatus: "",
                    help: ""
                },
                professoresStatus: {
                    validateStatus: "",
                    help: ""
                },
                estruturaStatus: {
                    validateStatus: "",
                    help: ""
                }
            },
            () => {
                this.listarCategoriasDrawer();
                this.listarCalendariosDrawer();
                this.listarAtividadesConteudosStepforma(true);
            }
        );
    };

    showDrawerDisciplina = (cursoId, dt_inicio, dt_fim, escolaId) => {
        this.props.form.resetFields();
        this.setState(
            {
                cursoid: cursoId,
                disciplinaid: 0,
                escolaId,
                capa: "",
                preview: null,
                titulo: "",
                codigo: "",
                breveDescricao: "",
                categoriaId: undefined,
                descricao: "",
                credito: 0,
                horas_trabalho: 1,
                carga_horaria: 1,
                horas_contacto: [],
                infos: [],
                //FORMADORES
                coordenadores: [],
                formadores: [],
                dt_inicio: null,
                dt_inicio_limite: dt_inicio,
                dt_fim: null,
                dt_fim_limite: dt_fim,
                atualizarEstruturasDisciplinas: false,
                pareceresPorAula: false,
                dt_disponivel: null,
                curso_visivel: false,
                conteudos_visiveis: false,
                inscricao: true,
                visivel_inscritos: true,
                esconderListaParticipantes: false,
                habilitarCompetencias: false,
                habilitarGamificacao: false,
                paginaExterna: "",
                calendarioId: undefined,
                ano: undefined,
                turma: "",
                turno: undefined,
                habilitarProfessorPolivalente: false,
                diario: false,
                fasesEscolares: [],
                habilitarConteudosStepforma: false,
                atividadeConteudosStepforma: undefined,
                listaAtividadesConteudosStepforma: [],
                curso_eja: false,
                percentagemFaltas: 0,
                curso_ordem: 0,
                curso_grupo: false,
                fichaId: undefined,
                fichaCampos: [],
                encerramento: "Manual",
                professor_submeter: false,
                //GERAL
                listaProcurarCoordenadores: [],
                //listaCoordenadores: [],
                listaProcurarFormadores: [],
                //listaFormadores: [],
                listaCalendarios: [],
                visibleDisciplina: true,
                fichaCurso: [],
                fichaInscricao: [],
                //VALIDATE
                coordenadoresStatus: {
                    validateStatus: "",
                    help: ""
                },
                professoresStatus: {
                    validateStatus: "",
                    help: ""
                },
                estruturaStatus: {
                    validateStatus: "",
                    help: ""
                }
            },
            () => {
                this.listarTiposDiarioDrawer(true, cursoId);
                this.listarFichas();
                this.listarAtividadesConteudosStepforma(true);
            }
        );

        axios({
            method: "get",
            url: "/api/gestao-turmas/carregar-coordenadores",
            params: {
                turmaId: cursoId
            }
        })
            .then(response => {
                this.setState({
                    listaProcurarCoordenadores: response.data,
                    coordenadores: response.data.map(x => x.id)
                })
            })
            .catch(() => {

            })
    };

    //FECHA O DRAWER DO CURSO SEM GUARDAR
    onClose = () => {
        this.setState({
            visible: false,
            visibleDisciplina: false,
            visibleGrupoDrawer: false,
            visibleGrupoDisciplinaDrawer: false,
            itemArray: []
        });
    };

    //ABRE O DRAWER DE UMA INFORMAÇÃO NO CURSO A VAZIO
    showDrawerInformacoes = () => {
        this.setState({
            index: "",
            dataInfo: moment(),
            descricaoInfo: "",
            detalhe: false,
            visibleInformacoes: true
        });
    };

    //FECHA O DRAWER DE UMA INFORMAÇÃO SEM GUARDAR
    onCloseInformacoes = () => {
        this.setState({
            visibleInformacoes: false
        });
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoFicheiro = formato => {
        var formatos = ["pdf"];
        var valido = false;

        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) valido = true;
        }

        return valido;
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            if (document.getElementById("input-foto")) document.getElementById("input-foto").value = "";
        }
    };

    validarLink = (rule, value, callback) => {
        var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator
        if (!pattern.test(value) && value) callback("Introduza uma página válida");

        callback();
    };

    adicionarHorario = horario => {
        let horarios = this.state.horarios;
        horarios.push(horario);

        this.setState({
            horarios,
            visibleDrawerHorarios: false
        });
    };

    alterarHorario = horario => {
        const index = this.state.horarioIndex;
        let horarios = this.state.horarios;

        horarios[index].data = horario.data;
        horarios[index].hora_ini = horario.hora_ini;
        horarios[index].hora_fim = horario.hora_fim;
        horarios[index].sala = horario.sala;
        horarios[index].morada = horario.morada;
        horarios[index].localidade = horario.localidade;

        this.setState({
            horarios,
            horarioIndex: "",
            visibleDrawerHorarios: false
        });
    };

    detalheHorario = index => {
        this.setState({
            horarioIndex: index,
            detalheHorario: true,
            horario: this.state.horarios[index],
            visibleDrawerHorarios: true
        });
    };

    excluirHorario = index => {
        confirm({
            title: "Pretende excluir este horário?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                const horarios = this.state.horarios.slice();
                horarios.splice(index, 1);
                this.setState({
                    horarios
                });
            }
        });
    };

    adicionarHorasContacto = () => {
        const registo = {
            id: undefined
        };

        this.setState({
            horas_contacto: [...this.state.horas_contacto, registo]
        });
    };

    adicionarCoordenadores = () => {
        const coordenador = {
            id: undefined
        };

        this.setState({
            listaProcurarCoordenadores: [...this.state.listaProcurarCoordenadores, coordenador],
            coordenadoresStatus: {
                validateStatus: "",
                help: ""
            }
        });
    };

    adicionarProfessores = () => {
        const professor = {
            id: undefined
        };

        this.setState({
            listaProcurarFormadores: [...this.state.listaProcurarFormadores, professor],
            professoresStatus: {
                validateStatus: "",
                help: ""
            }
        });
    };

    adicionarFaseEscolar = () => {
        const fasesEscolares = this.state.fasesEscolares;
        const fase = {
            id: undefined,
            titulo: "",
            tipo: undefined,
            ordem: 1
        };

        this.setState({
            fasesEscolares: [...this.state.fasesEscolares, fase],
            estruturaStatus: {
                validateStatus: "",
                help: ""
            }
        });
    };

    excluirFaseEscolar = index => {
        this.props.form.resetFields();
        let fasesEscolares = this.state.fasesEscolares;
        fasesEscolares.splice(index, 1);

        this.setState({
            fasesEscolares
        });
    };

    onRowClick = (cursoNome, turmaNome, cursoId, cursoPai, multisseriado) => {
        this.context.atualizarState({
            lista_modulos: [],
            lista_disciplinas: []
        });
        localStorage.setItem("codigo_curso", cursoId);
        localStorage.setItem(
            "opcao_voltar",
            JSON.stringify({
                pesquisa: this.state.pesquisa,
                calendario: this.state.filtroCalendario,
                organismo: this.state.filtroOrganismo,
                ativo: this.state.ativo,
                link_voltar: "/gestao-turmas",
                link_voltar_curso: "",
                pagination: this.state.pagination
            })
        );
        this.setState({
            redirect: true,
            redirectLink: `/${multisseriado ? "gerir-multisseriado" : "gerir-detalhe-turma"}/${turmaNome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase() }${cursoNome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${
                cursoPai ? "horario" : "horario-disciplina"
            }`
        });
    };

    validarPrincipalSelecionado = () => {
        var fase = this.state.fasesEscolares.find(x => x.principal);
        return fase != undefined;
    };

    moverCurso = (organismoId, cursoPaiId) => {
        this.setState(
            {
                visibleMover: false,
                visibleDuplicar: true,
                textoModal: "A mover curso..."
            },
            () => {
                axios({
                    method: "put",
                    url: "/api/gestao-turmas/mover-curso",
                    params: {
                        cursoId: this.state.cursoid,
                        organismoId,
                        cursoPaiId
                    }
                })
                    .then(response => {
                        this.setState({
                            visibleDuplicar: false
                        });
                        this.listarCursos();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível mover curso!");
                    });
            }
        );
    };

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    excluirHorasContacto = index => {
        this.props.form.resetFields();
        let _estrutura = this.state.horas_contacto;
        _estrutura.splice(index, 1);

        this.setState({
            horas_contacto: _estrutura
        });
    };

    excluirCoordenador = index => {
        this.props.form.resetFields();
        let _estrutura = this.state.listaProcurarCoordenadores;
        _estrutura.splice(index, 1);

        this.setState({
            listaProcurarCoordenadores: _estrutura
        });
    };

    excluirProfessor = index => {
        this.props.form.resetFields();
        let _estrutura = this.state.listaProcurarFormadores;
        _estrutura.splice(index, 1);

        this.setState({
            listaProcurarFormadores: _estrutura
        });
    };

    excluirCampo = index => {
        this.props.form.resetFields();
        let _estrutura = this.state.fasesEscolares;
        _estrutura.splice(index, 1);

        this.setState({
            fasesEscolares: _estrutura
        });
    };

    validar = (rule, value, callback) => {
        if (value.file)
            if (!this.validarFormatoFicheiro(value.file.name.split(".").pop().toLowerCase()) && value.file.status !== "removed")
                callback("Ficheiro com formato inválido");
            else if (value.file.status === "removed") callback("Campo obrigatório");

        callback();
    };

    validarFormatoFicheiro = formato => {
        let formatos = [
            "zip",
            "rar",
            "7z",
            "docx",
            "doc",
            "pdf",
            "odf",
            "txt",
            "rtf",
            "xlsx",
            "xls",
            "csv",
            "ods",
            "pptx",
            "ppt",
            "odp",
            "bmp",
            "gif",
            "png",
            "jpeg",
            "jpg"
        ];
        let valido = false;
        for (let i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    validarValor = (rule, value, callback) => {
        if (isNaN(value)) callback("O valor deve ser um número");

        callback();
    };

    excluirDoc = () => {
        confirm({
            title: "Pretende excluir este documento?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                this.setState({
                    plano_estudos: "",
                    plano_estudos_caminho: ""
                });
            }
        });
    };

    montarCoordenadores = coordenadorId => {
        let coordenadoresIds = this.state.listaProcurarCoordenadores.map(c => c.id);
        if (coordenadorId) coordenadoresIds = coordenadoresIds.filter(c => c !== coordenadorId);

        return this.state.listaCoordenadores.filter(x => !coordenadoresIds.includes(x.id));
    };

    montarProfessor = professorId => {
        let professoresIds = this.state.listaProcurarFormadores.map(c => c.id);
        if (professorId) professoresIds = professoresIds.filter(c => c !== professorId);

        return this.state.listaFormadores.filter(x => !professoresIds.includes(x.id));
    };

    listarDisciplinas = (expanded, record) => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/listar-disciplinas",
            params: {
                turmaId: record.id
            }
        })
            .then(response => {
                var _cursos = this.state.cursos;

                var index = _cursos.findIndex(x => x.id === record.id)

                _cursos[index].subcursos = response.data

                this.setState({
                    cursos: [..._cursos]
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarDisciplinasMobile = (expanded, record) => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/listar-disciplinas",
            params: {
                turmaId: record.id
            }
        })
            .then(response => {
                var _cursos = this.state.cursosMobile;

                var index = _cursos.findIndex(x => x.id === record.id)

                _cursos[index].subcursos = response.data

                this.setState({
                    cursosMobile: [..._cursos]
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    render() {
        const Dragger = Upload.Dragger;
        const { TextArea } = Input;
        const {
            escolas,
            edicoes,
            anosLetivos,
            turnos,
            categorias,
            //FILTROS
            filtroEdicao,
            filtroCategoria,
            filtroOrganismo,
            //TABELA
            loading_table,
            cursos,
            cursosMobile,
            //PAGINATION
            pagination,
            //INFO PRINCIPAL
            templates,
            templateId,
            cursoid,
            disciplinaid,
            escolaId,
            preview,
            titulo,
            breveDescricao,
            categoriaId,
            descricao,
            codigo,
            creditos,
            horas_trabalho,
            carga_horaria,
            horas_contacto,
            tipo_horas,
            horarios,
            horario,
            horarioIndex,
            calendarioId,
            profTitularId,
            discComAulas,
            ano,
            turma,
            turno,
            percentagemFaltas,
            curso_ordem,
            fasesEscolares,
            habilitarConteudosStepforma,
            atividadeConteudosStepforma,
            listaAtividadesConteudosStepforma,
            professor_submeter,
            fileList,
            plano_estudos,
            plano_estudos_caminho,
            atualizarEstruturasDisciplinas,
            pareceresPorAula,
            //FICHAS
            listaFichas,
            fichaId,
            fichaCampos,
            //MULTISSERIADO
            turmaId,
            multisseriadoId,
            visibleDrawerMultisseriado,
            //DRAWER
            visible,
            visibleDisciplina,
            visibleGrupoDrawer,
            visibleGrupoDisciplinaDrawer,
            procurar,
            iconLoading,
            //SELECTS
            listaCalendarios,
            listaTiposDiario,
            listaCategorias,
            listaOrganismos,
            listaProcurarCoordenadores,
            listaCoordenadores,
            listaProcurarFormadores,
            listaProfs,
            listaFormadores,
            curso_eja,
            curso_grupo,
            disciplina_grupo,
            //UPLOAD IMAGEM VALIDATE
            validateStatus,
            help,
            //DRAWER INFORMACOES
            index,
            dataInfo,
            descricaoInfo,
            detalhe,
            visibleInformacoes,
            //UPLOAD
            fichaCurso,
            fichaInscricao,
            encerramento,
            //REDIRECT
            redirect,
            redirectLink,
            //MODAL DUPLICAR CHECKLIST
            visibleDuplicarChecklist,
            //MODAL DUPLICAR
            duplicarDisciplina,
            visibleDuplicar,
            textoModal,
            //DRAWER DETALHE
            visibleDetalhe,
            detalheCategoria,
            detalhePeriodo,
            detalheEstado,
            detalheInscritos,
            //DRAWER HORARIOS,
            visibleDrawerHorarios,
            detalheHorario,
            //MODAL MOVER
            visibleMover,
            cursoPai,
            //EXPORT
            visibleExport,
            buttonDownload,
            exportFile,
            //DRAWER FICHA
            visibleDrawerFicha,
            //VIEWER
            docs,
            visibleViewer,
            //VALIDATE
            coordenadoresStatus,
            professoresStatus,
            estruturaStatus
        } = this.state;

        const acessoM = this.context.acessos?.find(x => x.func === 13);
        const acessoMultisseriado = this.context.acessos?.find(x => x.func === 27);

        const { getFieldDecorator } = this.props.form;

        const columnsCursosComDisciplinas = (acessoM || acessoMultisseriado) ?
            [
                {
                    title: "Edição",
                    dataIndex: "edicao",
                    className: "td-50"
                },
                {
                    title: "Nível",
                    dataIndex: "categoria",
                    className: "td-155"
                },
                {
                    title: "Turma",
                    dataIndex: "curso",
                    className: "td-300"
                },
                {
                    title: "Coordenadores",
                    dataIndex: "coordenadores",
                    className: "td-300"
                },
                {
                    title: "Inscritos",
                    dataIndex: "inscritos",
                    className: "td-160"
                },
                {
                    title: "",
                    dataIndex: "opcoes",
                    className: "td-50"
                }
            ]
            :
            [
                {
                    title: "Edição",
                    dataIndex: "edicao",
                    className: "td-50"
                },
                {
                    title: "Nível",
                    dataIndex: "categoria",
                    className: "td-155"
                },
                {
                    title: "Turma",
                    dataIndex: "curso",
                    className: "td-300"
                },
                {
                    title: "Coordenadores",
                    dataIndex: "coordenadores",
                    className: "td-300"
                },
                {
                    title: "Inscritos",
                    dataIndex: "inscritos",
                    className: "td-160"
                }
            ];

        const columnsCursosComDisciplinasMobile = [
            {
                title: "Turma",
                dataIndex: "curso",
                className: "td-265"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const expandedRowRenderDesktop = (record, index) => {
            const columns = [
                {
                    title: "Disciplina",
                    dataIndex: "curso",
                    render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
                },
                {
                    title: "Inscritos",
                    dataIndex: "inscritos",
                    className: "td-160",
                    render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
                },
                {
                    title: "Coordenadores / Professores",
                    dataIndex: "professores",
                    width: 400,
                    /*className: "td-300",*/
                    render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
                },
                {
                    title: "",
                    dataIndex: "opcoes",
                    className: "td-50"
                }
            ];

            return <Table
                id="disciplinas"
                columns={columns}
                dataSource={this.listarSubCursos(record.subcursos, index, record)}
                loading={{
                    spinning: !record.subcursos.length,
                    indicator: (
                        <div className="loading-data-table">
                            <div className="loading" />
                        </div>
                    )
                }}
                pagination={false}
                locale={{ emptyText: "Não existem dados!" }}
                rowClassName={acesso?.read ? "clickeble-row" : ""}
                onRow={(record2, index2) => {
                    return {
                        onClick: acesso?.read ? () => this.onRowClick(record2.nome, record.nome, record2.id, false, record.multisseriado) : () => { }
                    };
                }}
            />
        }

        const expandedRowRenderMobile = (record, index) => {
            const columns = [
                {
                    title: "Disciplina",
                    dataIndex: "curso",
                    className: "td-250",
                    render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
                },
                {
                    title: "",
                    dataIndex: "opcoes",
                    className: "td-50"
                }
            ];

            return <Table
                id="disciplinas"
                columns={columns}
                dataSource={this.listarSubCursosMobile(record.subcursos, index, record)}
                loading={{
                    spinning: !record.subcursos.length,
                    indicator: (
                        <div className="loading-data-table">
                            <div className="loading" />
                        </div>
                    )
                }}
                pagination={false}
                locale={{ emptyText: "Não existem dados!" }}
                rowClassName={acesso?.read ? "clickeble-row" : ""}
                onRow={(record2, index2) => {
                    return {
                        onClick: acesso?.read ? () => this.onRowClick(record2.nome, record.nome, record2.id, false, record.multisseriado) : () => { }
                    };
                }}
            />
        }

        if (redirect) return <Redirect to={redirectLink} />;

        let calendarioInfo = "";
        if (this.state.calendarioId) {
            const calendario = this.state.listaCalendarios.find(x => x.id === calendarioId);
            if (calendario)
                calendarioInfo = (
                    <div style={{ borderTop: "1px solid #d9d9d9", marginTop: 10 }}>
                        <span>
                            {moment(calendario.dt_inicio).format("DD/MM/YYYY")} - {moment(calendario.dt_termino).format("DD/MM/YYYY")}
                        </span>
                        <span style={{ fontSize: 13, color: "#16817a", display: "block", lineHeight: "initial" }}>
                            {JSON.parse(calendario.tipo).join(", ")}
                        </span>
                    </div>
                );
        }

        let calendariosFiltrados = listaCalendarios;

        //if (templateId) {
        //    const templateSelecionado = templates.find(template => template.id === templateId);

        //    if (templateSelecionado) {
        //        const templatePeriodos = templateSelecionado.periodos;

        //        calendariosFiltrados = listaCalendarios.filter(calendario => {
        //            const tipos = JSON.parse(calendario.tipo);
        //            return tipos.some(tipo => templatePeriodos.includes(tipo));
        //        });
        //    }
        //}

        const props = {
            multiple: false,
            onRemove: file => {
                this.props.form.resetFields("ficheiro");
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    return {
                        fileList: newFileList
                    };
                });
            },
            defaultFileList: this.state.fileList,
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file]
                }));

                return false;
            },
            fileList
        };

        const acesso = this.context.acessos?.find(x => x.func === 1);

        return (
            <div>
                <Acessos funcionalidadeId={1} />
                <Header titulo={`Turmas - ${this.context.estabelecimento?.nome}`} />
                <div className="container container-body">
                    <div className="bloco-cursos">
                        <div className="bloco-tabela">
                            <>
                                <div className="filtros filtros-desktop" style={{ display: "block" }}>
                                    <div className="bloco-filtros-1" style={{ gap: 10 }}>
                                        <div>
                                            {acesso?.create && (
                                                <button
                                                    className="botao-principal botao-principal-redondo"
                                                    title="Adicionar"
                                                    onClick={() => {
                                                        listaOrganismos.length
                                                            ? this.showDrawer()
                                                            : openNotificationWithIcon(
                                                                  "warning",
                                                                  "Atenção",
                                                                  "Tem que criar um organismo para poder criar um curso!"
                                                              );
                                                    }}
                                                    disabled={!this.state.ativo}
                                                >
                                                    +
                                                </button>
                                            )}
                                        </div>
                                        <div className="bloco-pesquisa bloco-filtros-2">
                                            <ProcurarTurma
                                                style={{ width: "100%" }}
                                                pesquisa={this.state.pesquisa}
                                                tem_placeholder
                                                placeholder="Procurar por estrutura"
                                                HandleKeyPress={this.HandleKeyPress}
                                                resetCaixaProcura={this.resetCaixaProcura}
                                            />
                                            <Select
                                                value={filtroEdicao}
                                                className="filtro-selects"
                                                onChange={this.handleChangeFiltroEdicoes}
                                                placeholder="Edição"
                                                disabled={!edicoes.length}
                                                allowClear={true}
                                            >
                                                {edicoes.map(edicao => (
                                                    <Option value={edicao.id} key={edicao.id}>
                                                        {edicao.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                            <Select
                                                value={filtroCategoria}
                                                className="filtro-selects"
                                                onChange={this.handleChangeFiltroCategorias}
                                                placeholder="Nível"
                                                disabled={!categorias.length}
                                                allowClear={true}
                                            >
                                                {categorias.map(categoria => (
                                                    <Option value={categoria.id} key={categoria.id}>
                                                        {categoria.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <Switch
                                            checkedChildren="Ativos"
                                            unCheckedChildren="Inativos"
                                            defaultChecked
                                            checked={this.state.ativo}
                                            onChange={this.handleChangeSwitch}
                                        />
                                    </div>
                                </div>
                                <div className="filtros filtros-mobile">
                                    <div className="bloco-esquerda bloco-esquerda-cursos">
                                        <div
                                            className="controlos"
                                            style={{ display: "flex", justifyContent: "space-between", marginBottom: 0 }}
                                        >
                                            {acesso?.create && (
                                                <button
                                                    className="botao-principal botao-principal-redondo"
                                                    title="Adicionar"
                                                    onClick={() => {
                                                        listaOrganismos.length
                                                            ? this.showDrawer()
                                                            : openNotificationWithIcon(
                                                                  "warning",
                                                                  "Atenção",
                                                                  "Tem que criar um organismo para poder criar um curso!"
                                                              );
                                                    }}
                                                >
                                                    +
                                                </button>
                                            )}
                                            <div className="filtros" style={{ paddingTop: 8 }}>
                                                <Switch
                                                    checkedChildren="Ativos"
                                                    unCheckedChildren="Inativos"
                                                    defaultChecked
                                                    onChange={this.handleChangeSwitch}
                                                />
                                            </div>
                                        </div>
                                        <div className="bloco-filtros">
                                            <ProcurarTurma
                                                pesquisa={this.state.pesquisa}
                                                tem_placeholder
                                                placeholder="Procurar por estrutura"
                                                HandleKeyPress={this.HandleKeyPress}
                                                resetCaixaProcura={this.resetCaixaProcura}
                                            />
                                            <Select
                                                value={filtroEdicao}
                                                className="filtro-selects"
                                                onChange={this.handleChangeFiltroEdicoes}
                                                placeholder="Edição"
                                                disabled={!edicoes.length}
                                                allowClear={true}
                                            >
                                                {edicoes.map(edicao => (
                                                    <Option value={edicao.id} key={edicao.id}>
                                                        {edicao.nome}
                                                    </Option>
                                                ))}
                                            </Select>

                                            <Select
                                                value={filtroCategoria}
                                                className="filtro-selects"
                                                onChange={this.handleChangeFiltroCategorias}
                                                placeholder="Nível"
                                                disabled={!categorias.length}
                                                allowClear={true}
                                            >
                                                {categorias.map(categoria => (
                                                    <Option value={categoria.id} key={categoria.id}>
                                                        {categoria.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </>
                            <Table
                                id="cursos-disciplinas"
                                className="curso-disciplinas-desktop"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columnsCursosComDisciplinas}
                                dataSource={cursos}
                                pagination={pagination}
                                locale={{ emptyText: "Não existem dados!" }}
                                onChange={this.handleChangeTable}
                                rowClassName={acesso?.read ? "clickeble-row" : ""}
                                onRow={(record, index) => {
                                    return {
                                        onClick: acesso?.read ? () => this.onRowClick(record.nome, "", record.id, true, record.multisseriado) : () => {}
                                    };
                                }}
                                onExpand={this.listarDisciplinas}
                                expandedRowRender={(record, index) => expandedRowRenderDesktop(record, index)}
                            />
                            <Table
                                id="cursos-disciplinas"
                                className="curso-disciplinas-mobile"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columnsCursosComDisciplinasMobile}
                                dataSource={cursosMobile}
                                pagination={pagination}
                                locale={{ emptyText: "Não existem dados!" }}
                                onChange={this.handleChangeTable}
                                rowClassName={acesso?.read ? "clickeble-row" : ""}
                                onRow={(record, index) => {
                                    return {
                                        onClick: acesso?.read ? () => this.onRowClick(record.nome, "", record.id, true, record.multisseriado) : () => {}
                                    };
                                }}
                                onExpand={this.listarDisciplinasMobile}
                                expandedRowRender={(record, index) => expandedRowRenderMobile(record, index)}
                            />
                        </div>
                    </div>
                </div>
                {!curso_grupo && !disciplina_grupo && (
                    <Drawer
                        className="drawer-add-cursos"
                        width={720}
                        onClose={this.onClose}
                        visible={visible || visibleDisciplina}
                        style={{
                            overflow: "auto",
                            height: "calc(100% - 108px)",
                            paddingBottom: "108px"
                        }}
                        maskClosable={false}
                        afterVisibleChange={this.afterVisibleChange}
                    >
                        <div className="bloco-info">
                            <Form className="form-add-curso" layout="horizontal">
                                {cursoid == 0 && !visibleDisciplina && (
                                    <Form.Item label="Plano de estudos">
                                        <Select
                                            placeholder="Selecionar"
                                            value={templateId}
                                            allowClear={true}
                                            onChange={this.handlerChangeTemplate}
                                        >
                                            {templates.map(template => (
                                                <Option key={template.id} value={template.id}>
                                                    {template.nome}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )}
                                {visibleDisciplina && (
                                    <Form.Item label="Nome">
                                        {getFieldDecorator("titulo", {
                                            initialValue: titulo,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(<Input name="titulo" onChange={this.handleChange} />)}
                                    </Form.Item>
                                )}
                                {!visibleDisciplina && (
                                    <>
                                        <Form.Item label="Ano">
                                            {getFieldDecorator("ano", {
                                                initialValue: ano,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ]
                                            })(
                                                <Select
                                                    placeholder="Selecionar"
                                                    disabled={templateId > 0}
                                                    allowClear={true}
                                                    onChange={ano => this.setState({ ano })}
                                                >
                                                    {anosLetivos.map(anoLetivo => (
                                                        <Option key={anoLetivo.id} value={anoLetivo.nome}>
                                                            {anoLetivo.nome}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Turma">
                                            {getFieldDecorator("turma", {
                                                initialValue: turma,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ]
                                            })(<Input name="turma" onChange={this.handleChange} />)}
                                        </Form.Item>
                                        <Form.Item label="Nível">
                                            {getFieldDecorator("categoria", {
                                                initialValue: categoriaId,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ]
                                            })(
                                                <Select
                                                    placeholder="Selecionar"
                                                    allowClear={true}
                                                    disabled={templateId > 0}
                                                    onChange={this.handlerChangeSelectCategoria}
                                                >
                                                    {listaCategorias.map((categoria, index) => (
                                                        <Option key={"categoria" + index} value={categoria.id}>
                                                            {categoria.nome}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                        {/*<Form.Item label="Modalidade">
                                            <Radio.Group
                                                name="curso_eja"
                                                disabled={templateId > 0}
                                                onChange={this.handlerChangeCheck}
                                                value={curso_eja}
                                            >
                                                <Radio value={false}>Regular</Radio>
                                                <Radio value={true}>Educação de Jovens e Adultos (EJA)</Radio>
                                            </Radio.Group>
                                        </Form.Item>*/}
                                        <Form.Item label="Turno">
                                            <Select
                                                placeholder="Selecionar"
                                                value={turno}
                                                disabled={templateId > 0}
                                                allowClear={true}
                                                onChange={turno => this.setState({ turno })}
                                            >
                                                {turnos.map(turno => (
                                                    <Option key={turno.id} value={turno.id}>
                                                        {turno.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </>
                                )}
                                <Form.Item label="Código">
                                    {getFieldDecorator("codigo", {
                                        initialValue: codigo,
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ]
                                    })(<Input name="codigo" disabled={templateId > 0} onChange={this.handleChange} />)}
                                </Form.Item>
                                {visibleDisciplina && (
                                    <Form.Item label="Créditos">
                                        {getFieldDecorator("creditos", {
                                            initialValue: creditos,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(<InputNumber name="creditos" min={1} onChange={creditos => this.setState({ creditos })} />)}
                                    </Form.Item>
                                )}
                                <Form.Item label="Carga Horária Total">
                                    {getFieldDecorator("horas_trabalho", {
                                        initialValue: horas_trabalho
                                        //rules: [
                                        //    {
                                        //        required: true,
                                        //        message: "Campo obrigatório"
                                        //    }
                                        //]
                                    })(
                                        <InputNumber
                                            name="horas_trabalho"
                                            min={1}
                                            onChange={horas_trabalho => this.setState({ horas_trabalho })}
                                        />
                                    )}
                                </Form.Item>
                                {visibleDisciplina && (
                                    <Form.Item label="">
                                        <div className="bloco-fases-escolares">
                                            <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>
                                                Tipos de Horas / Carga Horária
                                                <button className="botao-principal" onClick={this.adicionarHorasContacto}>
                                                    <Icon type="plus" />
                                                </button>
                                            </h3>
                                            <div className="curso-lista-fases">
                                                {!horas_contacto.length ? <span>Sem dados</span> : null}
                                                {horas_contacto.map((hora_contacto, index) => (
                                                    <div key={index} className="item">
                                                        <div className="item-inputs">
                                                            <Form.Item>
                                                                {getFieldDecorator(`tipo${index}`, {
                                                                    initialValue: hora_contacto.id,
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: "Campo obrigatório"
                                                                        }
                                                                    ]
                                                                })(
                                                                    <Select
                                                                        placeholder="Selecionar"
                                                                        allowClear={true}
                                                                        onChange={value => this.handleChangeHorasContacto(value, index)}
                                                                    >
                                                                        {tipo_horas.map(tipo => (
                                                                            <Option key={tipo.id} value={tipo.id}>
                                                                                {tipo.titulo}
                                                                            </Option>
                                                                        ))}
                                                                    </Select>
                                                                )}
                                                            </Form.Item>
                                                            <Form.Item>
                                                                {getFieldDecorator(`horas${index}`, {
                                                                    initialValue: hora_contacto.carga_horaria,
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: "Campo obrigatório"
                                                                        }
                                                                    ]
                                                                })(
                                                                    <InputNumber
                                                                        name={`horas${index}`}
                                                                        min={1}
                                                                        onChange={value =>
                                                                            this.handleChangeHorasContactoCarga(value, index)
                                                                        }
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </div>
                                                        <Link
                                                            className="botao-icon-excluir"
                                                            to="#"
                                                            title="Excluir"
                                                            onClick={() => this.excluirHorasContacto(index)}
                                                        >
                                                            <Icon type="delete" />
                                                        </Link>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Form.Item>
                                )}
                                {!visibleDisciplina && (
                                    <Form.Item label="Calendário">
                                        {getFieldDecorator("calendario", {
                                            initialValue: calendarioId,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(
                                            <>
                                                <Select
                                                    placeholder="Selecionar"
                                                    value={calendarioId}
                                                    allowClear={true}
                                                    onChange={this.handleChangeSelectCalendario}
                                                //disabled={discComAulas}
                                                >
                                                    {calendariosFiltrados.map((calendario, index) => (
                                                        <Option key={index} value={calendario.id}>
                                                            {calendario.nome}
                                                        </Option>
                                                    ))}
                                                </Select>
                                                {calendarioInfo}
                                            </>
                                        )}
                                    </Form.Item>
                                )}
                                <Form.Item validateStatus={coordenadoresStatus.validateStatus} help={coordenadoresStatus.help}>
                                    <div className="bloco-fases-escolares">
                                        <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>
                                            Coordenador(es) / Carga horaria da coordenação
                                            <button className="botao-principal" onClick={this.adicionarCoordenadores}>
                                                <Icon type="plus" />
                                            </button>
                                        </h3>
                                        <div className="curso-lista-fases">
                                            {!listaProcurarCoordenadores.length ? <span>Sem dados</span> : null}
                                            {listaProcurarCoordenadores.map((coordenador, index) => (
                                                <div key={index} className="item">
                                                    <div className="item-inputs">
                                                        <Form.Item>
                                                            {getFieldDecorator(`coordenador${index}`, {
                                                                initialValue: coordenador.id,
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Campo obrigatório"
                                                                    }
                                                                ]
                                                            })(
                                                                <Select
                                                                    showSearch
                                                                    placeholder="Selecionar"
                                                                    optionFilterProp="children"
                                                                    onChange={value => this.handleChangeCoordenador(value, index)}
                                                                    onFocus={() => {}}
                                                                    onBlur={() => {}}
                                                                    onSearch={() => {}}
                                                                    filterOption={(input, option) =>
                                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                                        0
                                                                    }
                                                                >
                                                                    {this.montarCoordenadores(coordenador.id).map((coordenador, index) => (
                                                                        <Option key={coordenador.id} value={coordenador.id}>
                                                                            {coordenador.nome}
                                                                        </Option>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                        </Form.Item>
                                                        <Form.Item>
                                                            {getFieldDecorator(`coordenador_carga${index}`, {
                                                                initialValue: coordenador.carga_horaria,
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Campo obrigatório"
                                                                    }
                                                                ]
                                                            })(
                                                                <InputNumber
                                                                    name={`coordenador_carga${index}`}
                                                                    min={0}
                                                                    onChange={value => this.handleChangeCoordenadorCarga(value, index)}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                        {!visibleDisciplina && (
                                                            <Form.Item>
                                                                {getFieldDecorator(`coordenador_replicar${index}`, {
                                                                    initialValue: coordenador.replicar,
                                                                })(
                                                                    <span style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                                                        <Switch size="small" checked={coordenador.replicar} onChange={value => this.handleChangeCoordenadorReplicar(value, index)} />
                                                                        Replicar nas disciplinas
                                                                        <Tooltip className="info-icon" title="O coordenador é replicado apenas na criação de uma nova disciplina.">
                                                                            <Icon type="question-circle-o" />
                                                                        </Tooltip>
                                                                    </span>
                                                                )}
                                                            </Form.Item>)}
                                                    </div>
                                                    <Link
                                                        className="botao-icon-excluir"
                                                        to="#"
                                                        title="Excluir"
                                                        onClick={() => this.excluirCoordenador(index)}
                                                    >
                                                        <Icon type="delete" />
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Form.Item>
                                {(cursoid > 0 && visible) && (
                                    <Form.Item label="Professor Titular">
                                        {getFieldDecorator("profTitularId", {
                                            initialValue: profTitularId
                                        })(
                                            <>
                                                <Select
                                                    placeholder="Selecionar"
                                                    value={profTitularId}
                                                    allowClear={true}
                                                    onChange={this.handleChangeSelectProfTitular}
                                                >
                                                    {listaProfs.map((prof, index) => (
                                                        <Option key={index} value={prof.id}>
                                                            {prof.nome}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </>
                                        )}
                                    </Form.Item>)}
                                {!visibleDisciplina && (
                                    <>
                                        <div className="bloco">
                                            <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>
                                                Ficha do plano de estudos
                                            </h3>
                                            {plano_estudos && (
                                                <Form.Item>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <span>{plano_estudos}</span>
                                                        <span
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "space-between",
                                                                gap: 10
                                                            }}
                                                        >
                                                            <Link
                                                                to="#"
                                                                title="Visualizar"
                                                                className="botao-icon-detalhe"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        visibleViewer: true,
                                                                        docs: [{ uri: plano_estudos_caminho }]
                                                                    })
                                                                }
                                                            >
                                                                <Icon type="eye" />
                                                            </Link>
                                                            <Link
                                                                to="#"
                                                                title="Visualizar"
                                                                className="botao-icon-excluir"
                                                                onClick={this.excluirDoc}
                                                            >
                                                                <Icon type="delete" />
                                                            </Link>
                                                        </span>
                                                    </div>
                                                </Form.Item>
                                            )}
                                            <Form.Item>
                                                <div className="anexos">
                                                    {getFieldDecorator("ficheiro", {
                                                        rules: [
                                                            {
                                                                validator: this.validar
                                                            }
                                                        ],
                                                        initialValue: fileList
                                                    })(
                                                        <Dragger {...props}>
                                                            <p className="ant-upload-drag-icon">
                                                                <i className="fas fa-upload" />
                                                            </p>
                                                            <p className="ant-upload-text">
                                                                Adicionar anexo{" "}
                                                                <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                                    <Icon type="question-circle-o" />
                                                                </Tooltip>
                                                            </p>
                                                            <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                                        </Dragger>
                                                    )}
                                                </div>
                                            </Form.Item>
                                        </div>
                                        {cursoid > 0 && visible && (
                                            <div className="bloco">
                                                <Form.Item>
                                                    <span style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                                        <Switch size="small" name="atualizarEstruturasDisciplinas" checked={atualizarEstruturasDisciplinas} onChange={this.handleChangeAtualizarEstruturasDisciplinas} />
                                                        Atualizar as estruturas avaliativas de cada disciplina
                                                    </span>
                                                </Form.Item>
                                            </div>
                                        )}
                                        <div className="bloco">
                                            <Form.Item>
                                                <span style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                                    <Switch size="small" name="pareceresPorAula" checked={pareceresPorAula} onChange={this.handleChangePareceresPorAula} />
                                                    Pareceres por aula
                                                </span>
                                            </Form.Item>
                                        </div>
                                    </>
                                )}
                                {visibleDisciplina && (
                                    <>
                                        <Form.Item validateStatus={professoresStatus.validateStatus} help={professoresStatus.help}>
                                            <div className="bloco-fases-escolares">
                                                <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>
                                                    Professor(es) / Carga Horária
                                                    <button className="botao-principal" onClick={this.adicionarProfessores}>
                                                        <Icon type="plus" />
                                                    </button>
                                                </h3>
                                                <div className="curso-lista-fases">
                                                    {!listaProcurarFormadores.length ? <span>Sem dados</span> : null}
                                                    {listaProcurarFormadores.map((professor, index) => (
                                                        <div key={index} className="item">
                                                            <div className="item-inputs">
                                                                <Form.Item>
                                                                    {getFieldDecorator(`professor${index}`, {
                                                                        initialValue: professor.id,
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: "Campo obrigatório"
                                                                            }
                                                                        ]
                                                                    })(
                                                                        <Select
                                                                            showSearch
                                                                            placeholder="Selecionar"
                                                                            optionFilterProp="children"
                                                                            onChange={value => this.handleChangeProfessor(value, index)}
                                                                            onFocus={() => {}}
                                                                            onBlur={() => {}}
                                                                            onSearch={() => {}}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children
                                                                                    .toLowerCase()
                                                                                    .indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {this.montarProfessor(professor.id).map((formador, index) => (
                                                                                <Option key={formador.id} value={formador.id}>
                                                                                    {formador.nome}
                                                                                </Option>
                                                                            ))}
                                                                        </Select>
                                                                    )}
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    {getFieldDecorator(`professor_carga${index}`, {
                                                                        initialValue: professor.carga_horaria,
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: "Campo obrigatório"
                                                                            }
                                                                        ]
                                                                    })(
                                                                        <InputNumber
                                                                            name={`professor_carga${index}`}
                                                                            min={0}
                                                                            onChange={value =>
                                                                                this.handleChangeProfessorCarga(value, index)
                                                                            }
                                                                        />
                                                                    )}
                                                                </Form.Item>
                                                            </div>
                                                            <Link
                                                                className="botao-icon-excluir"
                                                                to="#"
                                                                title="Excluir"
                                                                onClick={() => this.excluirProfessor(index)}
                                                            >
                                                                <Icon type="delete" />
                                                            </Link>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Form.Item>
                                        <Form.Item validateStatus={estruturaStatus.validateStatus} help={estruturaStatus.help}>
                                            <div className="bloco-fases-escolares">
                                                <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>
                                                    Estruturas de Avaliação
                                                    <button className="botao-principal" onClick={this.adicionarFaseEscolar}>
                                                        <Icon type="plus" />
                                                    </button>
                                                </h3>
                                                <div className="curso-lista-fases">
                                                    {!fasesEscolares.length ? <span>Sem dados</span> : null}
                                                    {fasesEscolares.map((faseEscolar, index) => (
                                                        <div key={index} className="item">
                                                            <div className="item-inputs">
                                                                <Form.Item>
                                                                    {getFieldDecorator(`fase${index}`, {
                                                                        initialValue: faseEscolar.tipo,
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: "Campo obrigatório"
                                                                            }
                                                                        ]
                                                                    })(
                                                                        <Select
                                                                            placeholder="Selecionar"
                                                                            filterOption={false}
                                                                            style={{ width: "100%" }}
                                                                            onChange={value => this.handleChangeFase(value, index)}
                                                                        >
                                                                            {listaTiposDiario.map((tipo, index) => (
                                                                                <Option key={index} value={tipo.id}>
                                                                                    {tipo.nome}
                                                                                </Option>
                                                                            ))}
                                                                        </Select>
                                                                    )}
                                                                    {faseEscolar.periodo && faseEscolar.genero && (
                                                                        <div
                                                                            style={{
                                                                                borderTop: "1px solid #d9d9d9",
                                                                                marginTop: 10,
                                                                                paddingTop: 5,
                                                                                display: "flex",
                                                                                gap: 10
                                                                            }}
                                                                        >
                                                                            <span style={{ display: "block", lineHeight: "initial" }}>
                                                                                Periodo Letivo: <strong>{faseEscolar.periodo}</strong>
                                                                            </span>
                                                                            <span style={{ display: "block", lineHeight: "initial" }}>
                                                                                Tipo: <strong>{faseEscolar.genero}</strong>
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    {getFieldDecorator(`fase_ordem${index}`, {
                                                                        initialValue: faseEscolar.ordem,
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: "Campo obrigatório"
                                                                            }
                                                                        ]
                                                                    })(
                                                                        <InputNumber
                                                                            placeholder="Ordem"
                                                                            min={1}
                                                                            onChange={value => this.handleChangeFaseOrdem(value, index)}
                                                                        />
                                                                    )}
                                                                </Form.Item>
                                                            </div>
                                                            <Link
                                                                className="botao-icon-excluir"
                                                                to="#"
                                                                title="Excluir"
                                                                onClick={() => this.excluirCampo(index)}
                                                            >
                                                                <Icon type="delete" />
                                                            </Link>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Form.Item>
                                        <Form.Item label="Ficha">
                                            {getFieldDecorator("ficha", {
                                                initialValue: fichaId
                                            })(
                                                <>
                                                    <Select
                                                        placeholder="Selecionar"
                                                        value={fichaId}
                                                        allowClear={true}
                                                        onChange={fichaId => this.setState({ fichaId })}
                                                    >
                                                        {listaFichas.map((ficha, index) => (
                                                            <Option key={index} value={ficha.id}>
                                                                {ficha.nome}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                    {fichaId ? (
                                                        <Link to="#" onClick={() => this.setState({ fichaId, visibleDrawerFicha: true })}>
                                                            Preencher ficha
                                                        </Link>
                                                    ) : null}
                                                </>
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Encerramento">
                                            {getFieldDecorator("encerramento", {
                                                initialValue: encerramento
                                            })(
                                                <Select placeholder="Selecionar" onChange={encerramento => this.setState({ encerramento })}>
                                                    <Option value="Manual">Manual</Option>
                                                    <Option value="Automatico">Automático</Option>
                                                    <Option value="FechoTurma">Fecho da turma</Option>
                                                </Select>
                                            )}
                                        </Form.Item>
                                        <Form.Item label="% máxima de faltas">
                                            {getFieldDecorator("percentagemFaltas", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    },
                                                    {
                                                        validator: this.validarValor
                                                    }
                                                ],
                                                initialValue: percentagemFaltas
                                            })(<Input className="input-50" name="percentagemFaltas" onChange={this.handleChange} />)}
                                        </Form.Item>
                                        <Form.Item label="Ordem">
                                            {getFieldDecorator("curso_ordem", {
                                                initialValue: curso_ordem,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ]
                                            })(
                                                <InputNumber
                                                    className="input-25"
                                                    name="curso_ordem"
                                                    min={1}
                                                    onChange={this.handleChangeOrdem}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item>
                                            <Checkbox name="habilitarConteudosStepforma" checked={habilitarConteudosStepforma} onChange={this.handleChangeCheckbox}>
                                                Habilitar Conteúdos Stepforma
                                            </Checkbox>
                                        </Form.Item>
                                        {habilitarConteudosStepforma &&
                                            <Form.Item label="Atividades">
                                                {getFieldDecorator("listaAtividadesConteudosStepforma", {
                                                    initialValue: atividadeConteudosStepforma,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Campo obrigatório"
                                                        }
                                                    ]
                                                })(
                                                    <Select placeholder="Selecionar" allowClear={true} onChange={atividadeConteudosStepforma => this.setState({ atividadeConteudosStepforma })}>
                                                        {listaAtividadesConteudosStepforma.map((atividade, index) => (
                                                            <Option key={index} value={atividade.id}>
                                                                {atividade.nome}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Form.Item>}
                                    </>
                                )}
                            </Form>
                        </div>
                        <div className="ant-drawer-footer">
                            <button
                                className="botao-secundario"
                                onClick={this.onClose}
                                style={{ marginRight: 20, display: "inline-block" }}
                            >
                                Voltar
                            </button>
                            {(cursoid > 0 && visible) || (disciplinaid > 0 && visibleDisciplina) ? (
                                <button
                                    className="botao-principal"
                                    disabled={iconLoading}
                                    onClick={visible ? e => this.guardarTurma(e) : e => this.guardarDisciplina(e)}
                                    type="primary"
                                >
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Guardar
                                </button>
                            ) : (
                                <button
                                    className="botao-principal"
                                    disabled={iconLoading}
                                    onClick={visible ? e => this.criarCurso(e) : e => this.criarDisciplina(e)}
                                    type="primary"
                                >
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Guardar
                                </button>
                            )}
                        </div>
                        <DrawerHorarios
                            detalheHorario={detalheHorario}
                            horarioIndex={horarioIndex}
                            horario={horario}
                            ultimoHorario={horarios[horarios.length - 1]}
                            adicionarHorario={this.adicionarHorario}
                            alterarHorario={this.alterarHorario}
                            visibleDrawerHorarios={visibleDrawerHorarios}
                            onClose={() => this.setState({ visibleDrawerHorarios: false })}
                        />
                        <DrawerInformacoes
                            index={index}
                            dataInfo={dataInfo}
                            descricaoInfo={descricaoInfo}
                            detalhe={detalhe}
                            visible={visibleInformacoes}
                            onClose={this.onCloseInformacoes}
                            handleChange={this.handleChange}
                            handleChangeData={this.handleChangeData}
                            adicionar={this.adicionarInfo}
                            editar={this.editarInfo}
                        />
                    </Drawer>
                )}
                <Modal visible={visibleDuplicar} maskClosable={false} className="exportar-csv" closable={false} footer={null}>
                    <div className="exportar-csv-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A {textoModal}...</p>
                    </div>
                </Modal>
                <Drawer
                    className="drawer-detalhe-curso"
                    //title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Nível">{detalheCategoria}</Form.Item>
                                <Form.Item label="Periodo">{detalhePeriodo}</Form.Item>
                                {detalheEstado ? <Form.Item label="Estado">{detalheEstado}</Form.Item> : null}
                                {detalheInscritos ? <Form.Item label="Inscritos">{detalheInscritos}</Form.Item> : null}
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
                <DrawerFicha
                    fichaId={fichaId}
                    fichaCampos={fichaCampos}
                    visible={visibleDrawerFicha}
                    onClose={() => this.setState({ visibleDrawerFicha: false })}
                    atualizar={fichaCampos => this.setState({ visibleDrawerFicha: false, fichaCampos })}
                />
                <DrawerMultisseriado
                    multisseriadoId={multisseriadoId}
                    turmaId={turmaId}
                    visible={visibleDrawerMultisseriado}
                    onClose={() => this.setState({ visibleDrawerMultisseriado: false })}
                    atualizar={() => { this.listarCursos(); this.setState({ visibleDrawerMultisseriado: false }); }}
                />
                <ModalMover
                    visible={visibleMover}
                    onClose={() => this.setState({ visibleMover: false })}
                    onConfirm={(organismo, curso) => this.moverCurso(organismo, curso)}
                    temOrganismos
                    cursoPai={cursoPai}
                />
                <ModalExportar
                    visibleExportar={visibleExport}
                    btnDescarregar={buttonDownload}
                    ficheiroExportar={exportFile}
                    onCancel={() => this.setState({ visibleExport: false })}
                />
                <ModalViewer visible={visibleViewer} docs={docs} onCancel={() => this.setState({ visibleViewer: false })} />
            </div>
        );
    }
}

const ContextGestaoCursosOrganismos = ({ form }) => (
    <GlobalContext.Consumer>
        {({ estabelecimento }) => <GestaoCursosOrganismos estabelecimento={estabelecimento} form={form} />}
    </GlobalContext.Consumer>
);

const FormGestaoCursosOrganismos = Form.create({ name: "cursos-organismos" })(ContextGestaoCursosOrganismos);

export default FormGestaoCursosOrganismos;
