import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Header from "./Header";
import Menu from "./Menu";
import VisaoGeralDisciplina from "./VisaoGeral/VisaoGeralDisciplina";
import VisaoGeral from "./VisaoGeral/VisaoGeralNovaVersao";
import Modulos from "./Modulos/Modulos";
import Diario from "./Diario/Main";
import GerirHorario from "./GerirHorario/GerirHorario";
import FrequenciaDiaria from "./Diario/Frequencia/Diaria";
import FrequenciaPorPeriodo from "./Diario/Frequencia/PorPeriodo";
/*import FasesEscolares from "./Diario/FasesEscolares/FasesEscolares";*/
import ListaRelatorios from "./Diario/ListaRelatorios";
import Detalhe from "./Diario/Detalhe";
import ListaNotas from "./Diario/ListaNotas";
import ListaNotasConceito from "./Diario/ListaNotasConceito";
import ListaAlunos from "./Diario/ListaAlunos";
import ListaFrequencia from "./Diario/ListaFrequencia";
import ListaObservacoes from "./Diario/ListaObservacoes";
import ListaPareceres from "./Diario/ListaPareceres";
import TurmaPareceres from "./Diario/TurmaPareceres/TurmaPareceres";
import ListaConteudos from "./Diario/ListaConteudos";
import ListaDisciplinas from "./ListaDisciplinas/ListaDisciplinas";
import Forum from "./Foruns/Forum";
import ForumDetalhe from "./Foruns/ForumDetalhe";
import Ficheiros from "./Ficheiros/Ficheiros";
import Participantes from "./Participantes/Participantes";
import Aproveitamento from "./Aproveitamento/Aproveitamento";
import AproveitamentoNotas from "./Aproveitamento/Notas/Notas";
import AproveitamentoNotasDetalhe from "./Aproveitamento/Notas/Detalhe";
import AproveitamentoConceitos from "./Aproveitamento/Conceitos/Conceitos";
import AproveitamentoConceitosPautasDetalhe from "./Aproveitamento/Conceitos/Pautas/Detalhe";
import AproveitamentoConceitosAlunos from "./Aproveitamento/Conceitos/Alunos/Alunos";
import AproveitamentoConceitosAlunosDetalhe from "./Aproveitamento/Conceitos/Alunos/Detalhe";
import AproveitamentoBnccPautasDetalhe from "./Aproveitamento/Bncc/Pautas/Detalhe";
import AproveitamentoBnccAlunosDetalhe from "./Aproveitamento/Bncc/Alunos/Detalhe";
import AproveitamentoRelatorios from "./Aproveitamento/Relatorios/Relatorios";
import Resultados from "./Resultados/Main";
import Ranking from "./Resultados/Ranking";
import Videoconferencias from "./Videoconferencias/Main";
import { GlobalContext } from "../GlobalState";
import ContextProvider from "./ContextProvider";
import axios from "axios";

class Layout extends Component {
    static contextType = GlobalContext;
    state = {
        //HEADER
        conteudoHeader: true,
        //MENU
        menuSemHeader: true,
        matriz: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };
    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.temComandos(localStorage.getItem("codigo_curso"));
    }

    componentDidUpdate() {
        if (!this.context.is_authenticated || this.context.role === "UTILIZADOR") {
            if (!this.context.is_authenticated)
                this.setState({
                    redirect: true,
                    redirectLink: "/login"
                });
            //else if (this.context.role === "UTILIZADOR")
            //    this.setState({
            //        redirect: true,
            //        redirectLink: "/user"
            //    });
        }
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.match.params.turma !== this.props.match.params.turma) {
            this.temComandos(localStorage.getItem("codigo_curso"));
        }
    }

    //VALIDA SE O UTILIZADOR LOGGEDIN TEM ACESSO AOS CONTROLOS CRUD DA PAGINA
    temComandos = cursoId => {
        axios({
            method: "get",
            url: "/api/autenticacao/tem-comandos",
            params: {
                cursoId
            }
        })
            .then(response => {
                localStorage.setItem("tem_comandos", response.data);
            })
            .catch(() => {});
    };

    render() {
        const {
            header,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <ContextProvider>
                    <Header matriz={this.state.matriz} state={this.state} />
                    <Menu matriz={this.state.matriz} state={this.state} acessos={this.context.acessos} />
                    <Switch>
                        <Route path="/gerir-detalhe-turma/:turma/visao-geral-disciplina" component={VisaoGeralDisciplina} />
                        <Route path="/gerir-detalhe-turma/:turma/visao-geral" component={VisaoGeral} />
                        <Route path="/gerir-detalhe-turma/:turma/modulos" component={Modulos} />
                        <Route path="/gerir-detalhe-turma/:turma/disciplinas" component={ListaDisciplinas} />
                        <Route path="/gerir-detalhe-turma/:turma/horario-disciplina" component={Diario} />
                        <Route path="/gerir-detalhe-turma/:turma/horario" component={GerirHorario} />
                        <Route path="/gerir-detalhe-turma/:turma/horario-detalhe" component={Detalhe} />
                        <Route path="/gerir-detalhe-turma/:turma/diario-frequencia-diaria" component={FrequenciaDiaria} />
                        <Route path="/gerir-detalhe-turma/:turma/diario-frequencia-por-periodo" component={FrequenciaPorPeriodo} />
                        {/*<Route path="/gerir-detalhe-turma/:turma/diario-fases-escolares" component={FasesEscolares} />*/}
                        <Route path="/gerir-detalhe-turma/:turma/diario-detalhe" component={Detalhe} />
                        <Route path="/gerir-detalhe-turma/:turma/diario-folha-notas" component={ListaNotas} />
                        <Route path="/gerir-detalhe-turma/:turma/relatorios-notas" component={ListaRelatorios} />
                        <Route path="/gerir-detalhe-turma/:turma/diario-folha-conceito" component={ListaNotasConceito} />
                        <Route path="/gerir-detalhe-turma/:turma/diario-lista-alunos" component={ListaAlunos} />
                        <Route path="/gerir-detalhe-turma/:turma/diario-folha-frequencia" component={ListaFrequencia} />
                        <Route path="/gerir-detalhe-turma/:turma/diario-folha-observacoes" component={ListaObservacoes} />
                        <Route path="/gerir-detalhe-turma/:turma/diario-folha-pareceres" component={ListaPareceres} />
                        <Route path="/gerir-detalhe-turma/:turma/diario-folha-pareceres-turma" component={TurmaPareceres} />
                        <Route path="/gerir-detalhe-turma/:turma/diario-folha-conteudos" component={ListaConteudos} />
                        <Route path="/gerir-detalhe-turma/:turma/forum" component={Forum} />
                        <Route path="/gerir-detalhe-turma/:turma/forum-detalhe" component={ForumDetalhe} />
                        <Route path="/gerir-detalhe-turma/:turma/ficheiros" component={Ficheiros} />
                        <Route path="/gerir-detalhe-turma/:turma/resultados" component={Resultados} />
                        <Route path="/gerir-detalhe-turma/:turma/ranking" component={Ranking} />
                        <Route path="/gerir-detalhe-turma/:turma/videoconferencias" component={Videoconferencias} />
                        <Route path="/gerir-detalhe-turma/:turma/participantes" component={Participantes} />
                        <Route path="/gerir-detalhe-turma/:turma/aproveitamento" component={Aproveitamento} />
                        <Route path="/gerir-detalhe-turma/:turma/aproveitamento-notas/:code" component={AproveitamentoNotasDetalhe} />
                        <Route path="/gerir-detalhe-turma/:turma/aproveitamento-notas" component={AproveitamentoNotas} />
                        <Route
                            path="/gerir-detalhe-turma/:turma/aproveitamento-conceitos-pautas/:code"
                            component={AproveitamentoConceitosPautasDetalhe}
                        />
                        <Route
                            path="/gerir-detalhe-turma/:turma/aproveitamento-conceitos-lista-alunos"
                            component={AproveitamentoConceitosAlunos}
                        />
                        <Route
                            path="/gerir-detalhe-turma/:turma/aproveitamento-conceitos-alunos/:code"
                            component={AproveitamentoConceitosAlunosDetalhe}
                        />
                        <Route
                            path="/gerir-detalhe-turma/:turma/aproveitamento-bncc-pautas/:code"
                            component={AproveitamentoBnccPautasDetalhe}
                        />
                        <Route
                            path="/gerir-detalhe-turma/:turma/aproveitamento-bncc-alunos/:code"
                            component={AproveitamentoBnccAlunosDetalhe}
                        />
                        <Route path="/gerir-detalhe-turma/:turma/aproveitamento-conceitos" component={AproveitamentoConceitos} />
                        <Route path="/gerir-detalhe-turma/:turma/relatorios-aproveitamento" component={AproveitamentoRelatorios} />
                        {/*<Route path="/gerir-detalhe-turma/:turma/:fase" component={FaseEscolar} />*/}
                    </Switch>
                </ContextProvider>
            </>
        );
    }
}

export default Layout;
