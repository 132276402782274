import React, { Component } from "react";
import { Redirect, Link, withRouter } from "react-router-dom";
import { Modal, Icon, notification } from "antd";
import Participante from "./Participante";
import DrawerNotificar from "./Drawers/DrawerNotificar";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";
import { GeralContext } from "../ContextProvider";

import ModalExportar from "../../Modals/ModalExportar";
import ModalViewer from "../../Modals/ModalViewer";
import ModalLoading from "../../Modals/ModalLoading";
import Acessos from "../../Geral/Acessos/Acessos";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};
export class Participantes extends Component {
    static contextType = GlobalContext;
    state = {
        participantes: [],
        //LOADING
        loading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER NOTIFICAR
        visible: false,
        //LOADING
        visibleLoading: false,
        //EXPORTAR
        visibleExport: false,
        buttonDownload: false,
        exportFile: "",
        //VIEWER
        visibleViewer: false,
        docs: []
    };

    componentDidMount() {
        this.listar(localStorage.getItem("codigo_curso"));
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.listar(localStorage.getItem("codigo_curso"));
        }
    }

    listar = turmaId => {
        this.setState(
            {
                loading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/turma/lista-alunos",
                    timeout: 60 * 10 * 1000,
                    params: {
                        turmaId
                    }
                })
                    .then(response => {
                        this.setState({
                            participantes: response.data,
                            loading: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (<Link
                to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar}
                className="botao-secundario botao-secundario-redondo"
                onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)}
                style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <Icon type="arrow-left" />
            </Link>);
        }
    }

    exportarPdf = () => {
        this.setState(
            {
                visibleLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas-exportar/listagem-alunos-pdf",
                    params: {
                        id: localStorage.getItem("codigo_curso")
                    }
                })
                    .then(response => {
                        this.setState({
                            visibleLoading: false,
                            visibleViewer: true,
                            docs: [{ uri: response.data }]
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "N�o foi poss�vel exportar!");
                        this.setState({
                            visibleLoading: false
                        });
                    });
            }
        );
    }

    exportarCsv = () => {
        this.setState(
            {
                visibleExport: true,
                buttonDownload: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas-exportar/listagem-alunos",
                    params: {
                        id: localStorage.getItem("codigo_curso")
                    }
                })
                    .then(response => {
                        this.setState({
                            buttonDownload: false,
                            exportFile: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "N�o foi poss�vel exportar!");
                        this.setState({
                            visibleExport: false
                        });
                    });
            }
        );
    }

    render() {
        const {
            participantes,
            //LOADING
            loading,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER NOTIFICAR
            visible,
            //DRAWER ENVIAR MENSAGENS
            utilizador,
            //LOADING
            visibleLoading,
            //EXPORTAR
            visibleExport,
            buttonDownload,
            exportFile,
            //VIEWER
            visibleViewer,
            docs
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Acessos funcionalidadeId={22} />
                <GeralContext.Consumer>
                    {geralContext => {
                        return (
                            <div className="container container-body">
                                <div className="curso-resultados">
                                    <div className="controlos controlos-fundo" style={{ justifyContent: "flex-end" }}>
                                        <div className="bloco-direita">
                                            <Link to="#" className="botao-icon-enviar botao-maior" onClick={() => this.setState({ visible: true })}>
                                                <Icon type="mail" />
                                            </Link>
                                            <Link to="#" className="botao-icon-detalhe botao-maior" title="Visualizar" onClick={this.exportarPdf}>
                                                <Icon type="eye" />
                                            </Link>
                                            <Link to="#" className="botao-icon-download botao-maior" title="Descarregar" onClick={this.exportarCsv}>
                                                <Icon type="download" />
                                            </Link>
                                            {this.montarBotaoVoltar()}
                                        </div>
                                    </div>
                                </div>
                                <div className="curso-participantes" style={loading ? { display: "block" } : {}}>
                                    {loading ? (
                                        <div className="loading-data">
                                            <div className="loading" />
                                        </div>
                                    ) : (
                                        participantes.map((participante, index) =>
                                            <Participante {
                                                ...participante}
                                                key={index}
                                                dt_fechado={geralContext.dt_fechado}
                                                dt_inicio={geralContext.dt_inicio}
                                                dt_fim={geralContext.dt_fim}
                                                fechado={geralContext.fechado}
                                                abrirDrawerNotificar={this.abrirDrawerNotificar}
                                                responsaveis_entidade={this.context.responsaveis_entidade}
                                                conf_total_participantes={this.context.conf_total_participantes}
                                                habilitar_gamificacao={geralContext.habilitar_gamificacao && this.context.gamificacao_entidade}
                                                perfil={"Aluno"}
                                            />
                                        )
                                    )}
                                </div>
                                <DrawerNotificar
                                    visible={visible}
                                    onClose={() => this.setState({ visible: false })}
                                    total={participantes.length}
                                />
                                <ModalExportar
                                    visibleExportar={visibleExport}
                                    btnDescarregar={buttonDownload}
                                    ficheiroExportar={exportFile}
                                    onCancel={() => this.setState({ visibleExport: false })}
                                />
                                <ModalLoading
                                    visible={visibleLoading}
                                    text="A gerar documento..."
                                />
                                <ModalViewer
                                    visible={visibleViewer}
                                    docs={docs}
                                    onCancel={() => this.setState({ visibleViewer: false })}
                                />
                            </div>
                        )
                    }}
                </GeralContext.Consumer>
            </>
        );
    }
}

export default withRouter(Participantes);
