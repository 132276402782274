import React from "react";
import { Modal } from 'antd';
import Viewer from "../Utilities/Viewer/Viewer";

const ModalViewer = ({ visible, docs, onCancel }) => {
    return (
        <Modal
            style={{ top: 0 }}
            width="100%"
            visible={visible}
            maskClosable={false}
            onCancel={onCancel}
            footer={null}
            //closable={false}
        >
            <Viewer docs={docs} />
        </Modal>
    );
}

export default ModalViewer;