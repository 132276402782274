import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, Icon, Select, Checkbox, Collapse, notification } from "antd";
import axios from "axios";

const DrawerPerfis = ({ utilizadorId, visible, onClose, onUpdate, form }) => {
    const [perfis, setPerfis] = useState([]);
    const [estabelecimentos, setEstabelecimentos] = useState([]);
    const [selecionados, setSelecionados] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [iconLoading, setIconLoading] = useState(false);

    const openNotificationWithIcon = (type, message, description) => {
        notification[type]({
            message: message,
            description: description
        });
    };
    const { Panel } = Collapse;
    const { Option } = Select;

    const listarEstabelecimentos = () => {
        axios({
            method: "get",
            url: "/api/gestao-utilizadores-organismos/listar-estabelecimentos",
            params: {
                utilizadorId
            }
        })
            .then(response => {
                setEstabelecimentos(response.data);
                setSelecionados(response.data.map(x => {
                    return {
                        id: x.id,
                        perfis: []
                    }
                }))
                listarSelecionados();
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    const listarPerfis = () => {
        axios({
            method: "get",
            url: "/api/gestao-perfis/listar",
            params: {
                utilizadorId
            }
        })
            .then(response => {
                setPerfis(response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    const listarSelecionados = () => {
        axios({
            method: "get",
            url: "/api/gestao-perfis/listar-selecionados",
            params: {
                utilizadorId
            }
        })
            .then(response => {
                setSelecionados(response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    const onChange = (estabelecimentoId, sels) => {
        let tempSelecionados = selecionados.filter(x => x.id !== estabelecimentoId);
        let tempSelecionado = selecionados.find(x => x.id === estabelecimentoId);

        tempSelecionado.perfis = sels.map(selId => {
            const jaSelecionado = tempSelecionado.perfis.find(x => x.id === selId);
            return {
                id: selId,
                principal: jaSelecionado?.principal || sels.length === 1
            }
        });

        setSelecionados([...tempSelecionados, tempSelecionado])
    }

    const confirma = () => {
        if (validarPerfis(selecionados)) {
            setIconLoading(true);
            var item = new FormData();
            item.append("id_utilizador", utilizadorId);
            item.append("estabelecimentos", JSON.stringify(selecionados));

            axios({
                method: "put",
                url: "/api/gestao-perfis/alterar",
                data: item
            })
                .then(response => {
                    setIconLoading(false);
                    openNotificationWithIcon("success", "Sucesso", "Perfis guardados");
                    onUpdate();
                })
                .catch(error => {
                    if (error.response.status === 401)
                        this.setState({
                            redirect: true,
                            redirectLink: "/login"
                        });
                    setIconLoading(false);
                    openNotificationWithIcon("error", "Erro", error.response.data);
                });
        }
        else {
            openNotificationWithIcon("error", "Erro", "Não foram selecionados estabelecimentos!");
        }
    }

    const validarPerfis = (estabelecimentos) => {
        let todosTemPerfis = true;

        estabelecimentos.forEach(estabelecimento => {
            if (estabelecimento.perfis.length === 0) {
                todosTemPerfis = false;
            }
        });

        return todosTemPerfis;
    }

    const marcarComoPrincipal = (estabelecimentoId, perfilId) => {
        let tempSelecionados = selecionados.filter(x => x.id !== estabelecimentoId);
        let tempSelecionado = selecionados.find(x => x.id === estabelecimentoId);

        let tempPerfisSelecionados = tempSelecionado.perfis.filter(x => x.id !== perfilId).map(sel => {
            return {
                id: sel.id,
                principal: false
            }
        });
        const selecionado = {
            id: perfilId,
            principal: true
        }

        tempSelecionado.perfis = [...tempPerfisSelecionados, selecionado];
        setSelecionados([...tempSelecionados, tempSelecionado])
    }

    const montrarBotaoCheck = (estabelecimentoId, perfil) => {
        const estabelecimento = selecionados.find(x => x.id === estabelecimentoId);
        const selecionado = estabelecimento?.perfis.find(x => x.id === perfil.id);

        if (selecionado) {
            if (selecionado.principal)
                return <Link className="botao-icon-check" to="#" title="Por defeito" onClick={event => event.stopPropagation()}>
                    <Icon type="check" />
                </Link>
            else
                return <Link className="botao-icon-uncheck" to="#" title="Marcar perfil por defeito" onClick={event => { event.stopPropagation(); marcarComoPrincipal(estabelecimentoId, perfil.id); }}></Link>
        }
        else {
            return <Link className="botao-icon-uncheck" to="#" title="Marcar perfil por defeito" style={{ cursor: "default" }} onClick={event => event.stopPropagation()}></Link>
        }
    }

    const afterVisibleChange = visible => {
        form.resetFields();
        if (visible) {
            listarPerfis();
            listarEstabelecimentos();
        }
    }

    return (
        <Drawer
            className="drawer-inscrever drawer-perfis"
            //title="Selecionar Perfis"
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-drawer-associar">
                <div className="bloco">
                    <h3 className="titulo-separador">Lista</h3>
                    <Collapse defaultActiveKey={[]} style={{ border: "none" }}>
                        {estabelecimentos.map(estabelecimento => (
                            <Panel
                                header={<span style={{ display: "flex", justifyContent: "space-between", alignContent: "center" }}><span>{estabelecimento.nome}</span><span>Principal</span></span>}
                                key={estabelecimento.id}>
                                <Checkbox.Group className="lista-estabelecimentos" style={{ width: '100%' }} value={selecionados.find(x => x.id === estabelecimento.id)?.perfis.map(x => x.id)} onChange={(sels) => onChange(estabelecimento.id, sels)}>
                                    {perfis.map(perfil => (
                                        <div key={perfil.id} style={{ marginBottom: "20px" }}>
                                            <Checkbox value={perfil.id}>
                                                {perfil.nome}
                                                {montrarBotaoCheck(estabelecimento.id, perfil)}
                                            </Checkbox>
                                        </div>
                                    ))}
                                </Checkbox.Group>
                            </Panel>
                        ))}
                    </Collapse>
                </div>
            </div>
            <div className="ant-drawer-footer">
                <button
                    className="botao-secundario"
                    onClick={onClose}
                    style={{ marginRight: 20, display: "inline-block" }}
                >
                    Voltar
                </button>
                <button className="botao-principal" disabled={iconLoading} type="primary" onClick={confirma}>
                    {iconLoading ? <Icon type="loading" /> : null}
                    Guardar
                </button>
            </div>
        </Drawer>
    );
}
const FormDrawerPerfis = Form.create({ name: "drawer-perfis" })(DrawerPerfis);

export default FormDrawerPerfis;