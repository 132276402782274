import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, Table, notification, Icon, Form, Input, InputNumber, Switch, Menu, Modal, Tooltip } from "antd";
import Header from "../../../User/Header";
import noimage from "../../../images/noimage.png";
import axios from "axios";
import LinesEllipsis from "react-lines-ellipsis";
import { GlobalContext } from "../../../GlobalState";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class AnosLetivos extends Component {
    static contextType = GlobalContext;
    state = {
        anos: [],
        ativo: true,
        //DRAWER
        id: 0,
        nome: "",
        ordem: 1,
        visible: false,
        iconLoading: false,
        //LOADING
        loading_table: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listar();
    }

    //componentDidUpdate() {
    //    if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
    //        this.setState({
    //            redirect: true,
    //            redirectLink: "/user"
    //        });
    //}

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeOrdem = ordem => {
        this.setState({ ordem });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    //MONTA AS OPÇÕES DE MENU POR CADA EDIÇÃO NA TABELA
    montarMenu = (id, ativo) => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (this.state.ativo)
            return (
                <div style={divStyle}>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => { event.stopPropagation(); this.ativar(id, ativo) }}
                    >
                        <Icon type="close" />
                    </Link>
                </div>
            );

        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => { event.stopPropagation(); this.ativar(id, ativo) }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => { event.stopPropagation(); this.excluir(id) }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };

    //CARREGA TODAS AS EDIÇÕES DA ENTIDADE PARA A TABELA
    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-turmas-anos-letivos/listar",
                    params: {
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var anos = response.data.map((ano, index) => {
                            return {
                                key: index,
                                id: ano.id,
                                nome: ano.nome,
                                ordem: ano.ordem,
                                opcoes: this.montarMenu(ano.id, ano.ativo)
                            };
                        });

                        this.setState({
                            anos,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    //ABRE O DRAWER COM OS DADOS DA EDIÇÃO
    carregar = id => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/gestao-turmas-anos-letivos/carregar",
            params: {
                id
            }
        })
            .then(response => {
                this.setState({
                    id: response.data.id,
                    nome: response.data.nome,
                    ordem: response.data.ordem,
                    ativo: response.data.ativo,
                    visible: true
                });
            })
            .catch(() => {});
    };

    //CRIA UMA NOVA EDIÇÃO
    criar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("nome", this.state.nome);
                item.append("ordem", this.state.ordem);

                axios({
                    method: "post",
                    url: "/api/gestao-turmas-anos-letivos/adicionar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ano letivo adicionado!");
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //ATUALIZA A EDIÇÕES
    alterar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.state.id);
                item.append("nome", this.state.nome);
                item.append("ordem", this.state.ordem);

                axios({
                    method: "put",
                    url: "/api/gestao-turmas-anos-letivos/alterar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ano letivo alterado!");
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //EXCLUI A EDIÇÃO
    excluir = id => {
        confirm({
            title: "Pretende excluir este ano letivo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-turmas-anos-letivos/excluir",
                    params: {
                        id
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ano letivo excluido!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não é possível excluir este registo!");
                    });
            }
        });
    };

    //ATIVA E INATIVA A EDIÇÃO
    ativar = (id, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar este ano letivo?" : "Pretende ativar este ano letivo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-turmas-anos-letivos/ativar",
                    params: {
                        id
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Ano letivo inativado!" : "Ano letivo ativado!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar o ano letivo!" : "Não foi possível ativar o ano letivo!"
                        );
                    });
            }
        });
    };

    //ABRE O DRAWER VAZIO PARA CRIAR UMA NOVA EDIÇÃO
    showDrawer = () => {
        this.props.form.resetFields();
        this.setState({
            id: 0,
            nome: "",
            ordem: 1,
            visible: true
        });
    };

    //FECHA O DRAWER SEM GUARDAR A EDIÇÃO
    onClose = () => {
        this.setState({
            visible: false
        });
    };

    onRowClick = (id) => {
        this.carregar(id);
    }

    render() {
        const {
            anos,
            ativo,
            id,
            nome,
            ordem,
            visible,
            iconLoading,
            //UPLOAD VALIDATE
            validateStatus,
            help,
            //LOADING
            loading_table,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const columns = [
            {
                title: "Nome",
                dataIndex: "nome"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Anos Letivos" />
                <div className="container container-body">
                    <div className="bloco-categorias">
                        <div className="bloco-tabela">
                            <div className="filtros" style={{ justifyContent: "space-between" }}>
                                <div className="bloco-pesquisa">
                                    <button
                                        className="botao-principal botao-principal-redondo"
                                        title="Adicionar"
                                        onClick={this.showDrawer}
                                        disabled={!this.state.ativo}
                                    >
                                        +
                                    </button>
                                </div>
                                <Switch
                                    checkedChildren="Ativos"
                                    unCheckedChildren="Inativos"
                                    defaultChecked
                                    onChange={this.handleChangeSwitch}
                                />
                            </div>
                            <Table
                                id="tabela_categorias"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={anos}
                                pagination={false}
                                rowClassName="clickeble-row"
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => this.onRowClick(record.id)
                                    };
                                }}
                                locale={{ emptyText: "Não existem dados!" }}
                            />
                        </div>
                    </div>
                </div>
                <Drawer
                    className="drawer-add-cursos"
                    width={720}
                    onClose={this.onClose}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <Form.Item label="Nome">
                                {getFieldDecorator("nome", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: nome
                                })(<Input name="nome" disabled={!ativo} onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="Ordem">
                                {getFieldDecorator("ordem", {
                                    initialValue: ordem,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(
                                    <InputNumber
                                        className="input-25"
                                        name="ordem"
                                        min={1}
                                        onChange={this.handleChangeOrdem}
                                    />
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        {ativo && (
                            <button className="botao-principal" onClick={id ? this.alterar : this.criar} disabled={iconLoading} type="primary">
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                        )}
                    </div>
                </Drawer>
            </div>
        );
    }
}
const FormAnosLetivos = Form.create({ name: "form-anos-letivos" })(AnosLetivos);

export default FormAnosLetivos;
