import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../User/Header";
import { Table, Select, DatePicker, Icon, notification, Modal, Drawer, Form } from "antd";
import noimage from "../../images/noimage.png";
import axios from "axios";
import moment from "moment";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Lista extends Component {
    state = {
        utilizador: "",
        edicoes: [],
        cursos: [],
        cursosMobile: [],
        colunas: [],
        //FILTROS
        edicao: undefined,
        dataInicio: "",
        dataTermino: "",
        //LOADING
        iconLoading: false,
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //DRAWER
        visibleDetalhe: false,
        detalheDatas: "",
        detalhePeriodos: "",
        detalheMediaFinal: "",
        detalhePontuacaoFinal: ""
    };

    UNSAFE_componentWillMount() {
        this.carregarUtilizadorInfo()
        this.listarEdicoes()
        this.listarCursos()
    }

    handleChangeFiltroEdicoes = value => {
        this.setState(
            {
                edicao: value
            },
            () => {
                this.listarCursos();
            }
        );
    };

    onChangeDataInicio = dataInicio => {
        this.setState({
            dataInicio
        });
    };

    onChangeDataTermino = dataTermino => {
        this.setState({
            dataTermino
        });
    };

    pesquisar = () => {
        if (this.state.dataInicio && this.state.dataTermino)
            this.listarCursos();
        else {
            if (!this.state.dataInicio)
                openNotificationWithIcon("warning", "Atenção", "Não foi selecionada uma data de inicio");
            else
                openNotificationWithIcon("warning", "Atenção", "Não foi selecionada uma data de termino");
        }
    };

    carregarUtilizadorInfo = () => {
        axios({
            method: "get",
            url: "/api/consultar-notas/carregar-utilizador",
            params: {
                utilizadorId: this.props.match.params.utilizadorid
            }
        })
            .then(response => {
                this.setState({
                    utilizador: response.data
                })
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar");
            });
    }

    listarEdicoes = () => {
        axios({
            method: "get",
            url: "/api/consultar-notas/lista-edicoes",
            params: {
                entidadeId: localStorage.getItem("entidadeId")
            }
        })
            .then(response => {
                this.setState({
                    edicoes: response.data
                })
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar");
            });
    }

    listarCursos = () => {
        this.setState({
            iconLoading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/consultar-notas/listar-cursos",
                params: {
                    utilizadorId: this.props.match.params.utilizadorid,
                    edicao: this.state.edicao,
                    dataInicio: this.state.dataInicio ? this.state.dataInicio.format("YYYY/MM/DD") : "",
                    dataTermino: this.state.dataTermino ? this.state.dataTermino.format("YYYY/MM/DD") : ""
                }
            })
                .then(response => {
                    let cursos = [];
                    let cursosMobile = [];
                    let totalPeriodos = 0;
                    response.data.map(curso => {
                        if (curso.periodos.length > totalPeriodos)
                            totalPeriodos = curso.periodos.length;

                        let tempCurso = {
                            key: curso.id,
                            ano: curso.ano,
                            datas: <>
                                <span>{moment(curso.dt_inicio).format("DD/MM/YYYY")}</span><br />
                                {curso.fim ?
                                    <span>{moment(curso.fim).format("DD/MM/YYYY")}</span>
                                    : null}
                            </>,
                            curso: <>
                                <div className="imagem-curso">
                                    <img src={curso.imagem ? curso.imagem : noimage} />
                                </div>
                                <div className="info-curso">
                                    <span className="titulo">
                                        {curso.codigo} - {curso.nome}
                                    </span>
                                    <span className="edicao">{curso.edicao} {curso.turma || curso.ano ? ` - ${curso.turma} ${curso.ano}` : ""}</span>
                                </div>
                            </>,
                            final: curso.mediaFinal && curso.tipoDiario === "REGULAR" ?
                                <>
                                    <span> {curso.mediaFinal}</span>
                                    {curso.ano !== "9º" && !curso.eja || curso.ano !== "10º" && curso.eja ?
                                        parseFloat(curso.mediaFinal) >= 6 ?
                                            <span className="estado-aprovado">Aprovado</span>
                                            :
                                            <span className="estado-reprovado">Reprovado</span>
                                        : null
                                    }
                                </>
                                : null,
                            pontuacao: curso.pontuacaoFinal && curso.tipoDiario === "REGULAR" ?
                                <>
                                    <span> {curso.pontuacaoFinal}</span>
                                    {parseFloat(curso.pontuacaoFinal) >= 6 ?
                                        <span className="estado-aprovado">Aprovado</span>
                                        :
                                        <span className="estado-reprovado">Reprovado</span>
                                    }
                                </>
                                : null,
                            opcoes: curso.tipoDiario !== "REGULAR" ?
                                <Link to={`/${this.props.match.params.entidade}/consultar/${curso.tipoDiario === "CRECHE" || curso.tipoDiario === "PRE" ? curso.tipoDiario === "CRECHE" ? "notas-creche" : "notas-pre" : "notas-regular"}/${this.props.match.params.utilizadorid}`}
                                    className="botao-icon-detalhe"
                                    title="Detalhe"
                                    onClick={() => localStorage.setItem("codigo_curso", curso.id)}
                                >
                                    <Icon type="eye" />
                                </Link>
                                : null
                        };

                        for (let i = 0; i < curso.periodos.length; i++) {
                            tempCurso[`n${i + 1}`] = curso.periodos[i].nota;
                        }

                        cursos.push(tempCurso)

                        cursosMobile.push({
                            key: curso.id,
                            curso: <>
                                <div className="imagem-curso">
                                    <img src={curso.imagem ? curso.imagem : noimage} />
                                </div>
                                <div className="info-curso">
                                    <span className="titulo">
                                        {curso.codigo} - {curso.nome}
                                    </span>
                                    <span className="edicao">{curso.edicao} {curso.turma || curso.ano ? ` - ${curso.turma} ${curso.ano}` : ""}</span>
                                </div>
                            </>,
                            opcoes: curso.tipoDiario === "REGULAR" ?
                                <Link to="#"
                                    className="botao-icon-detalhe"
                                    title="Detalhe"
                                    onClick={() => this.abrirDetalhe(curso)}
                                >
                                    <Icon type="eye" />
                                </Link>
                                :
                                <Link to={`/${this.props.match.params.entidade}/consultar/${curso.tipoDiario === "CRECHE" || curso.tipoDiario === "PRE" ? curso.tipoDiario === "CRECHE" ? "notas-creche" : "notas-pre" : "notas-regular"}/${this.props.match.params.utilizadorid}`}
                                    className="botao-icon-detalhe"
                                    title="Detalhe"
                                    onClick={() => localStorage.setItem("codigo_curso", curso.id)}
                                >
                                    <Icon type="eye" />
                                </Link>
                        })
                    });

                    const colunas = [];
                    for (let i = 0; i < totalPeriodos; i++) {
                        colunas.push({
                            title: `N${i + 1}`,
                            dataIndex: `n${i + 1}`,
                        })
                    }

                    const colunas9Anos = [];
                    if (cursos.find(x => x.ano === "9º" && !x.eja || x.ano === "10º" && x.eja))
                        colunas9Anos.push({
                            title: "Pontuação Final",
                            dataIndex: "pontuacao",
                        })

                    let columnsCursos = [
                        {
                            title: "",
                            dataIndex: "datas",
                            className: "td-150"
                        },
                        {
                            title: "Curso",
                            dataIndex: "curso",
                            className: "td-curso"
                        },
                        ...colunas,
                        {
                            title: "Média Final",
                            dataIndex: "final",
                        },
                        ...colunas9Anos,
                        {
                            title: "",
                            dataIndex: "opcoes",
                            className: "td-50"
                        }
                    ]

                    this.setState({
                        cursos,
                        cursosMobile,
                        colunas: columnsCursos,
                        iconLoading: false
                    })
                })
                .catch(() => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar");
                    this.setState({
                        iconLoading: false
                    })
                });
        })
    }

    exportar = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/consultar-notas/notas",
                    params: {
                        utilizadorId: this.props.match.params.utilizadorid,
                        edicao: this.state.edicao,
                        dataInicio: this.state.dataInicio ? this.state.dataInicio.format("YYYY/MM/DD") : "",
                        dataTermino: this.state.dataTermino ? this.state.dataTermino.format("YYYY/MM/DD") : ""
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    }

    exportarPdf = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/consultar-notas/notas-pdf",
                    params: {
                        utilizadorId: this.props.match.params.utilizadorid,
                        edicao: this.state.edicao,
                        dataInicio: this.state.dataInicio ? this.state.dataInicio.format("YYYY/MM/DD") : "",
                        dataTermino: this.state.dataTermino ? this.state.dataTermino.format("YYYY/MM/DD") : ""
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    }

    abrirDetalhe = (curso) => {
        var periodos = curso.periodos.map((periodo, index) => {
            return <Form.Item key={index} label={periodo.titulo}>
                {periodo.nota}
            </Form.Item>
        });

        this.setState({
            detalheDatas: <>
                <span>{moment(curso.dt_inicio).format("DD/MM/YYYY")}</span><br />
                {curso.fim ?
                    <span>{moment(curso.fim).format("DD/MM/YYYY")}</span>
                    : null}
            </>,
            detalhePeriodos: periodos,
            detalheMediaFinal: curso.mediaFinal ?
                <>
                    <span> {curso.mediaFinal}</span>
                    {
                        curso.ano !== "9º" && !curso.eja || curso.ano !== "10º" && curso.eja ?
                            parseFloat(curso.mediaFinal) >= 6 ?
                                <span className="estado-aprovado">Aprovado</span>
                                :
                                <span className="estado-reprovado">Reprovado</span>
                            : null
                    }
                </>
                : null,
            detalhePontuacaoFinal: curso.pontuacaoFinal ?
                <>
                    <span> {curso.pontuacaoFinal}</span>
                    {parseFloat(curso.pontuacaoFinal) >= 6 ?
                        <span className="estado-aprovado">Aprovado</span>
                        :
                        <span className="estado-reprovado">Reprovado</span>
                    }
                </>
                : null,
            visibleDetalhe: true
        })
    }

    render() {
        const {
            utilizador,
            edicoes,
            cursos,
            cursosMobile,
            colunas,
            //FILTROS
            edicao,
            dataInicio,
            dataTermino,
            //LOADING
            iconLoading,
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //DRAWER
            visibleDetalhe,
            detalheDatas,
            detalhePeriodos,
            detalheMediaFinal,
            detalhePontuacaoFinal
        } = this.state;

        const colunasMobile = [
            {
                title: "Curso",
                dataIndex: "curso",
                className: "td-curso td-265"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }        ]

        return (
            <>
                <Header titulo="CONSULTAR RESULTADOS" />
                <div className="container container-noticias conteiner-consultar container-body">
                    <div className="content">
                        <div className="bloco-cursos">
                            <div className="bloco-tabela">
                                <div className="header">
                                    <div className="utilizador-info">
                                        {utilizador ?
                                            <>
                                                <div className="container-img">
                                                    <img src={utilizador.foto ? utilizador.foto : noimage} />
                                                </div>
                                                <div className="info">
                                                    <span className="nome">
                                                        {utilizador.nome.split("_").length > 1 ? `${utilizador.nome.split("_")[0]} ${utilizador.nome.split("_")[utilizador.nome.split("_").length - 1]}` : utilizador.nome}
                                                    </span>
                                                    <span className="numero">{utilizador.numero}</span>
                                                </div>
                                            </>
                                            : null}
                                    </div>
                                    <div className="controlos">
                                        <Link className="botao-icon-pdf" to="#" title="Descarregar" onClick={this.exportarPdf}>
                                            <Icon type="file-pdf" />
                                        </Link>
                                        <Link className="botao-icon-download" to="#" title="Descarregar" onClick={this.exportar}>
                                            <Icon type="download" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="filtros">
                                    <Select
                                        className="filtro-selects"
                                        placeholder="Edição"
                                        allowClear={true}
                                        onChange={this.handleChangeFiltroEdicoes}
                                        value={edicao}
                                    >
                                        {edicoes.map(edicao => (
                                            <Option key={edicao.id} value={edicao.id}>{edicao.nome}</Option>
                                        ))}
                                    </Select>
                                    <div className="filtros-datas">
                                        <div className="inicio">
                                            <label>Inicio:</label>
                                            <DatePicker
                                                placeholder="Data Inicio"
                                                format="DD/MM/YYYY"
                                                onChange={this.onChangeDataInicio}
                                                value={dataInicio}
                                            />
                                        </div>
                                        <div className="termino">
                                            <label>Término:</label>
                                            <DatePicker
                                                placeholder="Data Término"
                                                format="DD/MM/YYYY"
                                                onChange={this.onChangeDataTermino}
                                                value={dataTermino}
                                            />
                                        </div>
                                        <button className="procurar" onClick={this.pesquisar}>
                                            <Icon type="search" />
                                        </button>
                                    </div>
                                </div>
                                <Table
                                    id="tabela-cursos"
                                    className="tabela-cursos-desktop"
                                    columns={colunas}
                                    dataSource={cursos}
                                    locale={{ emptyText: "Não existem dados!" }}
                                    pagination={false}
                                    loading={{
                                        spinning: iconLoading,
                                        indicator: (
                                            <div className="loading-data-table">
                                                <div className="loading" />
                                            </div>
                                        )
                                    }}
                                />
                                <Table
                                    id="tabela-cursos"
                                    className="tabela-cursos-mobile"
                                    columns={colunasMobile}
                                    dataSource={cursosMobile}
                                    locale={{ emptyText: "Não existem dados!" }}
                                    pagination={false}
                                    loading={{
                                        spinning: iconLoading,
                                        indicator: (
                                            <div className="loading-data-table">
                                                <div className="loading" />
                                            </div>
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Drawer
                    className="drawer-detalhe-curso"
                    //title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Datas">
                                    {detalheDatas}
                                </Form.Item>
                                {detalhePeriodos}
                                <Form.Item label="Média Final">
                                    {detalheMediaFinal}
                                </Form.Item>
                                <Form.Item label="Pontuação Final">
                                    {detalhePontuacaoFinal}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
                <Modal
                    visible={visibleExportar}
                    okText={
                        <a href={ficheiroExportar} download onClick={() => this.setState({ visibleExportar: false })}>
                            Descarregar
                        </a>
                    }
                    onCancel={() => this.setState({ visibleExportar: false })}
                    cancelText="Cancelar"
                    okButtonProps={{ disabled: btnDescarregar }}
                    maskClosable={false}
                    className="exportar-csv"
                >
                    <div className="exportar-csv-bloco">
                        {btnDescarregar ? (
                            <>
                                <p>
                                    <Icon type="loading" />
                                </p>
                                <p className="texto">A gerar documento...</p>
                            </>
                        ) : (
                            <>
                                <p>
                                    <Icon type="check-circle" style={{ color: "#84bd00" }} />
                                </p>
                                <p className="texto">Dados exportados com sucesso!</p>
                                <p className="ficheiro-extensao">.{ficheiroExportar.split(".")[ficheiroExportar.split(".").length - 1]}</p>
                            </>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}
export default Lista;
