import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, notification, Icon, Modal } from "antd";
import axios from "axios";

import ModalLoading from "../../../../Modals/ModalLoading";
import ModalViewer from "../../../../Modals/ModalViewer";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerDocumentos = ({ visible, inscricaoId, form, onClose, abrirDrawers, onDeleteFile }) => {
    const [documentos, setDocumentos] = useState([]);
    const [docs, setDocs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [visibleLoading, setVisibleLoading] = useState(false);

    const carregar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/documentos/carregar-documentos-matricula",
            params: {
                inscricaoId
            }
        })
            .then(response => {
                setDocumentos(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar documentos!");
                setLoading(false);
            });
    }

    const gerar = (tipo) => {
        confirm({
            title: "Pretende gerar novamente este documento?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                abrirDrawers(tipo);
            }
        })
    }

    const visualizar = (link) => {
        setDocs([{ uri: link }]);
        setModalVisible(true);
    }

    const excluir = (documentoId) => {
        confirm({
            title: "Pretende excluir este documento?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/documentos/excluir",
                    params: {
                        documentoId
                    }
                })
                    .then(() => {
                        let _documentos = documentos;
                        setDocumentos(_documentos.filter(x => x.id !== documentoId))
                        const doc = documentos.find(x => x.id === documentoId)
                        onDeleteFile(inscricaoId, doc.tipo)
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível carregar documentos!");
                        setLoading(false);
                    });
            }
        })
    }

    const afterVisibleChange = visible => {
        form.resetFields();
        if (!visible) {
            setDocs([]);
        }
        else
            carregar();
    }

    return (
        <>
            <Drawer
                className="drawer-inscrever"
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={afterVisibleChange}
            >
                <div className="bloco-drawer-associar">
                    <div className="bloco">
                        <h3 className="drawer-titulo-separador">
                            Documentos
                        </h3>
                        <div className="lista-videos">
                            {documentos.length === 0 ? <span>Sem dados</span> : null}
                            {documentos.map(doc => (
                                <div className="item-video">
                                    <span className="video-titulo">{doc.nome}</span>
                                    <div className="video-controlos">
                                        <Link to="#" className="botao-icon-download" title="Gerar documento" onClick={() => gerar(doc.tipo)}>
                                            <Icon type="reload" />
                                        </Link>
                                        <Link to="#" className="botao-icon-detalhe" title="Visualizar" onClick={() => visualizar(doc.link)}>
                                            <Icon type="eye" />
                                        </Link>
                                        <Link to="#" className="botao-icon-excluir" title="Excluir" onClick={() => excluir(doc.id)}>
                                            <Icon type="delete" />
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="ant-drawer-footer">
                    <button
                        className="botao-secundario"
                        onClick={onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                    </button>
                </div>
            </Drawer>
            <ModalLoading
                visible={visibleLoading}
                text="A gerar documento..."
            />
            <ModalViewer
                visible={modalVisible}
                docs={docs}
                onCancel={() => setModalVisible(false)}
            />
        </>
    );
}

const FormDrawerDocumentos = Form.create({ name: "drawer-documentos" })(DrawerDocumentos);

export default FormDrawerDocumentos;
