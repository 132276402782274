import React, { Component } from "react";
import { matchPath, withRouter } from "react-router-dom";
import { Drawer, Form, Upload, notification, Icon, Input, Select, DatePicker, TimePicker } from "antd";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";
import moment from "moment";

const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerHorarios extends Component {
    static contextType = GlobalContext;
    state = {
        index: "",
        data: "",
        hora_ini: "",
        hora_fim: "",
        sala: "",
        morada: "",
        localidade: ""
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {

    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleChangeData = data => {
        this.setState({
            data
        });
    };

    handleChangeHoraIni = hora_ini => {
        this.setState({
            hora_ini
        });
    };

    handleChangeHoraFim = hora_fim => {
        this.setState({
            hora_fim
        });
    };

    handlerChangeCheck = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    adicionar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                var registo = {
                    data: moment(this.state.data).format("YYYY/MM/DD"),
                    hora_ini: this.state.hora_ini.format("HH:mm"),
                    hora_fim: this.state.hora_fim.format("HH:mm"),
                    sala: this.state.sala,
                    morada: this.state.morada,
                    localidade: this.state.localidade
                }
                this.props.adicionarHorario(registo);
                this.props.resetFields();
            }
        });
    }

    alterar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                var registo = {
                    data: moment(this.state.data).format("YYYY/MM/DD"),
                    hora_ini: this.state.hora_ini.format("HH:mm"),
                    hora_fim: this.state.hora_fim.format("HH:mm"),
                    sala: this.state.sala,
                    morada: this.state.morada,
                    localidade: this.state.localidade
                }
                this.props.alterarHorario(registo);
                this.props.resetFields();
            }
        });
    }

    afterVisibleChange = aberto => {
        this.props.form.resetFields();
        if (aberto) {
            var horario = this.props.horario;
            if (this.props.detalheHorario) {
                this.setState({
                    data: moment(horario.data),
                    hora_ini: moment(horario.hora_ini, "HH:mm"),
                    hora_fim: moment(horario.hora_fim, "HH:mm"),
                    sala: horario.sala,
                    morada: horario.morada,
                    localidade: horario.localidade
                })
            }
            else {
                if (!this.props.ultimoHorario)
                    this.setState({
                        data: "",
                        hora_ini: "",
                        hora_fim: "",
                        sala: "",
                        morada: "",
                        localidade: ""
                    })
                else {
                    var last = this.props.ultimoHorario;

                    this.setState({
                        data: moment(last.data),
                        hora_ini: moment(last.hora_ini, "HH:mm"),
                        hora_fim: moment(last.hora_fim, "HH:mm"),
                        sala: last.sala,
                        morada: last.morada,
                        localidade: last.localidade
                    })
                }
            }
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            data,
            hora_ini,
            hora_fim,
            sala,
            morada,
            localidade,
            iconLoading
        } = this.state;

        const {
            visibleDrawerHorarios
        } = this.props;

        return (
            <Drawer
                //title={this.props.relatorioid ? "Editar" : "Adicionar"}
                placement="right"
                className="drawer-envio-mensagem-inscricao"
                maskClosable={false}
                closable={true}
                onClose={this.props.onClose}
                visible={visibleDrawerHorarios}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-nova-pergunta">
                        <div className="bloco">
                            <Form.Item label="Data">
                                {getFieldDecorator("data", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: data
                                })(<DatePicker
                                    className="input-25"
                                    name="data"
                                    format="DD-MM-YYYY"
                                    onChange={this.handleChangeData}
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                />)}
                            </Form.Item>
                            <Form.Item label="Hora Inicio">
                                {getFieldDecorator("hora_ini", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: hora_ini
                                })(<TimePicker
                                    className="input-25"
                                    name="hora_ini"
                                    defaultOpenValue={moment("00:00", "HH:mm")}
                                    format="HH:mm"
                                    onChange={this.handleChangeHoraIni}
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                />)}
                            </Form.Item>
                            <Form.Item label="Hora Término">
                                {getFieldDecorator("hora_fim", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: hora_fim
                                })(<TimePicker
                                    className="input-25"
                                    name="hora_fim"
                                    defaultOpenValue={moment("00:00", "HH:mm")}
                                    format="HH:mm"
                                    onChange={this.handleChangeHoraFim}
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                />)}
                            </Form.Item>
                            <Form.Item label="Sala">
                                {getFieldDecorator("sala", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: sala
                                })(<Input name="sala" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="Morada">
                                {getFieldDecorator("morada", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: morada
                                })(<TextArea
                                    name="morada"
                                    onChange={this.handleChange}
                                    rows={3}
                                    style={{ height: 100 }}
                                />)}
                            </Form.Item>
                            <Form.Item label="Localidade">
                                {getFieldDecorator("localidade", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: localidade
                                })(<Input name="localidade" onChange={this.handleChange} />)}
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button
                        className="botao-secundario"
                        onClick={this.props.onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                    </button>
                    <button
                        className="botao-principal"
                        onClick={this.props.detalheHorario ? this.alterar : this.adicionar}
                        disabled={iconLoading}
                    >
                        {iconLoading ? <Icon type="loading" /> : null}
                        Guardar
                    </button>
                    {/*{this.props.detalheHorario ?*/}
                    {/*    <button*/}
                    {/*        className="botao-principal"*/}
                    {/*        onClick={this.alterar}*/}
                    {/*        disabled={iconLoading}*/}
                    {/*    >*/}
                    {/*        {iconLoading ? <Icon type="loading" /> : null}*/}
                    {/*                Guardar*/}
                    {/*            </button>*/}
                    {/*    :*/}
                    {/*    <button*/}
                    {/*        className="botao-principal"*/}
                    {/*        onClick={this.adicionar}*/}
                    {/*        disabled={iconLoading}*/}
                    {/*    >*/}
                    {/*        {iconLoading ? <Icon type="loading" /> : null}*/}
                    {/*                Guardar*/}
                    {/*            </button>*/}
                    {/*}*/}
                </div>
            </Drawer>
        );
    }
}

const FormDrawerHorarios = Form.create({ name: "form-horarios" })(DrawerHorarios);

export default withRouter(FormDrawerHorarios);
