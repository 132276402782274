import React, { Component } from "react";
import { Button, Icon, notification, Modal } from "antd";
import axios from "axios";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class CardsConteudos extends Component {
    state = {
        num: 1,
        total: 0,
        semPeriodos: false,
        periodos: [],
        //PESQUISA
        dt_inicio: "",
        dt_fim: "",
        //PERIODO
        mes: "",
        ano: "",
        titulo: "",
        aulas: [],
        //LOADING
        iconLoading: false,
    }

    componentDidMount() {
        this.total();
    }

    total = () => {
        this.setState({
            iconLoading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/aulas/total-periodos-conteudos",
                params: {
                    cursoId: localStorage.getItem("codigo_curso")
                }
            })
                .then(response => {
                    if (response.data.length > 0) {
                        this.setState({
                            total: response.data.length,
                            periodos: response.data,
                            titulo: response.data[0].titulo,
                            dt_inicio: response.data[0].dt_inicio,
                            dt_fim: response.data[0].dt_fim,
                        }, () => {
                            this.listar();
                        })
                    }
                    else {
                        this.setState({
                            semPeriodos: true,
                            iconLoading: false
                        })
                        openNotificationWithIcon("warning", "Atenção", "Não foram adicionados períodos ao curso!");
                    }
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
                });
        })
    }

    listar = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-conteudos",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                dt_inicio: this.state.dt_inicio,
                dt_fim: this.state.dt_fim
            }
        })
            .then(response => {
                this.setState({
                    aulas: response.data,
                    iconLoading: false
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    }

    anteriorProximo = (num) => {
        var periodo = this.state.periodos[num - 1];
        this.setState({
            num,
            titulo: periodo.titulo,
            dt_inicio: periodo.dt_inicio,
            dt_fim: periodo.dt_fim,
            iconLoading: true
        }, () => this.listar())
    }

    render() {
        const {
            num,
            semPeriodos,
            periodos,
            //PERIODO
            titulo,
            aulas,
            //LOADING
            iconLoading,
        } = this.state;

        return (
            <>
                <div>
                    <div className="bloco-controlos-frequencia">
                        {!semPeriodos ?
                            <div>
                                <Button className='botao-periodo botao-periodo-anterior' disabled={num === 1} style={{ color: "rgba(0, 0, 0, 0.65)" }} onClick={() => this.anteriorProximo(num - 1)}>
                                    <Icon type="left" />
                                </Button>
                                <span>{titulo}</span>
                                <Button className='botao-periodo botao-periodo-proximo' disabled={num === periodos.length} style={{ color: "rgba(0, 0, 0, 0.65)" }} onClick={() => this.anteriorProximo(num + 1)}>
                                    <Icon type="right" />
                                </Button>
                            </div>
                            : null}
                    </div>
                    <div className="bloco-cards">
                        {aulas.map((aula, index) => (
                            <div key={index} className="card">
                                <div className="header">
                                    {aula.curso &&
                                        <span className="titulo" style={{ display: "block" }}>{aula.curso}</span>}
                                    <span className="titulo">{moment(aula.dt_inicio).format("DD/MM/YYYY")}</span>
                                    <span className="titulo" style={{ marginLeft: 10 }}>{aula.hr_inicio} - {aula.hr_termino}</span>
                                </div>
                                <div className="body">
                                    <div className="item">
                                        <span className="titulo" style={{ textAlign: "left" }}>OBJETO DE CONHECIMENTO/CONTEÚDO</span>
                                        <p className="texto">{aula.conteudo ? aula.conteudo : "Sem dados..."}</p>
                                    </div>
                                    <div className="item">
                                        <span className="titulo" style={{ textAlign: "left" }}>DESDP/METODOLOGIA</span>
                                        <p className="texto">{aula.metodologia ? aula.metodologia : "Sem dados..."}</p>
                                    </div>
                                </div>
                            </div>))}
                    </div>
                </div>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
            </>
        );
    }
}

export default CardsConteudos;