import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Drawer, Form, Input, DatePicker, Icon, notification } from "antd";
import axios from "axios";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerEstado = ({ turmaId, estado, atualizar, visible, onClose, form, match }) => {
    const [data, setData] = useState(null);
    const [motivo, setMotivo] = useState("");
    const [loading, setLoading] = useState(false);

    const { TextArea } = Input;
    const { getFieldDecorator } = form;

    const confirma = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);

                var item = new FormData();
                item.append("id_turma", turmaId);
                item.append("code", match.params.code);
                item.append("id_estado", estado);
                item.append("data", moment(data).format("YYYY/MM/DD"));
                item.append("motivo", motivo);

                axios({
                    method: "post",
                    url: "/api/user-detalhe/alterar-estado",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Estado alterado.");
                        setLoading(false)
                        atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false)
                    });
            }
        })
    }

    const afterVisibleChange = visible => {
        form.resetFields();
        if (!visible) {
            setData(null);
            setMotivo("");
            setLoading(false);
        }
    }

    return (
        <Drawer
            className="drawer-inscrever drawer-editar-matricula"
            //title="Alterar estado"
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-drawer-associar">
                <Form className="form-emitir-declaracao">
                    <div className="bloco">
                        <Form.Item label="Data">
                            {getFieldDecorator("data", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    }
                                ],
                                initialValue: data
                            })(
                                <DatePicker
                                    name="data"
                                    format="DD-MM-YYYY"
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                    onChange={(data) => setData(data)}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Motivo">
                            <TextArea
                                value={motivo}
                                name="descricao"
                                onChange={(e) => setMotivo(e.target.value)}
                                rows={4}
                                style={{ height: 100 }}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button
                    className="botao-secundario"
                    onClick={onClose}
                    style={{ marginRight: 20, display: "inline-block" }}
                >
                    Voltar
                </button>
                <button className="botao-principal" disabled={loading} type="primary" onClick={confirma}>
                    {loading ? <Icon type="loading" /> : null}
                    Guardar
                </button>
            </div>
        </Drawer>
    );
}
const FormDrawerEstado = Form.create({ name: "drawer-estado" })(DrawerEstado);

export default withRouter(FormDrawerEstado);