import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, Table, notification, Icon, Form, Input, Dropdown, Switch, Menu, Modal, Select, Checkbox, Tooltip } from "antd";
import { FaClone } from "react-icons/fa";
import Header from "../User/Header";
import axios from "axios";
import moment from "moment";
import DrawerQuestionario from "./_Aux/FormQuestionario";
import { GlobalContext } from "../GlobalState";

const confirm = Modal.confirm;
const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Questionarios extends Component {
    static contextType = GlobalContext;
    state = {
        //TABELA
        questionarios: [],
        questionariosMobile: [],
        ativo: true,
        //PAGINATION
        pagination: {
            pageSize: 10,
            current: 1,
            total: 0
        },
        //LOADING
        loading_table: false,
        //DRAWER
        questionarioId: 0,
        visible: false,
        nome: "",
        tipo: undefined,
        anonimo: false,
        realizado: false,
        //LOADING
        iconLoading: false,
        editar: false,
        //DRAWER QUESTIONARIO
        drawerQuestionarioVisible: false,
        questionario: false,
        questoes2: [
            {
                id: 0,
                pergunta: "",
                tipo_pergunta: undefined,
                ordem: 1,
                opcoes2: []
            }
        ],
        //VALIDACAO QUESTOES
        status: "",
        help: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //MODAL DUPLICAR
        visibleDuplicar: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.total();
    }

    //componentDidUpdate() {
    //    if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
    //        this.setState({
    //            redirect: true,
    //            redirectLink: "/user"
    //        });
    //}

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeSelect = value => {
        this.setState({ tipo: value });
    };

    handleChangeCheck = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeTable = (pagination, filters, sorter) => {
        var _pagination = this.state.pagination;
        _pagination.current = pagination.current;

        this.setState(
            {
                pagination: _pagination,
                current: pagination.current
            },
            () => {
                this.listar();
            }
        );
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.total();
            }
        );
    };

    //CARREGAR TOTAL DE EMPRESAS
    total = () => {
        axios({
            method: "get",
            url: "/api/gestao-questionarios/total",
            params: {
                ativo: this.state.ativo
            }
        })
            .then(response => {
                var _pagination = this.state.pagination;
                _pagination.total = response.data;

                this.setState(
                    {
                        pagination: _pagination
                    },
                    () => {
                        this.listar();
                    }
                );
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //CARREGA TODAS AS CATEGORIA DA ENTIDADE PARA A TABELA
    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-questionarios",
                    params: {
                        pageSize: this.state.pagination.pageSize,
                        page: this.state.pagination.current,
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var questionarios = [];
                        var questionariosMobile = [];

                        response.data.map((questionario, index) => {
                            questionarios.push({
                                key: index,
                                id: questionario.id,
                                nome: questionario.nome,
                                data: moment(questionario.dt_criado).format("DD/MM/YYYY HH:mm"),
                                opcoes2: this.montarMenu(questionario.id, questionario.aberto)
                            });
                            questionariosMobile.push({
                                key: index,
                                id: questionario.id,
                                nome: <div className="bloco-questionario">
                                    <div className="info-questionario">
                                        <span className="titulo-questionario">{questionario.nome}</span>
                                        <span className="tipo-questionario" style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.65)" }}>{this.montarTipos(questionario.tipo)}</span>
                                    </div>
                                </div>,
                                opcoes2: this.montarMenu(questionario.id, questionario.aberto)
                            });
                        });

                        this.setState({
                            questionarios,
                            questionariosMobile,
                            loading_table: false
                        });
                    })
                    .catch(() => { });
            }
        );
    };

    alterarEstado = (aberto, questionarioId) => {
        confirm({
            title: aberto ? "Pretende fechar este Stepform?" : "Pretende abrir este Stepform?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "get",
                    url: "/api/gestao-questionarios/alterar-estado",
                    params: {
                        questionarioId
                    }
                })
                    .then(() => {
                        this.listar();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", aberto ? "Não foi possível fechar." : "Não foi possível abrir.");
                    });
            }
        });
    }

    montarTipos = tipo => {
        var nome = "";

        switch (tipo) {
            case "ALUNO_GERAL":
                nome = "Aluno sobre o Geral";
                break;
            case "ALUNO_CURSO":
                nome = "Aluno sobre o Curso";
                break;
            case "ALUNO_INSTRUTORES":
                nome = "Aluno sobre os Professores";
                break;
            case "INSTRUTOR_GERAL":
                nome = "Professor sobre o Geral";
                break;
            case "INSTRUTOR_CURSO":
                nome = "Professor sobre o Curso";
                break;
            case "INSTRUTOR_ALUNOS":
                nome = "Professor sobre os Participantes";
                break;
        }

        return nome;
    }

    //MONTA AS OPÇÕES DE MENU POR CADA QUESTIONARIO NA TABELA
    montarMenu = (questionarioId, aberto) => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (this.state.ativo)
            return (
                <div style={divStyle}>
                    {aberto ?
                        <Link to="#" className="botao-icon-aberto" title="Abrir" onClick={event => { event.stopPropagation(); this.alterarEstado(questionarioId) }}>
                            <Icon type="unlock" />
                        </Link>
                        :
                        <Link to="#" className="botao-icon-fechado" title="Fechar" onClick={event => { event.stopPropagation(); this.alterarEstado(questionarioId) }}>
                            <Icon type="lock" />
                        </Link>
                    }
                    <Link
                        to="#"
                        className="botao-icon-enviar"
                        title="Duplicar"
                        onClick={event => { event.stopPropagation(); this.duplicar(questionarioId) }}
                    >
                        <FaClone />
                    </Link>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => { event.stopPropagation(); this.ativar(questionarioId, this.state.ativo) }}
                    >
                        <Icon type="close" />
                    </Link>
                </div>
            );

        return (
            <div style={divStyle}>
                {aberto ?
                    <Link to="#" className="botao-icon-aberto" title="Abrir" onClick={event => { event.stopPropagation(); this.alterarEstado(questionarioId) }}>
                        <Icon type="unlock" />
                    </Link>
                    :
                    <Link to="#" className="botao-icon-fechado" title="Fechar" onClick={event => { event.stopPropagation(); this.alterarEstado(questionarioId) }}>
                        <Icon type="lock" />
                    </Link>
                }
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => { event.stopPropagation(); this.ativar(questionarioId, this.state.ativo) }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => { event.stopPropagation(); this.excluir(questionarioId) }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };

    //ATIVA E INATIVA QUESTIONÁRIO
    ativar = (questionarioId, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar este questionário?" : "Pretende ativar este questionário?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-questionarios/ativar",
                    params: {
                        questionarioId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Questionário inativado!" : "Questionário ativado!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar o questionário!" : "Não foi possível ativar o questionário!"
                        );
                    });
            }
        });
    };

    //EXCLUIR QUESTIONÁRIO
    excluir = questionarioId => {
        confirm({
            title: "Pretende excluir este questionário?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-questionarios/excluir",
                    params: {
                        questionarioId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Questionário excluido!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o questionário!");
                    });
            }
        });
    };

    //DUPLICAR QUESTIONÁRIO
    duplicar = questionarioId => {
        confirm({
            title: "Pretende duplicar este questionário?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                this.setState({ visibleDuplicar: true })
                axios({
                    method: "delete",
                    url: "/api/gestao-questionarios/duplicar",
                    params: {
                        questionarioId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Questionário duplicado!");
                        this.setState({ visibleDuplicar: false })
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível duplicar o questionário!");
                        this.setState({ visibleDuplicar: false })
                    });
            }
        });
    };

    //ABRE O DRAWER E CARREGA COM OS DADOS DO CURSO PARA EDIÇÃO
    detalhe = questionarioId => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/gestao-questionarios/detalhe",
            params: {
                questionarioId
            }
        })
            .then(response => {
                var questionario = false;
                var questoes2 = [
                    {
                        id: 0,
                        pergunta: "",
                        tipo_pergunta: undefined,
                        ordem: 1,
                        opcoes2: []
                    }
                ];

                //ADICIONA AOS ARRAYS TODAS AS QUESTOES DO QUESTIONARIO E AVALIAÇÃO
                if (response.data.questoes2.length > 0)
                    response.data.questoes2.forEach(questao => {
                        var opcoes2 = [];

                        //ADICIONA AO ARRAY TODAS AS OPÇÕES DE RESPOSTA
                        questao.opcoes2.forEach(opcao => {
                            opcoes2.push({
                                id: opcao.id,
                                texto: opcao.texto,
                                ordem: opcao.ordem,
                                verdadeiro: opcao.verdadeiro
                            });
                        });

                        //EM CASO DE QUESTÃO DO TIPO ESCOLHA UNICA OU ESCOLHA MULTIPLA ADICIONA PRIMEIRA OPÇÃO VAZIA
                        // A FRENTE DA ULTIMA INSERIDA ANTERIORMENTE MESMO QUE NÃO EXISTA
                        if (questao.opcoes2.length === 0)
                            if (questao.tipo_pergunta === "Escolha única" || questao.tipo_pergunta === "Escolha múltipla")
                                opcoes2.push({
                                    id: 0,
                                    texto: "",
                                    ordem: 1,
                                    verdadeiro: false
                                });

                        if (!questionario) questoes2 = [];

                        questionario = true;

                        questoes2.push({
                            id: questao.id,
                            pergunta: questao.pergunta,
                            tipo_pergunta: questao.tipo_pergunta,
                            ordem: questao.ordem,
                            opcoes2: opcoes2
                        });
                    });

                this.setState({
                    questionarioId: response.data.id,
                    nome: response.data.nome,
                    tipo: response.data.tipo,
                    anonimo: response.data.anonimo,
                    realizado: response.data.realizado,
                    questionario,
                    questoes2: questoes2,
                    visible: true,
                    editar: true
                });
            })
            .catch(error => { });
    };

    //ADICIONA UM NOVO QUESTIONARIO
    adicionar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.questionario) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("nome", this.state.nome);
                item.append("tipo", this.state.tipo);
                item.append("anonimo", this.state.anonimo);
                item.append("questionario", this.state.questionario ? JSON.stringify(this.state.questoes2) : "");

                axios({
                    method: "post",
                    url: "/api/gestao-questionarios/adicionar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Adicionado novo questionário!");
                        this.listar();
                        this.setState({
                            visible: false,
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            } else {
                if (!this.state.questionario) {
                    this.setState({
                        status: "error",
                        help: "Campo obrigatório"
                    });
                }
            }
        });
    };

    //ATUALIZA O QUESTIONARIO
    guardar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.questionario) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("questionarioId", this.state.questionarioId);
                item.append("nome", this.state.nome);
                item.append("tipo", this.state.tipo);
                item.append("anonimo", this.state.anonimo);
                item.append("questionario", this.state.questionario ? JSON.stringify(this.state.questoes2) : "");

                axios({
                    method: "post",
                    url: "/api/gestao-questionarios/alterar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Questionário editado com sucesso!");
                        this.listar();
                        this.setState({
                            visible: false,
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            } else {
                if (!this.state.questionario) {
                    this.setState({
                        status: "error",
                        help: "Campo obrigatório"
                    });
                }
            }
        });
    };

    //ABRE O DRAWER VAZIO PARA CRIAR UM NOVO QUESTIONARIO
    showDrawer = () => {
        this.props.form.resetFields();
        this.setState({
            nome: "",
            tipo: undefined,
            anonimo: false,
            realizado: false,
            questionario: false,
            questoes2: [
                {
                    id: 0,
                    pergunta: "",
                    tipo_pergunta: undefined,
                    ordem: 1,
                    opcoes2: []
                }
            ],
            visible: true,
            editar: false
        });
    };

    //FECHA O DRAWER SEM GUARDAR O QUESTIONARIO
    onClose = () => {
        this.setState({
            visible: false
        });
    };

    //GUARDA AS NOVAS RESPOSTAS AO QUESTIONARIO
    alterarQuestionario = questoes2 => {
        this.setState({
            questionario: questoes2.length > 0 ? true : false,
            questoes2: questoes2,
            status: "",
            help: ""
        });
    };

    //FECHA O DRAWER DO QUESTIONARIO SEM GUARDAR
    onCloseQuestionario = () => {
        this.setState({
            drawerQuestionarioVisible: false
        });
    };

    onRowClick = (questionarioId) => {
        this.detalhe(questionarioId);
    }

    render() {
        const {
            ativo,
            //TABELA
            questionarios,
            questionariosMobile,
            //PAGINATION
            pagination,
            //LOADING
            loading_table,
            //DRAWER
            visible,
            editar,
            nome,
            tipo,
            anonimo,
            realizado,
            iconLoading,
            //VALIDACAO QUESTOES
            status,
            help,
            //REDIRECT
            redirect,
            redirectLink,
            //MODAL DUPLICAR
            visibleDuplicar
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const columns = [
            {
                title: "Nome",
                dataIndex: "nome"
            },
            {
                title: "Data / Hora",
                dataIndex: "data"
            },
            {
                title: "",
                dataIndex: "opcoes2",
                width: 10
            }
        ];

        const columnsMobile = [
            {
                title: "Nome",
                dataIndex: "nome"
            },
            {
                title: "",
                dataIndex: "opcoes2",
                width: 10,
                className: "bloco-opcoes"
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Stepforms" />
                <div className="container container-body">
                    <div className="bloco-questionarios">
                        <div className="bloco-tabela">
                            <div className="filtros">
                                <div className="bloco-pesquisa">
                                    <button className="botao-principal botao-principal-redondo" title="Adicionar" onClick={this.showDrawer}>
                                        +
                                    </button>
                                </div>
                                <Switch
                                    checkedChildren="Ativos"
                                    unCheckedChildren="Inativos"
                                    defaultChecked
                                    onChange={this.handleChangeSwitch}
                                />
                            </div>
                            <Table
                                id="tabela_questionarios"
                                className="tabela-questionarios-desktop"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={questionarios}
                                pagination={pagination}
                                locale={{ emptyText: "Não existem dados!" }}
                                rowClassName="clickeble-row"
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => this.onRowClick(record.id)
                                    };
                                }}
                                onChange={this.handleChangeTable}
                            />
                            <Table
                                id="tabela_questionarios"
                                className="tabela-questionarios-mobile"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columnsMobile}
                                dataSource={questionariosMobile}
                                pagination={pagination}
                                locale={{ emptyText: "Não existem dados!" }}
                                rowClassName="clickeble-row"
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => this.onRowClick(record.id)
                                    };
                                }}
                                onChange={this.handleChangeTable}
                            />
                        </div>
                    </div>
                </div>
                <Drawer
                    className="drawer-form-questionario"
                    //title={this.state.editar ? "Alterar questionário" : "Adicionar novo questionário"}
                    width={720}
                    onClose={this.onClose}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <Form.Item label="Nome">
                                {getFieldDecorator("nome", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: nome
                                })(<Input name="nome" disabled={!ativo} onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item validateStatus={status} help={help}>
                                <h3 className="titulo-separador">
                                    Questões {this.state.questionario && "(Total: " + this.state.questoes2.length + ")"}
                                </h3>
                                {ativo && (
                                    <>
                                        {this.state.questionario ? (
                                            <Link
                                                to="#"
                                                className="link-principal"
                                                onClick={() => this.setState({ drawerQuestionarioVisible: true })}
                                            >
                                                Editar
                                            </Link>
                                        ) : (
                                            <Link
                                                to="#"
                                                className="link-principal"
                                                onClick={() => {
                                                    this.setState({
                                                        drawerQuestionarioVisible: true,
                                                        questoes2: [
                                                            {
                                                                pergunta: "",
                                                                tipo_pergunta: undefined,
                                                                ordem: 1,
                                                                opcoes2: []
                                                            }
                                                        ]
                                                    });
                                                }}
                                            >
                                                Adicionar
                                            </Link>
                                        )}
                                    </>
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        {ativo && (
                            <button className="botao-principal" onClick={editar ? this.guardar : this.adicionar} disabled={iconLoading} type="primary">
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                        )}
                    </div>
                </Drawer>
                <DrawerQuestionario
                    questoes2={this.state.questoes2}
                    questionario={this.state.questionario}
                    visible={this.state.drawerQuestionarioVisible}
                    onClose={this.onCloseQuestionario}
                    alterarQuestionario={this.alterarQuestionario}
                />
                <Modal visible={visibleDuplicar} maskClosable={false} className="exportar-csv" closable={false} footer={null}>
                    <div className="exportar-csv-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A duplicar...</p>
                    </div>
                </Modal>
            </>
        );
    }
}
const FormQuestionarios = Form.create({ name: "form-questionarios" })(Questionarios);

export default FormQuestionarios;
