import React, { Component } from "react";
import moment from "moment";

class aulaGeral extends Component {
    render() {
        const {
            aula,
            key
        } = this.props;

        return (
            <div key={key} className="bloco-diario-observacoes">
                <div className="observacao-data-aula">
                    {aula.curso ?
                        <span>{aula.curso} - {aula.titulo}</span>
                        :
                        <span> {aula.titulo}</span>
                    }
                    <span>{moment(aula.dt_inicio).format("DD/MM/YYYY")} <i className="fas fa-circle separador-data-hora"></i> {aula.hr_inicio} - {aula.hr_termino}</span>
                </div>
                <p className="observacao-geral">{aula.observacoes ? aula.observacoes : "Sem dados..."}</p>
            </div>
        );
    }
}

export default aulaGeral;