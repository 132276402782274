import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Select, Form, Checkbox, notification, Icon, Input, Modal } from "antd";
import axios from "axios";
import moment from "moment";
import noimage from "../../../images/noimage.png";
import DrawerDetalhe from "./DrawerDetalhe";

import { GlobalContext } from "../../../GlobalState";

const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Frequencia extends Component {
    static contextType = GlobalContext;

    state = {
        //DRAWER PRESENÇAS
        dt_inicio: "",
        falta: false,
        mostrarObservacoes: false,
        presenca: false,
        presencas: [],
        observacoes: "",
        participantes: [],
        aplicar: false,
        loadingParticipantes: false,
        //LOADING
        iconLoading: false,
        //DRAWER DETALHE
        visivel: false,
        info: ""
    };

    componentDidMount() {
        this.listar();
    }

    componentDidUpdate() {
        if (this.props.frequenciaExcluido) {
            this.props.form.resetFields();
            const novasPresencas = this.state.presencas.map(presenca => {
                return {
                    ...presenca,
                    estado: "",
                    observacoes: ""
                };
            });

            this.setState({
                falta: false,
                presenca: false,
                presencas: novasPresencas,
                observacoes: "",
                mostrarObservacoes: false
            });
            this.props.resetFrequenciaExcluido();
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeParticipante = (event, participanteId) => {
        var presencas = this.state.presencas;
        var index = presencas.findIndex(x => x.id === participanteId);
        presencas[index].observacoes = event.target.value;

        this.setState({ presencas });
    };

    handlerChangeCheckboxPresencaColetiva = event => {
        this.props.form.resetFields();

        this.setState({
            presenca: event.target.checked,
            falta: false,
            presencas: this.state.participantes.map(participante => {
                var registo = this.state.presencas.find(x => x.id === participante.id);
                registo.estado = "P";
                return registo;
            })
        });
    };

    handlerChangeCheckboxFaltaColetiva = event => {
        this.props.form.resetFields();
        this.setState({
            falta: event.target.checked,
            presenca: false,
            presencas: this.state.participantes.map(participante => {
                var registo = this.state.presencas.find(x => x.id === participante.id);
                registo.estado = "F";
                return registo;
            })
        });
    };

    handlerChangeCheckboxPresencas = (event, participanteId) => {
        var presencas = this.state.presencas;
        var index = presencas.findIndex(x => x.id === participanteId);
        presencas[index].presente = event.target.checked;

        this.setState({
            falta: false,
            presenca: false,
            presencas
        });
    };

    handlerChangeSelect = (value, participanteId) => {
        var presencas = this.state.presencas;
        var index = presencas.findIndex(x => x.id === participanteId);
        presencas[index].estado = value;

        this.setState({
            falta: false,
            presenca: false,
            presencas
        });
    };

    handlerChangeCheckboxObservacoes = (event, participanteId) => {
        var presencas = this.state.presencas;
        var index = presencas.findIndex(x => x.id === participanteId);
        presencas[index].mostrarObservacoes = event.target.checked;

        this.setState({
            falta: false,
            presenca: false,
            presencas
        });
    };

    handlerChangeCheckbox = (event, participanteId) => {
        var presencas = this.state.presencas;
        var index = presencas.findIndex(x => x.id === participanteId);
        presencas[index].confirmada = event.target.checked;

        this.setState({
            falta: false,
            presenca: false,
            presencas
        });
    };

    listar = () => {
        this.setState(
            {
                loadingParticipantes: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas/detalhe-assiduidade",
                    timeout: 60 * 10 * 1000,
                    params: {
                        aulaId: localStorage.getItem("aula_diario")
                    }
                })
                    .then(response => {
                        if (!response.data.presenca && !response.data.falta && !response.data.presencas) {
                            let presencas = response.data.participantes.map(participante => {
                                return {
                                    id: participante.id,
                                    estado: undefined,
                                    observacoes: "",
                                    mostrarObservacoes: false
                                };
                            });

                            this.setState({
                                dt_inicio: response.data.dt_inicio,
                                presencas,
                                participantes: response.data.participantes,
                                observacoes: response.data.observacoes ? response.data.observacoes : "",
                                loadingParticipantes: false
                            });
                        } else {
                            let presencas = JSON.parse(response.data.presencas);
                            if (presencas.length === 0)
                                presencas = response.data.participantes.map(participante => {
                                    return {
                                        id: participante.id,
                                        estado: undefined,
                                        observacoes: "",
                                        mostrarObservacoes: false
                                    };
                                });
                            else
                                presencas = response.data.participantes.map(participante => {
                                    var registo = presencas.find(x => x.id === participante.id);
                                    if (registo) {
                                        if (registo.presente) registo.estado = registo.presente ? "P" : "F";

                                        return registo;
                                    } else
                                        return {
                                            id: participante.id,
                                            estado: undefined,
                                            observacoes: "",
                                            mostrarObservacoes: false
                                        };
                                });

                            this.setState({
                                dt_inicio: response.data.dt_inicio,
                                presenca: response.data.presenca,
                                falta: response.data.falta,
                                presencas,
                                observacoes: response.data.observacoes ? response.data.observacoes : "",
                                participantes: response.data.participantes,
                                loadingParticipantes: false
                            });
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    verificar = () => {
        if (this.state.aplicar)
            axios({
                method: "get",
                url: "/api/aulas/verificar-existencia-frequencia",
                params: {
                    aulaId: localStorage.getItem("aula_diario"),
                    cursoId: localStorage.getItem("codigo_curso")
                },
                timeout: 60 * 30 * 1000
            })
                .then(response => {
                    if (response.data)
                        confirm({
                            title: `Aula(s) ${response.data} já possui frequência, deseja continuar?`,
                            okText: "Confirmar",
                            okType: "Cancelar",
                            onOk: () => {
                                this.guardarPresencas();
                            }
                        });
                    else this.guardarPresencas();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi verificar estado das aulas posteriores.");
                });
        else this.guardarPresencas();
    };

    guardarPresencas = () => {
        if (
            this.state.presenca ||
            this.state.falta ||
            this.state.presencas.filter(x => x.estado || x.observacoes).length ||
            this.state.observacoes
        ) {
            this.setState({ iconLoading: true });

            var item = new FormData();
            item.append("id", localStorage.getItem("aula_diario"));
            item.append("cursoId", localStorage.getItem("codigo_curso"));
            item.append("presenca", this.state.presenca);
            item.append("falta", this.state.falta);
            item.append("presencas", JSON.stringify(this.state.presencas));
            item.append("observacoes", this.state.observacoes);
            item.append("aplicar", this.state.aplicar);

            const participantes = this.state.presencas.filter(precensa => precensa.ficheiro != null);

            participantes.forEach(participante => {
                item.append(`ficheiro_${participante.id}`, participante.ficheiro[0]);
            });

            axios({
                method: "put",
                url: "/api/aulas/alterar-assiduidade",
                data: item,
                timeout: 60 * 30 * 1000
            })
                .then(() => {
                    openNotificationWithIcon("success", "Sucesso", "Assiduidade guardada!");
                    var presencas = this.state.presencas;
                    this.state.presencas.map(presenca => {
                        var index = presencas.findIndex(x => x.id === presenca.id);
                        if (presencas[index].confirmada) presencas[index].pendente = false;
                    });

                    this.setState({
                        presencas,
                        iconLoading: false,
                        aplicar: false
                    });
                    this.props.disponibilizarExcluir();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi guardar assiduidade!");
                    this.setState({ iconLoading: false, aplicar: false });
                });
        } else {
            openNotificationWithIcon("info", "Informação", "Não existem dados a serem guardados");
        }
    };

    montarParticipante = presenca => {
        var participante = this.state.participantes.find(x => x.id === presenca.id);

        if (participante)
            return (
                <div className="participante">
                    <div className="info-participante">
                        <div className="container-img">
                            <img src={participante.foto ? participante.foto : noimage} />
                        </div>
                        <div className="info">
                            <span className="nome">{participante.nome}</span>
                            <p className="numero">{participante.numero}</p>
                        </div>
                        {presenca.pendente && (
                            <div>
                                <Icon type="warning" className="estado-pendente" title="Aguarda validação" />
                            </div>
                        )}
                    </div>
                </div>
            );
    };

    adicionarDetalhe = (id, detalhe) => {
        var index = this.state.presencas.findIndex(x => x.id === id);
        var presencas = this.state.presencas;
        presencas[index].tipo = detalhe.tipo;
        presencas[index].ficheiro = detalhe.ficheiro;
        presencas[index].variosDias = detalhe.variosDias;
        presencas[index].dataInicio = detalhe.dataInicio;
        presencas[index].dataFim = detalhe.dataFim;

        this.setState({
            presencas,
            visivel: false
        });
    };

    render() {
        const { TextArea } = Input;
        const {
            //DRAWER PRESENÇAS
            falta,
            presenca,
            presencas,
            observacoes,
            mostrarObservacoes,
            aplicar,
            //LOADING
            iconLoading,
            //DRAWER DETALHE
            visivel,
            info
        } = this.state;

        const { dtInicio } = this.props;

        const hoje = moment();
        hoje.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        dtInicio.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        const bloquear = dtInicio.isAfter(hoje);

        const acesso = this.context.acessos?.find(x => x.func === 17);

        return (
            <>
                <div className="bloco-presencial">
                    <div className="bloco-frequencia-diario" style={{ boxShadow: "none" }}>
                        <div className="listagem-participantes" style={{ marginTop: 0 }}>
                            <Form className="form-presencas" layout="horizontal">
                                <div className="bloco bloco-opcoes">
                                    <div>
                                        <Form.Item className="form-item-checkbox" style={{ marginBottom: 0 }}>
                                            <Checkbox
                                                name="presenca"
                                                checked={presenca}
                                                disabled={bloquear || !acesso?.update}
                                                onChange={this.handlerChangeCheckboxPresencaColetiva}
                                            >
                                                Presença coletiva
                                            </Checkbox>
                                            <Checkbox
                                                name="falta"
                                                checked={falta}
                                                disabled={bloquear || !acesso?.update}
                                                onChange={this.handlerChangeCheckboxFaltaColetiva}
                                            >
                                                Falta coletiva
                                            </Checkbox>
                                        </Form.Item>
                                    </div>
                                    <div className="bloco-totais">
                                        <span className="presencas">
                                            P: <strong>{presencas.filter(x => x.estado === "P").length}</strong>
                                        </span>
                                        <span className="faltas">
                                            F: <strong>{presencas.filter(x => x.estado === "F").length}</strong>
                                        </span>
                                        <span className="justificadas">
                                            FJ: <strong>{presencas.filter(x => x.estado === "FJ").length}</strong>
                                        </span>
                                    </div>
                                </div>
                                <div className="bloco">
                                    <Form.Item>
                                        <div className="listagem-presencas">
                                            {presencas.map((presenca, index) => (
                                                <div key={presenca.id} className="item-presenca">
                                                    <div className="header">
                                                        {this.montarParticipante(presenca)}
                                                        <div className="opcao">
                                                            <div className="bloco-opcoes">
                                                                <button
                                                                    className={`btn-presenca ${presenca.estado === "P" ? "ativo" : ""}`}
                                                                    disabled={bloquear || !acesso?.update}
                                                                    onClick={() => this.handlerChangeSelect("P", presenca.id)}
                                                                >
                                                                    P
                                                                </button>
                                                                <button
                                                                    className={`btn-falta ${presenca.estado === "F" ? "ativo" : ""}`}
                                                                    disabled={bloquear || !acesso?.update}
                                                                    onClick={() => this.handlerChangeSelect("F", presenca.id)}
                                                                >
                                                                    F
                                                                </button>
                                                                <button
                                                                    className={`btn-justificada ${presenca.estado === "FJ" ? "ativo" : ""}`}
                                                                    disabled={bloquear || !acesso?.update}
                                                                    onClick={() => this.handlerChangeSelect("FJ", presenca.id)}
                                                                >
                                                                    FJ
                                                                </button>
                                                            </div>
                                                            {presenca.estado === "FJ" && (
                                                                <Form.Item>
                                                                    <Link
                                                                        to="#"
                                                                        className="link-detalhe"
                                                                        onClick={() => this.setState({ visivel: true, info: presenca })}
                                                                    >
                                                                        <Icon type="plus" />
                                                                        {!presenca.tipo ? "Adicionar" : "Alterar"} detalhe
                                                                    </Link>
                                                                </Form.Item>
                                                            )}
                                                            {presenca.pendente && (
                                                                <Form.Item>
                                                                    <Checkbox
                                                                        name="aplicar"
                                                                        checked={presenca.confirmada}
                                                                        onChange={e => this.handlerChangeCheckbox(e, presenca.id)}
                                                                    >
                                                                        Confirmar decisão
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            )}
                                                            <Checkbox
                                                                name="observacoes"
                                                                checked={presenca.mostrarObservacoes}
                                                                disabled={bloquear || !acesso?.update}
                                                                onChange={e => this.handlerChangeCheckboxObservacoes(e, presenca.id)}
                                                            >
                                                                Mostrar Observações
                                                            </Checkbox>
                                                        </div>
                                                    </div>
                                                    {presenca.mostrarObservacoes === false ? (
                                                        <></>
                                                    ) : (
                                                        <div className="body">
                                                            <Form.Item label="Observações">
                                                                <TextArea
                                                                    name="observacoes"
                                                                    disabled={bloquear || !acesso?.update}
                                                                    rows={4}
                                                                    style={{ height: 100 }}
                                                                    value={presenca.observacoes}
                                                                    onChange={e => this.handleChangeParticipante(e, presenca.id)}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </Form.Item>
                                    <Form.Item label="Observações gerais">
                                        <TextArea
                                            name="observacoes"
                                            rows={4}
                                            style={{ height: 100 }}
                                            value={observacoes}
                                            disabled={bloquear || !acesso?.update}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Checkbox
                                            name="aplicar"
                                            checked={aplicar}
                                            disabled={bloquear || !acesso?.update}
                                            onChange={this.handleChangeCheckbox}
                                        >
                                            Aplicar esta frequência a todas as aulas posteriores já registadas <strong>neste dia</strong>.
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                        {!bloquear && acesso?.update && (
                            <div style={{ width: "100%" }}>
                                <Link className="botao-principal" to="#" disabled={iconLoading} onClick={this.verificar}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Guardar
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
                <DrawerDetalhe
                    visivel={visivel}
                    info={info}
                    onClose={() => this.setState({ visivel: false })}
                    adicionarDetalhe={this.adicionarDetalhe}
                />
            </>
        );
    }
}

const FormFrequencia = Form.create({ name: "form-frequencia" })(Frequencia);

export default FormFrequencia;
