import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Drawer, notification, Icon, Form, Input, Row, Col, Checkbox, Modal, Select, DatePicker, Tooltip, Radio } from "antd";
import axios from "axios";
import { GlobalContext } from "../../../GlobalState";
import moment from "moment";

import noimage from "../../../images/noimage.png";

import DrawerHTML from "../../Paginas/Paginas/Artigos/DrawerHTML"

const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerCalendario extends Component {
    static contextType = GlobalContext;
    state = {
        imagens: [],
        opcao: false,
        capa: "",
        preview: "",
        nome: "",
        estabelecimentos: [],
        selecionados: [],
        inicio: "",
        termino: "",
        aprovado: "",
        descricao: "",
        inscricao: 0,
        linkInscricao: "",
        links: [],
        codigo: "",
        //STATUS
        html_status: "",
        html_help: "",
        validate: {
            totalBytes: 0,
            formatoValido: true,
            validateStatus: "",
            help: ""
        },
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER HTML
        drawerHTMLVisible: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.carregarImagens();
        this.listarEstabelecimentos();
    }

    componentDidUpdate() {
        //if (!this.context.is_authenticated || this.context.role === "UTILIZADOR") {
        //    if (!this.context.is_authenticated)
        //        this.setState({
        //            redirect: true,
        //            redirectLink: "/login"
        //        });
        //    else if (this.context.role === "UTILIZADOR")
        //        this.setState({
        //            redirect: true,
        //            redirectLink: "/user"
        //        });
        //}
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeInicio = (date) => {
        this.setState({ inicio: date });
    };

    handleChangeTermino = (date) => {
        this.setState({ termino: date });
    };

    handleChangeAprovado = (date) => {
        this.setState({ aprovado: date });
    };

    handleChangeEditor = descricao => {
        this.setState({
            html_status: "",
            html_help: "",
            descricao
        })
    }

    handlerChangeRadio = event => {
        let codigo = "";
        let links = [];
        if (event.target.value === 1) {
            codigo = Math.floor(100000 + Math.random() * 900000);
            links = this.state.selecionados.map(selecionado => {
                const url = this.state.estabelecimentos.find(x => x.id === selecionado)?.url;
                if (url)
                    return `${window.location.origin}/${url}/inscricao-evento/${codigo}`
            })
        }

        this.setState({
            [event.target.name]: event.target.value,
            links,
            codigo
        });
    };

    carregarImagens = () => {
        axios({
            method: "get",
            url: "/api/empresa/carregar-imagens",
            params: {
                estabalecimentoId: this.context.estabelecimento.id
            }
        })
            .then(response => {
                this.setState({
                    imagens: response.data
                });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar informação");
            });
    }

    listarEstabelecimentos = () => {
        axios({
            method: "get",
            url: "/api/gestao-organismos/listar-organismos"
        })
            .then(response => {
                this.setState({
                    estabelecimentos: response.data
                });
            })
            .catch(error => {

            });
    };

    //ABRE O DRAWER COM OS DADOS DA CATEGORIA
    carregarInfo = calendarioId => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/gestao-calendario-eventos/detalhe",
            params: {
                calendarioId
            }
        })
            .then(response => {
                const info = response.data;
                this.setState({
                    opcao: response.data.opcao,
                    capa: response.data.capa,
                    preview: response.data.capa,
                    nome: info.nome,
                    selecionados: info.estabelecimentos,
                    inicio: moment(info.inicio, "YYYY-MM-DD"),
                    termino: moment(info.termino, "YYYY-MM-DD"),
                    aprovado: info.aprovado ? moment(info.aprovado, "YYYY-MM-DD") : "",
                    descricao: info.descricao,
                    inscricao: info.inscricao,
                    linkInscricao: info.link_inscricao,
                    codigo: info.codigo
                });
            })
            .catch(() => { });
    };

    //CRIA UMA NOVA CATEGORIA
    criar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && (!this.state.opcao && this.state.validate.totalBytes <= 500 * 1024 && this.state.validate.formatoValido || this.state.opcao) && this.state.preview != "") {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("opcao", this.state.opcao);
                item.append("preview", this.state.preview);
                item.append("capa", this.state.capa);
                item.append("nome", this.state.nome);
                item.append("estabelecimentos", JSON.stringify(this.state.selecionados));
                item.append("dt_inicio", moment(this.state.inicio).format("YYYY/MM/DD"));
                item.append("dt_termino", moment(this.state.termino).format("YYYY/MM/DD"));
                if (this.state.aprovado)
                    item.append("dt_aprovado", moment(this.state.aprovado).format("YYYY/MM/DD"));
                item.append("descricao", this.state.descricao);
                item.append("inscricao", this.state.inscricao);
                if (this.state.inscricao === 2)
                    item.append("link_inscricao", this.state.linkInscricao);
                item.append("codigo", this.state.codigo);

                axios({
                    method: "post",
                    url: "/api/gestao-calendario-eventos/adicionar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Adicionado novo calendário!");
                        this.setState({
                            iconLoading: false
                        });
                        this.props.atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
            else {
                if (this.state.preview == "" && !this.state.opcao)
                    this.setState({
                        validate: {
                            totalBytes: 0,
                            formatoValido: false,
                            validateStatus: "error",
                            help: "Campo obrigatório",
                        }
                    })
            }
        });
    };

    //ATUALIZA A COMPETENCIA
    alterar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && (!this.state.opcao && this.state.validate.totalBytes <= 500 * 1024 && this.state.validate.formatoValido || this.state.opcao) && this.state.preview != "") {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.calendarioId);
                item.append("opcao", this.state.opcao);
                item.append("preview", this.state.preview);
                item.append("capa", this.state.capa);
                item.append("nome", this.state.nome);
                item.append("estabelecimentos", JSON.stringify(this.state.selecionados));
                item.append("dt_inicio", moment(this.state.inicio).format("YYYY/MM/DD"));
                item.append("dt_termino", moment(this.state.termino).format("YYYY/MM/DD"));
                if (this.state.aprovado)
                    item.append("dt_aprovado", moment(this.state.aprovado).format("YYYY/MM/DD"));
                item.append("descricao", this.state.descricao);
                item.append("inscricao", this.state.inscricao);
                if (this.state.inscricao === 2)
                    item.append("link_inscricao", this.state.linkInscricao);
                item.append("codigo", this.state.codigo);

                axios({
                    method: "put",
                    url: "/api/gestao-calendario-eventos/alterar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Calendário alterada!");
                        this.setState({
                            iconLoading: false
                        });
                        this.props.atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
            else {
                if (this.state.preview == "" && !this.state.opcao)
                    this.setState({
                        validate: {
                            totalBytes: 0,
                            formatoValido: false,
                            validateStatus: "error",
                            help: "Campo obrigatório",
                        }
                    })
            }
        });
    };

    //UPLOAD DA FOTO/IMAGEM DO CURSO
    uploadFoto = event => {
        if (event.target.files[0].size < 500 * 1024) {
            if (this.validarFormatoImagem(event.target.files[0].name.split(".").pop())) {
                this.setState({
                    capa: event.target.files[0],
                    preview: URL.createObjectURL(event.target.files[0]),
                    validate: {
                        totalBytes: event.target.files[0].size,
                        formatoValido: true,
                        validateStatus: "",
                        help: "",
                    }
                })
            }
            else {
                document.getElementById("input-foto").value = "";
                openNotificationWithIcon("error", "Erro", "Imagem com formato inválido");
            }
        } else {
            document.getElementById("input-foto").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 500 KB para a imagem.");
        }
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoImagem = formato => {
        var formatos = ["jpg", "jpeg", "png", "gif"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //ABRE O FILE BROWSER
    ativarUploadFoto = () => {
        document.getElementById("input-foto").click();
    };

    //REMOVE A FOTO
    removerFoto = () => {
        document.getElementById("input-foto").value = "";
        this.setState({ capa: "", preview: "" })
    };

    afterVisibleChange = aberto => {
        if (aberto) {

            if (this.props.calendarioId) {
                this.carregarInfo(this.props.calendarioId);
            }
            else {
                this.props.form.resetFields();
                this.setState({
                    opcao: false,
                    capa: "",
                    preview: "",
                    nome: "",
                    selecionados: [],
                    inicio: "",
                    termino: "",
                    aprovado: "",
                    descricao: "",
                    inscricao: 0,
                    linkInscricao: "",
                    links: [],
                    codigo: "",
                    //STATUS
                    html_status: "",
                    html_help: "",
                    validate: {
                        totalBytes: 0,
                        formatoValido: true,
                        validateStatus: "",
                        help: ""
                    },
                    //LOADING
                    iconLoading: false,
                    //DRAWER HTML
                    drawerHTMLVisible: false
                });
            }
        }
    };

    render() {
        const {
            imagens,
            opcao,
            capa,
            preview,
            nome,
            estabelecimentos,
            selecionados,
            inicio,
            termino,
            aprovado,
            descricao,
            inscricao,
            linkInscricao,
            links,
            //LOADING
            iconLoading,
            //STATUS
            html_status,
            html_help,
            validate,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER HTML
            drawerHTMLVisible
        } = this.state;

        const {
            ativo,
            calendarioId,
            visible,
            onClose
        } = this.props;

        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Drawer
                    className="drawer-add-cursos"
                    //title={`${calendarioId ? "Alterar" : "Adicionar"} calendário`}
                    width={720}
                    onClose={onClose}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-competencias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item>
                                    {getFieldDecorator("opcao", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: opcao
                                    })(
                                        <Radio.Group name="opcao" disabled={!ativo} onChange={(e) => this.setState({opcao: e.target.value, preview: "" })}>
                                            <Radio value={false}>Upload de imagem</Radio>
                                            <Radio value={true} >Banco de imagens</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                <div className="bloco-img">
                                    {!opcao ?
                                        <Form.Item validateStatus={validate.validateStatus} help={validate.help}>
                                            <div className="container-img">
                                                <img src={preview ? preview : noimage} />
                                            </div>
                                            {ativo && (
                                                <>
                                                    {!preview ? (
                                                        <>
                                                            <Link to="#" className="link-principal-border" onClick={this.ativarUploadFoto}>
                                                                Adicionar
                                                            </Link>{" "}
                                                            <Tooltip
                                                                className="info-icon"
                                                                title={
                                                                    <div>
                                                                        <p>Tamanho máximo: 500KB</p>
                                                                        <p>Tamanho recomendado: 670x400px</p>
                                                                        <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                                    </div>
                                                                }
                                                            >
                                                                <Icon type="question-circle-o" />
                                                            </Tooltip>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Link to="#" className="link-principal-border" onClick={this.ativarUploadFoto}>
                                                                Alterar
                                                            </Link>
                                                            <Tooltip
                                                                className="info-icon"
                                                                title={
                                                                    <div>
                                                                        <p>Tamanho máximo: 500KB</p>
                                                                        <p>Tamanho recomendado: 670x400px</p>
                                                                        <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                                    </div>
                                                                }
                                                            >
                                                                <Icon type="question-circle-o" style={{ marginRight: 20 }} />
                                                            </Tooltip>
                                                            <Link to="#" className="link-principal-border" onClick={this.removerFoto}>
                                                                Remover
                                                            </Link>
                                                        </>
                                                    )}
                                                </>)}
                                        </Form.Item>
                                        :
                                        <>
                                            <Form.Item>
                                                <div className="container-img">
                                                    <img src={preview ? preview : noimage} />
                                                </div>
                                            </Form.Item>
                                            {ativo && (
                                                <Form.Item label="Imagens">
                                                    {getFieldDecorator("preview", {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Campo obrigatório"
                                                            }
                                                        ],
                                                        initialValue: preview
                                                    })(
                                                        <Select placeholder="Selecionar" onChange={(preview) => this.setState({ preview })}>
                                                            {imagens.map(imagem => (
                                                                <Option value={imagem.caminho} >{imagem.titulo}</Option>
                                                            ))}
                                                        </Select>
                                                    )}
                                                </Form.Item>)}
                                        </>
                                    }
                                    <input
                                        type="file"
                                        id="input-foto"
                                        accept="image/*"
                                        onChange={this.uploadFoto}
                                        style={{ display: "none" }}
                                    />
                                </div>
                                <Form.Item label="Nome">
                                    {getFieldDecorator("nome", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: nome
                                    })(<Input name="nome" maxLength={75} disabled={!ativo} onChange={this.handleChange} />)}
                                </Form.Item>
                                <Form.Item label="Mostrar em">
                                    {getFieldDecorator("selecionados", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: selecionados
                                    })(
                                        <Checkbox.Group style={{ width: '100%' }} disabled={!ativo} onChange={(selecionados) => this.setState({ selecionados })}>
                                            <Row>
                                                {estabelecimentos.map(estabelecimento => (
                                                    <Col key={estabelecimento.id} span={16}>
                                                        <Checkbox value={estabelecimento.id}>
                                                            {estabelecimento.nome}
                                                        </Checkbox>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>
                                <Form.Item label="Inicio">
                                    {getFieldDecorator("inicio", {
                                        initialValue: inicio,
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ]
                                    })(
                                        <DatePicker
                                            name="inicio"
                                            format="DD-MM-YYYY"
                                            disabled={!ativo}
                                            onChange={this.handleChangeInicio}
                                            placeholder="Selecionar"
                                            suffixIcon={false}
                                            clearIcon={false}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Término">
                                    {getFieldDecorator("termino", {
                                        initialValue: termino,
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ]
                                    })(
                                        <DatePicker
                                            name="termino"
                                            format="DD-MM-YYYY"
                                            disabled={!ativo}
                                            onChange={this.handleChangeTermino}
                                            placeholder="Selecionar"
                                            suffixIcon={false}
                                            clearIcon={false}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Descrição" validateStatus={html_status} help={html_help}>
                                    {ativo && (
                                        <>
                                            {descricao ? (
                                                <Link
                                                    to="#"
                                                    className="link-principal"
                                                    style={{ paddingLeft: 0 }}
                                                    onClick={() => this.setState({ drawerHTMLVisible: true })}
                                                >
                                                    Editar
                                                </Link>
                                            ) : (
                                                <Link
                                                    to="#"
                                                    className="link-principal"
                                                    style={{ paddingLeft: 0 }}
                                                    onClick={() => this.setState({ drawerHTMLVisible: true })}
                                                >
                                                    Adicionar
                                                </Link>
                                            )}
                                        </>
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <Radio.Group name="inscricao" className="radio-options" disabled={!ativo} onChange={this.handlerChangeRadio} value={inscricao}>
                                        <Radio value={0}>
                                            Sem inscrição
                                        </Radio>
                                        <Radio value={1}>
                                            Com inscrição via Stepforma
                                            <span className="subtext">(Formulário automático onde será pedido o nome, email e a instituição/empresa)</span>
                                        </Radio>
                                        <Radio value={2}>
                                            Com inscrição via link externo
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {inscricao === 1 && (
                                    <Form.Item label="Links">
                                        {links.map(link => (
                                            <div>{link}</div>
                                        ))}
                                    </Form.Item>)}
                                {inscricao === 2 && (
                                    <Form.Item label="Link">
                                        {getFieldDecorator("linkInscricao", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ],
                                            initialValue: linkInscricao
                                        })(<Input name="linkInscricao" maxLength={75} disabled={!ativo} onChange={this.handleChange} />)}
                                    </Form.Item>)}
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        {ativo && (
                            <button className="botao-principal" onClick={calendarioId ? this.alterar : this.criar} disabled={iconLoading} type="primary">
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>)}
                    </div>
                </Drawer>
                <DrawerHTML
                    texto={descricao}
                    visible={drawerHTMLVisible}
                    onClose={() => this.setState({ drawerHTMLVisible: false })}
                    handleChangeEditor={this.handleChangeEditor}
                />
            </>
        );
    }
}
const FormDrawerCalendario = Form.create({ name: "form-drawer-calenario" })(DrawerCalendario);

export default withRouter(FormDrawerCalendario);
