import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Form, Input, Icon, Drawer, Tooltip, Upload, notification } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerDoc = ({ documentoId, visible, onClose, atualizar, download, form, match }) => {
    const [nome, setNome] = useState("");
    const [link, setLink] = useState("");
    const [fileList, setFileList] = useState([]);
    const [estado, setEstado] = useState("");
    const [loading, setLoading] = useState(false);

    const { getFieldDecorator } = form;
    const Dragger = Upload.Dragger;

    const handlerChange = event => {
        setNome(event.target.value);
    };

    const detalhe = () => {
        axios({
            method: "get",
            url: "/api/documentos/detalhe",
            params: {
                documentoId
            }
        })
            .then(response => {
                const dados = response.data;
                setNome(dados.nome);
                setLink(dados.ficheiro);
                setEstado(dados.estado);
            })
            .catch(error => {

            });
    }

    const adicionar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);

                var item = new FormData();
                item.append("nome", nome);
                item.append("ficheiro", fileList[0]);

                axios({
                    method: "post",
                    url: "/api/documentos/adicionar",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", response.data);
                        atualizar();
                        setLoading(false);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false);
                    });
            }
        })
    }

    const alterar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);

                var item = new FormData();
                item.append("id", documentoId);
                item.append("nome", nome);
                item.append("ficheiro", fileList[0]);

                axios({
                    method: "put",
                    url: "/api/documentos/alterar",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", response.data);
                        atualizar();
                        setLoading(false);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false);
                    });
            }
        })
    }

    const afterVisibleChange = visible => {
        form.resetFields();
        if (visible) {
            if (documentoId > 0)
                detalhe();
        }
        else {
            setNome("");
            setEstado("");
            setFileList([]);
            setLoading(false);
        }
    }

    const validarFormatoFicheiro = formato => {
        var formatos = ["docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    const validar = (rule, value, callback) => {
        if (value.file)
            if (!validarFormatoFicheiro(value.file.name.split(".").pop().toLowerCase()) && value.file.status !== "removed")
                callback("Ficheiro com formato inválido");
            else if (value.file.status === "removed")
                callback("Campo obrigatório");

        callback();
    };

    const props = {
        multiple: false,
        onRemove: file => {
            form.resetFields("ficheiro");
            setFileList(fileList => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);

                return newFileList;
            });
        },
        defaultFileList: fileList,
        beforeUpload: file => {
            setFileList([file])

            return false;
        },
        fileList
    };

    return (
        <Drawer
            className="drawer-add-cursos drawer-videoconferencias"
            //title=""
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info">
                <Form className="form-categorias" layout="horizontal">
                    <div className="bloco">
                        <Form.Item label="Nome">
                            {getFieldDecorator("nome", {
                                rules: [{
                                    required: true,
                                    message: "Campo obrigatório"
                                }],
                                initialValue: nome
                            })(<Input name="nome" onChange={handlerChange} />)}
                        </Form.Item>
                        <div className="bloco">
                            <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>Documento</h3>
                            {documentoId > 0 &&
                                <Form.Item>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <span>{nome}</span>
                                        <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 10 }}>
                                            {/*<Link to="#" title="Visualizar" className="botao-icon-detalhe">*/}
                                            {/*    <Icon type="eye" />*/}
                                            {/*</Link>*/}
                                            <Link to="#" title="Descarregar" className="botao-icon-download" onClick={() => download(documentoId)}>
                                                <Icon type="download" />
                                            </Link>
                                        </span>
                                    </div>
                                </Form.Item>}
                            <Form.Item>
                                <div className="anexos">
                                    {getFieldDecorator("ficheiro", {
                                        rules: [
                                            {
                                                required: documentoId === 0,
                                                message: "Campo obrigatório"
                                            },
                                            {
                                                validator: validar
                                            }
                                        ],
                                        initialValue: fileList
                                    })(
                                        <Dragger {...props}>
                                            <p className="ant-upload-drag-icon">
                                                <i className="fas fa-upload" />
                                            </p>
                                            <p className="ant-upload-text">
                                                Adicionar anexo{" "}
                                                <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </p>
                                            <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                        </Dragger>
                                    )}
                                </div>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <Link to="#" className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                    Voltar
                </Link>
                <button className="botao-principal" disabled={loading} onClick={documentoId > 0 ? alterar : adicionar}>
                    {loading ? <Icon type="loading" /> : null}
                    Guardar
                </button>
                {/*{documentoId > 0 ?*/}
                {/*    <button className="botao-principal" disabled={loading} onClick={alterar}>*/}
                {/*        {loading ? <Icon type="loading" /> : null}*/}
                {/*        Guardar*/}
                {/*    </button>*/}
                {/*    :*/}
                {/*    <button className="botao-principal" disabled={loading} onClick={adicionar}>*/}
                {/*        {loading ? <Icon type="loading" /> : null}*/}
                {/*        Criar*/}
                {/*    </button>*/}
                {/*}*/}
            </div>
        </Drawer>
    );
}

const FormDrawerDoc = Form.create({ name: "form-drawer-doc" })(DrawerDoc);

export default withRouter(FormDrawerDoc);