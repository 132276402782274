import React, { Component } from "react";
import axios from "axios";
import { Modal, Select, Form, DatePicker } from "antd";
import moment from "moment";

const { Option } = Select;

class ModalHorarios extends Component {
    state = {
        selectedOption1: undefined,
        selectedOption2: undefined,
        selectedOption3: undefined,
        dataEmissao: moment(),
        placeholder: "",
        disciplina: this.props.disciplina,
        listaDisciplinas: [],
        calendars: [],
        startDate: "",
        endDate: "",
        dates: [],
        datesFreq: [],
        option1Options: ["Periodo", "Mês", "Completo"],
        option2Options: [],
        option3Options: [],
        monthNames: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
        ],
        months: [
            { mes: "Janeiro", id: 0 },
            { mes: "Fevereiro", id: 1 },
            { mes: "Março", id: 2 },
            { mes: "Abril", id: 3 },
            { mes: "Maio", id: 4 },
            { mes: "Junho", id: 5 },
            { mes: "Julho", id: 6 },
            { mes: "Agosto", id: 7 },
            { mes: "Setembro", id: 8 },
            { mes: "Outubro", id: 9 },
            { mes: "Novembro", id: 10 },
            { mes: "Dezembro", id: 11 }
        ],
        months2: [
            ["Janeiro", 0],
            ["Fevereiro", 1],
            ["Março", 2],
            ["Abril", 3],
            ["Maio", 4],
            ["Junho", 5],
            ["Julho", 6],
            ["Agosto", 7],
            ["Setembro", 8],
            ["Outubro", 9],
            ["Novembro", 10],
            ["Dezembro", 11]
        ]
    };

    componentDidMount() {
        this.getCalendario();
        this.getMeses();
        this.getMesesFreq();
        this.getDisciplinas();
    }

    getCalendario = () => {
        axios({
            method: "get",
            url: "/api/aulas-exportar/get-calendarios",
            params: {
                id: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                const datas = this.ordenarDatas(response.data);
                let startDate = "";
                let endDate = "";

                if (datas.length > 0) {
                    startDate = datas[0];
                    endDate = datas[datas.length - 1];
                }

                this.setState({
                    calendars: response.data,
                    startDate,
                    endDate
                });
            })
            .catch(() => {});
    };

    getMeses = () => {
        axios({
            method: "get",
            url: "/api/aulas-exportar/get-meses",
            params: {
                id: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    dates: response.data
                });
            })
            .catch(() => {});
    };

    getDisciplinas = () => {
        axios({
            method: "get",
            url: "/api/aulas-exportar/get-disciplinas",
            params: {
                id: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    option3Options: response.data,
                    listaDisciplinas: response.data
                });
            })
            .catch(() => {});
    };

    getMesesFreq = () => {
        axios({
            method: "get",
            url: "/api/aulas-exportar/get-meses-frequencias",
            params: {
                id: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    datesFreq: response.data
                });
            })
            .catch(() => {});
    };

    ordenarDatas = listaDeDatas => {
        let datasObjeto = listaDeDatas.reduce((arr, data) => {
            arr.push(new Date(data.dt_inicio), new Date(data.dt_termino));
            return arr;
        }, []);

        datasObjeto.sort((a, b) => a - b);

        return datasObjeto;
    };

    handleOk = () => {
        const { selectedOption1, selectedOption2, selectedOption3, startDate, endDate, months, calendars } = this.state;
        let start_date = startDate;
        let end_date = endDate;

        if (selectedOption1 === "Mês") {
            const selectedMonth = months.findIndex(month => month.mes === selectedOption2);

            if (selectedMonth >= 0) {
                const startYear = startDate.getFullYear();
                const endYear = endDate.getFullYear();
                const startMonth = startDate.getMonth();
                const monthIndices = [];

                let year = endYear;

                if (startYear !== endYear) {
                    for (let i = startMonth; i <= 11; i++) {
                        monthIndices.push(i);
                    }

                    if (monthIndices.find(x => x === selectedMonth)) year = startYear;
                }

                const firstDay = new Date(year, selectedMonth, 1);
                const lastDay = new Date(year, selectedMonth + 1, 0);

                start_date = firstDay;
                end_date = lastDay;
            }
        } else if (selectedOption1 === "Periodo" && selectedOption2) {
            const selectedCalendar = calendars.find(calendar => calendar.nome === selectedOption2);

            if (selectedCalendar) {
                start_date = new Date(selectedCalendar.dt_inicio);
                end_date = new Date(selectedCalendar.dt_termino);
            }
        }

        if (this.state.listaDisciplinas.length > 1) this.setState({ selectedOption3: this.state.disciplina });

        this.props.onSelect(
            this.state.listaDisciplinas.length > 1 ? selectedOption3 : this.state.disciplina,

            selectedOption1,
            {
                start_date,
                end_date
            },
            this.state.dataEmissao
        );
        this.props.onClose();
        this.setState({
            selectedOption3: undefined,
            selectedOption1: undefined,
            selectedOption2: undefined,
            dataEmissao: moment()
        });
    };

    handleOption1Change = value => {
        let placeholder = "";

        let option2Options = [];

        if (value === "Periodo") {
            placeholder = "Selecionar Periodo";
            option2Options = this.state.calendars.map(calendar => calendar.nome);
        } else if (value === "Mês") {
            placeholder = "Selecionar Mês";
            option2Options = this.state.datesFreq.map(date => {
                return date;
            });
        } else if (value === "Completo") {
            placeholder = "";
        }

        this.setState({
            selectedOption1: value,
            selectedOption2: undefined,
            option2Options,
            placeholder
        });
    };

    handleOption2Change = value => {
        this.setState({
            selectedOption2: value
        });
    };

    handleOption3Change = value => {
        this.setState({
            selectedOption3: value
        });
    };

    handleChangeDataEmissao = date => {
        this.setState({ dataEmissao: date });
    };

    render() {
        return (
            <Modal
                title="Selecione a opção"
                visible={this.props.visible}
                onOk={this.handleOk}
                okText={"Visualizar"}
                onCancel={this.props.onClose}
                okButtonProps={{
                    style: {
                        backgroundColor: "rgb(70, 176, 255)",
                        borderColor: "rgb(27, 185, 255)"
                    },
                    disabled:
                        this.state.listaDisciplinas.length > 1
                            ? !(
                                  this.state.selectedOption1 &&
                                  (this.state.selectedOption1 === "Completo" || this.state.selectedOption2) &&
                                  this.state.selectedOption3
                              )
                            : !(this.state.selectedOption1 && (this.state.selectedOption1 === "Completo" || this.state.selectedOption2))
                }}
            >
                <div className="my-modal-wrapper">
                    {this.state.listaDisciplinas.length > 1 ? (
                        <>
                            <div className="select-wrapper">
                                <Select
                                    value={this.state.selectedOption3}
                                    placeholder="Selecionar Opção"
                                    onChange={this.handleOption3Change}
                                    className="custom-select ant-select-selection--single"
                                >
                                    {this.state.option3Options.map(disciplina => (
                                        <Option key={disciplina.id} value={disciplina.id}>
                                            {disciplina.nome}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="select-wrapper">
                                <Select
                                    value={this.state.selectedOption1}
                                    disabled={!this.state.selectedOption3}
                                    placeholder="Selecionar Opção"
                                    onChange={this.handleOption1Change}
                                    className="custom-select ant-select-selection--single"
                                >
                                    {this.state.option1Options.map(option => (
                                        <Option key={option} value={option}>
                                            {option}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="select-wrapper">
                                <Select
                                    value={this.state.selectedOption2}
                                    placeholder={this.state.placeholder}
                                    onChange={this.handleOption2Change}
                                    className="custom-select ant-select-selection--single"
                                    disabled={this.state.selectedOption1 === "Completo" || !this.state.selectedOption1}
                                >
                                    {this.state.option2Options.map(option => (
                                        <Option key={option} value={option}>
                                            {option}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="select-wrapper">
                                <Form>
                                    <Form.Item>
                                        <DatePicker
                                            name="dataEmissao"
                                            format="DD-MM-YYYY"
                                            placeholder="Selecionar Data de Emissão"
                                            suffixIcon={false}
                                            clearIcon={false}
                                            onChange={this.handleChangeDataEmissao}
                                        />
                                    </Form.Item>
                                </Form>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="select-wrapper">
                                <Select
                                    value={this.state.selectedOption1}
                                    placeholder="Selecionar Opção"
                                    onChange={this.handleOption1Change}
                                    className="custom-select ant-select-selection--single"
                                >
                                    {this.state.option1Options.map(option => (
                                        <Option key={option} value={option}>
                                            {option}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="select-wrapper">
                                <Select
                                    value={this.state.selectedOption2}
                                    placeholder={this.state.placeholder}
                                    onChange={this.handleOption2Change}
                                    className="custom-select ant-select-selection--single"
                                    disabled={this.state.selectedOption1 === "Completo" || !this.state.selectedOption1}
                                >
                                    {this.state.option2Options.map(option => (
                                        <Option key={option} value={option}>
                                            {option}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="select-wrapper">
                                <Form>
                                    <Form.Item>
                                        <DatePicker
                                            name="dataEmissao"
                                            format="DD-MM-YYYY"
                                            placeholder="Selecionar Data de Emissão"
                                            suffixIcon={false}
                                            clearIcon={false}
                                            onChange={this.handleChangeDataEmissao}
                                        />
                                    </Form.Item>
                                </Form>
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        );
    }
}

export default ModalHorarios;
