import React, { Component } from "react"
import { Link, withRouter, Redirect } from "react-router-dom"
import { Menu, Table, DatePicker, Dropdown, Modal, notification, Form, Input, Icon, Upload, Drawer, Tooltip } from "antd"
import Header from "../User/Header"
import axios from "axios"
import moment from "moment"
import { GlobalContext } from "../GlobalState"
import noimage from "../images/noimage.png"

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    })
}

class Documentacao extends Component {
    static contextType = GlobalContext
    state = {
        utilizador: "",
        documentos: [],
        //DRAWER
        visible: false,
        documentoId: 0,
        nome: "",
        fileList: [],
        ficheiros: [],
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER DETALHE
        visibleDetalhe: false,
        detalheModificado: "",
        detalheAutor: ""
    }

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.setState(
            {
                utilizador: JSON.parse(localStorage.getItem("sis_utilizador"))
            },
            () => {
                this.listarDocumentos();
            }
        );
    }

    handlerChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    listarDocumentos = () => {
        axios({
            method: "get",
            url: "/api/colaboradores/listar-documentos",
            params: {
                utilizadorid: this.state.utilizador.id
            }
        })
            .then(response => {
                let documentos = [];

                response.data.map(documento => {
                    documentos.push({
                        id: documento.id,
                        tipo: documento.nome.split(".").pop(),
                        nome: documento.nome,
                        data: documento.dt_alterado
                            ? moment(documento.dt_alterado).format("DD/MM/YYYY")
                            : moment(documento.dt_criado).format("DD/MM/YYYY"),
                        autor: <div className="info-autor">
                            <div className="container-img">
                                <img src={documento.autor.foto ? documento.autor.foto : noimage} />
                            </div>
                            <div className="info">
                                <span className="nome">
                                    {documento.autor.nome.split(" ").length > 1 ? `${documento.autor.nome.split(" ")[0]} ${documento.autor.nome.split(" ")[documento.autor.nome.split(" ").length - 1]}` : documento.autor.nome}
                                </span>
                                <p className="numero">{documento.autor.numero}</p>
                            </div>
                        </div>,
                        link: documento.ficheiro
                    });
                });

                this.setState({
                    documentos
                });
            })
            .catch(error => {
                //if (error.response.status === 401)
                //    this.setState({
                //        redirect: true,
                //        redirectLink: "/login"
                //    });
            });
    };

    carregarDetalhe = documento => {
        this.props.form.resetFields();
        this.setState({
            documentoId: documento.id,
            nome: documento.nome.replace("." + documento.nome.split(".").pop(), ""),
            fileList: [],
            ficheiros: [documento.nome],
            visible: true
        });
    };

    excluir = documentoId => {
        confirm({
            title: "Pretende excluir este ficheiro?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/colaboradores/excluir-documento",
                    params: {
                        documentoId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Ficheiro excluido!");
                        let documentos = this.state.documentos.filter(x => x.id !== documentoId);
                        this.setState({
                            documentos
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o ficheiro!");
                    });
            }
        });
    };

    //ABRE O DRAWER A VAZIO PARA ADICIONAR UMA NOVA ENTIDADE
    abrirDrawer = () => {
        this.props.form.resetFields();
        this.setState({
            visible: true,
            documentoId: 0,
            nome: "",
            fileList: []
        });
    };

    guardarFicheiro = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                let item = new FormData();
                item.append("nome", this.state.nome + "." + this.state.fileList[0].name.split(".").pop());
                item.append("ficheiro", this.state.fileList[0]);
                item.append("utilizadorid", this.state.utilizador.id);

                axios({
                    method: "post",
                    url: "/api/colaboradores/adicionar-documento",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Documento adicionado!");
                        this.listarDocumentos();
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    alterarFicheiro = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                let item = new FormData();
                item.append("id", this.state.documentoId);
                item.append("nome", this.state.nome + "." + this.state.fileList[0].name.split(".").pop());
                item.append("ficheiro", this.state.fileList[0]);
                item.append("utilizadorid", this.state.utilizador.id);

                axios({
                    method: "put",
                    url: "/api/colaboradores/alterar-documento",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ficheiro alterado!");
                        this.listarDocumentos();
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    downloadFicheiro = (documentoId) => {
        axios({
            method: "post",
            url: "/api/colaboradores/download-ficheiro",
            params: {
                documentoId
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute(
                    'download',
                    response.data.nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                else
                    openNotificationWithIcon("error", "Erro", error.response.data);

                this.setState({ iconLoading: false });
            });
    }

    validar = (rule, value, callback) => {
        if (value.file)
            if (!this.validarFormatoFicheiro(value.file.name.split(".").pop().toLowerCase()) && value.file.status !== "removed")
                callback("Ficheiro com formato inválido");
            else if (value.file.status === "removed") callback("Campo obrigatório");

        callback();
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoFicheiro = formato => {
        const formatos = [
            "zip",
            "rar",
            "7z",
            "docx",
            "doc",
            "pdf",
            "odf",
            "txt",
            "rtf",
            "xlsx",
            "xls",
            "csv",
            "ods",
            "pptx",
            "ppt",
            "odp",
            "bmp",
            "gif",
            "png",
            "jpeg",
            "jpg"
        ];
        let valido = false;
        for (let i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    abrirDetalhe = (autor, modificado) => {
        this.setState({
            visibleDetalhe: true,
            detalheModificado: modificado,
            detalheAutor: autor
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const Dragger = Upload.Dragger;

        const {
            utilizador,
            documentos,
            //DRAWER
            visible,
            documentoId,
            nome,
            fileList,
            ficheiros,
            //LOADING
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER DETALHE
            visibleDetalhe,
            detalheModificado,
            detalheAutor
        } = this.state

        if (redirect) return <Redirect to={redirectLink} />

        const opcoes = documento => {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.carregarDetalhe(documento)}>
                            Editar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.excluir(documento.id)}>
                            Excluir
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        };

        const opcoesMobile = documento => {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.downloadFicheiro(documento.id)}>
                            Descarregar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalhe(documento.autor, documento.data)}>
                            Detalhe
                            </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.carregarDetalhe(documento)}>
                            Editar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.excluir(documento.id)}>
                            Excluir
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        };

        const montar_icon = tipo => {
            if (tipo === "pdf") {
                return (
                    <div className="start" title="Editor" style={{ width: 30, height: 30 }}>
                        <img
                            src={require("../images/icons/pdf.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "zip" || tipo === "rar" || tipo === "7z") {
                return (
                    <div className="start" title="Ficheiro" style={{ width: 30, height: 30 }}>
                        <img
                            src={require("../images/icons/compress.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "docx" || tipo === "doc" || tipo === "odf") {
                return (
                    <div className="start" title="Word" style={{ width: 30, height: 30 }}>
                        <img
                            src={require("../images/icons/word.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "txt" || tipo === "rtf") {
                return (
                    <div className="start" title="Word" style={{ width: 30, height: 30 }}>
                        <img
                            src={require("../images/icons/text.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "xlsx" || tipo === "xls" || tipo === "csv" || tipo === "ods") {
                return (
                    <div className="start" title="Word" style={{ width: 30, height: 30 }}>
                        <img
                            src={require("../images/icons/excel.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "pptx" || tipo === "ppt" || tipo === "odp") {
                return (
                    <div className="start" title="Word" style={{ width: 30, height: 30 }}>
                        <img
                            src={require("../images/icons/powerpoint.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else {
                return (
                    <div className="start" title="Word" style={{ width: 30, height: 30 }}>
                        <img
                            src={require("../images/icons/images.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            }
        };

        const props = {
            multiple: false,
            onRemove: file => {
                this.props.form.resetFields("ficheiro");
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    return {
                        fileList: newFileList
                    };
                });
            },
            defaultFileList: this.state.fileList,
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file]
                }));

                return false;
            },
            fileList
        };

        return (
            <div>
                <Header titulo="Documentação" />
                <div className="container container-body">
                    <div className="bloco-entidades bloco-documentacao-utilizador">
                        <div className="bloco">
                            <div className="bloco-flex">
                                <div className="bloco-info" style={{ padding: 0 }}>
                                    <div className="dados-user">
                                        <div className="container-img">
                                            <img src={utilizador.foto ? utilizador.foto : noimage} alt="" />
                                        </div>
                                        <div>
                                            <h6 style={{ marginBottom: 0, color: "#3d464d", fontSize: "14px" }}>{utilizador.nome}</h6>
                                            <span style={{ fontSize: 12 }}>{utilizador.numero}</span>
                                        </div>
                                    </div>
                                </div>
                                <Link to="/gestao-utilizadores-todos" className="botao-principal">
                                    Voltar
                                </Link>
                            </div>
                            <div className="bloco-documentacao">
                                <div className="box-ficheiros box-ficheiros-desktop">
                                    <div className="header">
                                        <span className="desc"></span>
                                        <span className="desc">Nome</span>
                                        <span className="desc">Modificado</span>
                                        <span className="desc">Autor</span>
                                        <span className="desc"></span>
                                    </div>
                                    {documentos.length > 0 ? (
                                        documentos.map((documento, index) => (
                                            <div key={index} className="content">
                                                <span className="desc">{montar_icon(documento.tipo)}</span>
                                                <span className="desc">{documento.nome}</span>
                                                <span className="desc">{documento.data}</span>
                                                <span className="desc">{documento.autor}</span>
                                                <span className="desc box-ficheiros-opcoes">
                                                    <Link to="#" title="Descarregar" className="botao-icon-download" onClick={() => this.downloadFicheiro(documento.id)}>
                                                        <Icon type="download" />
                                                    </Link>
                                                    <Link className="botao-icon-editar" to="#" onClick={() => this.carregarDetalhe(documento)} title="Editar">
                                                        <Icon type="edit" />
                                                    </Link>
                                                    <Link className="botao-icon-excluir" to="#" onClick={() => this.excluir(documento.id)} title="Excluir">
                                                        <Icon type="delete" />
                                                    </Link>
                                                </span>
                                            </div>
                                        ))
                                    ) : (
                                            <div className="sem-registos">Não existem dados!</div>
                                        )}
                                </div>
                                <div className="box-ficheiros box-ficheiros-mobile">
                                    <div className="header">
                                        <span className="desc">Nome</span>
                                        <span className="desc"></span>
                                    </div>
                                    {documentos.length > 0 ? (
                                        documentos.map((documento, index) => (
                                            <div key={index} className="content">
                                                <span className="desc">
                                                    <div className="box-ficheiro">
                                                        {montar_icon(documento.tipo)}
                                                        <div className="info-ficheiro">
                                                            <span className="nome-ficheiro">
                                                                {documento.nome}
                                                            </span>
                                                            <div className="data-ficheiro">
                                                                {documento.data}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                                <span className="desc">
                                                    <Dropdown overlay={opcoesMobile(documento)} placement="bottomLeft">
                                                        <Link to="#" className="botao-icon-configs">
                                                            <i className="fas fa-cog" />
                                                        </Link>
                                                    </Dropdown>
                                                </span>
                                            </div>
                                        ))
                                    ) : (
                                            <div className="sem-registos">Não existem dados!</div>
                                        )}
                                </div>
                            </div>
                            <div className="opcoes-meus-cursos">
                                <Link to="#" className="btn-adicionar" title="Adicionar" onClick={this.abrirDrawer}>
                                    +
                            </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Drawer
                    className="drawer-add-cursos drawer-videoconferencias"
                    //title={documentoId > 0 ? "Alterar" : "Adicionar documento"}
                    width={720}
                    onClose={() => this.setState({ visible: false })}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Nome">
                                    {getFieldDecorator("nome", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: nome
                                    })(<Input name="nome" onChange={this.handlerChange} />)}
                                </Form.Item>
                                <Form.Item label="Ficheiro">
                                    <div className="anexos">
                                        {getFieldDecorator("ficheiro", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                },
                                                {
                                                    validator: this.validar
                                                }
                                            ],
                                            initialValue: fileList
                                        })(
                                            <Dragger {...props}>
                                                <p className="ant-upload-drag-icon">
                                                    <i className="fas fa-upload" />
                                                </p>
                                                <p className="ant-upload-text">
                                                    Adicionar anexo{" "}
                                                    <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                        <Icon type="question-circle-o" />
                                                    </Tooltip>
                                                </p>
                                                <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                            </Dragger>
                                        )}
                                    </div>
                                </Form.Item>
                                {ficheiros.length ?
                                    <Form.Item>
                                        <h3 className="titulo-separador">Anexos</h3>
                                        <div className="lista-videos">
                                            {ficheiros.map((ficheiro, index) => (
                                                <div key={index} className="item-video">
                                                    <span className="video-titulo">{ficheiro}</span>
                                                    <div className="video-controlos">
                                                        <Link to="#" className="botao-icon-download" title="Descarregar" onClick={() => this.downloadFicheiro(documentoId)}>
                                                            <Icon type="download" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Form.Item>
                                    : null}
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <Link
                            to="#"
                            className="botao-secundario"
                            onClick={() => this.setState({ visible: false })}
                            style={{ marginRight: 20, display: "inline-block" }}
                        >
                            Voltar
                        </Link>
                        {documentoId > 0 ? (
                            <button className="botao-principal" disabled={iconLoading} onClick={this.alterarFicheiro}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                        ) : (
                                <button className="botao-principal" disabled={iconLoading} onClick={this.guardarFicheiro}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                                </button>
                            )}
                    </div>
                </Drawer>
                <Drawer
                    className="drawer-detalhe-drive"
                    //title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Modificado">
                                    {detalheModificado}
                                </Form.Item>
                                <Form.Item label="Autor">
                                    {detalheAutor}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
            </div>
        )
    }
}

const FormDocumentacao = Form.create({ name: "documentacao" })(Documentacao)

export default withRouter(FormDocumentacao)
