import React, { Component } from "react";
import { Redirect, Link, withRouter } from "react-router-dom";
import { Form, Input, Icon, notification, Modal, Select, Spin, Menu, Dropdown, Collapse } from "antd";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";
import Ficheiros from "./Ficheiros";
import moment from "moment";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ConteudoTrabalho extends Component {
    static contextType = GlobalContext;
    state = {
        cursoid: 0,
        aulaid: 0,
        utilizadorId: 0,
        nome: "",
        objetivos: "",
        tipo: "",
        dt_inicio: "",
        dt_fim: "",
        enunciado: "",
        enunciado_nome: "",
        descricao: "",
        observacoes: "",
        numero_elementos: 0,
        participantes: [],
        listaProcurarParticipantes: [],
        listaParticipantes: [],
        ficheiros: [],
        utilizador: "",
        concluido: false,
        dt_concluido: "",
        //concluido: false,
        disponivel: false,
        /////////////////
        fileList: [],
        procurar: false,
        //LOADING
        iconLoading: false,
        //TEM COMANDOS
        tem_comandos: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //LEITOR PDF
        numPages: null,
        pageNumber: 1,
        scale: 1.5,
        rotate: 0,
        //LOADING
        loading: true
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        var aulaId = localStorage.getItem("codigo_aula");

        this.setState({
            cursoid: localStorage.getItem("codigo_curso"),
            aulaid: aulaId
        });

        this.CarregarConteudo(aulaId);
    }

    handleChange = event => {
        this.setState({
            observacoes: event.target.value
        });
    };

    //MONTA AS OPÇÕES DE MENU POR AULA DE PRIMEIRO NIVEL
    montarMenu = (doc, doc_nome, index, uId, disponivel) => {
        return (
            <Menu>
                <Menu.Item key={index + "_descarregar"}>
                    <a href={doc} download={doc_nome}>
                        Descarregar
                    </a>
                </Menu.Item>
                {disponivel && (
                    <Menu.Item key={index + "_excluir"}>
                        <Link to="#" onClick={() => this.excluirFicheiro(uId)}>
                            Excluir
                        </Link>
                    </Menu.Item>
                )}
            </Menu>
        );
    };

    //CARREGA A AULA
    CarregarConteudo = aulaid => {
        axios({
            method: "get",
            url: "/api/player-trabalho/carregar-trabalho",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                var participantes = [];
                var listaProcurarParticipantes = [];
                var disponivel = response.data.dt_inicio ? moment().isBetween(moment(response.data.dt_inicio), moment(response.data.dt_fim), 'days') : moment().isBefore(moment(response.data.dt_fim), 'days') || moment().isSame(moment(response.data.dt_fim), 'days');

                //ADICIONA AO ARRAY TODOS OS PARTICIPANTES
                if (response.data.utilizadores.length > 0) {
                    response.data.utilizadores.forEach(utilizador => {
                        if (response.data.tipo_trabalho !== "INDIVIDUAL") {
                            participantes.push(utilizador.id + "");
                            listaProcurarParticipantes.push({
                                key: utilizador.id + "",
                                label: utilizador.nome_completo
                            });
                        }
                    });
                }
                else {
                    participantes.push(response.data.utilizador.id + "");
                    listaProcurarParticipantes.push({
                        key: response.data.utilizador.id + "",
                        label: response.data.utilizador.nome_completo
                    });
                }

                let tipo = response.data.tipo_trabalho.charAt(0).toUpperCase() + response.data.tipo_trabalho.slice(1).toLowerCase();
                let icon = require("../../images/icons/trabalho_individual.png");
                if (tipo === "Grupo")
                    icon = require("../../images/icons/trabalho_grupo.png")
                if (tipo === "Colaborativo")
                    icon = require("../../images/icons/trabalho_colaborativo.png")
                this.props.carregarInfo(response.data.nome, response.data.objetivos, icon, moment(response.data.dataHora).format("DD/MM/YYYY HH:mm"))

                this.setState({
                    utilizadorId: response.data.utilizador.id + "",
                    nome: response.data.nome,
                    objetivos: response.data.objetivos,
                    tipo,
                    dt_inicio: response.data.dt_inicio ? moment(response.data.dt_inicio).format("DD/MM/YYYY") : "",
                    dt_fim: response.data.dt_fim ? moment(response.data.dt_fim).format("DD/MM/YYYY") : "",
                    enunciado: response.data.recurso,
                    enunciado_nome: response.data.recurso_nome,
                    descricao: response.data.descricao,
                    participantes,
                    listaProcurarParticipantes,
                    //ficheiros,
                    observacoes: response.data.observacoes ? response.data.observacoes : "",
                    numero_elementos: response.data.numero_elementos,
                    concluido: response.data.concluido,
                    dt_concluido: response.data.dt_concluido,
                    disponivel,
                    loading: false
                });
            })
            .catch(error => {
                //if (error.response.data === "SEM_PERMISSAO")
                //    this.setState({
                //        redirect: true,
                //        redirectLink: "/user"
                //    });
                //else 
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                this.props.marcarComoNaoDisponivel();
            });
    };

    //PROCURA OS UTILIZADORES DA ENTIDADE COM BASE NO INSERT DO UTILIZADOR
    procurarParticipantes = value => {
        if (this.state.participantes.length < this.state.numero_elementos)
            if (value.length > 3)
                this.setState(
                    {
                        listaParticipantes: [],
                        procurar: true
                    },
                    () => {
                        axios({
                            method: "get",
                            url: "/api/player-trabalho/procurar-utilizador",
                            params: {
                                nome: value,
                                cursoid: this.state.cursoid,
                                aulaid: this.state.aulaid
                            }
                        })
                            .then(response => {
                                this.setState({
                                    listaParticipantes: response.data,
                                    procurar: false
                                });
                            })
                            .catch(error => {
                                openNotificationWithIcon("error", "Erro", "Não foi possível pesquisar!");
                            });
                    }
                );
    };

    //SELECIONA O UTILIZADOR
    checkParticipantes = value => {
        var participantes = [];
        for (var i = 0; i < value.length; i++) {
            participantes.push(value[i].key);
        }

        this.setState({
            participantes,
            listaProcurarParticipantes: value,
            listaParticipantes: []
        });
    };

    //SUBMETE O TRABALHO PARA AVALIACAO
    submeter = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (this.state.participantes.filter(x => x === this.state.utilizadorId).length > 0) {
                    confirm({
                        title: "Submeter trabalho para avaliação",
                        content: "Poderá submeter nova versão até à data final.",
                        okText: "Confirmar",
                        okType: "Cancelar",
                        onOk: () => {
                            this.setState({ iconLoading: true });

                            var item = new FormData();
                            item.append("cursoid", this.state.cursoid);
                            item.append("aulaid", this.state.aulaid);
                            item.append("tipo", this.state.tipo);
                            item.append("participantes", JSON.stringify(this.state.participantes));
                            item.append("observacoes", this.state.observacoes);
                            item.append("conc", this.state.observacoes);
                            this.state.fileList.forEach(file => {
                                item.append(file.uid, file);
                            });

                            axios({
                                method: "post",
                                url: "/api/player-trabalho/submeter-trabalho",
                                data: item
                            })
                                .then(() => {
                                    openNotificationWithIcon("success", "Sucesso", "Trabalho submetido!");
                                    //this.context.concluirAula(this.state.aulaid);
                                    this.props.desbloquearRating();
                                    this.context.atualizarListaModulos();
                                    this.setState({
                                        //concluido: true,
                                        iconLoading: false,
                                        fileList: [],
                                        dt_concluido: moment()
                                    });
                                })
                                .catch(error => {
                                    openNotificationWithIcon("error", "Erro", error.response.data);
                                    this.setState({ iconLoading: false });
                                });
                        }
                    });
                }
                else {
                    confirm({
                        title: "Pretende sair do grupo?",
                        okText: "Confirmar",
                        okType: "Cancelar",
                        onOk: () => {
                            axios({
                                method: "post",
                                url: "/api/player-trabalho/sair-grupo",
                                params: {
                                    aulaid: this.props.match.params.aulaid
                                }
                            })
                                .then(response => {
                                    openNotificationWithIcon("success", "Sucesso", "Saiu do grupo!");
                                    this.setState({
                                        iconLoading: false
                                    });
                                    window.location.reload();
                                })
                                .catch(error => {
                                    openNotificationWithIcon("error", "Erro", "Não foi possível sair do grupo!");
                                });
                        },
                        onCancel: () => {
                            this.setState({
                                iconLoading: false
                            });
                            window.location.reload();
                        }
                    });
                }
            }
        });
    };

    //GUARDA AS ALTERACOES DO TRABALHO MAS NAO FICA PARA AVALIACAO
    guardar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });
                if (this.state.participantes.filter(x => x === this.state.utilizadorId).length > 0) {
                    var item = new FormData();
                    item.append("cursoid", this.state.cursoid);
                    item.append("aulaid", this.state.aulaid);
                    item.append("tipo", this.state.tipo);
                    item.append("participantes", JSON.stringify(this.state.participantes));
                    item.append("observacoes", this.state.observacoes);
                    this.state.fileList.forEach(file => {
                        item.append(file.uid, file);
                    });

                    axios({
                        method: "post",
                        url: "/api/player-trabalho/guardar-trabalho",
                        data: item
                    })
                        .then(() => {
                            openNotificationWithIcon("success", "Sucesso", "Trabalho guardado!");
                            this.setState({
                                iconLoading: false
                            });
                            this.CarregarConteudo(this.state.aulaid);
                        })
                        .catch(error => {
                            openNotificationWithIcon("error", "Erro", error.response.data);
                            this.setState({ iconLoading: false });
                        });
                }
                else {
                    confirm({
                        title: "Pretende sair do grupo?",
                        okText: "Confirmar",
                        okType: "Cancelar",
                        onOk: () => {
                            axios({
                                method: "post",
                                url: "/api/player-trabalho/sair-grupo",
                                params: {
                                    aulaid: this.props.match.params.aulaid
                                }
                            })
                                .then(response => {
                                    openNotificationWithIcon("success", "Sucesso", "Saiu do grupo!");
                                    this.setState({
                                        iconLoading: false
                                    });
                                    window.location.reload();
                                })
                                .catch(error => {
                                    openNotificationWithIcon("error", "Erro", "Não foi possível sair do grupo!");
                                });
                        },
                        onCancel: () => {
                            this.setState({
                                iconLoading: false
                            });
                            window.location.reload();
                        }
                    });
                }
            }
        });
    };

    //SUBMETE O TRABALHO PARA AVALIACAO
    submeterIndividual = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                confirm({
                    title: "Submeter trabalho para avaliação",
                    content: "Poderá submeter nova versão até à data final.",
                    okText: "Confirmar",
                    okType: "Cancelar",
                    onOk: () => {
                        this.setState({ iconLoading: true });

                        var item = new FormData();
                        item.append("cursoid", this.state.cursoid);
                        item.append("aulaid", this.state.aulaid);
                        item.append("tipo", this.state.tipo);
                        item.append("participantes", JSON.stringify(this.state.participantes));
                        item.append("observacoes", this.state.observacoes);
                        item.append("conc", this.state.observacoes);
                        this.state.fileList.forEach(file => {
                            item.append(file.uid, file);
                        });

                        axios({
                            method: "post",
                            url: "/api/player-trabalho/submeter-trabalho",
                            data: item
                        })
                            .then(() => {
                                openNotificationWithIcon("success", "Sucesso", "Trabalho submetido!");
                                this.context.atualizarListaModulos();
                                this.props.desbloquearRating();
                                this.setState({
                                    //concluido: true,
                                    iconLoading: false,
                                    fileList: []
                                });
                            })
                            .catch(error => {
                                openNotificationWithIcon("error", "Erro", error.response.data);
                                this.setState({ iconLoading: false });
                            });
                    }
                });
            }
        });
    };

    //GUARDA AS ALTERACOES DO TRABALHO MAS NAO FICA PARA AVALIACAO
    guardarIndividual = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });
                var item = new FormData();
                item.append("cursoid", this.state.cursoid);
                item.append("aulaid", this.state.aulaid);
                item.append("tipo", this.state.tipo);
                item.append("participantes", JSON.stringify(this.state.participantes));
                item.append("observacoes", this.state.observacoes);
                this.state.fileList.forEach(file => {
                    item.append(file.uid, file);
                });

                axios({
                    method: "post",
                    url: "/api/player-trabalho/guardar-trabalho",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Trabalho guardado!");
                        this.setState({
                            iconLoading: false
                        });
                        this.CarregarConteudo(this.state.aulaid);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoFicheiro = formato => {
        var formatos = ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //LEITOR DE PDF
    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    scaleDown = () => {
        if (this.state.scale >= 0.8) {
            this.setState({ scale: this.state.scale - 0.3 });
        }
    };

    scaleUp = () => {
        if (this.state.scale <= 1.8) {
            this.setState({ scale: this.state.scale + 0.3 });
        }
    };

    downloadFicheiro = (anexo, nome) => {
        const link = document.createElement('a');
        link.href = anexo;
        link.setAttribute(
            'download',
            nome,
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
    }

    render() {
        const { Panel } = Collapse;
        const { getFieldDecorator } = this.props.form;
        const { Option } = Select;
        const { TextArea } = Input;
        const {
            nome,
            objetivos,
            tipo,
            dt_inicio,
            dt_fim,
            enunciado,
            enunciado_nome,
            descricao,
            observacoes,
            listaParticipantes,
            listaProcurarParticipantes,
            utilizador,
            fileList,
            procurar,
            concluido,
            dt_concluido,
            //concluido,
            //LOADING
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink,
            //LOADING
            loading
        } = this.state;

        const propsUpload = {
            multiple: false,
            showUploadList: false,
            onChange: info => {
                var ficheiros = this.state.ficheiros;

                ficheiros.push({
                    key: ficheiros.length,
                    id: 0,
                    uid: info.file.uid,
                    nome: info.file.name,
                    data: moment().format("DD/MM/YYYY"),
                    autor: utilizador.nome_completo,
                    opcoes: (
                        <Dropdown
                            overlay={this.montarMenu(URL.createObjectURL(info.file), info.file.name, ficheiros.length, info.file.uid, true)}
                            placement="bottomLeft"
                            onClick={event => {
                                event.stopPropagation();
                            }}
                        >
                            <Link to="#" className="botao-icon-configs">
                                <i className="fas fa-cog" />
                            </Link>
                        </Dropdown>
                    ),
                    doc: (
                        <a href={URL.createObjectURL(info.file)} download={info.file.name}>
                            Descarregar
                        </a>
                    )
                });
            },
            beforeUpload: file => {
                if (this.validarFormatoFicheiro(file.name.split(".").pop().toLowerCase())) {
                    this.setState(state => ({
                        fileList: [...state.fileList, file]
                    }));
                }
                return false;
            },
            fileList
        };

        const montar_botoes = tipo => {
            if (tipo === "Individual") {
                return (
                    <>
                        <button className="botao-principal botao-submeter-trabalho" type="primary" disabled={iconLoading} onClick={this.submeterIndividual}>
                            {iconLoading ? <Icon type="loading" /> : null}
                                        Submeter
                                    </button>
                        <button className="botao-secundario botao-guardar-trabalho" type="primary" disabled={iconLoading} onClick={this.guardarIndividual}>
                            {iconLoading ? <Icon type="loading" /> : null}
                                        Guardar
                                    </button>
                        {dt_concluido ?
                            <span className="player-trabalho-nota">Trabalho submetido a {moment(dt_concluido).format("DD/MM/YYYY")}. Pode voltar a submeter até {dt_fim}</span> : null}
                    </>
                )
            }
            else if (tipo === "Grupo") {
                return (
                    <>
                        <button className="botao-principal botao-submeter-trabalho" type="primary" disabled={iconLoading} onClick={this.submeter}>
                            {iconLoading ? <Icon type="loading" /> : null}
                                        Submeter
                                    </button>
                        <button className="botao-secundario botao-guardar-trabalho" type="primary" disabled={iconLoading} onClick={this.guardar}>
                            {iconLoading ? <Icon type="loading" /> : null}
                                        Guardar
                                    </button>
                        {dt_concluido ?
                            <span className="player-trabalho-nota">Trabalho submetido a {moment(dt_concluido).format("DD/MM/YYYY")}. Pode voltar a submeter até {dt_fim}</span> : null}
                    </>
                )
            }
            else {
                return (
                    <button className="botao-secundario botao-submeter-trabalho" type="primary" disabled={iconLoading} onClick={this.guardar}>
                        {iconLoading ? <Icon type="loading" /> : null}
                                        Guardar
                    </button>
                )
            }
        }

        if (redirect) return <Redirect to={redirectLink} />;

        return loading ? (
            <div className="loading-data">
                <div className="loading" />
            </div>
        ) : (
                <div className="container">
                    <div className="trabalho-intro bloco-player-template">
                        <div className="bloco-info-tarefa">
                            <span className="icon-tarefa">
                                {tipo === "Individual" ?
                                    <img src={require("../../images/icons/trabalho_individual.png")} title="Trabalho individual"/>
                                    : null}
                                {tipo === "Grupo" ?
                                    <img src={require("../../images/icons/trabalho_grupo.png")} title="Trabalho de grupo"/>
                                    : null}
                                {tipo === "Colaborativo" ?
                                    <img src={require("../../images/icons/trabalho_colaborativo.png")} title="Trabalho colaborativo"/>
                                    : null}
                            </span>
                            <span className="titulo-tarefa">{nome}</span>
                        </div>
                        <p className="descricao-tarefa" dangerouslySetInnerHTML={{ __html: objetivos?.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></p>
                        {dt_inicio && dt_fim && (
                            <div className="item">
                                Período de realização:
                                <span className="label-conteudo">
                                    {dt_inicio} a {dt_fim}
                                </span>
                            </div>
                        )}
                        {dt_fim && !dt_inicio && (
                            <div className="item">
                                Entrega até:<span className="label-conteudo">{dt_fim}</span>
                            </div>
                        )}
                        <div className="item">
                            Enunciado:
                            <span className="label-conteudo" dangerouslySetInnerHTML={{ __html: descricao.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></span>
                        </div>
                        {enunciado &&
                            <>
                                <div className="item bloco-enunciado-leitor">
                                    {enunciado_nome.split(".").pop() === "pdf" ?
                                        <Collapse
                                            defaultActiveKey={['1']}
                                            expandIcon={({ isActive }) =>
                                                <div className="bloco-links-ficheiro">
                                                    <span className="bloco-download">
                                                        <Link to="#" className="botao-icon-download" title="Descarregar" onClick={event => {
                                                            event.stopPropagation();
                                                            this.downloadFicheiro(enunciado, enunciado_nome);
                                                        }}>
                                                            <Icon type="download" />
                                                        </Link>
                                                    </span>
                                                    <span className="bloco-links-img-container">
                                                        <img src={require("../../images/icons/pdf.png")} />
                                                    </span>
                                                    <span className="bloco-links-texto">{isActive ? "Fechar" : "Abrir"}</span>
                                                </div>
                                            }
                                        >
                                            <Panel key="1">
                                                <Document file={enunciado} onLoadSuccess={this.onDocumentLoadSuccess}>
                                                    <Page
                                                        pageNumber={this.state.pageNumber}
                                                        scale={this.state.scale}
                                                        rotate={this.state.rotate}
                                                    />
                                                </Document>
                                                <div className="controlos-pdf">
                                                    <button onClick={() => this.setState(prevState => ({ pageNumber: (prevState.pageNumber > this.state.numPages ? prevState.pageNumber - 1 : 1) }))}>
                                                        <Icon type="caret-left" />
                                                    </button>
                                                    <span>
                                                        {this.state.pageNumber}/{this.state.numPages}
                                                    </span>
                                                    <button onClick={() => this.setState(prevState => ({ pageNumber: (prevState.pageNumber < this.state.numPages ? prevState.pageNumber + 1 : this.state.numPages) }))}>
                                                        <Icon type="caret-right" />
                                                    </button>
                                                    <button onClick={this.scaleUp}>
                                                        <Icon type="zoom-in" />
                                                    </button>
                                                    <button onClick={this.scaleDown}>
                                                        <Icon type="zoom-out" />
                                                    </button>
                                                    <button onClick={() => this.setState({ rotate: this.state.rotate === 180 ? 180 : this.state.rotate + 90 })}>
                                                        <Icon type="reload" />
                                                    </button>
                                                    <button onClick={() => this.setState({ rotate: this.state.rotate === -180 ? -180 : this.state.rotate - 90 })}>
                                                        <Icon type="reload" style={{ transform: "scaleX(-1) " }} />
                                                    </button>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                        :
                                        <Collapse
                                            defaultActiveKey={['1']}
                                            expandIcon={({ isActive }) =>
                                                <div className="bloco-links-ficheiro">
                                                    <span className="bloco-download">
                                                        <Link to="#" className="botao-icon-download" title="Descarregar" onClick={event => {
                                                            event.stopPropagation();
                                                            this.downloadFicheiro(enunciado, enunciado_nome);
                                                        }}>
                                                            <Icon type="download" />
                                                        </Link>
                                                    </span>
                                                    <span className="bloco-links-img-container">
                                                        <img src={require("../../images/icons/images.png")} />
                                                    </span>
                                                    <span className="bloco-links-texto">{isActive ? "Fechar" : "Abrir"}</span>
                                                </div>
                                            }
                                        >
                                            <Panel key="1">
                                                <img src={enunciado} style={{ width: "100%" }} />
                                            </Panel>
                                        </Collapse>
                                    }
                                </div>
                            </>
                        }
                    </div>
                    <div className="bloco-trabalho">
                        <div className="bloco-info">
                            <Form className="form-aula" layout="horizontal">
                                {tipo !== "Individual" && (
                                    <Form.Item
                                        label={`Participantes:${this.state.numero_elementos}`}
                                    >
                                        {getFieldDecorator("participantes", {
                                            initialValue: listaProcurarParticipantes,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(
                                            <Select
                                                mode="multiple"
                                                labelInValue
                                                style={{ width: "100%" }}
                                                placeholder="Selecionar"
                                                filterOption={false}
                                                notFoundContent={procurar ? <Spin size="small" /> : null}
                                                onSearch={this.procurarParticipantes}
                                                onChange={this.checkParticipantes}
                                                disabled={concluido || !this.state.disponivel}
                                            >
                                                {listaParticipantes.map(participante => (
                                                    <Option key={participante.id}>{participante.nome_completo}</Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                )}
                                <Form.Item label="Observações">
                                    <TextArea rows={5} value={observacoes} disabled={this.state.tem_comandos || !this.state.disponivel} onChange={this.handleChange} />
                                </Form.Item>
                                <Form.Item label="Anexos">
                                    <Ficheiros
                                        participantes={this.state.participantes}
                                        tipo={this.state.tipo}
                                        disponivel={this.state.disponivel}
                                    />
                                </Form.Item>
                            </Form>
                            {(!this.state.tem_comandos && this.state.disponivel) &&
                                <div className="controlos">
                                    {montar_botoes(tipo)}
                                </div>}
                        </div>
                    </div>
                </div>
            );
    }
}
const ConteudoTrabalhoForm = Form.create({ name: "form-trabalho" })(ConteudoTrabalho);

export default withRouter(ConteudoTrabalhoForm);
