import React, { useState, useContext, useEffect } from "react";
import { Link, matchPath, withRouter } from "react-router-dom";
import { Icon, notification } from "antd";
import { GlobalContext } from "../../GlobalState";

import ModalViewer from "../../Modals/ModalViewer";
import ModalLoading from "../../Modals/ModalLoading";

import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        iconLoading: false
    });
};

const Menu = (props) => {
    const [planoEstudo, setPlanoEstudo] = useState("");
    const [docs, setDocs] = useState([]);
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const { entidade, turma, id } = props.match.params;
    const context = useContext(GlobalContext);

    const gerarDoc = () => {
        if (menuOpcaoAtivo === "visao-geral") {
            setDocs([{ uri: planoEstudo.ficheiro }]);
            setModalVisible(true)
        }
        else {
            gerarHorario();
        }
    }

    const carregarPlanoEstudo = () => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/carregar-plano-estudo",
            params: {
                turmaId: match.params.id
            }
        })
            .then(response => {
                setPlanoEstudo(response.data);
            })
            .catch(error => {

            });
    };

    const gerarHorario = () => {
        setVisibleLoading(true);
        if (localStorage.getItem("vista_calendario") === 'month')
            axios({
                method: "get",
                url: "/api/pdf/calendario-mes",
                params: {
                    cursoId: props.match.params.id,
                    inicio: context.dataInicialMes
                }
            })
                .then(response => {
                    setDocs([{ uri: response.data }]);
                    setVisibleLoading(false);
                    setModalVisible(true)
                })
                .catch(() => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível gerar documento!");
                    setVisibleLoading(true);
                });
        else
            axios({
                method: "get",
                url: "/api/pdf/horarios-pp",
                params: {
                    cursoId: props.match.params.id,
                    inicio: context.horario.inicio,
                    termino: context.horario.termino
                }
            })
                .then(response => {
                    setDocs([{ uri: response.data }]);
                    setVisibleLoading(false);
                    setModalVisible(true)
                })
                .catch(() => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível gerar documento!");
                    setVisibleLoading(true);
                });
    }

    var inicioUrl = `/${entidade}/turma/${turma}/${id}`;

    const match = matchPath(window.location.pathname, {
        path: "/:entidade/turma/:turma/:id/:pagina"
    });

    let menuOpcaoAtivo = "";
    if (match && match.params.pagina)
        menuOpcaoAtivo = match.params.pagina;

    useEffect(() => {
        if (menuOpcaoAtivo === "visao-geral")
            carregarPlanoEstudo();
    }, [menuOpcaoAtivo])

    return (
        <>
            <div className="menu-detalhe-curso menu-detalhe-curso-v2 menu-detalhe-curso-pp">
                <div className="container">
                    <ul>
                        <span>
                            <li>
                                <Link to={`${inicioUrl}/visao-geral`}
                                    className={menuOpcaoAtivo === "visao-geral" ? "ativo" : ""}
                                >
                                    Visão Geral
                                </Link>
                            </li>
                            <li>
                                <Link to={`${inicioUrl}/horarios`} className={menuOpcaoAtivo === "horarios" ? "ativo" : ""}>
                                    Horário
                                </Link>
                            </li>
                        </span>
                        {(planoEstudo.titulo && menuOpcaoAtivo === "visao-geral" || menuOpcaoAtivo === "horarios") &&
                            <span className="menu-opcoes">
                                <Link className="botao-icon-detalhe botao-maior" to="#" title="Visualizar" onClick={gerarDoc}>
                                    <Icon type="eye" />
                                </Link>
                            </span>}
                    </ul>
                </div>
            </div>
            <ModalLoading
                visible={visibleLoading}
                text="A gerar documento..."
            />
            <ModalViewer
                visible={modalVisible}
                docs={docs}
                onCancel={() => setModalVisible(false)}
            />
        </>
    );
}

export default withRouter(Menu);
