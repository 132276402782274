import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Select, Form, Checkbox, notification, Icon, Input } from "antd";
import axios from "axios";
import moment from "moment";
import noimage from "../../../../images/noimage.png";
import DrawerDetalhe from "./DrawerDetalhe";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class InfoAluno extends Component {
    state = {
        //DRAWER PRESENÇAS
        dt_inicio: "",
        presenca: "",
        participante: "",
        loadingParticipantes: false,
        //LOADING
        iconLoading: false,
        //DRAWER DETALHE
        visivel: false,
        info: ""
    };

    componentWillReceiveProps = nextProps => {
        if (nextProps.participanteId !== this.props.participanteId) {
            this.listar();
        }
    };

    componentDidMount() {
        this.listar();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeParticipante = (event, participanteId) => {
        var presencas = this.state.presenca;

        presencas.observacoes = event.target.value;

        this.setState({ presencas });
    };

    handlerChangeSelect = value => {
        var presenca = this.state.presenca;
        presenca.estado = value;

        this.setState({
            presenca
        });
    };

    listar = () => {
        this.setState(
            {
                loadingParticipantes: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas/detalhe-assiduidade-individual",
                    timeout: 60 * 10 * 1000,
                    params: {
                        aulaId: this.props.aulaId,
                        userId: this.props.participanteId
                    }
                })
                    .then(response => {
                        let registo = response.data;
                        let participante = registo.participante;
                        let presenca = {
                            id: participante.id,
                            estado: undefined,
                            observacoes: ""
                        };

                        if (registo.presenca) presenca.estado = "P";
                        else if (registo.falta) presenca.estado = "F";
                        else {
                            if (response.data.presencas) {
                                let presencas = JSON.parse(response.data.presencas);
                                const temp = presencas.find(x => x.id === participante.id);
                                if (temp) presenca = temp;
                            }
                        }

                        this.setState({
                            dt_inicio: response.data.dt_inicio,
                            presenca,
                            participante,
                            loadingParticipantes: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    adicionarDetalhe = (id, detalhe) => {
        var presenca = this.state.presenca;
        presenca.tipo = detalhe.tipo;
        presenca.ficheiro = detalhe.ficheiro;
        presenca.variosDias = detalhe.variosDias;
        presenca.dataInicio = detalhe.dataInicio;
        presenca.dataFim = detalhe.dataFim;

        this.setState({
            presenca,
            visivel: false
        });
    };

    guardarPresencas = () => {
        this.setState({ iconLoading: true });

        var item = new FormData();
        item.append("id", this.props.aulaId);
        item.append("presenca", JSON.stringify(this.state.presenca));

        axios({
            method: "put",
            url: "/api/aulas/alterar-assiduidade-individual",
            data: item,
            timeout: 60 * 30 * 1000
        })
            .then(() => {
                openNotificationWithIcon("success", "Sucesso", "Assiduidade guardada!");
                this.setState({ iconLoading: false });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi guardar assiduidade!");
                this.setState({ iconLoading: false });
            });
    };

    render() {
        const { TextArea } = Input;
        const {
            //DRAWER PRESENÇAS
            participante,
            presenca,
            //LOADING
            iconLoading,
            //DRAWER DETALHE
            visivel,
            info
        } = this.state;

        return (
            <>
                <div className="bloco-presencial">
                    <div className="bloco-frequencia-diario" style={{ boxShadow: "none" }}>
                        <div className="listagem-participantes" style={{ marginTop: 0 }}>
                            <Form className="form-presencas" layout="horizontal">
                                <div className="bloco">
                                    <Form.Item>
                                        <div className="listagem-presencas">
                                            <div key={presenca.id} className="item-presenca">
                                                <div className="header">
                                                    <div className="participante">
                                                        <div className="info-participante">
                                                            <div className="container-img">
                                                                <img src={participante.foto ? participante.foto : noimage} />
                                                            </div>
                                                            <div className="info">
                                                                <span className="nome">{participante.nome}</span>
                                                                <p className="numero">{participante.numero}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="opcao">
                                                        <div className="bloco-opcoes">
                                                            <button
                                                                className={`btn-presenca ${presenca.estado === "P" ? "ativo" : ""}`}
                                                                onClick={() => this.handlerChangeSelect("P", presenca.id)}
                                                            >
                                                                P
                                                            </button>
                                                            <button
                                                                className={`btn-falta ${presenca.estado === "F" ? "ativo" : ""}`}
                                                                onClick={() => this.handlerChangeSelect("F", presenca.id)}
                                                            >
                                                                F
                                                            </button>
                                                            <button
                                                                className={`btn-justificada ${presenca.estado === "FJ" ? "ativo" : ""}`}
                                                                onClick={() => this.handlerChangeSelect("FJ", presenca.id)}
                                                            >
                                                                FJ
                                                            </button>
                                                        </div>
                                                        {presenca.estado === "FJ" && (
                                                            <Form.Item>
                                                                <Link
                                                                    to="#"
                                                                    className="link-detalhe"
                                                                    onClick={() => this.setState({ visivel: true, info: presenca })}
                                                                >
                                                                    <Icon type="plus" />
                                                                    {!presenca.tipo ? "Adicionar" : "Alterar"} detalhe
                                                                </Link>
                                                            </Form.Item>
                                                        )}
                                                        {presenca.pendente && (
                                                            <Form.Item>
                                                                <Checkbox
                                                                    name="aplicar"
                                                                    checked={presenca.confirmada}
                                                                    onChange={e => this.handlerChangeCheckbox(e, presenca.id)}
                                                                >
                                                                    Confirmar decisão
                                                                </Checkbox>
                                                            </Form.Item>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="body">
                                                    <Form.Item label="Observações">
                                                        <TextArea
                                                            name="observacoes"
                                                            rows={4}
                                                            style={{ height: 100 }}
                                                            value={presenca.observacoes}
                                                            onChange={e => this.handleChangeParticipante(e, presenca.id)}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Link className="botao-principal" to="#" disabled={iconLoading} onClick={this.guardarPresencas}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </Link>
                        </div>
                    </div>
                </div>
                <DrawerDetalhe
                    visivel={visivel}
                    info={info}
                    onClose={() => this.setState({ visivel: false })}
                    adicionarDetalhe={this.adicionarDetalhe}
                />
            </>
        );
    }
}

const FormInfoAluno = Form.create({ name: "form-info-aluno" })(InfoAluno);

export default FormInfoAluno;
