import React, { Component } from "react"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import Header from "./Header"
import Menu from "./Menu"
import VisaoGeral from "./VisaoGeral/VisaoGeral"
import Modulos from "./Modulos/Modulos"
import Diario from "./Diario/Main"
import FrequenciaDiaria from "./Diario/Frequencia/Diaria";
import FrequenciaPorPeriodo from "./Diario/Frequencia/PorPeriodo";
import FasesEscolares from "./Diario/FasesEscolares/FasesEscolares";
import Detalhe from "./Diario/Detalhe"
import Notas from "./Diario/Notas"
import NotasPre from "./Diario/NotasPre"
import NotasRegular from "./Diario/NotasRegular"
import FaseEscolar from "./Diario/FaseEscolar"
import ListaNotas from "./Diario/ListaNotas"
import ListaNotasConceito from "./Diario/ListaNotasConceito"
import ListaAlunos from "./Diario/ListaAlunos"
import ExcelNotasPre from "./Diario/ExcelNotasPre"
import ExcelNotasCreche from "./Diario/ExcelNotasCreche"
import ExcelNotasRegular from "./Diario/ExcelNotasRegular"
import ListaFrequencia from "./Diario/ListaFrequencia"
import ListaObservacoes from "./Diario/ListaObservacoes"
import ListaPareceres from "./Diario/ListaPareceres"
import ListaConteudos from "./Diario/ListaConteudos"
/*import DetalheNotasPre from "./Diario/DetalheNotasPre"*/
import Matriz from "./Matriz"
import Forum from "./Foruns/Forum"
import ForumDetalhe from "./Foruns/ForumDetalhe"
import Anotacoes from "./Anotacoes/Anotacoes"
import AnotacoesDetalhe from "./Anotacoes/AnotacoesDetalhe"
import Ficheiros from "./Ficheiros/Ficheiros"
import Participantes from "./Participantes/Participantes"
import Videoconferencias from "./Videoconferencias/Main"
import Resultados from "./Resultados/Main";
import Ranking from "./Resultados/Ranking";
import { GlobalContext } from "../GlobalState"
import ContextProvider from "./ContextProvider"
import axios from "axios"

class CursoLayout extends Component {
    static contextType = GlobalContext
    state = {
        //HEADER
        conteudoHeader: true,
        //MENU
        menuSemHeader: true,
        matriz: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    }
    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000
        this.temComandos(localStorage.getItem("codigo_curso"))
    }

    componentDidUpdate() {
        if (!this.context.is_authenticated || this.context.role === "UTILIZADOR") {
            if (!this.context.is_authenticated)
                this.setState({
                    redirect: true,
                    redirectLink: "/login"
                })
            else if (this.context.role === "UTILIZADOR")
                this.setState({
                    redirect: true,
                    redirectLink: "/user"
                })
        }
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.match.params.curso !== this.props.match.params.curso) {
            this.temComandos(localStorage.getItem("codigo_curso"))
        }
    }

    //VALIDA SE O UTILIZADOR LOGGEDIN TEM ACESSO AOS CONTROLOS CRUD DA PAGINA
    temComandos = cursoId => {
        axios({
            method: "get",
            url: "/api/autenticacao/tem-comandos",
            params: {
                cursoId
            }
        })
            .then(response => {
                localStorage.setItem("tem_comandos", response.data)
            })
            .catch(() => { })
    }

    render() {
        const {
            header,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state

        if (redirect) return <Redirect to={redirectLink} />

        return (
            <>
                <ContextProvider>
                    <Header matriz={this.state.matriz} state={this.state} />
                    <Menu matriz={this.state.matriz} state={this.state} />
                    <Switch>
                        <Route path="/gerir-curso/:curso/curso-visao-geral" component={VisaoGeral} />
                        <Route path="/gerir-curso/:curso/curso-modulos" component={Modulos} />
                        {/*<Route path="/gerir-curso/:curso/curso-diario" component={Diario} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-frequencia-diaria" component={FrequenciaDiaria} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-frequencia-por-periodo" component={FrequenciaPorPeriodo} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-fases-escolares" component={FasesEscolares} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-detalhe" component={Detalhe} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-notas" component={Notas} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-notas-pre" component={NotasRegular} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-notas-regular" component={NotasRegular} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-folha-notas" component={ListaNotas} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-folha-conceito" component={ListaNotasConceito} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-lista-alunos" component={ListaAlunos} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-folha-notas-pre" component={ExcelNotasPre} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-folha-notas-creche" component={ExcelNotasCreche} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-folha-notas-regular" component={ExcelNotasRegular} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-folha-notas-creche" component={ExcelNotasCreche} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-folha-frequencia" component={ListaFrequencia} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-folha-observacoes" component={ListaObservacoes} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-folha-pareceres" component={ListaPareceres} />*/}
                        {/*<Route path="/gerir-curso/:curso/curso-diario-folha-conteudos" component={ListaConteudos} />*/}
                        <Route path="/gerir-curso/:curso/curso-cursos" component={Matriz} />
                        <Route path="/gerir-curso/:curso/curso-forum" component={Forum} />
                        <Route path="/gerir-curso/:curso/curso-forum-detalhe" component={ForumDetalhe} />
                        <Route path="/gerir-curso/:curso/curso-anotacoes" component={Anotacoes} />
                        <Route path="/gerir-curso/:curso/curso-anotacoes-detalhe" component={AnotacoesDetalhe} />
                        <Route path="/gerir-curso/:curso/curso-ficheiros" component={Ficheiros} />
                        <Route path="/gerir-curso/:curso/curso-videoconferencias" component={Videoconferencias} />
                        <Route path="/gerir-curso/:curso/curso-resultados" component={Resultados} />
                        <Route path="/gerir-curso/:curso/curso-ranking" component={Ranking} />
                        <Route path="/gerir-curso/:curso/curso-participantes" component={Participantes} />
                        {/*<Route path="/gerir-curso/:curso/:fase" component={FaseEscolar} />*/}
                    </Switch>
                </ContextProvider>
            </>
        )
    }
}

export default CursoLayout
