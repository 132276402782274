import React, { useState, useEffect, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import { Icon, Table, notification, Modal, Select } from "antd";
import axios from "axios";
import moment from "moment";
import ModalLoading from "../../../Modals/ModalLoading";
import ModalViewer from "../../../Modals/ModalViewer";
import ModalAta from "./Drawer/Ata";
import ModalEmissao from "./Drawer/EmissaoAtaClasse";

import Acessos from "../../../Geral/Acessos/Acessos";
import Item from "antd/lib/list/Item";

import { GlobalContext } from "../../../GlobalState";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};
const { Option } = Select;

const Relatorios = ({ match }) => {
    const [parametros, setParametros] = useState([]);
    const [estruturaId, setEstruturaId] = useState(undefined);
    const [alunos, setAlunos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    const [isEnsinoInfantil, setIsEnsinoInfantil] = useState(0);
    const [loadingText, setLoadingText] = useState("");
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [docs, setDocs] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [visibleExportar, setVisibleExportar] = useState(false);
    const [btnDescarregar, setBtnDescarregar] = useState(false);
    const [ficheiroExportar, setFicheiroExportar] = useState("");
    const [ataVisible, setAtaVisible] = useState(false);
    const [ataClasseVisible, setAtaClasseVisible] = useState(false);
    const [ataIndividualAgrupEmMassaVisible, setAtaIndividualAgrupEmMassaVisible] = useState(false);
    const [fichaDescritivaEmMassaVisible, setFichaDescritivaEmMassaVisible] = useState(false);
    const [fichaExcel, setFichaExcel] = useState(false);
    const [ataAgrupVisible, setAtaAgrupVisible] = useState(false);
    const [individualInfo, setIndividualInfo] = useState({
        visible: false,
        code: ""
    });
    const [individualInfoAgrup, setIndividualInfoAgrup] = useState({
        visible: false,
        code: ""
    });
    const [descritivaInfo, setDescritivaInfo] = useState({
        visible: false,
        code: ""
    });
    const [existeFicha, setExisteFicha] = useState(false);
    const [existeAtaClasse, setExisteAtaClasse] = useState(false);

    const context = useContext(GlobalContext);

    const listar = estruturaId => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/aproveitamento/listar-alunos",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                //let alunos = [];

                response.data.map(item => {
                    alunos.push({
                        key: item.id,
                        aluno: `${item.codigo} - ${item.nome}`,
                        opcoes: (
                            <div className="documentos-opcoes" style={{ display: "flex", gap: 10 }}>
                                <Link
                                    to="#"
                                    title="Visualizar"
                                    className="botao-icon-detalhe"
                                    onClick={() =>
                                        setIndividualInfoAgrup({
                                            visible: true,
                                            code: item.code
                                        })
                                    }
                                >
                                    <Icon type="switcher" />
                                </Link>
                                <Link
                                    to="#"
                                    title="Visualizar"
                                    className={`botao-icon-detalhe ${!estruturaId ? "botao-disabled" : ""}`}
                                    disabled={!estruturaId}
                                    onClick={() =>
                                        setIndividualInfo({
                                            visible: true,
                                            code: item.code
                                        })
                                    }
                                >
                                    <Icon type="eye" />
                                </Link>
                            </div>
                        )
                    });
                });

                setLoading(false);
                setAlunos(response.data);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    };

    const listarParametros = () => {
        axios({
            method: "get",
            url: "/api/aproveitamento/listar-estruturas-avaliacao-turma",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setParametros(response.data);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }
            });
    };

    const validarExistenciaFichaDescritiva = () => {
        axios({
            method: "get",
            url: "/api/aproveitamento/validar-existencia-ficha-descritiva",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setExisteFicha(response.data);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }
            });
    };

    const validarExistenciaAtaClasse = () => {
        axios({
            method: "get",
            url: "/api/aproveitamento/validar-existencia-ata-classe",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setExisteAtaClasse(response.data);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }
            });
    };

    const validarFichaEnsinoInfantil = () => {
        axios({
            method: "get",
            url: "/api/aproveitamento/validar-ficha-ensino-infantil",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setIsEnsinoInfantil(response.data);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }
            });
    };

    const carregarFichaIndividualAgrupamento = (code, dataEmissao) => {
        setLoadingText("A carregar documento...");
        setVisibleLoading(true);

        axios({
            method: "get",
            url: "/api/pdf/ficha-anual-fundamental-agrupamento",
            params: {
                estruturaId: estruturaId,
                cursoId: localStorage.getItem("codigo_curso"),
                code,
                dataEmissao: dataEmissao.format("YYYY-MM-DD")
            }
        })
            .then(response => {
                setVisibleLoading(false);
                setDocs([{ uri: response.data }]);
                setModalVisible(true);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar documento!");
                setVisibleLoading(false);
            });
    };

    const carregarFichaAtaClasse = (periodoId, dataEmissao, horaEmissao) => {
        setLoadingText("A carregar documento...");
        setVisibleLoading(true);

        axios({
            method: "get",
            url: "/api/pdf/ata-classes",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                periodoId,
                dataEmissao: dataEmissao.format("YYYY-MM-DD"),
                horaEmissao: horaEmissao.format("HH:mm")
            }
        })
            .then(response => {
                setVisibleLoading(false);
                setDocs([{ uri: response.data }]);
                setModalVisible(true);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar documento!");
                setVisibleLoading(false);
            });
    };

    const carregarFichaIndividualAgrupamentoEmMassa = dataEmissao => {
        setVisibleExportar(true);
        setBtnDescarregar(true);
        axios({
            method: "get",
            url: "/api/pdf/ficha-anual-fundamental-agrupamento-em-massa",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                dataEmissao: dataEmissao.format("YYYY-MM-DD")
            }
        })
            .then(response => {
                setBtnDescarregar(false);
                setFicheiroExportar(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar documento!");

                setVisibleExportar(false);
            });
    };

    const carregarFichaDescritivaEmMassa = dataEmissao => {
        setVisibleExportar(true);
        setBtnDescarregar(true);
        axios({
            method: "get",
            url: "/api/pdf/ficha-descritiva-em-massa",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                dataEmissao: dataEmissao.format("YYYY-MM-DD")
            }
        })
            .then(response => {
                setBtnDescarregar(false);
                setFicheiroExportar(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar documento!");

                setVisibleExportar(false);
            });
    };

    const carregarFichaDescritiva = (code, dataEmissao) => {
        setLoadingText("A carregar documento...");
        setVisibleLoading(true);

        axios({
            method: "get",
            url: "/api/pdf/ficha-descritiva",
            params: {
                code,
                cursoId: localStorage.getItem("codigo_curso"),
                dataEmissao: dataEmissao.format("YYYY-MM-DD")
            }
        })
            .then(response => {
                setVisibleLoading(false);
                setDocs([{ uri: response.data }]);
                setModalVisible(true);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar documento!");

                setVisibleExportar(false);
            });
    };

    const ExportarExcel = () => {
        setVisibleExportar(true);
        setBtnDescarregar(true);
        axios({
            method: "get",
            url: "/api/excel/exportar-notas-excel",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setBtnDescarregar(false);
                setFicheiroExportar(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar documento!");

                setVisibleExportar(false);
            });
    };

    const carregarAtaAgrupamentoResultados = dataEmissao => {
        setLoadingText("A carregar documento...");
        setVisibleLoading(true);
        axios({
            method: "get",
            url: "/api/pdf/ata-notas-agrupamentos",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                estruturaId: estruturaId,
                dataEmissao: dataEmissao.format("YYYY-MM-DD")
            }
        })
            .then(response => {
                setVisibleLoading(false);
                setDocs([{ uri: response.data }]);
                setModalVisible(true);
                setAtaVisible(false);
            })
            .catch(error => {
                /*openNotificationWithIcon("error", "Erro", "Não foi possível carregar documento!");*/
                openNotificationWithIcon("error", "Erro", error.response.data);
                setVisibleLoading(false);
            });
    };

    const montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (
                <Link
                    to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar}
                    className="botao-secundario botao-secundario-redondo"
                    onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)}
                    style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                    <Icon type="arrow-left" />
                </Link>
            );
        }
    };

    useEffect(() => {
        listar();
        listarParametros();
        validarExistenciaFichaDescritiva();
        validarExistenciaAtaClasse();
        validarFichaEnsinoInfantil();
    }, []);

    const columns = [
        {
            title: "Aluno",
            dataIndex: "aluno"
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    if (redirect) return <Redirect to={redirectLink} />;

    const alunosTemp = alunos.map(item => {
        return {
            key: item.id,
            aluno: `${item.codigo} - ${item.nome}`,
            opcoes: (
                <div className="documentos-opcoes" style={{ display: "flex", gap: 10 }}>
                    {isEnsinoInfantil !== 236 && (
                        <Link
                            to="#"
                            title="Visualizar"
                            className="botao-icon-detalhe"
                            onClick={() =>
                                setIndividualInfoAgrup({
                                    visible: true,
                                    code: item.code
                                })
                            }
                        >
                            <Icon type="eye" />
                        </Link>
                    )}
                    {(context.id_entidade === 3 || context.id_entidade === 28 || context.id_entidade === 37) && existeFicha && (
                        <Link
                            to="#"
                            title="Visualizar"
                            className="botao-icon-enviar"
                            onClick={() =>
                                setDescritivaInfo({
                                    visible: true,
                                    code: item.code
                                })
                            }
                        >
                            <Icon type="eye" />
                        </Link>
                    )}
                </div>
            )
        };
    });

    const titulo =
        (context.id_entidade === 3 || context.id_entidade === 28 || context.id_entidade === 37) && existeFicha
            ? isEnsinoInfantil === 236
                ? "Fichas Descritivas"
                : "Fichas individuais & Fichas Descritivas"
            : "Fichas individuais";

    return (
        <>
            <Acessos funcionalidadeId={15} />
            <div className="container container-body">
                <div className="turma-visao-geral">
                    <div className="curso-resultados">
                        <div className="controlos controlos-fundo">
                            <div className="bloco-esquerda"></div>
                            <div className="bloco-direita">
                                <span style={{ fontSize: 18, fontWeight: "bold" }}>Ata de resultados finais:</span>
                                <Link to="#" className="botao-icon-excel" title="Excel" onClick={() => setFichaExcel(true)}>
                                    <Icon type="file-excel" />
                                </Link>
                                <Link to="#" className="botao-icon-detalhe" title="Visualizar" onClick={() => setAtaAgrupVisible(true)}>
                                    <Icon type="eye" />
                                </Link>
                                {montarBotaoVoltar()}
                            </div>
                        </div>
                    </div>
                    <div className="bloco">
                        <div className="controlos controlos-fundo">
                            <div className="bloco-esquerda">
                                <span style={{ fontSize: 22, fontWeight: "bold" }}>{titulo}</span>
                            </div>
                            <div className="bloco-direita" style={{ marginRight: "10px" }}>
                                {isEnsinoInfantil !== 236 && (
                                    <Link
                                        to="#"
                                        className="botao-icon-detalhe"
                                        style={{ display: "flex", gap: 10 }}
                                        title="Visualizar"
                                        onClick={() => setAtaIndividualAgrupEmMassaVisible(true)}
                                    >
                                        <Icon type="eye" />
                                    </Link>
                                )}
                                {(isEnsinoInfantil === 290 && existeAtaClasse) && (
                                    <Link
                                        to="#"
                                        title="Visualizar"
                                        className="botao-icon-pdf"
                                        onClick={() => setAtaClasseVisible(true)}
                                    >
                                        <Icon type="eye" />
                                    </Link>
                                )}
                                {(context.id_entidade === 3 || context.id_entidade === 28 || context.id_entidade === 37) && existeFicha && (
                                    <Link
                                        to="#"
                                        className="botao-icon-enviar"
                                        style={{ display: "flex", gap: 10 }}
                                        title="Visualizar"
                                        onClick={() => setFichaDescritivaEmMassaVisible(true)}
                                    >
                                        <Icon type="eye" />
                                    </Link>
                                )}
                            </div>
                        </div>
                        <Table
                            id="disciplinas"
                            loading={{
                                spinning: loading,
                                indicator: (
                                    <div className="loading-data-table">
                                        <div className="loading" />
                                    </div>
                                )
                            }}
                            columns={columns}
                            dataSource={alunosTemp}
                            pagination={false}
                            locale={{ emptyText: "Não existem dados!" }}
                        />
                    </div>
                </div>
            </div>
            <ModalLoading visible={visibleLoading} text={loadingText} />
            <ModalViewer visible={modalVisible} docs={docs} onCancel={() => setModalVisible(false)} />
            <Modal
                visible={visibleExportar}
                okText={
                    <a href={ficheiroExportar} download onClick={() => setVisibleExportar(false)}>
                        Descarregar
                    </a>
                }
                onCancel={() => setVisibleExportar(false)}
                cancelText="Cancelar"
                okButtonProps={{ disabled: btnDescarregar }}
                maskClosable={false}
                className="exportar-csv"
            >
                <div className="exportar-csv-bloco">
                    {btnDescarregar ? (
                        <>
                            <p>
                                <Icon type="loading" />
                            </p>
                            <p className="texto">A gerar documento...</p>
                        </>
                    ) : (
                        <>
                            <p>
                                <Icon type="check-circle" style={{ color: "#84bd00" }} />
                            </p>
                            <p className="texto">Dados exportados com sucesso!</p>
                            <p className="ficheiro-extensao">.{ficheiroExportar.split(".")[ficheiroExportar.split(".").length - 1]}</p>
                        </>
                    )}
                </div>
            </Modal>

            <ModalAta //GERAR ZIP COM PDFS DA FICHA INDIVIDUAL
                visible={ataIndividualAgrupEmMassaVisible}
                onClose={() => setAtaIndividualAgrupEmMassaVisible(false)}
                onConfirm={dataEmissao => {
                    carregarFichaIndividualAgrupamentoEmMassa(dataEmissao);
                    setAtaIndividualAgrupEmMassaVisible(false);
                }}
            />

            <ModalAta //GERAR ZIP COM PDFS DA FICHA DESCRITIVA
                visible={fichaDescritivaEmMassaVisible}
                onClose={() => setFichaDescritivaEmMassaVisible(false)}
                onConfirm={dataEmissao => {
                    carregarFichaDescritivaEmMassa(dataEmissao);
                    setFichaDescritivaEmMassaVisible(false);
                }}
            />

            <ModalAta //GERAR PDF ATA DE RESULTADOS
                visible={ataAgrupVisible}
                onClose={() => setAtaAgrupVisible(false)}
                onConfirm={dataEmissao => {
                    carregarAtaAgrupamentoResultados(dataEmissao);
                    setAtaAgrupVisible(false);
                }}
            />

            <ModalAta //GERAR EXCEL
                visible={fichaExcel}
                onClose={() => setFichaExcel(false)}
                onConfirm={() => {
                    ExportarExcel();
                    setFichaExcel(false);
                }}
            />

            <ModalAta //GERAR PDF FICHA INDIVIDUAL
                visible={individualInfoAgrup.visible}
                onClose={() => setIndividualInfoAgrup({ ...individualInfoAgrup, visible: false })}
                onConfirm={dataEmissao => {
                    carregarFichaIndividualAgrupamento(individualInfoAgrup.code, dataEmissao);
                    setIndividualInfoAgrup({ ...individualInfoAgrup, visible: false });
                }}
            />

            {(isEnsinoInfantil === 290 && existeAtaClasse) && (
                <ModalEmissao //GERAR PDF ATA CLASSE
                    visible={ataClasseVisible}
                    onClose={() => setAtaClasseVisible(false)}
                    onConfirm={(periodo, dataEmissao, horaEmissao) => {
                        carregarFichaAtaClasse(periodo, dataEmissao, horaEmissao);
                        setAtaClasseVisible(false);
                    }}
                />)}

            <ModalAta //GERAR PDF FICHA DESCRITIVA
                visible={descritivaInfo.visible}
                onClose={() => setDescritivaInfo({ ...descritivaInfo, visible: false })}
                onConfirm={dataEmissao => {
                    carregarFichaDescritiva(descritivaInfo.code, dataEmissao);
                    setDescritivaInfo({ ...descritivaInfo, visible: false });
                }}
            />
        </>
    );
};
export default Relatorios;
