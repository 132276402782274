import React, { useState, useEffect, useContext } from "react";
import { Redirect, Link, useLocation } from "react-router-dom";
import { Icon, notification } from "antd";
import { GeralContext } from "../ContextProvider";
import { GlobalContext } from "../../GlobalState";
import Card from "../Participantes/Participante";
import DrawerFicha from "./Drawer/DrawerFicha";
import ModalFicha from "./Modal/ModalFicha";
import ModalExportar from "../../Modals/ModalLoading";
import ModalViewer from "../../Modals/ModalViewer";
import axios from "axios";
import ModalLoading from "../../Modals/ModalLoading";

import Acessos from "../../Geral/Acessos/Acessos";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const VisaoGeralDisciplina = ({ match }) => {
    const globalContext = useContext(GlobalContext);
    const [professores, setProfessores] = useState([]);
    const [ficha, setFicha] = useState("");
    const [fichaValidada, setFichaValidada] = useState(false);
    const [permissao, setPermissao] = useState(false);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [visibleViewer, setVisibleViewer] = useState(false);
    const [docs, setDocs] = useState([]);

    const context = useContext(GeralContext);

    const location = useLocation();

    const listarProfessores = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/turma/lista-professores",
            params: {
                id: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setProfessores(response.data);
                setLoading(false);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    };

    const carregarFicha = () => {
        axios({
            method: "get",
            url: "/api/turma/listar-ficha",
            params: {
                id: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setPermissao(
                    response.data.coordenador || globalContext.perfil?.nome == "Suporte" || globalContext.perfil?.nome == "Gestor"
                );
                setFicha(response.data.ficha);
                setFichaValidada(response.data.ficha_validada);
            })
            .catch(() => {});
    };

    const montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (
                <Link
                    to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar}
                    className="botao-secundario botao-secundario-redondo"
                    onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)}
                    style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                    <Icon type="arrow-left" />
                </Link>
            );
        }
    };

    const gerarDoc = () => {
        setVisibleLoading(true);
        axios({
            method: "get",
            url: "/api/pdf/ficha-curricular",
            params: {
                cursoId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setVisibleLoading(false);
                setDocs([{ uri: response.data }]);
                setVisibleViewer(true);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível gerar documento!");
                setVisibleLoading(false);
            });
    };

    useEffect(() => {
        listarProfessores();
    }, [location.pathname]);

    useEffect(() => {
        if (globalContext.perfil?.id)
            carregarFicha();
    }, [globalContext.perfil, location.pathname]);

    if (redirect) return <Redirect to={redirectLink} />;

    const acesso = globalContext.acessos?.find(x => x.func === 16);

    return (
        <>
            <Acessos funcionalidadeId={16} />
            <div className="container container-body">
                {loading ? (
                    <div className="loading-data">
                        <div className="loading" />
                    </div>
                ) : (
                    <div className="turma-visao-geral">
                        <div className="controlos controlos-fundo" style={{ justifyContent: "flex-end" }}>
                            <div className="bloco-direita">{montarBotaoVoltar()}</div>
                        </div>
                        <div className="bloco esquerda">
                            <div className="item">
                                <div className="lista-professores">
                                    {professores.map(professor => (
                                        <Card
                                            {...professor}
                                            key={professor.id}
                                            dt_fechado={context.dt_fechado}
                                            dt_inicio={context.dt_inicio}
                                            dt_fim={context.dt_fim}
                                            fechado={context.fechado}
                                            perfil={professor.coordenador ? "Coordenador" : "Professor"}
                                        />
                                    ))}
                                </div>
                            </div>
                            {context.temFicha && (
                                <div className="bloco esquerda bloco-ficha">
                                    <div className="item">
                                        <h2>
                                            <span className="bloco-ficha-titulos">
                                                <span>Ficha</span>
                                                {!fichaValidada && <i className="bloco-ficha-aguarda">Aguarda validação...</i>}
                                            </span>
                                            <span className="bloco-ficha-opcoes">
                                                {permissao && !fichaValidada && acesso?.update && (
                                                    <>
                                                        <Link
                                                            className="botao-icon-editar"
                                                            to="#"
                                                            title="Editar"
                                                            onClick={() => setVisible(true)}
                                                        >
                                                            <Icon type="edit" />
                                                        </Link>
                                                        <Link
                                                            className="botao-icon-check"
                                                            to="#"
                                                            title="Validar"
                                                            onClick={() => setVisibleModal(true)}
                                                        >
                                                            <Icon type="check" />
                                                        </Link>
                                                    </>
                                                )}
                                                <Link className="botao-icon-detalhe" to="#" title="Descarregar" onClick={gerarDoc}>
                                                    <Icon type="eye" />
                                                </Link>
                                            </span>
                                        </h2>
                                        <div className="lista-campos-ficha">
                                            {ficha?.estrutura?.map((campo, index) => (
                                                <div key={index} className="campo-ficha">
                                                    <h3>{campo.nome}</h3>
                                                    {campo.texto && (
                                                        <p dangerouslySetInnerHTML={{ __html: campo.texto.replace(/\n/g, "<br>") }}></p>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <DrawerFicha
                    visible={visible}
                    onClose={() => setVisible(false)}
                    atualizar={() => {
                        setVisible(false);
                        carregarFicha();
                    }}
                />
                <ModalFicha
                    visible={visibleModal}
                    onClose={() => setVisibleModal(false)}
                    atualizar={() => {
                        setVisibleModal(false);
                        carregarFicha();
                    }}
                />
                <ModalLoading visible={visibleLoading} text="A gerar documento..." />
                <ModalViewer visible={visibleViewer} docs={docs} onCancel={() => setVisibleViewer(false)} />
            </div>
        </>
    );
};
export default VisaoGeralDisciplina;
