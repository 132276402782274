import React, { useState, useEffect, useContext } from "react";
import { Redirect, Link, withRouter, useLocation } from "react-router-dom";
import { Dropdown, Menu, Table, notification, Modal, Icon } from "antd";
import axios from "axios";
import moment from "moment";

import { GlobalContext } from "../../../../GlobalState";

import DrawerAvalicao from "../../Drawer/DrawerAvalicao";
import ModalFichaGrupo from "../../Modal/ModalFichaGrupo";
import ModalLoading from "../../../../Modals/ModalLoading";
import ModalViewer from "../../../../Modals/ModalViewer";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Pautas = ({ estruturaId, periodo, tipo, match }) => {
    const context = useContext(GlobalContext);

    const [avaliacoes, setAvalicoes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    const [pautaId, setPautaId] = useState(0);
    const [bloqueada, setBloqueada] = useState(false);
    const [visible, setVisible] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    //MODAL
    const [visibleModal, setVisibleModal] = useState(false);
    //MODAL
    const [modalVisible, setModalVisible] = useState(false);
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [docs, setDocs] = useState([]);

    const acesso = context.acessos?.find(x => x.func === 21);
    const acessoValidar = context.acessos?.find(x => x.func === 23);
    const acessoInvalidar = context.acessos?.find(x => x.func === 24);

    const location = useLocation();

    const listar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/aproveitamento/listar-items-avaliacao-conceitos",
            params: {
                disciplinaId: localStorage.getItem("codigo_curso"),
                estruturaId: estruturaId
            }
        })
            .then(response => {
                let avaliacoes = [];

                response.data.map(item => {
                    //let data = "";
                    //let estado = "";

                    //if (item.guardado) {
                    //    data = moment(item.dt_guardado).format("DD/MM/YYYY");
                    //    estado = "Por publicar";
                    //}

                    //if (item.publicado) {
                    //    data = moment(item.dt_publicado).format("DD/MM/YYYY");
                    //    estado = "Publicado";
                    //}

                    //if (item.validado) {
                    //    data = moment(item.dt_validado).format("DD/MM/YYYY");
                    //    estado = "Validado";
                    //}

                    avaliacoes.push({
                        key: item.id,
                        id: item.id,
                        code: item.code,
                        epoca: item.epoca,
                        nome: item.titulo,
                        //autor: item.autor,
                        //data: data,
                        //estado: estado,
                        ordem: item.ordem,
                        campoCalculado: item.campoCalculado,
                        opcoes: <div style={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" }}>
                            {/*{(item.guardado && (!item.validado && !item.publicado || item.publicado && item.permissao) && (acesso?.update || acessoValidar)) && (*/}
                            {/*{((item.guardado || item.publicado) && (acesso?.update || acessoValidar) && !item.validado) && (*/}
                            {/*    <Dropdown overlay={montarMenu(item.id, item)} placement="bottomLeft">*/}
                            {/*        <Link to="#" className="botao-icon-configs">*/}
                            {/*            <i className="fas fa-cog" />*/}
                            {/*        </Link>*/}
                            {/*    </Dropdown>*/}
                            {/*)}*/}
                            {(!item.publicado && !item.validado && acesso?.update || item.publicado && !item.validado && acessoValidar || item.validado && acessoInvalidar) && (
                                <Dropdown overlay={montarMenu(item)} placement="bottomLeft">
                                    <Link to="#" className="botao-icon-configs">
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>)}
                            <Link
                                to="#"
                                className="botao-icon-detalhe"
                                title="Visualizar"
                                onClick={() => gerarDoc(item.id)}
                            >
                                <Icon type="eye" />
                            </Link>
                            <Link
                                to={`/gerir-multisseriado/${match.params.turma.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/aproveitamento-conceitos-lista-alunos`}
                                className="botao-icon-adicionar"
                                title="Alunos"
                                onClick={() => {
                                    localStorage.setItem("grupo", JSON.stringify({ id: item.id, nome: item.titulo }));
                                    localStorage.setItem("aproveitamento", JSON.stringify({
                                        estrutura: estruturaId,
                                        periodo: periodo,
                                        tipo: tipo
                                    }))
                                }}
                            >
                                <i className="fas fa-user-friends" />
                            </Link>
                        </div>
                    });
                });

                setLoading(false);
                setAvalicoes(avaliacoes);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    };

    const listarsubItems = (expanded, record) => {
        const expandedKeys = [...expandedRows];
        if (expanded) {
            expandedKeys.push(record.key);
        } else {
            const index = expandedKeys.indexOf(record.key);
            if (index !== -1) {
                expandedKeys.splice(index, 1);
            }
        }
        setExpandedRows(expandedKeys);

        if (expanded)
            axios({
                method: "get",
                url: "/api/aproveitamento/listar-items-avaliacao-conceitos-subitems",
                params: {
                    grupoId: record.id
                }
            })
                .then(response => {
                    let lista = [];

                    response.data.map(item => {
                        let data = "";
                        let autor = "";
                        let estado = "";

                        if (item.guardado) {
                            data = moment(item.dt_guardado).format("DD/MM/YYYY");
                            autor = item.autor_guardado;
                            estado = "Por publicar";
                        }

                        if (item.publicado) {
                            data = moment(item.dt_publicado).format("DD/MM/YYYY");
                            autor = item.autor_publicado;
                            estado = "Publicado";
                        }

                        if (item.validado) {
                            data = moment(item.dt_validado).format("DD/MM/YYYY");
                            autor = item.autor_validado;
                            estado = "Validado";
                        }

                        lista.push({
                            key: item.id,
                            id: item.id,
                            code: item.code,
                            epoca: item.epoca,
                            nome: item.titulo,
                            autor: autor,
                            data: data,
                            //estado: estado,
                            ordem: item.ordem,
                            campoCalculado: item.campoCalculado,
                            //opcoes: <div style={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" }}>
                            //    {(item.guardado && (!item.validado && !item.publicado || item.publicado && item.permissao)) && (
                            //        <Dropdown overlay={montarMenu(item.id, item.processado, item.campoCalculado, item.mediaDisciplinas, item.bloqueada, item.permissao)} placement="bottomLeft">
                            //            <Link to="#" className="botao-icon-configs">
                            //                <i className="fas fa-cog" />
                            //            </Link>
                            //        </Dropdown>)}
                            //    <Link
                            //        to="#"
                            //        className="botao-icon-adicionar"
                            //        title="Adicionar Disciplina"
                            //        onClick={() => { }}
                            //    >
                            //        <i className="fas fa-calendar-check" />
                            //    </Link>
                            //    <Link
                            //        to="#"
                            //        className="botao-icon-adicionar"
                            //        title="Adicionar Disciplina"
                            //        onClick={() => { }}
                            //    >
                            //        <i className="fas fa-check-double" />
                            //    </Link>
                            //    <Link
                            //        to="#"
                            //        className="botao-icon-adicionar"
                            //        title="Adicionar Disciplina"
                            //        onClick={() => { }}
                            //    >
                            //        <i className="fas fa-user-friends" />
                            //    </Link>
                            //</div> 
                        });
                    });

                    var tempAvaliacoes = avaliacoes;
                    var index = tempAvaliacoes.findIndex(x => x.id === record.id)
                    tempAvaliacoes[index].subItems = lista
                    setAvalicoes([...tempAvaliacoes]);
                })
                .catch(error => {
                    if (error.response.data)
                        openNotificationWithIcon("error", "Erro", error.response.data);
                });
    };

    const isRowExpanded = (record) => {
        return expandedRows.includes(record.key);
    };

    const publicar = (pautaId) => {
        confirm({
            title: "Pretende publicar os resultados?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                let item = new FormData()
                item.append("disciplinaId", localStorage.getItem("codigo_curso"))
                item.append("pautaId", pautaId)

                axios({
                    method: "put",
                    url: "/api/turma/publicar-pauta",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", response.data);
                        listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível publicar os resultados.");
                    });
            }
        });
    }

    const invalidar = (pautaId) => {
        confirm({
            title: "Pretende invalidar esta avaliação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/aproveitamento/invalidar",
                    params: {
                        pautaId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", response.data);
                        listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        });
    }

    const montarMenu = (pauta) => {
        return (
            <Menu>
                {!pauta.publicado && !pauta.validado && acesso?.update && (
                    <Menu.Item>
                        <Link to="#" onClick={() => publicar(pauta.id)}>
                            Publicar
                        </Link>
                    </Menu.Item>)}
                {pauta.publicado && !pauta.validado && acessoValidar && (
                    <Menu.Item>
                        <Link to="#" onClick={() => { setPautaId(pauta.id); setVisibleModal(true); }}>
                            Validar
                        </Link>
                    </Menu.Item>)}
                {(pauta.validado && acessoInvalidar) && (
                    <Menu.Item>
                        <Link to="#" onClick={(event) => { event.stopPropagation(); invalidar(pauta.id) }}>
                            Invalidar
                        </Link>
                    </Menu.Item>)}
            </Menu>
        );
    };

    const onRowClick = (epoca, grupoId, grupo, record) => {
        localStorage.setItem(record.code, JSON.stringify({ epoca, grupoId, grupo, pauta: record.nome }))
        setRedirectLink(`/gerir-multisseriado/${match.params.turma.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/aproveitamento-bncc-pautas/${record.code}`);
        setRedirect(true);

        localStorage.setItem("aproveitamento", JSON.stringify({
            estrutura: estruturaId,
            periodo: periodo,
            tipo: tipo
        }))
    }

    const montarCores = (estado) => {
        if (estado === "Por publicar")
            return <span className="pauta-estado estado-por-publicar">Por publicar</span>

        if (estado === "Publicado")
            return <span className="pauta-estado estado-publicado">Publicado</span>

        if (estado === "Validado")
            return <span className="pauta-estado estado-validado">Validado</span>

        return null;
    }

    useEffect(() => {
        listar();
    }, [estruturaId, location.pathname])

    const columns = [
        {
            title: "Época",
            dataIndex: "epoca",
            className: "bold-field"
        },
        {
            title: "Conceito",
            dataIndex: "nome",
            className: "bold-field",
            render: (text, record) => text,
        },
        {
            title: "", //"Registo",
            dataIndex: "autor"
        },
        {
            title: "", //"Data",
            dataIndex: "data"
        },
        {
            title: "", //"Estado",
            dataIndex: "estado",
            render: (text, record) => montarCores(text)
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    const expandedRowRender = (record) => {
        const columns = [
            {
                title: "Conceito",
                dataIndex: "nome",
                render: (text, record) => text,
            }
        ];

        return (<Table
            id="disciplinas"
            className="tabela-sem-header"
            columns={columns}
            dataSource={record.subItems}
            pagination={false}
            locale={{ emptyText: "Não existem dados!" }}
            rowClassName={acesso?.read ? "clickeble-row" : ""}
            onRow={record2 => {
                return {
                    onClick: acesso?.read ? () => onRowClick(record.epoca, record.id, record.nome, record2) : () => { }
                };
            }}
        />)
    }

    const gerarDoc = (grupoId) => {
        setVisibleLoading(true);
        axios({
            method: "get",
            url: "/api/pdf/notas-grupo",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                grupoId
            }
        })
            .then(response => {
                setDocs([{ uri: response.data }]);
                setModalVisible(true);
                setVisibleLoading(false);
            })
            .catch(error => {
                //if (error.response.data)
                //    openNotificationWithIcon("error", "Erro", error.response.data);

                openNotificationWithIcon("error", "Erro", "Não foi possível gerar documento.");
                setVisibleLoading(false);
            });
    }

    if (redirect) return <Redirect to={redirectLink} />;

    return (
        <>
            <Table
                id="cursos-disciplinas"
                loading={{
                    spinning: loading,
                    indicator: (
                        <div className="loading-data-table">
                            <div className="loading" />
                        </div>
                    )
                }}
                columns={columns}
                dataSource={avaliacoes}
                pagination={false}
                locale={{ emptyText: "Não existem dados!" }}
                onExpand={listarsubItems}
                rowClassName={(record, index) =>
                    isRowExpanded(record) ? 'parent-row-expanded' : ''
                }
                expandedRowRender={expandedRowRender}
            />
            <DrawerAvalicao
                pautaId={pautaId}
                bloqueada={bloqueada}
                ordem={avaliacoes.length > 0 ? avaliacoes[avaliacoes.length - 1].ordem + 1 : 1}
                visible={visible}
                onClose={() => setVisible(false)}
                atualizar={() => { setVisible(false); listar(); }}
            />
            <ModalFichaGrupo
                grupoId={pautaId}
                visible={visibleModal}
                onClose={() => setVisibleModal(false)}
                atualizar={() => { setVisibleModal(false); listar(); }}
            />
            <ModalLoading
                visible={visibleLoading}
                text="A gerar documento..."
            />
            <ModalViewer
                visible={modalVisible}
                docs={docs}
                onCancel={() => setModalVisible(false)}
            />
        </>
    );
}
export default withRouter(Pautas);
