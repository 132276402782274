import React, { Component } from "react";
import { notification, Drawer, Form, Input, Icon, DatePicker, Select, Tooltip } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerAlterarLogin extends Component {
    state = {
        old_login: "",
        new_login: "",
        //LOADING
        iconLoading: false
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    alterar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });
                axios({
                    method: "post",
                    url: "/api/gestao-alterar-login/alterar",
                    params: {
                        oldlogin: this.state.old_login,
                        newlogin: this.state.new_login,
                        utilizadorId: this.props.utilizadorid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Login alterado!");
                        this.setState({ iconLoading: false });
                        this.props.onCloseAtualizar();
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });

                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    compareToFirstlogin = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue("new_login")) {
            callback("Dados de login não coincidentes");
        } else {
            callback();
        }
    };

    validateToNextlogin = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(["confirm"], { force: true });
        }
        callback();
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            this.setState({
                old_login: this.props.loginAtual,
                new_login: "",
                //LOADING
                iconLoading: false
            })
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { old_login, iconLoading } = this.state;
        const { visible, onClose } = this.props;

        return (
            <>
                <Drawer
                    className="drawer-add-cursos"
                    width={720}
                    onClose={onClose}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-add-curso" layout="horizontal">
                            <Form.Item label="Login atual">
                                <Input name="old_login" value={old_login} disabled />
                            </Form.Item>
                            <Form.Item label={<span>Novo login</span>}>
                                {getFieldDecorator("new_login", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                            whitespace: true
                                        },
                                        {
                                            validator: this.validateToNextlogin
                                        }
                                    ]
                                })(<Input name="new_login" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label={<span>Confirmar login</span>}>
                                {getFieldDecorator("confirmar-login", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                            whitespace: true
                                        },
                                        {
                                            validator: this.compareToFirstlogin
                                        }
                                    ]
                                })(<Input />)}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        <button className="botao-principal" type="primary" disabled={iconLoading} onClick={this.alterar}>
                            {iconLoading ? <Icon type="loading" /> : null}
                            Guardar
                        </button>
                    </div>
                </Drawer>
            </>
        );
    }
}

const FormDrawerAlterarLogin = Form.create({ name: "drawer-alterar-login" })(DrawerAlterarLogin);

export default FormDrawerAlterarLogin;
