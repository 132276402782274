import React, { Component } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { Menu, Table, DatePicker, Dropdown, Modal, notification, Form, Input, Icon } from "antd";
import Header from "../../User/Header";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";
import axios from "axios";
import moment from "moment";
//import { CSVLink } from "react-csv";
import DrawerCertificacao from "./_Aux/DrawerCertificacao";
import DrawerAtribuirClassificacao from "./_Aux/DrawerAtribuirClassificacao";
import { GlobalContext } from "../../GlobalState";
import noimage from "../../images/noimage.png";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class CursosDisciplinaResultados extends Component {
    static contextType = GlobalContext;
    state = {
        cursoid: 0,
        questoes: [],
        utilizadores: [],
        //FILTRO
        pesquisa: "",
        dt_inicio: "",
        dt_fim: "",
        //MODAL
        utilizadorid: 0,
        utilizadorNome: "",
        modal_visible: false,
        nota: "",
        //DRAWER ATRIBUIR CLASSIFICACAO
        atribuir_classificacao_visible: false,
        atribuir_classificacao: "",
        atribuir_observacoes: "",
        atribuir_medalha: false,
        //MODAL TRABALHO
        aulaid: 0,
        alunoId: 0,
        modal_trabalho_visible: false,
        nota_trabalho: "",
        //DRAWER TRABALHO
        trabalho_visible: false,
        nome: "",
        tipo: "",
        participantes: [],
        observacoes: "",
        ficheiros: [],
        //DRAWER CERTIFICACAO
        certificacao_visible: false,
        documento: "",
        documento_nome: "",
        //DRAWER OUTRA AVALIACAO
        outraId: 0,
        outra_avaliacao_visible: false,
        nome_avaliacao: "",
        data_avaliacao: null,
        classificacao: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //LOADING
        loading_table: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        var cursoId = localStorage.getItem("codigo_curso");

        this.setState({
            cursoid: cursoId
        });
        this.listar(cursoId);
    }

    //componentDidUpdate() {
    //    if (this.context.role === "UTILIZADOR")
    //        this.setState({
    //            redirect: true,
    //            redirectLink: "/user"
    //        });
    //}

    //PESQUISA NA CAIXA DE PROCURA APOS ENTER
    HandleKeyPress = e => {
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa: e.target.value
                },
                () => {
                    this.listar(this.state.cursoid);
                }
            );
        }
    };

    //LIMPA A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: ""
            },
            () => {
                this.listar(this.state.cursoid);
            }
        );
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeDataInicio = date => {
        this.setState({ dt_inicio: date });
    };

    handleChangeDataFim = date => {
        this.setState({ dt_fim: date });
    };

    handleChangeData = date => {
        this.setState({ data_avaliacao: date });
    };

    handlerChangeCheck = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    //CARREGA TODOS OS ALUNOS E SUAS AVALIAÇÕES
    listar = cursoid => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/formador-curso-resultados/listar-resultados-curso-disciplinas",
                    params: {
                        cursoid: cursoid,
                        pesquisa: this.state.pesquisa
                    }
                })
                    .then(response => {
                        this.setState({
                            utilizadores: response.data,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    procurar = () => {
        this.listar(this.state.cursoid);
    };

    // MONTA O MENU DE OPÇÕES POR CADA UTILIZADOR
    montarMenuUtilizador = (utilizador, classificacao) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.openDrawerAtribuirClassificacao(utilizador)}>
                        Classificação
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.openDrawerCertificacao(utilizador)}>
                        Certificado
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    //DRAWER ATRIBUIR CLASSIFICACAO
    openDrawerAtribuirClassificacao = utilizador => {
        this.props.form.resetFields();
        this.setState({
            utilizadorid: utilizador.id,
            atribuir_classificacao_visible: true,
            atribuir_classificacao: utilizador.classificacao,
            atribuir_observacoes: utilizador.classificacao_observacoes,
            atribuir_medalha: utilizador.medalha,
        });
    };

    onCloseAtribuirClassificacaoAtualizar = () => {
        this.setState({
            atribuir_classificacao_visible: false
        });

        this.listar(this.state.cursoid);
    };

    openDrawerCertificacao = utilizador => {
        this.setState({
            certificacao_visible: true,
            utilizadorid: utilizador.id,
            utilizadorNome: utilizador.nome_completo,
            documento: utilizador.documento,
            documento_nome: utilizador.documento_nome
        });
    };

    //CARREGA OS DADOS NA TABELA DE NIVEL 1
    carregaDados = lista => {
        var utilizadores = [];

        lista.map((utilizador, index) => {
            utilizadores.push({
                key: index,
                id: utilizador.id,
                nome: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="imagem-curso">
                            <img src={utilizador.foto ? utilizador.foto : noimage} />
                        </div>
                        <div className="nome-colaborador">
                            <span className="titulo-curso">{utilizador.nome_completo}</span>
                            <span style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.65)" }}>{utilizador.nr_aluno}</span>
                        </div>
                    </div>
                ),
                registo: utilizador.dt_criado ? moment(utilizador.dt_criado).format("DD/MM/YYYY") : "",
                classificacao: utilizador.classificacao,
                classificacao_observacoes: utilizador.classificacao_observacoes,
                medalha: utilizador.medalha,
                descarregar: utilizador.documento ? (
                    <a href={utilizador.documento} className="botao-icon-download" download={utilizador.documento_nome} title="Descarregar certificado">
                        <Icon type="download" />
                    </a>
                ) : (
                    ""
                ),
                opcoes: (
                    <Dropdown overlay={this.montarMenuUtilizador(utilizador, utilizador.classificacao)} placement="bottomLeft">
                        <Link to="#" className="botao-icon-configs">
                            <i className="fas fa-cog" />
                        </Link>
                    </Dropdown>
                )
            });
        });
        return utilizadores;
    };

    //CHAMADA QUANDO CONFIRMADO NO MODAL
    handleOk = e => {
        this.props.form.validateFieldsAndScroll(["nota"], (err, values) => {
            if (!err) {
                axios({
                    method: "put",
                    url: "/api/gestao-curso-resultados/atribuir-nota-final",
                    params: {
                        utilizadorid: this.state.utilizadorid,
                        cursoid: this.state.cursoid,
                        nota: this.state.nota
                    }
                })
                    .then(response => {
                        this.setState({
                            modal_visible: false
                        });
                        this.listar(this.state.cursoid);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível atribuir a classificação!");
                    });
            }
        });
    };

    atualizarDocumento = (documento, documento_nome) => {
        var index = this.state.utilizadores.findIndex(utilizador => utilizador.id === this.state.utilizadorid);
        var _utilizadores = this.state.utilizadores;
        _utilizadores[index].documento = documento;
        _utilizadores[index].documento_nome = documento_nome;

        this.setState({
            utilizadores: _utilizadores
        });
    };

    montarMenuExportar = cursoId => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.exportarQuestionarioAlunoCSV(cursoId)}>
                        Exportar classificações alunos
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.exportarQuestionarioInstrutorCSV(cursoId)}>
                        Exportar classificações professores
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.exportarSumario(cursoId)}>
                        Exportar Sumários
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            utilizadores,
            nome_curso,
            //DRAWER ATRIBUIR CLASSIFICACAO
            atribuir_classificacao_visible,
            atribuir_classificacao,
            atribuir_observacoes,
            atribuir_medalha,
            //REDIRECT
            redirect,
            redirectLink,
            //LOADING
            loading_table
        } = this.state;

        //COLUNAS DA TABELA DE NIVEL 1
        const columns = [
            {
                title: "Nome",
                dataIndex: "nome",
                className: "td-500"
            },
            {
                title: "Data",
                dataIndex: "registo",
                key: "registo",
                className: "td-200"
            },
            {
                title: "Classificação",
                dataIndex: "classificacao",
                key: "classificacao",
                className: "td-200",
                render: (text, record) => <div className="bloco-render"><span className="span-text">{text}{record.classificacao_observacoes ? "*" : ""}</span> {record.medalha && <Icon type="trophy" className="icon-medalha-curso" title="Medalha de Curso" />}</div>,
            },
            {
                title: "",
                dataIndex: "descarregar",
                className: "td-50"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Resultados" />
                <div className="container container-body">
                    <div className="bloco-formadores">
                        <div className="bloco">
                            <div className="bloco-flex">
                                <h3 className="tabela_titulo">
                                    {localStorage.getItem("cod_curso")} - {localStorage.getItem("nome_curso")}
                                </h3>
                                <Link
                                    to="/formador-lista-cursos"
                                    className="botao-principal"
                                    style={{ display: "inline" }}
                                >
                                    Voltar
                                </Link>
                            </div>
                            <div className="filtro-pesquisa-resultados" style={{ marginTop: 40 }}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <BotaoProcurar
                                            tem_placeholder
                                            placeholder="Pesquisar pelo nome..."
                                            HandleKeyPress={this.HandleKeyPress}
                                            resetCaixaProcura={this.resetCaixaProcura}
                                        />
                                    </div>
                                    {/*<div className="col-lg-1">
                                        <button className="botao-principal" onClick={this.procurar} title="Pesquisar por data de conclusão">
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </div>*/}
                                    {/*<div
                                        className="col-lg-6"
                                        style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", fontSize: 16 }}
                                    >
                                        <Dropdown overlay={this.montarMenuExportar(this.state.cursoid)} placement="bottomLeft">
                                            <Link to="#" className="btn-opcoes">
                                                <i className="fas fa-cog" />
                                            </Link>
                                        </Dropdown>
                                    </div>*/}
                                </div>
                            </div>
                            <Table
                                className="tabela-avaliacoes-finais"
                                style={{ background: "white", boxShadow: "0 0 5px rgba(178, 178, 178, 0.35)" }}
                                columns={columns}
                                dataSource={this.carregaDados(utilizadores)}
                                pagination={false}
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                locale={{ emptyText: "Não existem dados!" }}
                            />
                        </div>
                    </div>
                </div>
                <DrawerAtribuirClassificacao
                    visible={atribuir_classificacao_visible}
                    onClose={() => this.setState({ atribuir_classificacao_visible: false })}
                    classificacao={atribuir_classificacao}
                    observacoes={atribuir_observacoes}
                    medalha={atribuir_medalha}
                    cursoid={this.state.cursoid}
                    utilizadorid={this.state.utilizadorid}
                    handleChange={this.handleChange}
                    onCloseAtribuirClassificacaoAtualizar={this.onCloseAtribuirClassificacaoAtualizar}
                    handlerChangeCheck={this.handlerChangeCheck}
                />
                <DrawerCertificacao
                    visible={this.state.certificacao_visible}
                    onClose={() => this.setState({ certificacao_visible: false })}
                    atualizar={this.atualizarDocumento}
                    documento={this.state.documento}
                    documento_nome={this.state.documento_nome}
                    cursoid={this.state.cursoid}
                    utilizadorId={this.state.utilizadorid}
                    utilizadorNome={this.state.utilizadorNome}
                />
            </div>
        );
    }
}

const FormCursosDisciplinaResultados = Form.create({ name: "form-cursos-disciplina-resultados" })(CursosDisciplinaResultados);

export default withRouter(FormCursosDisciplinaResultados);
