import React, { Component } from "react";
import { notification, Drawer, Form, Input, Icon, DatePicker, Select, Tooltip, Upload } from "antd";
import axios from "axios";
import moment from "moment";

const { TextArea } = Input;
const { Option } = Select;
const Dragger = Upload.Dragger;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerDetalheInscricao extends Component {
    state = {
        anexo: "",
        nome: "",
        email: "",
        nr_aluno: "",
        contacto: "",
        descricao: "",
        habilitacao: "",
        paginaPessoal: "",
        dt_nascimento: "",
        naturalidade: "",
        sexo: "",
        estado_civil: "",
        cc: "",
        dt_ccvalidade: "",
        nif: "",
        cep: "",
        localidade: "",
        morada: "",
        pais: "",
        motivo: "",
        responsavel_1: "",
        responsavel_2: "",
        nomeID: "",
        ccID: "",
        dt_ccvalidadeID: "",
        ficha_inscricao: "",
        ficheiro_inscricao: [],
        dt_ficheiro_inscricao: "",
        //LOADING
        iconLoading: false,
        inscricao: ""
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeSelectSexo = value => {
        this.setState({ sexo: value });
    };

    handleChangeSelectEstado = value => {
        this.setState({ estado_civil: value });
    };

    handleChangeSelectPais = value => {
        this.setState({ pais: value });
    };

    handleChangeDataNascimento = value => {
        this.setState({ dt_nascimento: value });
    };

    handleChangeDataCc = value => {
        this.setState({ dt_ccvalidade: value });
    };

    handleChangeDataCcID = value => {
        this.setState({ dt_ccvalidadeID: value });
    };

    detalhe = inscricaoId => {
        axios({
            method: "get",
            url: "/api/gestao-curso-inscricoes/consultar-inscricao",
            params: {
                inscricaoId
            }
        })
            .then(response => {
                var ficheiro_inscricao = [];

                if (response.data.ficheiro_inscricao)
                    ficheiro_inscricao.push({
                        uid: -1,
                        name: response.data.ficheiro_inscricao_nome,
                        status: "done",
                        url: response.data.ficheiro_inscricao
                    });

                this.setState({
                    inscricaoId,
                    anexo: response.data.anexo,
                    nome: response.data.nome,
                    email: response.data.email,
                    nr_aluno: response.data.nr_aluno,
                    contacto: response.data.contacto ? response.data.contacto : "",
                    descricao: response.data.descricao ? response.data.descricao : "",
                    habilitacao: response.data.habilitacao ? response.data.habilitacao : "",
                    paginaPessoal: response.data.paginaPessoal ? response.data.paginaPessoal : "",
                    dt_nascimento: response.data.dt_nascimento ? moment(response.data.dt_nascimento) : null,
                    naturalidade: response.data.naturalidade ? response.data.naturalidade : "",
                    sexo: response.data.sexo ? response.data.sexo : undefined,
                    estado_civil: response.data.estado_civil ? response.data.estado_civil : undefined,
                    cc: response.data.cc ? response.data.cc : "",
                    dt_ccvalidade: response.data.dt_ccvalidade ? moment(response.data.dt_ccvalidade) : null,
                    nif: response.data.nif ? response.data.nif : "",
                    cep: response.data.cep ? response.data.cep : "",
                    localidade: response.data.localidade ? response.data.localidade : "",
                    morada: response.data.morada ? response.data.morada : "",
                    pais: response.data.pais ? response.data.pais : undefined,
                    motivo: response.data.motivo ? response.data.motivo : "",
                    responsavel_1: response.data.responsavel_1 ? response.data.responsavel_1 : "",
                    responsavel_2: response.data.responsavel_2 ? response.data.responsavel_2 : "",
                    nomeID: response.data.nomeCompletoID ? response.data.nomeCompletoID : "",
                    ccID: response.data.docIdentificacaoID ? response.data.docIdentificacaoID : "",
                    dt_ccvalidadeID: response.data.identificacaoValidadeID ? response.data.identificacaoValidadeID : "",
                    ficha_inscricao: response.data.ficha_inscricao,
                    ficheiro_inscricao: ficheiro_inscricao,
                    dt_ficheiro_inscricao: response.data.dt_ficheiro_inscricao,
                    inscricao_visible: true
                });
            })
            .catch(error => { });
    };

    guardar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.inscricaoId);
                item.append("nome", this.state.nome);
                item.append("email", this.state.email);
                item.append("nr_aluno", this.state.nr_aluno);
                item.append("contacto", this.state.contacto);
                item.append("descricao", this.state.descricao);
                item.append("habilitacao", this.state.habilitacao);
                item.append("paginaPessoal", this.state.paginaPessoal);
                if (this.state.dt_nascimento)
                    item.append("dt_nascimento", moment(this.state.dt_nascimento).format("YYYY/MM/DD"));
                item.append("naturalidade", this.state.naturalidade);
                if (this.state.sexo)
                    item.append("sexo", this.state.sexo);
                if (this.state.estado_civil)
                    item.append("estado_civil", this.state.estado_civil);
                item.append("cc", this.state.cc);
                if (this.state.dt_ccvalidade)
                    item.append("dt_ccvalidade", moment(this.state.dt_ccvalidade).format("YYYY/MM/DD"));
                item.append("nif", this.state.nif);
                item.append("cod_postal", this.state.cep);
                item.append("localidade", this.state.localidade);
                item.append("morada", this.state.morada);
                if (this.state.pais)
                    item.append("pais", this.state.pais);
                item.append("motivo", this.state.motivo);
                item.append("responsavel_1", this.state.responsavel_1);
                item.append("responsavel_2", this.state.responsavel_2);
                item.append("nomeCompletoID", this.state.nomeID);
                item.append("docIdentificacaoID", this.state.ccID);
                if (this.state.dt_ccvalidadeID)
                    item.append("identificacaoValidadeID", moment(this.state.dt_ccvalidadeID).format("YYYY/MM/DD"));
                if (this.state.ficheiro_inscricao.length)
                    item.append("ficheiro_inscricao", this.state.ficheiro_inscricao[0]);

                axios({
                    method: "put",
                    url: "/api/gestao-curso-inscricoes/guardar-inscricao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Dados da inscrição atualizados!");
                        this.setState({
                            iconLoading: false
                        });
                        this.props.onCloseUpdate();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível atualizar a inscrição");
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.detalhe(this.props.inscricaoId);
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            anexo,
            nome,
            email,
            nr_aluno,
            contacto,
            descricao,
            habilitacao,
            paginaPessoal,
            dt_nascimento,
            naturalidade,
            sexo,
            estado_civil,
            cc,
            dt_ccvalidade,
            nif,
            cep,
            localidade,
            morada,
            pais,
            motivo,
            responsavel_1,
            responsavel_2,
            nomeID,
            ccID,
            dt_ccvalidadeID,
            ficheiro_inscricao,
            propsInscricao,
            temMotivo,
            //LOADING
            iconLoading
        } = this.state;

        const {
            visible
        } = this.props;

        return (
            <>
                <Drawer
                    className="drawer-add-cursos drawer-detalhe-inscricao"
                    title={<div className="drawer-titulo">
                        Inscrição
                        {localStorage.getItem("nome_curso_pai") ? (
                            <>
                                <h3>
                                    {localStorage.getItem("cod_curso_pai")} - {localStorage.getItem("nome_curso_pai")}
                                </h3>
                                <span>
                                    {localStorage.getItem("cod_subcurso")} - {localStorage.getItem("nome_subcurso")}
                                </span>
                                <span className="escola-nome">
                                    {localStorage.getItem("escola_nome")}
                                </span>
                            </>
                        ) : (
                            <>
                                <h3>
                                    {localStorage.getItem("cod_curso")} - {localStorage.getItem("nome_curso")}
                                </h3>
                                <span className="escola-nome">
                                    {localStorage.getItem("escola_nome")}
                                </span>
                            </>
                        )}
                    </div>
                    }
                    width={720}
                    onClose={this.props.onClose}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-add-curso" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Nome Completo">
                                    {getFieldDecorator("nome", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                                whitespace: true
                                            }
                                        ],
                                        initialValue: nome
                                    })(<Input name="nome" onChange={this.handleChange} />)}
                                </Form.Item>
                                <Form.Item label="E-mail">
                                    {getFieldDecorator("email", {
                                        initialValue: email
                                    })(<Input name="email" onChange={this.handleChange} />)}
                                </Form.Item>
                                <Form.Item label="Código">
                                    {getFieldDecorator("nr_aluno", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                                whitespace: true
                                            }
                                        ],
                                        initialValue: nr_aluno
                                    })(<Input name="nr_aluno" onChange={this.handleChange} />)}
                                </Form.Item>
                                <Form.Item label="Telefone / Celular">
                                    <Input name="contacto" className="input-50" value={contacto} onChange={this.handleChange} />
                                </Form.Item>
                                <Form.Item label="Breve Descrição">
                                    <TextArea name="descricao" value={descricao} onChange={this.handleChange} rows={4} style={{ height: "100%" }} />
                                </Form.Item>
                                <Form.Item label="Habilitações">
                                    <TextArea name="habilitacao" value={habilitacao} onChange={this.handleChange} rows={4} style={{ height: "100%" }} />
                                </Form.Item>
                                <Form.Item label="Página Web pessoal">
                                    <Input name="paginaPessoal" value={paginaPessoal} onChange={this.handleChange} />
                                </Form.Item>
                                <Form.Item label="Data de nascimento">
                                    <DatePicker
                                        className="input-50"
                                        placeholder="Selecionar"
                                        format="DD-MM-YYYY"
                                        name="dt_nascimento"
                                        value={dt_nascimento ? moment(dt_nascimento) : null}
                                        onChange={this.handleChangeDataNascimento}
                                    />
                                </Form.Item>
                                <Form.Item label="Naturalidade">
                                    <Input name="naturalidade" value={naturalidade} onChange={this.handleChange} />
                                </Form.Item>
                                <Form.Item label="Sexo">
                                    <Select placeholder="Selecionar" name="certificacao" className="input-50" allowClear={true} value={sexo} onChange={this.handleChangeSelectSexo}>
                                        <Option value="MASCULINO">Masculino</Option>
                                        <Option value="FEMININO">Feminino</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Estado Civil">
                                    <Select placeholder="Selecionar" className="input-50" allowClear={true} value={estado_civil} onChange={this.handleChangeSelectEstado}>
                                        <Option key="Solteiro(a)">Solteiro(a)</Option>
                                        <Option key="Casado(a)">Casado(a)</Option>
                                        <Option key="Viúvo(a)">Viúvo(a)</Option>
                                        <Option key="Separado(a) Judicialmente">Separado(a) Judicialmente</Option>
                                        <Option key="Divorciado(a)">Divorciado(a)</Option>
                                        <Option key="Outro">Outro</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <>
                                            Identificação/RG
                                        <Tooltip className="info-icon" title="Documento de Identificação, Passaporte, ...">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </>
                                    }
                                >
                                    <Input name="cc" value={cc} onChange={this.handleChange} />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <>
                                            Validade
                                        <Tooltip className="info-icon" className="info-icon" title="Validade do documento de identificação">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </>
                                    }
                                >
                                    <DatePicker
                                        className="input-50"
                                        placeholder="Selecionar"
                                        format="DD-MM-YYYY"
                                        name="dt_ccvalidade"
                                        value={dt_ccvalidade ? moment(dt_ccvalidade) : null}
                                        onChange={this.handleChangeDataCc}
                                    />
                                </Form.Item>
                                <Form.Item label="Dados Fiscais / CPF">
                                    <Input name="nif" className="input-50" value={nif} onChange={this.handleChange} />
                                </Form.Item>
                                <Form.Item label="Código Postal / CEP">
                                    <Input value={cep} name="cep" className="input-25" onChange={this.handleChange} />
                                </Form.Item>
                                <Form.Item label="Localidade / Estado">
                                    <Input value={localidade} name="localidade" className="input-50" onChange={this.handleChange} />
                                </Form.Item>
                                <Form.Item label="Endereço">
                                    <TextArea name="morada" value={morada} onChange={this.handleChange} rows={4} style={{ height: "100%" }} />
                                </Form.Item>
                                <Form.Item label="País">
                                    <Select
                                        showSearch
                                        placeholder="Selecionar"
                                        value={pais}
                                        allowClear={true}
                                        onChange={this.handleChangeSelectPais}
                                    >
                                        <Option value="África do Sul">África do Sul</Option>
                                        <Option value="Albânia">Albânia</Option>
                                        <Option value="Alemanha">Alemanha</Option>
                                        <Option value="Andorra">Andorra</Option>
                                        <Option value="Angola">Angola</Option>
                                        <Option value="Anguilla">Anguilla</Option>
                                        <Option value="Antigua">Antigua</Option>
                                        <Option value="Arábia Saudita">Arábia Saudita</Option>
                                        <Option value="Argentina">Argentina</Option>
                                        <Option value="Armênia">Armênia</Option>
                                        <Option value="Aruba">Aruba</Option>
                                        <Option value="Austrália">Austrália</Option>
                                        <Option value="Áustria">Áustria</Option>
                                        <Option value="Azerbaijão">Azerbaijão</Option>
                                        <Option value="Bahamas">Bahamas</Option>
                                        <Option value="Bahrein">Bahrein</Option>
                                        <Option value="Bangladesh">Bangladesh</Option>
                                        <Option value="Barbados">Barbados</Option>
                                        <Option value="Bélgica">Bélgica</Option>
                                        <Option value="Benin">Benin</Option>
                                        <Option value="Bermudas">Bermudas</Option>
                                        <Option value="Botsuana">Botsuana</Option>
                                        <Option value="Brasil" selected>
                                            Brasil
                                        </Option>
                                        <Option value="Brunei">Brunei</Option>
                                        <Option value="Bulgária">Bulgária</Option>
                                        <Option value="Burkina Fasso">Burkina Fasso</Option>
                                        <Option value="botão">botão</Option>
                                        <Option value="Cabo Verde">Cabo Verde</Option>
                                        <Option value="Camarões">Camarões</Option>
                                        <Option value="Camboja">Camboja</Option>
                                        <Option value="Canadá">Canadá</Option>
                                        <Option value="Cazaquistão">Cazaquistão</Option>
                                        <Option value="Chade">Chade</Option>
                                        <Option value="Chile">Chile</Option>
                                        <Option value="China">China</Option>
                                        <Option value="Cidade do Vaticano">Cidade do Vaticano</Option>
                                        <Option value="Colômbia">Colômbia</Option>
                                        <Option value="Congo">Congo</Option>
                                        <Option value="Coréia do Sul">Coréia do Sul</Option>
                                        <Option value="Costa do Marfim">Costa do Marfim</Option>
                                        <Option value="Costa Rica">Costa Rica</Option>
                                        <Option value="Croácia">Croácia</Option>
                                        <Option value="Dinamarca">Dinamarca</Option>
                                        <Option value="Djibuti">Djibuti</Option>
                                        <Option value="Dominica">Dominica</Option>
                                        <Option value="EUA">EUA</Option>
                                        <Option value="Egito">Egito</Option>
                                        <Option value="El Salvador">El Salvador</Option>
                                        <Option value="Emirados Árabes">Emirados Árabes</Option>
                                        <Option value="Equador">Equador</Option>
                                        <Option value="Eritréia">Eritréia</Option>
                                        <Option value="Escócia">Escócia</Option>
                                        <Option value="Eslováquia">Eslováquia</Option>
                                        <Option value="Eslovênia">Eslovênia</Option>
                                        <Option value="Espanha">Espanha</Option>
                                        <Option value="Estônia">Estônia</Option>
                                        <Option value="Etiópia">Etiópia</Option>
                                        <Option value="Fiji">Fiji</Option>
                                        <Option value="Filipinas">Filipinas</Option>
                                        <Option value="Finlândia">Finlândia</Option>
                                        <Option value="França">França</Option>
                                        <Option value="Gabão">Gabão</Option>
                                        <Option value="Gâmbia">Gâmbia</Option>
                                        <Option value="Gana">Gana</Option>
                                        <Option value="Geórgia">Geórgia</Option>
                                        <Option value="Gibraltar">Gibraltar</Option>
                                        <Option value="Granada">Granada</Option>
                                        <Option value="Grécia">Grécia</Option>
                                        <Option value="Guadalupe">Guadalupe</Option>
                                        <Option value="Guam">Guam</Option>
                                        <Option value="Guatemala">Guatemala</Option>
                                        <Option value="Guiana">Guiana</Option>
                                        <Option value="Guiana Francesa">Guiana Francesa</Option>
                                        <Option value="Guiné-bissau">Guiné-bissau</Option>
                                        <Option value="Haiti">Haiti</Option>
                                        <Option value="Holanda">Holanda</Option>
                                        <Option value="Honduras">Honduras</Option>
                                        <Option value="Hong Kong">Hong Kong</Option>
                                        <Option value="Hungria">Hungria</Option>
                                        <Option value="Iêmen">Iêmen</Option>
                                        <Option value="Ilhas Cayman">Ilhas Cayman</Option>
                                        <Option value="Ilhas Cook">Ilhas Cook</Option>
                                        <Option value="Ilhas Curaçao">Ilhas Curaçao</Option>
                                        <Option value="Ilhas Marshall">Ilhas Marshall</Option>
                                        <Option value="Ilhas Turks & Caicos">Ilhas Turks & Caicos</Option>
                                        <Option value="Ilhas Virgens (brit.)">Ilhas Virgens (brit.)</Option>
                                        <Option value="Ilhas Virgens(amer.)">Ilhas Virgens(amer.)</Option>
                                        <Option value="Ilhas Wallis e Futuna">Ilhas Wallis e Futuna</Option>
                                        <Option value="Índia">Índia</Option>
                                        <Option value="Indonésia">Indonésia</Option>
                                        <Option value="Inglaterra">Inglaterra</Option>
                                        <Option value="Irlanda">Irlanda</Option>
                                        <Option value="Islândia">Islândia</Option>
                                        <Option value="Israel">Israel</Option>
                                        <Option value="Itália">Itália</Option>
                                        <Option value="Jamaica">Jamaica</Option>
                                        <Option value="Japão">Japão</Option>
                                        <Option value="Jordânia">Jordânia</Option>
                                        <Option value="Kuwait">Kuwait</Option>
                                        <Option value="Latvia">Latvia</Option>
                                        <Option value="Líbano">Líbano</Option>
                                        <Option value="Liechtenstein">Liechtenstein</Option>
                                        <Option value="Lituânia">Lituânia</Option>
                                        <Option value="Luxemburgo">Luxemburgo</Option>
                                        <Option value="Macau">Macau</Option>
                                        <Option value="Macedônia">Macedônia</Option>
                                        <Option value="Madagascar">Madagascar</Option>
                                        <Option value="Malásia">Malásia</Option>
                                        <Option value="Malaui">Malaui</Option>
                                        <Option value="Mali">Mali</Option>
                                        <Option value="Malta">Malta</Option>
                                        <Option value="Marrocos">Marrocos</Option>
                                        <Option value="Martinica">Martinica</Option>
                                        <Option value="Mauritânia">Mauritânia</Option>
                                        <Option value="Mauritius">Mauritius</Option>
                                        <Option value="México">México</Option>
                                        <Option value="Moldova">Moldova</Option>
                                        <Option value="Mônaco">Mônaco</Option>
                                        <Option value="Montserrat">Montserrat</Option>
                                        <Option value="Nepal">Nepal</Option>
                                        <Option value="Nicarágua">Nicarágua</Option>
                                        <Option value="Niger">Niger</Option>
                                        <Option value="Nigéria">Nigéria</Option>
                                        <Option value="Noruega">Noruega</Option>
                                        <Option value="Nova Caledônia">Nova Caledônia</Option>
                                        <Option value="Nova Zelândia">Nova Zelândia</Option>
                                        <Option value="Omã">Omã</Option>
                                        <Option value="Palau">Palau</Option>
                                        <Option value="Panamá">Panamá</Option>
                                        <Option value="Papua-nova Guiné">Papua-nova Guiné</Option>
                                        <Option value="Paquistão">Paquistão</Option>
                                        <Option value="Peru">Peru</Option>
                                        <Option value="Polinésia Francesa">Polinésia Francesa</Option>
                                        <Option value="Polônia">Polônia</Option>
                                        <Option value="Porto Rico">Porto Rico</Option>
                                        <Option value="Portugal">Portugal</Option>
                                        <Option value="Qatar">Qatar</Option>
                                        <Option value="Quênia">Quênia</Option>
                                        <Option value="Rep. Dominicana">Rep. Dominicana</Option>
                                        <Option value="Rep. Tcheca">Rep. Tcheca</Option>
                                        <Option value="Reunion">Reunion</Option>
                                        <Option value="Romênia">Romênia</Option>
                                        <Option value="Ruanda">Ruanda</Option>
                                        <Option value="Rússia">Rússia</Option>
                                        <Option value="Saipan">Saipan</Option>
                                        <Option value="Samoa Americana">Samoa Americana</Option>
                                        <Option value="Senegal">Senegal</Option>
                                        <Option value="Serra Leone">Serra Leone</Option>
                                        <Option value="Seychelles">Seychelles</Option>
                                        <Option value="Singapura">Singapura</Option>
                                        <Option value="Síria">Síria</Option>
                                        <Option value="Sri Lanka">Sri Lanka</Option>
                                        <Option value="St. Kitts & Nevis">St. Kitts & Nevis</Option>
                                        <Option value="St. Lúcia">St. Lúcia</Option>
                                        <Option value="St. Vincent">St. Vincent</Option>
                                        <Option value="Sudão">Sudão</Option>
                                        <Option value="Suécia">Suécia</Option>
                                        <Option value="Suiça">Suiça</Option>
                                        <Option value="Suriname">Suriname</Option>
                                        <Option value="Tailândia">Tailândia</Option>
                                        <Option value="Taiwan">Taiwan</Option>
                                        <Option value="Tanzânia">Tanzânia</Option>
                                        <Option value="Togo">Togo</Option>
                                        <Option value="Trinidad & Tobago">Trinidad & Tobago</Option>
                                        <Option value="Tunísia">Tunísia</Option>
                                        <Option value="Turquia">Turquia</Option>
                                        <Option value="Ucrânia">Ucrânia</Option>
                                        <Option value="Uganda">Uganda</Option>
                                        <Option value="Uruguai">Uruguai</Option>
                                        <Option value="Venezuela">Venezuela</Option>
                                        <Option value="Vietnã">Vietnã</Option>
                                        <Option value="Zaire">Zaire</Option>
                                        <Option value="Zâmbia">Zâmbia</Option>
                                        <Option value="Zimbábue">Zimbábue</Option>
                                    </Select>
                                </Form.Item>
                                {temMotivo && (
                                    <Form.Item label="Motivo">
                                        <TextArea name="motivo" value={motivo} onChange={this.handleChange} rows={4} style={{ height: "100%" }} />
                                    </Form.Item>
                                )}
                            </div>
                            <div className="bloco">
                                <h3 className="titulo-separador">Filiação</h3>
                                <Form.Item label="Nome da Mãe">
                                    <Input name="responsavel_1" value={responsavel_1} onChange={this.handleChange} />
                                </Form.Item>
                                <Form.Item label="Nome da Pai">
                                    <Input name="responsavel_2" value={responsavel_2} onChange={this.handleChange} />
                                </Form.Item>
                            </div>
                            <div className="bloco">
                                <h3 className="titulo-separador">Ficha de inscrição - Contrato {localStorage.getItem("tipo_inscricao") === "MANUAL" ? "Manual" : "Digital"}</h3>
                                {localStorage.getItem("tipo_inscricao") === "DIGITAL" ?
                                    <>
                                        <Form.Item label="Nome">
                                            {getFieldDecorator("nomeID", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório",
                                                        whitespace: true
                                                    }
                                                ],
                                                initialValue: nomeID
                                            })(<Input name="nomeID" onChange={this.handleChange} />)}
                                        </Form.Item>
                                        <Form.Item
                                            label={
                                                <>
                                                    Identificação/RG
                                        <Tooltip className="info-icon" title="Documento de Identificação, Passaporte, ...">
                                                        <Icon type="question-circle-o" />
                                                    </Tooltip>
                                                </>
                                            }
                                        >
                                            <Input name="ccID" value={ccID} onChange={this.handleChange} />
                                        </Form.Item>
                                        <Form.Item
                                            label={
                                                <>
                                                    Validade
                                        <Tooltip className="info-icon" className="info-icon" title="Validade do documento de identificação">
                                                        <Icon type="question-circle-o" />
                                                    </Tooltip>
                                                </>
                                            }
                                        >
                                            <DatePicker
                                                className="input-50"
                                                placeholder="Selecionar"
                                                format="DD-MM-YYYY"
                                                name="dt_ccvalidade"
                                                value={dt_ccvalidadeID ? moment(dt_ccvalidadeID) : null}
                                                onChange={this.handleChangeDataCcID}
                                            />
                                        </Form.Item>
                                    </> : null}
                                {anexo && (
                                    <Form.Item label="Modelo para descarregar">
                                        <label className="titulo-donwload">
                                            Modelo para descarregar:
                                            <a href={anexo} className="botao-icon-download margin-left" download="Ficha de inscrição.pdf">
                                                <Icon type="download" />
                                            </a>
                                        </label>
                                    </Form.Item>
                                )}
                                <Form.Item label="Submeter ficha preenchida">
                                    <Dragger {...propsInscricao} fileList={ficheiro_inscricao}>
                                        <p className="ant-upload-drag-icon">
                                            <i className="fas fa-upload" />
                                        </p>
                                        <p className="ant-upload-text">
                                            Adicionar anexo{" "}
                                            <Tooltip title="Formato válido: .pdf">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </p>
                                        <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                    </Dragger>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        <button className="botao-principal" type="primary" disabled={iconLoading} onClick={this.guardar}>
                            {iconLoading ? <Icon type="loading" /> : null}
                            Guardar
                        </button>
                    </div>
                </Drawer>
            </>
        );
    }
}

const FormDrawerDetalheInscricao = Form.create({ name: "drawer-detalhe-inscricao" })(DrawerDetalheInscricao);

export default FormDrawerDetalheInscricao;
