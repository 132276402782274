import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Icon, Skeleton, notification } from "antd";
import { GeralContext } from "./ContextProvider";
import { GlobalContext } from "../GlobalState";
import noimage from "../images/noimage.png";
import axios from "axios";
import LazyLoad from "react-lazy-load";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Header extends Component {
    static contextType = GeralContext;
    state = {
        //DADOS
        cursoId: 0,
        imagem: "",
        id: 0,
        codigo: "",
        nome: "",
        edicao: "",
        ano: "",
        turma: "",
        nivel: "",
        turno: "",
        periodo: "",
        objectivo: "",
        avaliacao: "",
        totalAvaliacao: "",
        coordenador: "",
        dt_curso: "",
        linguas: [],
        linguasLegendas: [],
        formacao: "",
        progress: 0,
        classificacao: "",
        concluido: "",
        inscricao: false,
        aula: "",
        retomar: "",
        fechado: false,
        escola: "",
        favorito: false,
        //DRAWER
        visible: false,
        loading: true,
        //INSCRICAO
        inscrito: false,
        preco: "",
        subscricao: false,
        tipologia: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        loaded: false,
        //MULTISSERIADO
        multisseriados: [],
        bloquear: false,
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        var cursoId = localStorage.getItem("codigo_curso");

        this.setState({
            cursoId
        });
        this.carregarInfo(cursoId);
        this.validarPagina();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.turma !== prevProps.match.params.turma) {
            var cursoId = localStorage.getItem("codigo_curso");

            this.setState({
                cursoId
            });
            this.carregarInfo(cursoId);
        }

        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.validarPagina();
        }
    }

    //CARREGA A INFORMAÇÃO DO CURSO
    carregarInfo = cursoid => {
        axios({
            method: "get",
            url: "/api/gerir-turma/carregar-header-info",
            params: {
                cursoid: cursoid
            }
        })
            .then(response => {
                this.context.atualizar({
                    fechado: response.data.fechado,
                    subcursos: response.data.subcursos,
                    dt_fechado: response.data.dt_fechado,
                    dt_inicio: response.data.dt_inicio,
                    dt_fim: response.data.dt_fim,
                    habilitar_competencias: response.data.habilitar_competencias,
                    habilitar_gamificacao: response.data.habilitar_gamificacao,
                    diarioCurso: response.data.diario,
                    tipoDiarioCurso: response.data.tipo_diario,
                    professorPolivalente: response.data.professor_polivalente,
                    tipoAvaliacao: response.data.tipoAvaliacao,
                    temFicha: response.data.temFicha,
                    pareceresPorAula: response.data.pareceres_por_aula,
                    existeProfTitular: response.data.existe_prof_titular,
                    temPermissao: response.data.tem_permissao,
                });

                if (response.data.inscricao) this.context.atualizarInscricao(response.data.inscrito);

                this.setState({
                    id: response.data.id,
                    codigo: response.data.codigo,
                    nome: response.data.nome,
                    edicao: response.data.edicao,
                    ano: response.data.ano,
                    turma: response.data.turma,
                    nivel: response.data.nivel,
                    turno: response.data.turno,
                    periodo: response.data.periodo ? JSON.parse(response.data.periodo).toString() : "",
                    disciplina: response.data.subcurso,
                    fechado: response.data.fechado,
                    escola: response.data.escola,
                    multisseriados: response.data.multisseriados,
                    loading: false
                });

                localStorage.setItem("curso_fechado", response.data.fechado);
                localStorage.setItem("habilitar_competencias", response.data.habilitar_competencias);
                localStorage.setItem("habilitar_gamificacao", response.data.habilitar_gamificacao);
                localStorage.setItem("nome_curso", response.data.nome);
                localStorage.setItem("header_nome", response.data.nome);
                localStorage.setItem("header_codigo", response.data.codigo);
                localStorage.setItem("nome_curso_pai", "");
                localStorage.setItem("tipoDiario", response.data.tipo_diario);
                localStorage.setItem("organismo", response.data.id_organismo);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    validarPagina = () => {
        this.setState({
            bloquear: this.props.match.params.pagina === "aproveitamento-conceitos-alunos"
        })
    }

    render() {
        const {
            //DADOS
            id,
            codigo,
            nome,
            edicao,
            ano,
            turma,
            nivel,
            turno,
            periodo,
            disciplina,
            fechado,
            escola,
            //REDIRECT
            redirect,
            redirectLink,
            loaded,
            //MULTISSERIADO
            multisseriados,
            bloquear
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const validarAtivo = (nome) => {
            const nomeUrl = nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase();
            
            return this.props.match.params.turma === nomeUrl ? "ativo" : "";
        }

        return (
            <GlobalContext.Consumer>
                {GlobalContext => {
                    if (!loaded && fechado) {
                        GlobalContext.atualizarState({ fechado });
                        this.setState({
                            loaded: true
                        });
                    }

                    return (
                        <div className="header-cursos" style={{ height: "auto" }}>
                            <div className="container container-multisseriado">
                                <Skeleton active={this.state.loading} loading={this.state.loading}>
                                    <div className="completo">
                                        {!disciplina ?
                                            (<>
                                                <div className="titulo-favorito titulo-multisseriado">
                                                    <div className="titulo-curso">
                                                        {multisseriados.map(turma => (
                                                            <h2 className={validarAtivo(turma.nome)} title={turma.nome} onClick={() => localStorage.setItem("codigo_curso", turma.id)}>
                                                                <Link to={`/gerir-multisseriado/${turma.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${this.props.match.params.pagina}`}>{turma.codigo} - {turma.nome}</Link>
                                                            </h2>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>)
                                            :
                                            (<>
                                                <div className="titulo-favorito titulo-multisseriado titulo-multisseriado-disciplina">
                                                    <div className="titulo-curso">
                                                        {multisseriados.map(turma => (
                                                            <>
                                                                {
                                                                    bloquear ?
                                                                        <h2 className={`${validarAtivo(turma.nome + nome)} h2-disabled`} title={turma.nome}>
                                                                            <Link to="#" disabled={bloquear}>{turma.codigo} - {turma.nome}</Link>
                                                                        </h2>
                                                                        :
                                                                        <h2 className={`${validarAtivo(turma.nome + nome)}`} title={turma.nome} onClick={() => localStorage.setItem("codigo_curso", turma.disciplina.id)}>
                                                                            {this.props.match.params.code ?
                                                                                <Link to={`/gerir-multisseriado/${turma.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}${nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${this.props.match.params.pagina}/${this.props.match.params.code}`}>{turma.codigo} - {turma.nome}</Link>
                                                                                :
                                                                                <Link to={`/gerir-multisseriado/${turma.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}${nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${this.props.match.params.pagina}`}>{turma.codigo} - {turma.nome}</Link>
                                                                            }
                                                                        </h2>
                                                                }
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="titulo-favorito">
                                                    <div className="titulo-curso">
                                                        <h2 title={nome}>
                                                            {codigo} - {nome}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </>
                                            )} 
                                        <div className="bloco-rating-info bloco-rating-info-desktop">
                                            <div className="bloco-esquerda info-items">
                                                {fechado && (
                                                    <span
                                                        className="info-item tag-fechado"
                                                        title="Uma vez que curso está fechado, não é possível realizar avaliações, questionários e trabalhos"
                                                    >
                                                        Fechado
                                                    </span>
                                                )}
                                                {disciplina && (ano || turma) && (
                                                    <span className="info-item">
                                                        {ano ? ano : ""} {turma ? turma : ""}
                                                    </span>
                                                )}
                                                {nivel && <span className="info-item">{nivel}</span>}
                                                {turno && <span className="info-item">{turno}</span>}
                                                {periodo && <span className="info-item">{periodo}</span>}
                                                {edicao && <span className="info-item">{edicao}</span>}
                                                {escola && GlobalContext.escolas_entidade && <span className="info-item">{escola}</span>}
                                            </div>
                                        </div>
                                        <div className="bloco-rating-info bloco-rating-info-mobile">
                                            <div className="bloco-items info-items">
                                                {fechado && (
                                                    <span
                                                        className="info-item tag-fechado"
                                                        title="Uma vez que curso está fechado, não é possível realizar avaliações, questionários e trabalhos"
                                                    >
                                                        <Icon className="alerta" type="warning" />
                                                        Fechado
                                                    </span>
                                                )}
                                                {disciplina && (
                                                    <span className="info-item">
                                                        {ano ? ano : ""} {turma ? turma : ""}
                                                    </span>
                                                )}
                                                {nivel && <span className="info-item">{nivel}</span>}
                                                {turno && <span className="info-item">{turno}</span>}
                                                {periodo && <span className="info-item">{periodo}</span>}
                                                {edicao && <span className="info-item">{edicao}</span>}
                                                {escola && GlobalContext.escolas_entidade && <span className="info-item">{escola}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </Skeleton>
                            </div>
                        </div>
                    );
                }}
            </GlobalContext.Consumer>
        );
    }
}

export default withRouter(Header);
