import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, Input, notification, Icon, Drawer } from "antd";
import { GeralContext } from "../ContextProvider";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerNovaPergunta extends Component {
    static contextType = GeralContext;
    state = {
        cursoid: 0,
        retomar: {},
        topico: "",
        descricao: "",
        redirect: false,
        iconLoading: false,
        total_conteudos: 0,
        total_conteudos_realizados: 0
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    //handleChangeEditor = descricao => {
    //    this.setState({ descricao });
    //};

    //ADICIONA NOVO TOPICO
    addTopico = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id_curso", localStorage.getItem("codigo_curso"));
                item.append("topico", this.state.topico);
                item.append("texto", this.state.descricao);

                axios({
                    method: "post",
                    url: "/api/gerir-forum/add-topico",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Novo tópico criado!");
                        this.context.atualizarForum(this.context.total_topicos + 1);

                        this.setState({
                            iconLoading: false
                        });

                        this.props.form.resetFields();
                        this.props.Adicionar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível criar o tópico!");
                    });
            }
        });
    };

    render() {
        const { iconLoading } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;

        return (
            <Drawer
                className="drawer-add-cursos"
                //title="Adicionar"
                width={720}
                onClose={this.props.onClose}
                visible={this.props.visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
            >
                <div className="bloco-info">
                    <Form className="form-nova-pergunta">
                        <Form.Item label="Tópico" style={{ flex: "0 0 50%" }}>
                            {getFieldDecorator("topico", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(<Input name="topico" maxLength={35} onChange={this.handleChange} />)}
                        </Form.Item>
                        <Form.Item label="Descrição">
                            {getFieldDecorator("descricao", {
                            })(<TextArea name="descricao" maxLength={300} onChange={this.handleChange} style={{ height: "100%" }} rows={15} />)}
                        </Form.Item>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    <button className="botao-principal" onClick={this.addTopico} disabled={iconLoading}>
                        {iconLoading ? <Icon type="loading" /> : null}
                        Guardar
                    </button>
                </div>
            </Drawer>
        );
    }
}

const FormDrawerNovaPergunta = Form.create({ name: "drawer-nova-pergunta" })(DrawerNovaPergunta);

export default withRouter(FormDrawerNovaPergunta);
