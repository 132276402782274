import React, { useState, useEffect, useContext } from "react";
import { Redirect, Link, withRouter, useLocation } from "react-router-dom";
import { Dropdown, Menu, Table, notification, Modal, Icon } from "antd";
import axios from "axios";
import moment from "moment";

import { GlobalContext } from "../../../GlobalState";

import DrawerAvalicao from "../Drawer/DrawerAvalicao";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Notas = ({ estruturaId, periodo, tipo, match }) => {
    const context = useContext(GlobalContext);

    const [permissao, setPermissao] = useState([]);
    const [avaliacoes, setAvalicoes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    const [grupoId, setGrupoId] = useState(0);
    const [pautaId, setPautaId] = useState(0);
    const [bloqueada, setBloqueada] = useState(false);
    const [visible, setVisible] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    //MODAL
    const [visibleModal, setVisibleModal] = useState(false);
    //MODAL
    const [modalVisible, setModalVisible] = useState(false);
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [docs, setDocs] = useState([]);

    const acesso = context.acessos?.find(x => x.func === 21);
    const acessoInvalidar = context.acessos?.find(x => x.func === 24);

    const location = useLocation();

    const infoProf = () => {
        axios({
            method: "get",
            url: "/api/turma/info-permissao",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setPermissao(response.data)
            })
            .catch(error => {

            });
    }

    const listar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/aproveitamento/listar-items-avaliacao-conceitos",
            params: {
                disciplinaId: localStorage.getItem("codigo_curso"),
                estruturaId: estruturaId
            }
        })
            .then(response => {
                let avaliacoes = [];

                response.data.map(item => {
                    //let data = "";
                    //let estado = "";

                    //if (item.guardado) {
                    //    data = moment(item.dt_guardado).format("DD/MM/YYYY");
                    //    estado = "Por publicar";
                    //}

                    //if (item.publicado) {
                    //    data = moment(item.dt_publicado).format("DD/MM/YYYY");
                    //    estado = "Publicado";
                    //}

                    //if (item.validado) {
                    //    data = moment(item.dt_validado).format("DD/MM/YYYY");
                    //    estado = "Validado";
                    //}

                    avaliacoes.push({
                        key: item.id,
                        id: item.id,
                        code: item.code,
                        epoca: item.epoca,
                        nome: item.titulo,
                        //autor: item.autor,
                        //data: data,
                        //estado: estado,
                        ordem: item.ordem,
                        campoCalculado: item.campoCalculado
                    });
                });

                setLoading(false);
                setAvalicoes(avaliacoes);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    };

    const listarsubItems = (expanded, record) => {
        const expandedKeys = [...expandedRows];
        if (expanded) {
            expandedKeys.push(record.key);
        } else {
            const index = expandedKeys.indexOf(record.key);
            if (index !== -1) {
                expandedKeys.splice(index, 1);
            }
        }
        setExpandedRows(expandedKeys);

        if (expanded)
            carregarSubItems(record.id)
    };

    const carregarSubItems = (grupoId) => {
        axios({
            method: "get",
            url: "/api/aproveitamento/listar-items-avaliacao-subitems",
            params: {
                grupoId
            }
        })
            .then(response => {
                let lista = [];

                response.data.map(item => {
                    let data = "";
                    let autor = "";
                    let estado = "";

                    if (item.guardado) {
                        data = moment(item.dt_guardado).format("DD/MM/YYYY");
                        autor = item.autor_guardado;
                        estado = "Por publicar";
                    }

                    if (item.publicado) {
                        data = moment(item.dt_publicado).format("DD/MM/YYYY");
                        autor = item.autor_publicado;
                        estado = "Publicado";
                    }

                    if (item.validado) {
                        data = moment(item.dt_validado).format("DD/MM/YYYY");
                        autor = item.autor_validado;
                        estado = "Validado";
                    }

                    lista.push({
                        key: item.id,
                        id: item.id,
                        code: item.code,
                        epoca: item.epoca,
                        nome: item.titulo,
                        autor: autor,
                        data: data,
                        //estado: estado,
                        ordem: item.ordem,
                        campoCalculado: item.campoCalculado,
                        opcoes: (item.podeInteragir && (acesso?.update || acesso?.delete)) && (
                            <Dropdown overlay={montarMenu(grupoId, item.id, item.processado, item.campoCalculado, item.mediaDisciplinas, item.bloqueada, item.validado)} placement="bottomLeft">
                                <Link to="#" className="botao-icon-configs">
                                    <i className="fas fa-cog" />
                                </Link>
                            </Dropdown>
                        )
                    });
                });

                var tempAvaliacoes = avaliacoes;
                var index = tempAvaliacoes.findIndex(x => x.id === grupoId)
                tempAvaliacoes[index].subItems = lista
                setAvalicoes([...tempAvaliacoes]);
            })
            .catch(error => {
                if (error.response.data)
                    openNotificationWithIcon("error", "Erro", error.response.data);
            });
    }

    const isRowExpanded = (record) => {
        return expandedRows.includes(record.key);
    };

    const processar = (pautaId, mediaDisciplinas) => {
        confirm({
            title: "Pretende processar as notas?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                if (!mediaDisciplinas)
                    axios({
                        method: "put",
                        url: "/api/aproveitamento/processar",
                        params: {
                            pautaId,
                            disciplinaId: localStorage.getItem("codigo_curso")
                        }
                    })
                        .then(response => {
                            openNotificationWithIcon("success", "Sucesso", response.data);
                        })
                        .catch(error => {
                            openNotificationWithIcon("error", "Erro",
                                <div>
                                    <p>{error.response.data.mensagem}</p>
                                    {error.response.data.avaliacoes && (
                                        <ul>
                                            {error.response.data.avaliacoes.map(avaliacao => (
                                                <li>{avaliacao}</li>
                                            ))}
                                        </ul>)}
                                </div>
                            );
                        });
                else
                    axios({
                        method: "put",
                        url: "/api/aproveitamento/processar-pauta-turma",
                        params: {
                            pautaId,
                            disciplinaId: localStorage.getItem("codigo_curso")
                        }
                    })
                        .then(response => {
                            openNotificationWithIcon("success", "Sucesso", response.data);
                        })
                        .catch(error => {
                            openNotificationWithIcon("error", "Erro",
                                <div>
                                    <p>{error.response.data.mensagem}</p>
                                    {error.response.data.avaliacoes && (
                                        <ul>
                                            {error.response.data.avaliacoes.map(avaliacao => (
                                                <li>{avaliacao}</li>
                                            ))}
                                        </ul>)}
                                </div>
                            );
                        });
            }
        });
    }

    const excluir = (pautaId) => {
        confirm({
            title: "Pretende excluir esta avaliação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/aproveitamento/excluir",
                    params: {
                        pautaId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", response.data);
                        listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        });
    }

    const invalidar = (pautaId) => {
        confirm({
            title: "Pretende invalidar esta avaliação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/aproveitamento/invalidar",
                    params: {
                        pautaId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", response.data);
                        listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        });
    }

    const montarMenu = (grupoId, pautaId, processado, campoCalculado, mediaDisciplinas, bloqueada, validado) => {
        return (
            <Menu>
                {(!processado && campoCalculado && acesso?.update) && (
                    <Menu.Item>
                        <Link to="#" onClick={(event) => { event.stopPropagation(); processar(pautaId, mediaDisciplinas); }}>
                            Processar
                        </Link>
                    </Menu.Item>)}
                {acesso?.update && (
                    <Menu.Item>
                        <Link to="#" onClick={(event) => { event.stopPropagation(); setVisible(true); setPautaId(pautaId); setGrupoId(grupoId); setBloqueada(bloqueada); }}>
                            Editar
                        </Link>
                    </Menu.Item>)}
                {(!bloqueada && acesso?.delete) && (
                    <Menu.Item>
                        <Link to="#" onClick={(event) => { event.stopPropagation(); excluir(pautaId); }}>
                            Excluir
                        </Link>
                    </Menu.Item>)}
                {(validado && acessoInvalidar) && (
                    <Menu.Item>
                        <Link to="#" onClick={(event) => { event.stopPropagation(); invalidar(pautaId) }}>
                            Invalidar
                        </Link>
                    </Menu.Item>)}
            </Menu>
        );
    };

    const onRowClick = (epoca, grupoId, grupo, record) => {
        localStorage.setItem(record.code, JSON.stringify({ epoca, grupoId, grupo, pauta: record.nome }))
        setRedirectLink(`/gerir-multisseriado/${match.params.turma.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/aproveitamento-notas/${record.code}`);
        setRedirect(true);

        localStorage.setItem("aproveitamento", JSON.stringify({
            estrutura: estruturaId,
            periodo: periodo,
            tipo: tipo
        }))
    }

    const montarCores = (estado) => {
        if (estado === "Por publicar")
            return <span className="pauta-estado estado-por-publicar">Por publicar</span>

        if (estado === "Publicado")
            return <span className="pauta-estado estado-publicado">Publicado</span>

        if (estado === "Validado")
            return <span className="pauta-estado estado-validado">Validado</span>

        return null;
    }

    useEffect(() => {
        infoProf();
        listar();
    }, [estruturaId, location.pathname])

    useEffect(() => {
        if (localStorage.getItem("btn_add") === "true") {
            setVisible(true);
            setPautaId(0);
        }

    }, [localStorage.getItem("btn_add")])

    const columns = [
        {
            title: "Época",
            dataIndex: "epoca",
            className: "bold-field"
        },
        {
            title: "Nome",
            dataIndex: "nome",
            className: "bold-field",
            render: (text, record) => text,
        },
        {
            title: "", //"Registo",
            dataIndex: "autor"
        },
        {
            title: "", //"Data",
            dataIndex: "data"
        },
        {
            title: "", //"Estado",
            dataIndex: "estado",
            render: (text, record) => montarCores(text)
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    const expandedRowRender = (record) => {
        const columns = [
            {
                title: "Conceito",
                dataIndex: "nome",
                render: (text, record) => text,
            },
            {
                title: "Registo",
                dataIndex: "autor"
            },
            {
                title: "Data",
                dataIndex: "data"
            },
            {
                title: "Estado",
                dataIndex: "estado",
                render: (text, record) => montarCores(text)
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        return (<Table
            id="disciplinas"
            className="tabela-sem-header"
            columns={columns}
            dataSource={record.subItems}
            pagination={false}
            locale={{ emptyText: "Não existem dados!" }}
            rowClassName={acesso?.read ? "clickeble-row" : ""}
            onRow={record2 => {
                return {
                    onClick: acesso?.read ? () => onRowClick(record.epoca, record.id, record.nome, record2) : () => { }
                };
            }}
        />)
    }

    if (redirect) return <Redirect to={redirectLink} />;

    return (
        <>
            <div className="bloco">
                <Table
                    id="cursos-disciplinas"
                    loading={{
                        spinning: loading,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading" />
                            </div>
                        )
                    }}
                    columns={columns}
                    dataSource={avaliacoes}
                    pagination={false}
                    locale={{ emptyText: "Não existem dados!" }}
                    onExpand={listarsubItems}
                    rowClassName={(record, index) =>
                        isRowExpanded(record) ? 'parent-row-expanded' : ''
                    }
                    expandedRowRender={expandedRowRender}
                />
            </div>
            <DrawerAvalicao
                estruturaId={estruturaId}
                pautaId={pautaId}
                bloqueada={bloqueada}
                ordem={avaliacoes.length > 0 ? avaliacoes[avaliacoes.length - 1].ordem + 1 : 1}
                visible={visible}
                onClose={() => { setVisible(false); localStorage.setItem("btn_add", false); }}
                atualizar={() => { setVisible(false); carregarSubItems(grupoId); localStorage.setItem("btn_add", false); }}
            />
        </>
    );
}
export default withRouter(Notas);
