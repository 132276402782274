import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, notification, Icon, Form, Input, Checkbox, Modal, Select, DatePicker, TimePicker } from "antd";
import axios from "axios";
import { GlobalContext } from "../../../GlobalState";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerCalendarioAtividade extends Component {
    static contextType = GlobalContext;
    state = {
        nome: "",
        descricao: "",
        datas: [],
        inicio: undefined,
        hrInicio: null,
        hrTermino: null,
        oradores: [
            {
                nome: "",
                texto: ""
            }
        ],
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    componentDidUpdate() {
        //if (!this.context.is_authenticated || this.context.role === "UTILIZADOR") {
        //    if (!this.context.is_authenticated)
        //        this.setState({
        //            redirect: true,
        //            redirectLink: "/login"
        //        });
        //    else if (this.context.role === "UTILIZADOR")
        //        this.setState({
        //            redirect: true,
        //            redirectLink: "/user"
        //        });
        //}
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeInicio = (date) => {
        this.setState({ inicio: date });
    };

    handleChangeTermino = (date) => {
        this.setState({ termino: date });
    };

    handleChangeHoraInicio = hrInicio => {
        this.setState({ hrInicio });
    };

    handleChangeHoraTermino = hrTermino => {
        this.setState({ hrTermino });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeNomeOrador = (event, index) => {
        let _oradores = this.state.oradores;
        _oradores[index].nome = event.target.value;

        this.setState({ oradores: _oradores });
    };

    handleChangeTextoOrador = (event, index) => {
        let _oradores = this.state.oradores;
        _oradores[index].texto = event.target.value;

        this.setState({ oradores: _oradores });
    };

    excluir = (index) => {
        this.props.form.resetFields();
        let _oradores = this.state.oradores;
        _oradores.splice(index, 1)

        this.setState({
            oradores: _oradores
        })
    }

    //ABRE O DRAWER COM OS DADOS DA CATEGORIA
    carregarInfo = atividadeId => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/gestao-calendario-eventos-atividades/detalhe",
            params: {
                atividadeId
            }
        })
            .then(response => {
                const info = response.data;
                const oradores = JSON.parse(info.oradores)

                this.setState({
                    nome: info.nome,
                    descricao: info.descricao,
                    inicio: moment(info.inicio).format("DD/MM/YYYY"),
                    hrInicio: info.hr_inicio ? moment(info.hr_inicio, "HH:mm") : null,
                    hrTermino: info.hr_termino ? moment(info.hr_termino, "HH:mm") : null,
                    oradores
                });
        })
        .catch(() => { });
    };

    //CRIA UMA NOVA CATEGORIA
    criar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id_calendario", this.props.calendarioId);
                item.append("nome", this.state.nome);
                item.append("descricao", this.state.descricao);
                if (this.state.datas.length > 0)
                    item.append("dt_inicio", this.state.datas.length > 1 ? this.state.inicio : this.state.datas[0]);
                item.append("hr_inicio", this.state.hrInicio.format("HH:mm"));
                item.append("hr_termino", this.state.hrTermino.format("HH:mm"));
                item.append("oradores", JSON.stringify(this.state.oradores));

                axios({
                    method: "post",
                    url: "/api/gestao-calendario-eventos-atividades/adicionar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Adicionado novo evento!");
                        this.setState({
                            iconLoading: false
                        });
                        this.props.atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //ATUALIZA A COMPETENCIA
    alterar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.atividadeId);
                item.append("id_calendario", this.props.calendarioId);
                item.append("nome", this.state.nome);
                item.append("descricao", this.state.descricao);
                if (this.state.datas.length > 0)
                    item.append("dt_inicio", this.state.datas.length > 1 ? this.state.inicio : this.state.datas[0]);
                item.append("hr_inicio", this.state.hrInicio.format("HH:mm"));
                item.append("hr_termino", this.state.hrTermino.format("HH:mm"));
                item.append("oradores", JSON.stringify(this.state.oradores));

                axios({
                    method: "put",
                    url: "/api/gestao-calendario-eventos-atividades/alterar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Evento alterada!");
                        this.setState({
                            iconLoading: false
                        });
                        this.props.atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    getDatesBetween = (startDate, endDate) => {
        var dates = [];

        var currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            dates.push(moment(new Date(currentDate)).format("DD/MM/YYYY"));

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    }

    disabledDate = (current) => {
        return current < moment(this.props.inicioCalendario, "DD-MM-YYYY").startOf("day") || current > moment(this.props.terminoCalendario, "DD-MM-YYYY").endOf("day");
    }

    afterVisibleChange = aberto => {
        if (aberto) {
            const datas = this.getDatesBetween(moment(this.props.inicioCalendario, "DD-MM-YYYY").startOf("day"), moment(this.props.terminoCalendario, "DD-MM-YYYY").endOf("day"));
            if (this.props.atividadeId) {
                this.carregarInfo(this.props.atividadeId);
                this.setState({
                    datas
                })
            }
            else {
                this.props.form.resetFields();
                this.setState({
                    nome: "",
                    descricao: "",
                    datas,
                    inicio: undefined,
                    hrInicio: null,
                    hrTermino: null,
                    oradores: [
                        {
                            nome: "",
                            texto: ""
                        }
                    ],
                    //LOADING
                    iconLoading: false,
                });
            }
        }
    };

    render() {
        const {
            nome,
            descricao,
            datas,
            inicio,
            hrInicio,
            hrTermino,
            oradores,
            //LOADING
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        const {
            atividadeId,
            visible,
            onClose
        } = this.props;

        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <Drawer
                className="drawer-add-cursos drawer-add-eventos"
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-competencias" layout="horizontal">
                        <div className="bloco">
                            <Form.Item label="Item do Evento">
                                {getFieldDecorator("nome", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: nome
                                })(<Input name="nome" maxLength={75} onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="Detalhe">
                                {getFieldDecorator("descricao", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: descricao
                                })(<TextArea name="descricao" onChange={this.handleChange} rows={4} style={{ height: 100 }} />)}
                            </Form.Item>
                            {datas.length > 1 && (
                                <Form.Item label="Inicio">
                                    {getFieldDecorator("inicio", {
                                        initialValue: inicio,
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ]
                                    })(
                                        <Select placeholder="Selecionar" allowClear={true} onChange={inicio => this.setState({ inicio })}>
                                            {datas.map((data, index) => (
                                                <Option key={index} value={data}>{data}</Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>)}
                            <Form.Item label="Hora Inicio">
                                {getFieldDecorator("hrInicio", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: hrInicio
                                })(
                                    <TimePicker
                                        className="input-25"
                                        name="duracaoAula"
                                        defaultOpenValue={moment("00:00", "HH:mm")}
                                        format="HH:mm"
                                        onChange={this.handleChangeHoraInicio}
                                        placeholder="Selecionar"
                                        suffixIcon={false}
                                        clearIcon={false}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="Hora Término">
                                {getFieldDecorator("hrTermino", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: hrTermino
                                })(
                                    <TimePicker
                                        className="input-25"
                                        name="duracaoAula"
                                        defaultOpenValue={moment("00:00", "HH:mm")}
                                        format="HH:mm"
                                        onChange={this.handleChangeHoraTermino}
                                        placeholder="Selecionar"
                                        suffixIcon={false}
                                        clearIcon={false}
                                    />
                                )}
                            </Form.Item>
                            <div className="bloco bloco-fases-escolares">
                                <h3 className="titulo-separador">
                                    <span> Oradores</span>
                                    <button className="botao-principal" onClick={(event) => { event.stopPropagation(); this.setState({ oradores: [...this.state.oradores, { nome: "", texto: "" }] }) }}>
                                        <Icon type="plus" />
                                    </button>
                                </h3>
                                <div className="lista-oradores">
                                    {oradores.map((orador, index) => (
                                        <div key={index} className="item-orador">
                                            <div className="item-inputs">
                                                <Form.Item label="Nome">
                                                    {getFieldDecorator(`nome${index}`, {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Campo obrigatório"
                                                            }
                                                        ],
                                                        initialValue: orador.nome
                                                    })(<Input name={`nome${index}`} maxLength={75} onChange={(e) => this.handleChangeNomeOrador(e, index)} />)}
                                                </Form.Item>
                                            </div>
                                            <Link className="botao-icon-excluir" to="#" title="Excluir" onClick={() => this.excluir(index)}>
                                                <Icon type="delete" />
                                            </Link>
                                        </div>
                                    ))}
                                    {!oradores.length && "Sem dados..."}
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    <button className="botao-principal" onClick={atividadeId ? this.alterar : this.criar} disabled={iconLoading} type="primary">
                        {iconLoading ? <Icon type="loading" /> : null}
                        Guardar
                    </button>
                </div>
            </Drawer>
        );
    }
}
const FormDrawerCalendarioAtividade = Form.create({ name: "form-drawer-calenario-atividade" })(DrawerCalendarioAtividade);

export default FormDrawerCalendarioAtividade;
