import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Icon, notification, Modal, Button, Collapse } from "antd";
import ParecerAluno from "./_Aux/ParecerAluno";
import ParecerGeral from "./_Aux/ParecerGeral";
import axios from "axios";

const { Panel } = Collapse;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ListaPareceres extends Component {
    state = {
        num: 1,
        total: 0,
        semPeriodos: false,
        periodos: [],
        //PESQUISA
        dt_inicio: "",
        dt_fim: "",
        //PERIODO
        mes: "",
        ano: "",
        titulo: "",
        aulasAlunos: [],
        aulas: [],
        participantes: [],
        //LOADING
        iconLoading: false,
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
    }

    componentDidMount() {
        this.total();
    }

    total = () => {
        this.setState({
            iconLoading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/aulas/total-periodos-observacoes",
                params: {
                    cursoId: localStorage.getItem("codigo_curso")
                }
            })
                .then(response => {
                    if (response.data.length > 0) {
                        this.setState({
                            total: response.data.length,
                            periodos: response.data,
                            titulo: response.data[0].titulo,
                            dt_inicio: response.data[0].dt_inicio,
                            dt_fim: response.data[0].dt_fim,
                        }, () => {
                            this.listarParecerAluno();
                            this.listarPareceres();
                        })
                    }
                    else {
                        this.setState({
                            semPeriodos: true,
                            iconLoading: false
                        })
                        openNotificationWithIcon("warning", "Atenção", "Não foram adicionados períodos ao curso!");
                    }
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
                });
        })
    }

    listarParecerAluno = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-pareceres-alunos",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                dt_inicio: this.state.dt_inicio,
                dt_fim: this.state.dt_fim
            }
        })
            .then(response => {
                this.setState({
                    aulasAlunos: response.data.aulas,
                    participantes: response.data.participantes,
                    iconLoading: false
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar os pareceres!");
            });
    }

    listarPareceres = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-pareceres",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                dt_inicio: this.state.dt_inicio,
                dt_fim: this.state.dt_fim
            }
        })
            .then(response => {
                this.setState({
                    aulas: response.data,
                    iconLoading: false
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar os pareceres!");
            });
    }

    anteriorProximo = (num) => {
        var periodo = this.state.periodos[num - 1];
        this.setState({
            num,
            titulo: periodo.titulo,
            dt_inicio: periodo.dt_inicio,
            dt_fim: periodo.dt_fim,
            iconLoading: true
        }, () => {
            this.listarParecerAluno();
            this.listarPareceres();
        })
    }

    exportar = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas-exportar/pareceres",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    }

    exportarPdf = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas-exportar/pareceres-pdf",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    }

    render() {
        const {
            num,
            semPeriodos,
            periodos,
            //PERIODO
            titulo,
            aulasAlunos,
            aulas,
            participantes,
            //LOADING
            iconLoading,
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar
        } = this.state;

        return (
            <>
                <div className="container container-body">
                    <div className="bloco-curso-diario-opcoes-end">
                        <Link to={`/gerir-curso/${this.props.match.params.curso}/curso-diario`} className="botao-icon-voltar" title="Voltar">
                            <Icon type="arrow-left" />
                        </Link>
                        <Link className="botao-icon-pdf" to="#" title="Descarregar" onClick={this.exportarPdf}>
                            <Icon type="file-pdf" />
                        </Link>
                        <Link className="botao-icon-download" to="#" title="Descarregar" onClick={this.exportar}>
                            <Icon type="download" />
                        </Link>
                    </div>
                    <div className="curso-diario">
                        <div className="bloco-controlos-frequencia">
                            {!semPeriodos ?
                                <div>
                                    <Button className='botao-periodo botao-periodo-anterior' disabled={num === 1} style={{ color: "rgba(0, 0, 0, 0.65)" }} onClick={() => this.anteriorProximo(num - 1)}>
                                        <Icon type="left" />
                                    </Button>
                                    <span>{titulo}</span>
                                    <Button className='botao-periodo botao-periodo-proximo' disabled={num === periodos.length} style={{ color: "rgba(0, 0, 0, 0.65)" }} onClick={() => this.anteriorProximo(num + 1)}>
                                        <Icon type="right" />
                                    </Button>
                                </div>
                                : null}
                        </div>
                        <Collapse defaultActiveKey={[]} className="collapse-diario-observacoes">
                            <Panel header="Pareceres por Aluno" key="1">
                                {!aulasAlunos.length ? "Sem dados..." : null}
                                {aulasAlunos.map((aula, index) => (
                                    <ParecerAluno
                                        key={index}
                                        aula={aula}
                                        participantes={participantes}
                                    />
                                ))}
                            </Panel>
                            <Panel header="Pareceres Gerais" key="2">
                                {!aulas.length ? "Sem dados..." : null}
                                {aulas.map((aula, index) => (
                                    <ParecerGeral
                                        key={index}
                                        aula={aula}
                                    />
                                ))}
                            </Panel>
                        </Collapse>
                    </div>
                </div>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
                <Modal
                    visible={visibleExportar}
                    okText={
                        <a href={ficheiroExportar} download onClick={() => this.setState({ visibleExportar: false })}>
                            Descarregar
                        </a>
                    }
                    onCancel={() => this.setState({ visibleExportar: false })}
                    cancelText="Cancelar"
                    okButtonProps={{ disabled: btnDescarregar }}
                    maskClosable={false}
                    className="exportar-csv"
                >
                    <div className="exportar-csv-bloco">
                        {btnDescarregar ? (
                            <>
                                <p>
                                    <Icon type="loading" />
                                </p>
                                <p className="texto">A gerar documento...</p>
                            </>
                        ) : (
                            <>
                                <p>
                                    <Icon type="check-circle" style={{ color: "#84bd00" }} />
                                </p>
                                <p className="texto">Dados exportados com sucesso!</p>
                                <p className="ficheiro-extensao">.{ficheiroExportar.split(".")[ficheiroExportar.split(".").length - 1]}</p>
                            </>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}

export default ListaPareceres;
