import React, { useEffect, useState } from "react";
import DropdownMeses from "./DropdownMeses";
import 'antd/dist/antd.css';
import PieChart from "./PieChart";
import axios from "axios";
import {Icon} from "antd";

export default function GraficoCircular() {
    const [datasetSource, setDatasetSource] = useState(null);
    const [ano, setAno] = useState(new Date().getFullYear());
    const [mes, setMes] = useState(new Date().getMonth() + 1);  // Convert to 1-based index

    const meses = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];
    const handlerMes = (mesSelecionado) => {
        const mesIndex = meses.indexOf(mesSelecionado) + 1;
        setMes(mesIndex);
    };

    const dados = () => {
        axios.get(`/api/dashboard/frequencia-professores?ano=${ano}&mes=${mes}`)
            .then(response => {
                // console.log(response.data[0]);
                setDatasetSource(response.data[0]);
            })
            .catch(error => {
                console.error("Erro ao carregar os dados dos alunos:", error);
            });
    };

    useEffect(() => {
        dados();
    }, [ano, mes]);

    // useEffect(() => {
    //     console.log(datasetSource)
    // }, [datasetSource]);

    return (
        <div className="graficoCircular">
            <div className="topo-tabela" style={{ position: 'relative' }}>
                <span>Frequência de Professores</span>
                {mes && <DropdownMeses alterarMes={handlerMes} mes={mes} />}
            </div>
            <div className="bordaPie">
                {datasetSource ? <PieChart data={datasetSource} /> :  <div className="carregar">
                    <div className="alinhar"><Icon type="loading"/> <span>A carregar</span></div>
            </div>}
            </div>
        </div>
    );
}