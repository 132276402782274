import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Drawer,
    Icon,
    Form,
    Input,
    InputNumber,
    Select,
    Collapse,
    notification,
    Radio,
    Checkbox
} from "antd";
import axios from "axios";
import uuid from 'react-uuid'

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerAvalicao = ({ tipo, ordem, visible, onClose, estrutura, estado, registo, atualizarAdicionado, atualizarEditado, registoPaiId, primeiroNivel, grupo, form }) => {
    const [formData, setFormData] = useState({
        codigo: "",
        nome: "",
        legenda: "",
        escala: false,
        notaMinima: 0,
        notaMaxima: 0,
        notaAprovacao: 0,
        casasDecimais: 2,
        ordem,
        visivelAluno: true,
        visivelRelatorios: false,
        visivelExportacoes: false,
        campoCalculado: "MANUAL",
        tipoFormula: "CALCULO",
        provaFinal: false,
        operador: "MAIOR"
    })
    const [escalas, setEscalas] = useState([])
    const [niveis, setNiveis] = useState([])
    const [avaliacoesSelecionadas, setAvaliacoesSelecionadas] = useState([])
    const [avaliacoes, setAvaliacoes] = useState([])
    const [iconLoading, setIconLoading] = useState(false)

    const { getFieldDecorator } = form;

    const handleChangeId = (value, index) => {
        let _avaliacoesSelecionadas = avaliacoesSelecionadas;
        _avaliacoesSelecionadas[index].id = value;

        setAvaliacoesSelecionadas([..._avaliacoesSelecionadas]);
    };

    const handleChangePeso = (event, index) => {
        let _avaliacoesSelecionadas = avaliacoesSelecionadas;
        _avaliacoesSelecionadas[index].peso = event.target.value;

        setAvaliacoesSelecionadas([..._avaliacoesSelecionadas]);
    };

    const handleChangeEscalao = (event, index) => {
        let _escalas = escalas;
        _escalas[index].escalao = event.target.value;

        setEscalas([..._escalas]);
    };

    const handleChangeNotaMaxima = (nota, index) => {
        let _escalas = escalas;
        _escalas[index].notaMaxima = nota;

        setEscalas([..._escalas]);
    };

    const handleChangeNotaMinima = (nota, index) => {
        let _escalas = escalas;
        _escalas[index].notaMinima = nota;

        setEscalas([..._escalas]);
    };

    const carregarAvaliacoes = () => {
        let lista = [];
        if (formData.mediaDisciplinas || estrutura.find(x => x.id === registoPaiId && x.nome === "Outros"))
            lista = estrutura.filter(x => x.idPai !== 0 && x.id !== registo?.id);
        else
            lista = estrutura.filter(x => x.idPai === registoPaiId && x.ordem < formData.ordem);

        setAvaliacoes(lista.map(item => {
            return {
                id: item.id,
                nome: item.nome
            }
        }))
    }

    const detalhe = () => {
        if (registo) {
            setFormData({
                codigo: registo.codigo,
                nome: registo.nome,
                legenda: registo.legenda,
                ordem: registo.ordem,
                escalas: registo.escala,
                notaMinima: registo.nota_minima,
                notaMaxima: registo.nota_maxima,
                notaAprovacao: registo.nota_aprovacao,
                casasDecimais: registo.casas_decimais,
                visivelAluno: registo.visivel_aluno,
                visivelRelatorios: registo.visivel_relatorios,
                visivelExportacoes: registo.visivel_exportacoes,
                campoCalculado: registo.campo_calculado ? "FORMULA" : "MANUAL",
                tipoFormula: registo.tipo_formula,
                provaFinal: registo.campo_calculado ? registo.campo_calculado_info.prova_final : false,
                operador: registo.campo_calculado ? registo.campo_calculado_info.operador : "MAIOR"
            })
            setEscalas(JSON.parse(registo.escalas))

            if (registo.campo_calculado) {
                if (registo.campo_calculado_info.niveis)
                    setNiveis(JSON.parse(registo.campo_calculado_info.niveis))

                let tempAv = [];
                let tempPesos = [];

                if (registo.campo_calculado_info.ids_avaliacoes) {
                    tempAv = registo.campo_calculado_info.ids_avaliacoes?.split(";");
                }

                if (registo.campo_calculado_info.pesos) {
                    tempPesos = registo.campo_calculado_info.pesos?.split(";");
                }

                const avs = tempAv.map(av => {
                    return {
                        id: parseFloat(av),
                        peso: parseFloat(tempPesos[tempAv.findIndex(x => x === av)])
                    }
                })

                setAvaliacoesSelecionadas(avs)
                carregarAvaliacoes();
            }
        }
    }

    const adicionar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                atualizarAdicionado({
                    id: Date.now(),
                    idPai: registoPaiId,
                    codigo: formData.codigo,
                    nome: formData.nome,
                    legenda: formData.legenda,
                    ordem: formData.ordem,
                    escala: formData.escalas,
                    escalas: JSON.stringify(escalas),
                    nota_minima: formData.notaMinima,
                    nota_maxima: formData.notaMaxima,
                    nota_aprovacao: formData.notaAprovacao,
                    casas_decimais: formData.casasDecimais,
                    visivel_aluno: formData.visivelAluno,
                    visivel_relatorios: formData.visivelRelatorios,
                    visivel_exportacoes: formData.visivelExportacoes,
                    tipo_formula: formData.tipoFormula,
                    campo_calculado: formData.campoCalculado === "FORMULA",
                    campo_calculado_info: formData.campoCalculado ?
                        {
                            operador: formData.operador,
                            avaliacoes: JSON.stringify(avaliacoesSelecionadas)
                        }
                        :
                        null,
                    ativo: true
                });
            }
        })
    }

    const alterar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                atualizarEditado({
                    id: registo.id,
                    idPai: registo.idPai,
                    codigo: formData.codigo,
                    nome: formData.nome,
                    legenda: formData.legenda,
                    ordem: formData.ordem,
                    escala: formData.escalas,
                    escalas: JSON.stringify(escalas),
                    nota_minima: formData.notaMinima,
                    nota_maxima: formData.notaMaxima,
                    nota_aprovacao: formData.notaAprovacao,
                    casas_decimais: formData.casasDecimais,
                    visivel_aluno: formData.visivelAluno,
                    visivel_relatorios: formData.visivelRelatorios,
                    visivel_exportacoes: formData.visivelExportacoes,
                    tipo_formula: formData.tipoFormula,
                    campo_calculado: formData.campoCalculado === "FORMULA",
                    campo_calculado_info: formData.campoCalculado ?
                        {
                            //prova_final: formData.provaFinal,
                            operador: formData.operador,
                            avaliacoes: JSON.stringify(avaliacoesSelecionadas),
                            //niveis: JSON.stringify(niveis)
                        }
                        :
                        null,
                    ativo: registo.ativo
                });
            }
        })
    }

    const afterVisibleChange = (aberto) => {
        form.resetFields();
        if (aberto) {
            if (estado === "EDITAR")
                detalhe();
            else {
                var lista = estrutura.filter(x => x.idPai === registoPaiId);
                setFormData({
                    codigo: "",
                    nome: "",
                    ordem: lista.length ? parseInt(lista[lista.length - 1].ordem + 1) : 1,
                    escala: false,
                    notaMinima: 0,
                    notaMaxima: 0,
                    notaAprovacao: 0,
                    casasDecimais: 2,
                    visivelAluno: true,
                    visivelRelatorios: false,
                    visivelExportacoes: false,
                    campoCalculado: "MANUAL",
                    tipoFormula: "CALCULO",
                    provaFinal: false,
                    operador: "MAIOR"
                })
                setAvaliacoesSelecionadas([])
                setEscalas([])
                setNiveis([{ id: uuid(), valor: "Sem avaliação", descricao: "Sem avaliação", blocked: true }])
            }

            carregarAvaliacoes();
        }
    }

    useEffect(() => {
        carregarAvaliacoes();
    }, [formData.ordem]);

    return (
        <Drawer
            className="drawer-add-cursos"
            //title="Período"
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info">
                {tipo === "Notas" && (
                    <Form className="form-categorias listagem-avaliacoes" layout="horizontal">
                        <div className="bloco">
                            <Form.Item label="Pauta">
                                {getFieldDecorator("nome", {
                                    initialValue: formData.nome,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<Input name="nome" onChange={(e) => setFormData({ ...formData, nome: e.target.value })} />)}
                            </Form.Item>
                            <Form.Item label="Legenda">
                                {getFieldDecorator("legenda", {
                                    initialValue: formData.legenda
                                })(<Input name="legenda" onChange={(e) => setFormData({ ...formData, legenda: e.target.value })} />)}
                            </Form.Item>
                            {/*<Form.Item>*/}
                            {/*    {getFieldDecorator("escalas", {*/}
                            {/*        initialValue: formData.escalas*/}
                            {/*    })(*/}
                            {/*        <Checkbox name="escalas" checked={formData.escalas} onChange={(e) => setFormData({ ...formData, escalas: e.target.checked })}>*/}
                            {/*            Escalas*/}
                            {/*        </Checkbox>*/}
                            {/*    )}*/}
                            {/*</Form.Item>*/}
                            {/*<div className="bloco">*/}
                            {/*    {formData.escalas &&*/}
                            {/*        <div className="bloco bloco-lista-avaliacao-opcoes">*/}
                            {/*            <h3 className="titulo-separador">*/}
                            {/*                Escalas*/}
                            {/*                <button className="botao-principal" onClick={(event) => { event.stopPropagation(); setEscalas([...escalas, { id: uuid(), escalao: "", notaMaxima: 0, notaMinima: 0 }]) }}>*/}
                            {/*                    <Icon type="plus" />*/}
                            {/*                </button>*/}
                            {/*            </h3>*/}
                            {/*            <div className="lista-avaliacao-opcoes">*/}
                            {/*                {escalas.map((escala, index) => (*/}
                            {/*                    <div key={index} className="avaliacao-opcao">*/}
                            {/*                        <div className="bloco-inputs">*/}
                            {/*                            <Form.Item label="Escalão">*/}
                            {/*                                {getFieldDecorator(`escalao${index}`, {*/}
                            {/*                                    initialValue: escala.escalao,*/}
                            {/*                                    rules: [*/}
                            {/*                                        {*/}
                            {/*                                            required: true,*/}
                            {/*                                            message: "Campo obrigatório"*/}
                            {/*                                        }*/}
                            {/*                                    ]*/}
                            {/*                                })(<Input name="escalao" onChange={(event) => handleChangeEscalao(event, index)} />)}*/}
                            {/*                            </Form.Item>*/}
                            {/*                            <Form.Item label="Nota mínima">*/}
                            {/*                                {getFieldDecorator(`nota_minima${index}`, {*/}
                            {/*                                    initialValue: escala.notaMinima,*/}
                            {/*                                    rules: [*/}
                            {/*                                        {*/}
                            {/*                                            required: true,*/}
                            {/*                                            message: "Campo obrigatório"*/}
                            {/*                                        }*/}
                            {/*                                    ]*/}
                            {/*                                })(<InputNumber name="nota_minima" onChange={(event) => handleChangeNotaMinima(event, index)} />)}*/}
                            {/*                            </Form.Item>*/}
                            {/*                            <Form.Item label="Nota máxima">*/}
                            {/*                                {getFieldDecorator(`nota_maxima${index}`, {*/}
                            {/*                                    initialValue: escala.notaMaxima,*/}
                            {/*                                    rules: [*/}
                            {/*                                        {*/}
                            {/*                                            required: true,*/}
                            {/*                                            message: "Campo obrigatório"*/}
                            {/*                                        }*/}
                            {/*                                    ]*/}
                            {/*                                })(<InputNumber name="nota_maxima" onChange={(event) => handleChangeNotaMaxima(event, index)} />)}*/}
                            {/*                            </Form.Item>*/}
                            {/*                        </div>*/}
                            {/*                        <button className="botao-excluir excluir-av" onClick={() => setEscalas([...escalas.filter(x => x.id !== escala.id)])}>*/}
                            {/*                            <Icon type="delete" />*/}
                            {/*                        </button>*/}
                            {/*                    </div>*/}
                            {/*                ))}*/}
                            {/*                {!escalas.length && "Sem dados..."}*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    }*/}
                            {/*</div>*/}
                            <Form.Item label="Nota mínima">
                                {getFieldDecorator("nota_minima", {
                                    initialValue: formData.notaMinima
                                })(<InputNumber name="notaMinima" onChange={(notaMinima) => setFormData({ ...formData, notaMinima })} />)}
                            </Form.Item>
                            <Form.Item label="Nota máxima">
                                {getFieldDecorator("nota_maxima", {
                                    initialValue: formData.notaMaxima
                                })(<InputNumber name="notaMaxima" onChange={(notaMaxima) => setFormData({ ...formData, notaMaxima })} />)}
                            </Form.Item>
                            <Form.Item label="Nota de aprovação">
                                {getFieldDecorator("nota_aprovacao", {
                                    initialValue: formData.notaAprovacao
                                })(<InputNumber name="notaAprovacao" onChange={(notaAprovacao) => setFormData({ ...formData, notaAprovacao })} />)}
                            </Form.Item>
                            <Form.Item label="Casas decimais">
                                {getFieldDecorator("casas_decimais", {
                                    initialValue: formData.casasDecimais
                                })(
                                    <Select placeholder="Selecionar" onChange={(casasDecimais) => setFormData({ ...formData, casasDecimais })}>
                                        <Option value={0}>0</Option>
                                        <Option value={1}>1</Option>
                                        <Option value={2}>2</Option>
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label="Ordem">
                                {getFieldDecorator("ordem", {
                                    initialValue: formData.ordem,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<Input name="ordem" onChange={(e) => setFormData({ ...formData, ordem: e.target.value })} />)}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator("visivel_aluno", {
                                    initialValue: formData.visivelAluno
                                })(
                                    <Checkbox name="visivel_aluno" checked={formData.visivelAluno} onChange={(e) => setFormData({ ...formData, visivelAluno: e.target.checked })}>
                                        Visível para os alunos
                                    </Checkbox>
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator("visivel_relatorios", {
                                    initialValue: formData.visivelRelatorios
                                })(
                                    <Checkbox name="visivel_relatorios" checked={formData.visivelRelatorios} onChange={(e) => setFormData({ ...formData, visivelRelatorios: e.target.checked })}>
                                        Visível nos relatórios
                                    </Checkbox>
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator("visivel_exportacoes", {
                                    initialValue: formData.visivelExportacoes
                                })(
                                    <Checkbox name="visivel_exportacoes" checked={formData.visivelExportacoes} onChange={(e) => setFormData({ ...formData, visivelExportacoes: e.target.checked })}>
                                        Visível nas exportações
                                    </Checkbox>
                                )}
                            </Form.Item>
                            {/*<Form.Item>*/}
                            {/*    {getFieldDecorator("campo_calculado", {*/}
                            {/*        initialValue: formData.campoCalculado,*/}
                            {/*        rules: [*/}
                            {/*            {*/}
                            {/*                required: true,*/}
                            {/*                message: "Campo obrigatório"*/}
                            {/*            }*/}
                            {/*        ]*/}
                            {/*    })(*/}
                            {/*        <Radio.Group name="operador" onChange={(e) => setFormData({ ...formData, campoCalculado: e.target.value })}>*/}
                            {/*            <Radio value="MANUAL"><span className="texto-com-marca">Manual <i className="marca-campo-normal medio"></i></span></Radio>*/}
                            {/*            <Radio value="FORMULA"><span className="texto-com-marca">Fórmula <i className="marca-campo-calculado medio"></i></span></Radio>*/}
                            {/*        </Radio.Group>*/}
                            {/*    )}*/}
                            {/*</Form.Item>*/}
                        </div>
                        {/*{formData.campoCalculado === "FORMULA" && (*/}
                        {/*    <>*/}
                        {/*        <div className="bloco">*/}
                        {/*            <Form.Item>*/}
                        {/*                {getFieldDecorator("media_disciplinas", {*/}
                        {/*                    initialValue: formData.tipoFormula*/}
                        {/*                })(*/}
                        {/*                    <Radio.Group name="operador" onChange={(e) => { setFormData({ ...formData, tipoFormula: e.target.value, operador: e.target.value === "MEDIA" ? "MEDIA" : formData.operador }); setAvaliacoesSelecionadas([{ id: undefined, peso: "" }]) }}>*/}
                        {/*                        <Radio value="CALCULO">Cálculo</Radio>*/}
                        {/*                        <Radio value="MEDIA">É média da seguinte nota para todas as disciplinas</Radio>*/}
                        {/*                    </Radio.Group>*/}
                        {/*                )}*/}
                        {/*            </Form.Item>*/}
                        {/*            {formData.tipoFormula === "CALCULO" ? (*/}
                        {/*                <>*/}
                        {/*                    <Form.Item label="Operador">*/}
                        {/*                        {getFieldDecorator("operador", {*/}
                        {/*                            initialValue: formData.operador,*/}
                        {/*                            rules: [*/}
                        {/*                                {*/}
                        {/*                                    required: true,*/}
                        {/*                                    message: "Campo obrigatório"*/}
                        {/*                                }*/}
                        {/*                            ]*/}
                        {/*                        })(*/}
                        {/*                            <Radio.Group name="operador" onChange={(e) => setFormData({ ...formData, operador: e.target.value })}>*/}
                        {/*                                <Radio value="MAIOR">Maior</Radio>*/}
                        {/*                                <Radio value="MEDIA">Média</Radio>*/}
                        {/*                                <Radio value="SOMA">Soma</Radio>*/}
                        {/*                            </Radio.Group>*/}
                        {/*                        )}*/}
                        {/*                    </Form.Item>*/}
                        {/*                    <div className="bloco bloco-lista-avaliacao-opcoes">*/}
                        {/*                        <h3 className="titulo-separador">*/}
                        {/*                            Notas*/}
                        {/*                            {!formData.mediaDisciplinas &&*/}
                        {/*                                <button className="botao-principal" onClick={(event) => { event.stopPropagation(); setAvaliacoesSelecionadas([...avaliacoesSelecionadas, { id: undefined, peso: "" }]) }}>*/}
                        {/*                                    <Icon type="plus" />*/}
                        {/*                                </button>}*/}
                        {/*                        </h3>*/}
                        {/*                        <div className="lista-avaliacao-opcoes">*/}
                        {/*                            {avaliacoesSelecionadas.map((avaliacaoSelecionada, index) => (*/}
                        {/*                                <div key={avaliacaoSelecionada} className="avaliacao-opcao">*/}
                        {/*                                    <div className="bloco-inputs">*/}
                        {/*                                        <Form.Item label="Nota">*/}
                        {/*                                            {getFieldDecorator(`avalicao${index}`, {*/}
                        {/*                                                initialValue: avaliacaoSelecionada.id,*/}
                        {/*                                                rules: [*/}
                        {/*                                                    {*/}
                        {/*                                                        required: true,*/}
                        {/*                                                        message: "Campo obrigatório"*/}
                        {/*                                                    }*/}
                        {/*                                                ]*/}
                        {/*                                            })(*/}
                        {/*                                                <Select placeholder="Selecionar" onChange={(value) => handleChangeId(value, index)}>*/}
                        {/*                                                    {avaliacoes.map(avaliacao => (*/}
                        {/*                                                        <Option key={avaliacao.id} value={avaliacao.id}>{avaliacao.nome}</Option>*/}
                        {/*                                                    ))}*/}
                        {/*                                                </Select>*/}
                        {/*                                            )}*/}
                        {/*                                        </Form.Item>*/}
                        {/*                                        {(formData.operador === "MEDIA" && !formData.mediaDisciplinas) &&*/}
                        {/*                                            <Form.Item label="Ponderação">*/}
                        {/*                                                {getFieldDecorator(`peso${index}`, {*/}
                        {/*                                                    initialValue: avaliacaoSelecionada.peso,*/}
                        {/*                                                    rules: [*/}
                        {/*                                                        {*/}
                        {/*                                                            required: true,*/}
                        {/*                                                            message: "Campo obrigatório"*/}
                        {/*                                                        }*/}
                        {/*                                                    ]*/}
                        {/*                                                })(<Input name="peso" onChange={(event) => handleChangePeso(event, index)} />)}*/}
                        {/*                                            </Form.Item>}*/}
                        {/*                                    </div>*/}
                        {/*                                    <button className="botao-excluir excluir-av" onClick={() => setAvaliacoesSelecionadas([...avaliacoesSelecionadas.filter(x => x.id !== avaliacaoSelecionada.id)])}>*/}
                        {/*                                        <Icon type="delete" />*/}
                        {/*                                    </button>*/}
                        {/*                                </div>*/}
                        {/*                            ))}*/}
                        {/*                            {!avaliacoesSelecionadas.length && "Sem dados..."}*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </>*/}
                        {/*            )*/}
                        {/*                :*/}
                        {/*                (*/}
                        {/*                    <div className="lista-avaliacao-opcoes">*/}
                        {/*                        {avaliacoesSelecionadas.map((avaliacaoSelecionada, index) => (*/}
                        {/*                            <div key={avaliacaoSelecionada} className="avaliacao-opcao">*/}
                        {/*                                <div className="bloco-inputs">*/}
                        {/*                                    <Form.Item label="Nota">*/}
                        {/*                                        {getFieldDecorator(`avalicao${index}`, {*/}
                        {/*                                            initialValue: avaliacaoSelecionada.id,*/}
                        {/*                                            rules: [*/}
                        {/*                                                {*/}
                        {/*                                                    required: true,*/}
                        {/*                                                    message: "Campo obrigatório"*/}
                        {/*                                                }*/}
                        {/*                                            ]*/}
                        {/*                                        })(*/}
                        {/*                                            <Select placeholder="Selecionar" onChange={(value) => handleChangeId(value, index)}>*/}
                        {/*                                                {avaliacoes.map(avaliacao => (*/}
                        {/*                                                    <Option key={avaliacao.id} value={avaliacao.id}>{avaliacao.nome}</Option>*/}
                        {/*                                                ))}*/}
                        {/*                                            </Select>*/}
                        {/*                                        )}*/}
                        {/*                                    </Form.Item>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                        ))}*/}
                        {/*                    </div>*/}
                        {/*                )}*/}
                        {/*        </div>*/}
                        {/*    </>*/}
                        {/*)}*/}
                    </Form>
                )}
                {tipo === "Conceitos" && (
                    <Form className="form-categorias listagem-avaliacoes" layout="horizontal">
                        <div className="bloco">
                            {(!grupo) && (
                                <Form.Item label="Codigo">
                                    {getFieldDecorator("codigo", {
                                        initialValue: formData.codigo,
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ]
                                    })(<Input name="codigo" onChange={(e) => setFormData({ ...formData, codigo: e.target.value })} />)}
                                </Form.Item>)}
                            {!grupo ?
                                <Form.Item label="Descrição">
                                    {getFieldDecorator("nome", {
                                        initialValue: formData.nome,
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ]
                                    })(<TextArea name="nome" onChange={(e) => setFormData({ ...formData, nome: e.target.value })} rows={4} style={{ height: 100 }} />)}
                                </Form.Item>
                                :
                                <Form.Item label="Grupo">
                                    {getFieldDecorator("nome", {
                                        initialValue: formData.nome,
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ]
                                    })(<Input name="nome" onChange={(e) => setFormData({ ...formData, nome: e.target.value })} />)}
                                </Form.Item>
                            }
                            <Form.Item label="Ordem">
                                {getFieldDecorator("ordem", {
                                    initialValue: formData.ordem,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<Input name="ordem" onChange={(e) => setFormData({ ...formData, ordem: e.target.value })} />)}
                            </Form.Item>
                        </div>
                    </Form>
                )}
            </div>
            <div className="ant-drawer-footer">
                <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                    Voltar
                </button>
                <button className="botao-principal" onClick={estado === "EDITAR" ? alterar : adicionar} disabled={iconLoading} type="primary">
                    {iconLoading ? <Icon type="loading" /> : null}
                    Guardar
                </button>
            </div>
        </Drawer>
    );
}

const FormDrawerAvalicao = Form.create({ name: "drawer-avaliacao" })(DrawerAvalicao);

export default FormDrawerAvalicao;