import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, Modal, Upload, notification, Radio, DatePicker, Tooltip, Icon } from "antd";
import axios from "axios";
import moment from "moment";

import ModalLoading from "../../../../Modals/ModalLoading";
import ModalViewer from "../../../../Modals/ModalViewer";

const confirm = Modal.confirm;
const Dragger = Upload.Dragger;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerEscolaridade = ({ visible, inscricaoId, form, onClose, onConfirm }) => {
    //const [data, setData] = useState(moment());
    const [dataEmissao, setDataEmissao] = useState(moment());
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState("");
    const [iconLoading, setIconLoading] = useState(false);
    const [tipoDocumento, setTipoDocumento] = useState(1);
    const [docGerado, setDocGerado] = useState("");
    const [modalVisible, setModalVisible] = useState(false);
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [docs, setDocs] = useState([]);
    const [validateStatus, setValidateStatus] = useState("");
    const [help, setHelp] = useState("");

    const { getFieldDecorator } = form;

    //const handleChangeData = (date) => {
    //    setData(date);
    //};

    const handleChangeDataEmissao = (date) => {
        setDataEmissao(date);
    };

    const handleChangeTipo = (e) => {
        setTipoDocumento(e.target.value);
        setFileList([]);
        setDocGerado("");
        setValidateStatus("")
        setHelp("")
    };

    const confirma = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err && docGerado != "") {
                confirm({
                    title: "Pretende guardar o documento de escolaridade?",
                    okText: "Confirmar",
                    okType: "Cancelar",
                    onOk: () => {
                        setIconLoading(true);

                        var item = new FormData();
                        item.append("id", inscricaoId);
                        //item.append("data", moment(data).format("YYYY-MM-DD"));
                        item.append("dataEmissao", moment(dataEmissao).format("YYYY-MM-DD"));
                        item.append("tipo", tipoDocumento);
                        item.append("anexo", fileList[0]);
                        item.append("doc", docGerado);

                        axios({
                            method: "post",
                            url: "/api/gestao-turma-matriculas/escolaridade",
                            data: item
                        })
                            .then(() => {
                                onConfirm(inscricaoId);
                                setIconLoading(false);
                                openNotificationWithIcon("success", "Sucesso", "Documento guardado!");
                            })
                            .catch(error => {
                                setIconLoading(false);
                                openNotificationWithIcon("error", "Erro", error.response.data);
                            });
                    }
                })
            }
            else {
                setValidateStatus("error");
                setHelp("Campo obrigatório");
            }
        })
    }

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    const validarFormatoFicheiro = formato => {
        var formatos = ["pdf"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    const validar = (rule, value, callback) => {
        if (value.file) {
            if (value.file.size > 26214400)
                callback("Limite de 25 MB por upload!");
            if (!validarFormatoFicheiro(value.file.name.split(".").pop().toLowerCase()) && value.file.status !== "removed")
                callback("Ficheiro com formato inválido!");
            //if (value.file.status === "removed") callback("Campo obrigatório");
        }

        callback();
    };

    const gerarDoc = () => {
        setLoading(true);

        var item = new FormData();
        item.append("inscricaoId", inscricaoId);
        item.append("dataEmissao", moment(dataEmissao).format("YYYY-MM-DD"));

        axios({
            method: "post",
            url: "/api/pdf/escolaridade",
            data: item
        })
            .then(response => {
                setDocGerado(response.data);
                setLoading(false);
                setValidateStatus("");
                setHelp("");
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível gerar documento!");
                setLoading(false);
            });
    }

    const download = () => {
        const link = document.createElement('a');
        link.href = docGerado;
        link.setAttribute(
            'download',
            'Escolaridade.pdf',
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
    }

    const abrirViewer = () => {
        setDocs([{ uri: docGerado }]);
        setModalVisible(true);
    }

    const excluirDoc = () => {
        setFileList([]);
        setDocGerado("");
    }

    const props = {
        accept: ".pdf",
        multiple: false,
        onRemove: file => {
            form.resetFields("ficheiro");
            setFileList(fileList => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);

                return newFileList;
            });
        },
        defaultFileList: fileList,
        beforeUpload: file => {

            setFileList([file]);

            const blob = new Blob([file]);
            const fileUrl = URL.createObjectURL(blob);
            setDocGerado(fileUrl);

            return false;
        },
        fileList
    };

    const afterVisibleChange = visible => {
        if (!visible) {
            form.resetFields();
            setDataEmissao(moment());
            setFileList([]);
            setLoading("");
            setIconLoading(false);
            setTipoDocumento(1);
            setDocGerado("");
        }
    }

    const radioStyle = {
        display: 'block',
        height: '20px'
    };

    return (
        <>
            <Drawer
                className="drawer-inscrever"
                //title="Informações da transferência"
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={afterVisibleChange}
            >
                <div className="bloco-drawer-associar">
                    <Form className="form-emitir-declaracao">
                        {/*<Form.Item label="Data">*/}
                        {/*    {getFieldDecorator("data", {*/}
                        {/*        rules: [*/}
                        {/*            {*/}
                        {/*                required: true,*/}
                        {/*                message: "Campo obrigatório",*/}
                        {/*            }*/}
                        {/*        ],*/}
                        {/*        initialValue: data*/}
                        {/*    })(*/}
                        {/*        <DatePicker*/}
                        {/*            name="data"*/}
                        {/*            format="DD-MM-YYYY"*/}
                        {/*            placeholder="Selecionar"*/}
                        {/*            suffixIcon={false}*/}
                        {/*            clearIcon={false}*/}
                        {/*            onChange={handleChangeData}*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*</Form.Item>*/}
                        <Form.Item label="Data de emissão">
                            {getFieldDecorator("dataEmissao", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    }
                                ],
                                initialValue: dataEmissao
                            })(
                                <DatePicker
                                    name="dataEmissao"
                                    format="DD-MM-YYYY"
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                    onChange={handleChangeDataEmissao}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Documento">
                            <Radio.Group name="tipo_doc" className="drawer-doc-opcoes" value={tipoDocumento} onChange={handleChangeTipo}>
                                <Radio style={radioStyle} value={1}>Carregar</Radio>
                                <Radio style={radioStyle} value={2}>Gerar</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {tipoDocumento === 1 ? (
                            <Form.Item>
                                <div className="anexos">
                                    {getFieldDecorator("ficheiro", {
                                        rules: [
                                            {
                                                validator: validar
                                            },
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            }
                                        ],
                                        initialValue: fileList
                                    })(
                                        <Dragger {...props} disabled={docGerado} showUploadList={false}>
                                            <p className="ant-upload-drag-icon">
                                                <i className="fas fa-upload" />
                                            </p>
                                            <p className="ant-upload-text">
                                                Adicionar anexo
                                                <Tooltip title="Formatos válidos: .pdf">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </p>
                                            <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                        </Dragger>
                                    )}
                                </div>
                            </Form.Item>
                        )
                            :
                            (<Form.Item
                                    validateStatus={validateStatus}
                                    help={help}
                                >
                                    <button disabled={fileList.length} className="botao-principal botao-gerar-doc" disabled={loading} onClick={gerarDoc}>
                                        {loading ? "A gerar..." : "Gerar Documento"}
                                    </button>
                                </Form.Item>
                            )}
                    </Form>
                    {docGerado && (
                        <div className="bloco">
                            <div className="lista-videos">
                                <div className="item-video">
                                    <span className="video-titulo">Escolaridade</span>
                                    <div className="video-controlos">
                                        {tipoDocumento === 1 ?
                                            (<Link to="#" className="botao-icon-download" title="Descarregar" onClick={download}>
                                                <Icon type="download" />
                                            </Link>)
                                            :
                                            (<Link to="#" className="botao-icon-detalhe" title="Descarregar" onClick={abrirViewer}>
                                                <Icon type="eye" />
                                            </Link>)
                                        }
                                        <Link to="#" className="botao-icon-excluir" title="Excluir" onClick={excluirDoc}>
                                            <Icon type="delete" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="ant-drawer-footer">
                    <button
                        className="botao-secundario"
                        onClick={onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                    </button>
                    <button className="botao-principal" disabled={iconLoading} type="primary" onClick={confirma}>
                        {iconLoading ? <Icon type="loading" /> : null}
                        Guardar
                    </button>
                </div>
            </Drawer>
            <ModalLoading
                visible={visibleLoading}
                text="A gerar documento..."
            />
            <ModalViewer
                visible={modalVisible}
                docs={docs}
                onCancel={() => setModalVisible(false)}
            />
        </>
    );
}

const FormDrawerEscolaridade = Form.create({ name: "drawer-escolaridade" })(DrawerEscolaridade);

export default FormDrawerEscolaridade;
