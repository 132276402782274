import React, { Component } from "react"
import { Link, matchPath, withRouter, NavLink } from "react-router-dom"
import { Dropdown, Icon } from "antd"
import { GeralContext } from "./ContextProvider"
import { GlobalContext } from "../GlobalState"

class MenuCurso extends Component {
    render() {
        const { curso } = this.props.match.params;
        var inicioUrl = `/curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}`;

        let menuOpcaoAtivo = ""

        const match = matchPath(window.location.pathname, {
            path: "/curso/:curso/:pagina"
        })

        if (match && match.params.pagina) menuOpcaoAtivo = match.params.pagina

        return (
            <GlobalContext.Consumer>
                {globalContext => {
                    const menuAtividades = (
                        <>
                            <li className="nav-item">
                                <NavLink to={`${inicioUrl}/curso-modulos`} className="nav-link">
                                    Conteúdos
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={`${inicioUrl}/curso-diario`} className="nav-link">
                                    Diário
                                </NavLink>
                            </li>
                        </>
                    )

                    const menuCursoDisciplinas = (
                        <>
                            <li className="nav-item">
                                <NavLink to={`${inicioUrl}/curso-cursos`} className="nav-link">
                                    Disciplinas
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={`${inicioUrl}/curso-diario`} className="nav-link">
                                    Diário
                                </NavLink>
                            </li>
                        </>
                    )

                    const menuDocumentacao = (
                        <>
                            <li className="nav-item">
                                <NavLink to={`${inicioUrl}/curso-ficheiros`} className="nav-link">
                                    Drive
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    to={`${inicioUrl}/curso-anotacoes`}
                                    className="nav-link"
                                    onClick={() => localStorage.removeItem("menu_mensagens")}
                                >
                                    Anotações
                                </NavLink>
                            </li>
                        </>
                    )

                    const menuComunicacao = (
                        <>
                            <li className="nav-item">
                                <NavLink to={`${inicioUrl}/curso-forum`} className="nav-link">
                                    Fórum
                                </NavLink>
                            </li>
                            {(globalContext.conf_total_participantes > 0 && !globalContext.stepmeet_gestao) &&
                                <li className="nav-item">
                                <NavLink to={`${inicioUrl}/curso-videoconferencias`} className="nav-link" onClick={() => localStorage.removeItem("menu_mensagens")}>
                                        Stepmeet
                                    </NavLink>
                                </li>}
                        </>
                    )

                    return (
                        <GeralContext.Consumer>
                            {geralContext => {
                                const montarOpcoes = () => {
                                    if (geralContext.tipo_inscricao === "DIGITAL" && !geralContext.inscrito && !geralContext.subscricaoAtiva ||
                                        geralContext.inscricao && !geralContext.inscrito && !geralContext.subscricaoAtiva ||
                                        geralContext.subscricao && !geralContext.subscricaoAtiva && !geralContext.inscrito ||
                                        !geralContext.inscricao && geralContext.preco && !geralContext.inscrito ||
                                        geralContext.precedenciaPorConcluir ||
                                        !geralContext.cursoIniciado) {
                                        return (<li>
                                            <Link
                                                to={`${inicioUrl}/curso-visao-geral`}
                                                className={menuOpcaoAtivo === "curso-visao-geral" ? "ativo" : ""}
                                            >
                                                Visão Geral
                                            </Link>
                                        </li>);
                                    }
                                    else if (!geralContext.inscrito || !geralContext.inscrito && geralContext.subscricaoAtiva) {
                                        return (<>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/curso-visao-geral`}
                                                    className={menuOpcaoAtivo === "curso-visao-geral" ? "ativo" : ""}
                                                >
                                                    Visão Geral
                                            </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/curso-modulos`}
                                                    className={menuOpcaoAtivo === "curso-modulos" ? "ativo" : ""}
                                                >
                                                    Atividades
                                                </Link>
                                            </li>
                                        </>);
                                    }
                                    else {
                                        return (<>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/curso-visao-geral`}
                                                    className={menuOpcaoAtivo === "curso-visao-geral" ? "ativo" : ""}
                                                >
                                                    Visão Geral
                                            </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/curso-modulos`}
                                                    className={menuOpcaoAtivo === "curso-modulos" ? "ativo" : ""}
                                                >
                                                    Atividades
                                                </Link>
                                            </li>
                                            <li>
                                                <Dropdown
                                                    overlay={menuDocumentacao}
                                                    overlayClassName="dropdown-menu-navbar"
                                                >
                                                    <Link
                                                        to="#"
                                                        className={
                                                            menuOpcaoAtivo === "curso-ficheiros" ||
                                                                menuOpcaoAtivo === "curso-anotacoes"
                                                                ? "ativo ant-dropdown-link"
                                                                : "ant-dropdown-link"
                                                        }
                                                    >
                                                        Documentação <Icon type="down" />
                                                    </Link>
                                                </Dropdown>
                                            </li>
                                            <li>
                                                <Dropdown overlay={menuComunicacao} overlayClassName="dropdown-menu-navbar">
                                                    <Link
                                                        to="#"
                                                        className={
                                                            menuOpcaoAtivo === "curso-forum" ||
                                                                menuOpcaoAtivo === "curso-nova-pergunta" ||
                                                                menuOpcaoAtivo === "curso-forum-detalhe" ||
                                                                menuOpcaoAtivo === "curso-mensagens" ||
                                                                menuOpcaoAtivo === "curso-mensagens-nova" ||
                                                                menuOpcaoAtivo === "curso-mensagens-detalhe" ||
                                                                menuOpcaoAtivo === "curso-chat" ||
                                                                menuOpcaoAtivo === "curso-videoconferencias"
                                                                ? "ativo ant-dropdown-link"
                                                                : "ant-dropdown-link"
                                                        }
                                                    >
                                                        Comunicação <Icon type="down" />
                                                    </Link>
                                                </Dropdown>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/curso-resultados`}
                                                    className={menuOpcaoAtivo === "curso-resultados" ? "ativo" : ""}
                                                >
                                                    Resultados
                                                </Link>
                                            </li>
                                            {!geralContext.esconderListaParticipantes ?
                                                <li>
                                                    <Link
                                                        to={`${inicioUrl}/curso-participantes`}
                                                        className={menuOpcaoAtivo === "curso-participantes" ? "ativo" : ""}
                                                    >
                                                        Turma
                                                </Link>
                                                </li> : null}
                                        </>);
                                    }
                                }

                                return (
                                    <div className="menu-detalhe-curso menu-detalhe-curso-v2">
                                        <div className="container">
                                            {(!globalContext.chave_entidade && globalContext.role === "UTILIZADOR") ||
                                                globalContext.role !== "UTILIZADOR" ? (
                                                    <ul>
                                                        {montarOpcoes()}
                                                    </ul>
                                                ) : (
                                                    <ul>
                                                        <li>
                                                            <Link
                                                                to={`${inicioUrl}/curso-visao-geral`}
                                                                className={menuOpcaoAtivo === "curso-visao-geral" ? "ativo" : ""}
                                                            >
                                                                Visão Geral
                                            </Link>
                                                        </li>
                                                        {!this.props.matriz ? (
                                                            <li>
                                                                <Link
                                                                    to={`${inicioUrl}/curso-modulos`}
                                                                    className={menuOpcaoAtivo === "curso-modulos" ? "ativo" : ""}
                                                                >
                                                                    Atividades
                                                </Link>
                                                            </li>
                                                        ) : (
                                                                <li>
                                                                    <Link
                                                                        to={`${inicioUrl}/curso-cursos`}
                                                                        className={
                                                                            menuOpcaoAtivo === "curso-cursos" ||
                                                                                menuOpcaoAtivo === "curso-inscricoes"
                                                                                ? "ativo"
                                                                                : ""
                                                                        }
                                                                    >
                                                                        Disciplinas
                                                </Link>
                                                                </li>
                                                            )}
                                                    </ul>
                                                )}
                                        </div>
                                    </div>
                                )
                            }}
                        </GeralContext.Consumer>
                    )
                }}
            </GlobalContext.Consumer>
        )
    }
}

export default withRouter(MenuCurso)
