import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, Input, InputNumber, Checkbox, Select, notification, DatePicker, TimePicker, Tooltip, Icon, Radio, Row, Col } from "antd";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../../../GlobalState"

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class FormVideoConf extends Component {
    static contextType = GlobalContext;
    state = {
        assunto: "",
        dia: null,
        hora: null,
        duracao: moment("01:00", "HH:mm"),
        //somInicio: false,
        //videoInicio: false,
        moderadorVideoSom: false,
        gravarSessao: false,
        habilitarChat: false,
        audioVideoAtivo: false,
        gravarChat: false,
        streaming: false,
        streamingKey: "",
        streamingLink: "",
        verificacao: 2,
        codigo: Math.floor(100000 + Math.random() * 900000),
        todosAlunos: false,
        todosInstrutores: false,
        todosResponsaveis: false,
        //totalParticipantes: 2,
        //totalDisponibilidade: 0,
        totalContratado: "",
        totalParticipantes: "",
        //LOADING
        iconLoading: false
    };

    handlerChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handlerChangeNumber = value => {
        this.setState({
            totalParticipantes: value
        });
    };

    handlerChangeNumber = value => {
        this.setState({
            totalParticipantes: value
        });
    };

    handlerChangeCheckbox = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    handlerChangeRadio = event => {
        this.setState({
            verificacao: event.target.value,
            codigo: event.target.value === 2 ? Math.floor(100000 + Math.random() * 900000) : ""
        });
    };

    copiarConvite = (token) => {
        var regex = /<br\s*[\/]?>/gi;
        var _token = token.replace(regex, "\n");

        var link = document.createElement("textarea");
        link.value = _token;

        link.style.top = "0";
        link.style.left = "0";
        link.style.position = "fixed";

        document.body.appendChild(link);
        link.focus();
        link.select();
        link.setSelectionRange(0, 99999)
        var sucesso = document.execCommand("copy");
        if (sucesso)
            openNotificationWithIcon("success", "Sucesso", "Webinar criada e convite copiado para memória!")
        else
            openNotificationWithIcon("error", "Erro", "Webinar criada, mas ocorreu um erro ao copiar o convite!")
        document.body.removeChild(link);
    }

    criar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    iconLoading: true
                });

                var item = new FormData()
                item.append("assunto", this.state.assunto);
                item.append("meetingDate", moment(this.state.dia).format("YYYY/MM/DD"));
                item.append("hora", this.state.hora.format("HH:mm"));
                item.append("duracao", this.state.duracao.format("HH:mm"));
                item.append("streamingKey", this.state.streamingKey);
                item.append("streamingLink", this.state.streamingLink);
                item.append("Verificacao", this.state.verificacao);
                item.append("habilitarChat", this.state.habilitarChat);
                item.append("audioVideoAtivo", this.state.audioVideoAtivo);
                item.append("Codigo", this.state.codigo);
                item.append("totalParticipantes", this.state.totalParticipantes);

                axios({
                    method: "post",
                    url: "/api/gestao-webinar/criar-nova",
                    data: item
                })
                    .then(response => {
                        this.copiarConvite(response.data);
                        this.setState({
                            iconLoading: false
                        });
                        this.context.atualizarEventosHoje();
                        this.props.atualizarListagem();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        })
    };

    alterar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    iconLoading: true
                });

                var item = new FormData()
                item.append("id", this.props.videoConfId);
                item.append("assunto", this.state.assunto);
                item.append("meetingDate", moment(this.state.dia).format("YYYY/MM/DD"));
                item.append("hora", this.state.hora.format("HH:mm"));
                item.append("duracao", this.state.duracao.format("HH:mm"));
                item.append("streamingKey", this.state.streamingKey);
                item.append("streamingLink", this.state.streamingLink);
                item.append("Verificacao", this.state.verificacao);
                item.append("habilitarChat", this.state.habilitarChat);
                item.append("audioVideoAtivo", this.state.audioVideoAtivo);
                item.append("Codigo", this.state.codigo);
                item.append("totalParticipantes", this.state.totalParticipantes);

                axios({
                    method: "post",
                    url: "/api/gestao-webinar/alterar",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Webinar alterada");
                        this.setState({
                            iconLoading: false
                        });
                        this.context.atualizarEventosHoje();
                        this.props.atualizarListagem();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //disabledDate = (current) => {
    //    return current && current < moment().startOf('day');
    //}

    //disabledHours = () => {
    //    if (moment(this.state.dia).isSame(moment(), 'day')) {
    //        var agora = moment();
    //        var inicio = moment().startOf("day");
    //        var duracao = moment.duration(agora.diff(inicio));
    //        var horas = duracao.asHours();

    //        return Array.from(Array(parseInt(horas)).keys());
    //    }
    //}

    //disabledMinutes = selectedHour => {
    //    if (moment(this.state.dia).isSame(moment(), 'day')) {
    //        if (parseInt(moment().format("HH")) === selectedHour) {
    //            var agora = moment();
    //            var inicio = moment().startOf("hour");
    //            var duracao = moment.duration(agora.diff(inicio));
    //            var minutos = duracao.asMinutes();

    //            return Array.from(Array(parseInt(minutos)).keys());
    //        }
    //    }
    //}

    carregarDetalhe = id => {
        axios({
            method: "get",
            url: "/api/gestao-webinar/detalhe",
            params: {
                id
            }
        })
            .then(response => {
                var instrutores = [];
                var listaProcurarInstrutores = [];
                var alunos = [];
                var listaProcurarAlunos = [];

                this.setState({
                    assunto: response.data.assunto,
                    dia: moment(response.data.meetingDate),
                    hora: response.data.hora ? moment(response.data.hora, "HH:mm") : null,
                    duracao: moment(response.data.duracao, "HH:mm"),
                    habilitarChat: response.data.habilitarChat,
                    audioVideoAtivo: response.data.audioVideoAtivo,
                    streamingKey: response.data.streamingKey,
                    streamingLink: response.data.streamingLink,
                    verificacao: response.data.verificacao,
                    codigo: response.data.codigo,
                    totalParticipantes: response.data.totalParticipantes,
                    todosAlunos: response.data.todosAlunos,
                    todosInstrutores: response.data.todosInstrutores,
                    instrutores,
                    listaProcurarInstrutores,
                    alunos,
                    listaProcurarAlunos
                }, () => this.carregarTotalContratado());
            })
            .catch(() => { });
    }

    carregarTotalContratado = () => {
        axios({
            method: "get",
            url: "/api/gerir-videoconferencia/total-contratado",
            timeout: 60 * 10 * 1000
        })
            .then(response => {
                this.setState({
                    totalContratado: response.data
                });
            })
            .catch(() => {

            });
    }

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            if (this.props.videoConfId) {
                this.carregarDetalhe(this.props.videoConfId);
            }
            else {
                const inicio = moment();
                const minutos = 15 - (inicio.minute() % 15);

                const hora = moment(inicio).add(minutos, "minutes");

                this.carregarTotalContratado();
                this.setState({
                    assunto: "",
                    dia: moment(),
                    hora: hora,
                    duracao: moment("01:00", "HH:mm"),
                    //somInicio: false,
                    //videoInicio: false,
                    moderadorVideoSom: false,
                    gravarSessao: false,
                    habilitarChat: true,
                    audioVideoAtivo: false,
                    gravarChat: false,
                    streaming: false,
                    streamingKey: "",
                    streamingLink: "",
                    todosAlunos: false,
                    todosInstrutores: false,
                    todosResponsaveis: false,
                    totalParticipantes: "",
                    totalDisponibilidade: 0,
                    //PROCURAR INSTRUTORES
                    instrutores: [],
                    listaProcurarInstrutores: [],
                    listaInstrutores: [],
                    procurarInstrutores: false,
                    //PROCURAR ALUNOS
                    alunos: [],
                    listaProcurarAlunos: [],
                    listaAlunos: [],
                    procurarAlunos: false,
                })
            }
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            assunto,
            dia,
            hora,
            duracao,
            //somInicio,
            //videoInicio,
            moderadorVideoSom,
            gravarSessao,
            habilitarChat,
            audioVideoAtivo,
            gravarChat,
            streaming,
            streamingKey,
            streamingLink,
            totalParticipantes,
            totalDisponibilidade,
            totalContratado,
            verificacao,
            codigo,
            //LOADING
            iconLoading
        } = this.state;

        const { videoConfId } = this.props;

        return (
            <Drawer
                className="drawer-add-cursos drawer-videoconferencias"
                //title={videoConfId ? "Alterar Stepmeet" : "Adicionar nova Stepmeet"}
                width={720}
                onClose={this.props.onClose}
                visible={this.props.visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-categorias" layout="horizontal">
                        <div className="bloco-info">
                            <Form.Item label="Assunto">
                                {getFieldDecorator("assunto", {
                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                    initialValue: assunto
                                })(<Input name="assunto" onChange={this.handlerChange} />)}
                            </Form.Item>
                            <Form.Item label="Dia">
                                {getFieldDecorator("dia", {
                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                    initialValue: dia
                                })(<DatePicker
                                    className="input-25"
                                    placeholder="Selecionar"
                                    format="DD-MM-YYYY"
                                    name="dia"
                                    onChange={dia => this.setState({ dia })}
                                    //disabledDate={this.disabledDate}
                                />)}
                            </Form.Item>
                            <Form.Item label="Hora">
                                {getFieldDecorator("hora", {
                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                    initialValue: hora
                                })(<TimePicker
                                    className="input-25"
                                    name="hora"
                                    defaultOpenValue={moment("00:00", "HH:mm")}
                                    format="HH:mm"
                                    onChange={hora => this.setState({ hora })}
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                    //disabledHours={this.disabledHours}
                                    //disabledMinutes={this.disabledMinutes}
                                    minuteStep={15}
                                />)}
                            </Form.Item>
                            <Form.Item label="Duração">
                                {getFieldDecorator("duracao", {
                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                    initialValue: duracao
                                })(<TimePicker
                                    className="input-25"
                                    name="duracao"
                                    defaultOpenValue={moment("01:00", "HH:mm")}
                                    format="HH:mm"
                                    onChange={duracao => this.setState({ duracao })}
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                />)}
                            </Form.Item>
                            <Form.Item label="Nome/chave do stream">
                                {getFieldDecorator("streamingKey", {
                                    rules: [{
                                        required: true,
                                        message: "Campo obrigatório"
                                    }],
                                    initialValue: streamingKey
                                })(<Input name="streamingKey" className="input-50" onChange={this.handlerChange} />)}
                            </Form.Item>
                            <Form.Item label="Compartilhar">
                                {getFieldDecorator("streamingLink", {
                                    rules: [{
                                        required: true,
                                        message: "Campo obrigatório"
                                    }],
                                    initialValue: streamingLink
                                })(<Input name="streamingLink" onChange={this.handlerChange} />)}
                            </Form.Item>
                        </div>
                        <div className="bloco-info">
                            <h3 className="titulo-separador">Configurações</h3>
                            <Form.Item className="form-item-checkbox">
                                {getFieldDecorator("habilitarChat")(
                                    <Checkbox name="habilitarChat" checked={habilitarChat} onChange={this.handlerChangeCheckbox}>Habilitar Chat</Checkbox>
                                )}
                            </Form.Item>
                            <Form.Item className="form-item-checkbox">
                                {getFieldDecorator("audioVideoAtivo")(
                                    <Checkbox name="audioVideoAtivo" checked={audioVideoAtivo} onChange={this.handlerChangeCheckbox}>Iniciar com audio e video para todos</Checkbox>
                                )}
                            </Form.Item>
                        </div>
                        <div className="bloco-info">
                            <h3 className="titulo-separador">Entrada na sessão pelos participantes a convidar</h3>
                            <Form.Item className="form-item-checkbox">
                                <Radio.Group onChange={this.handlerChangeRadio} value={verificacao}>
                                    <Radio value={1}>Por verificação do e-mail e introdução do código recebido</Radio>
                                    <Radio value={2}>Por introdução do código enviado com o convite</Radio>
                                </Radio.Group>
                                {verificacao === 2 &&
                                    <span style={{ display: "block" }}><strong>Código: </strong>{codigo}</span>}
                            </Form.Item>
                        </div>
                        <div className="bloco-info bloco">
                            <Form.Item>
                                Nº máx. de participantes em simulâneo: {totalContratado}
                                <Tooltip className="info-icon" title="Nº máximo de participantes contratualizado envolvido numa ou em mais Stepmeets  simultâneas">
                                    <Icon type="question-circle-o" />
                                </Tooltip>
                            </Form.Item>
                            <Form.Item>
                                <div className="bloco-numero-participantes">
                                    <label style={{ height: 45, marginTop: 5, color: "#3d464d", fontSize: 14 }}>Assinale o nº esperado de participantes</label>
                                    {getFieldDecorator("Assinale o nº esperado de participantes", {
                                        rules: [{ required: true, message: "Campo obrigatório" }],
                                        initialValue: totalParticipantes
                                    })(<InputNumber name="totalParticipantes" min={1} onChange={totalParticipantes => this.setState({ totalParticipantes })} />)}
                                </div>
                                {/*<Row gutter={8}>
                                    <Col span={13}>
                                        <label style={{ height: 45, marginTop: 5, color: "#3d464d", fontSize: 14 }}>Assinale o nº esperado de participantes</label>
                                    </Col>
                                    <Col span={3}>
                                        {getFieldDecorator("Assinale o nº esperado de participantes", {
                                            rules: [{ required: true, message: "Campo obrigatório" }],
                                            initialValue: totalParticipantes
                                        })(<InputNumber name="totalParticipantes" min={1} onChange={totalParticipantes => this.setState({ totalParticipantes })} />)}
                                    </Col>
                                </Row>*/}
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <Link
                        to="#"
                        className="botao-secundario"
                        onClick={this.props.onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                    </Link>
                    <button className="botao-principal" disabled={iconLoading} onClick={videoConfId ? this.alterar : this.criar}>
                        {iconLoading ? <Icon type="loading" /> : null}
                        Guardar
                    </button>
                    {/*{videoConfId ? (*/}
                    {/*    <button className="botao-principal" disabled={iconLoading} onClick={this.alterar}>*/}
                    {/*        {iconLoading ? <Icon type="loading" /> : null}*/}
                    {/*        Guardar*/}
                    {/*    </button>*/}
                    {/*) : (*/}
                    {/*        <button className="botao-principal" disabled={iconLoading} onClick={this.criar}>*/}
                    {/*            {iconLoading ? <Icon type="loading" /> : null}*/}
                    {/*            Criar*/}
                    {/*        </button>*/}
                    {/*    )}*/}
                </div>
            </Drawer>
        );
    }
}
const FormFormVideoConf = Form.create({ name: "form-video-conf" })(FormVideoConf);

export default FormFormVideoConf;
