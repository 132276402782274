import React, { Component } from "react";
import { Drawer, Form, Input, InputNumber, Tooltip, Icon, notification, Select, Checkbox, DatePicker, Spin } from "antd";
import axios from "axios";
import moment from "moment";
import { GeralContext } from "../ContextProvider";
import DrawerPesquisar from "./DrawerPesquisar";
import noimage from "../../images/noimage.png";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class FormModulo extends Component {
    static contextType = GeralContext;
    state = {
        modulo: {
            titulo: "",
            precedencia: undefined,
            estado: "ATIVO",
            inicio: null,
            termino: null,
            objetivos: "",
            obrigatorio: false,
            ordem: "",
            acessoLivre: false,
            //PARTICIANTES
            todos: false,
            competencias: [],
            participantes: []
        },
        precedencias: [],
        listaCompetencias: [],
        ////PROCURAR INSTRUTORES
        //instrutores: [],
        //listaProcurarInstrutores: [],
        //listaInstrutores: [],
        //procurarInstrutores: false,
        ////PROCURAR ALUNOS
        //alunos: [],
        //listaProcurarAlunos: [],
        //listaAlunos: [],
        //procurarAlunos: false,
        iconLoading: false,
        //DRAWER PESQUISAR PARTICIPATES,
        visibleDrawerPesqusiar: false
    };

    UNSAFE_componentWillUpdate() {
        this.props.form.resetFields();
    }

    handlerChange = event => {
        this.setState({
            modulo: { ...this.state.modulo, [event.target.name]: event.target.value }
        });
    };

    handlerChangeSelectPrecedencia = value => {
        this.setState({
            modulo: { ...this.state.modulo, precedencia: value }
        });
        this.props.carregarDataPrecedencia(value);
    };

    handlerChangeSelectEstado = value => {
        this.setState({
            modulo: { ...this.state.modulo, estado: value }
        });
    };

    handlerChangeSelectCompetencia = value => {
        var competencia = this.state.listaCompetencias.find(x => x.id === value);
        var existe = this.state.modulo.competencias.find(x => x.id === value)

        this.props.form.setFieldsValue({
            selecionarCompetencia: undefined
        })

        if (!existe)
            this.setState({
                modulo: { ...this.state.modulo, competencias: [...this.state.modulo.competencias, competencia] }
            });
    };

    handlerChangeCheck = event => {
        this.setState({
            modulo: { ...this.state.modulo, [event.target.name]: event.target.checked }
        });
    };

    handlerChangeNumber = value => {
        this.carregarPrecedencia(value);

        this.setState({
            modulo: { ...this.state.modulo, ordem: value }
        });
    };

    handleChangeDataInicio = date => {
        this.setState({
            modulo: { ...this.state.modulo, inicio: date }
        });
    };

    handleChangeDataTermino = date => {
        this.setState({
            modulo: { ...this.state.modulo, termino: date }
        });
    };

    listarCompetencias = () => {
        axios({
            method: "get",
            url: "/api/gerir-curso-modulo/listar-competencias",
            params: {
                cursoid: this.props.cursoid
            }
        })
            .then(response => {
                this.setState({
                    listaCompetencias: response.data
                });
            })
            .catch(() => { });
    };

    carregarModulo = moduloid => {
        axios({
            method: "get",
            url: "/api/gerir-curso-modulo/detalhe-modulos",
            params: {
                moduloid
            }
        })
            .then(response => {
                this.carregarPrecedencia(response.data.ordem);
                this.setState({
                    modulo: {
                        titulo: response.data.nome,
                        precedencia: response.data.precedencia ? response.data.precedencia : undefined,
                        obrigatorio: response.data.obrigatoria,
                        estado: response.data.estado,
                        inicio: response.data.dt_inicio ? moment(response.data.dt_inicio) : null,
                        termino: response.data.dt_termino ? moment(response.data.dt_termino) : null,
                        objetivos: response.data.objetivos ? response.data.objetivos : "",
                        ordem: response.data.ordem,
                        acessoLivre: response.data.acessoLivre,
                        todos: response.data.todos,
                        participantes: response.data.participantes,
                        competencias: response.data.competencias,
                    },
                    precedencias: this.state.precedencias.filter(x => x.id !== response.data.id)
                });
            })
            .catch(() => {});
    };

    //LISTA TODOS OS MODULOS QUE PRECEDEM A ORDEM DO MODULO ABERTO (NOVO OU EDIÇÃO)
    carregarPrecedencia = ordem => {
        axios({
            method: "get",
            url: "/api/gerir-curso-modulo/listar-precedencia-modulos",
            params: {
                cursoid: this.props.cursoid,
                ordem: ordem
            }
        })
            .then(response => {
                this.setState({
                    precedencias: response.data
                });
            })
            .catch(() => {});
    };

    //CRIA NOVO MODULO
    criar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && (this.state.modulo.todos || this.state.modulo.participantes.length > 0)) {
                this.setState({ iconLoading: true });
                const { titulo, precedencia, estado, inicio, termino, objetivos, obrigatorio, ordem, todos, participantes, competencias, acessoLivre } = this.state.modulo;

                var item = new FormData();
                item.append("id_curso", this.props.cursoid);
                item.append("nome", titulo.trim());
                if (precedencia) {
                    item.append("precedencia", precedencia);
                    item.append("obrigatoria", obrigatorio);
                }
                item.append("estado", estado);
                if (inicio) {
                    item.append("dt_inicio", inicio.format("YYYY/MM/DD"));
                }
                if (termino) {
                    item.append("dt_termino", termino.format("YYYY/MM/DD"));
                }
                item.append("objetivos", objetivos);
                item.append("ordem", ordem);
                item.append("acessoLivre", acessoLivre);
                item.append("todos", todos);
                item.append("participantes", JSON.stringify(participantes))
                item.append("competencias", JSON.stringify(competencias.map(competencia => competencia.id)))

                axios({
                    method: "post",
                    url: "/api/gerir-curso-modulo/criar-modulo",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Nova atividade adicionada!");
                        this.setState({ iconLoading: false });
                        this.props.atualizar();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível adicionar atividade!");
                        this.setState({ iconLoading: false });
                    });
            }
            else {
                if (!this.state.modulo.todos && this.state.modulo.participantes.length === 0)
                    openNotificationWithIcon("error", "Erro", "Não existem participantes para esta atividade");
            }
        });
    };

    //ATUALIZA O MODULO
    alterar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });
                const { titulo, precedencia, estado, inicio, termino, objetivos, obrigatorio, ordem, todos, participantes, competencias, acessoLivre } = this.state.modulo;

                var item = new FormData();
                item.append("id", this.props.moduloid);
                item.append("id_curso", this.props.cursoid);
                item.append("nome", titulo.trim());
                if (precedencia) {
                    item.append("precedencia", precedencia);
                    item.append("obrigatoria", obrigatorio);
                }
                item.append("estado", estado);
                if (inicio) {
                    item.append("dt_inicio", inicio.format("YYYY/MM/DD"));
                }
                if (termino) {
                    item.append("dt_termino", termino.format("YYYY/MM/DD"));
                }
                item.append("objetivos", objetivos);
                item.append("ordem", ordem);
                item.append("acessoLivre", acessoLivre);
                item.append("todos", todos);
                item.append("participantes", JSON.stringify(participantes))
                item.append("competencias", JSON.stringify(competencias.map(competencia => competencia.id)))

                axios({
                    method: "post",
                    url: "/api/gerir-curso-modulo/alterar-modulo",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Atividade editada com sucesso.");
                        this.setState({ iconLoading: false });
                        this.props.atualizar();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível editar a atividade!");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    //SELECIONA A PRECEDENCIA DO MODULO
    selecionarPrecedencia = value => {
        this.props.handlerChangeSelectTipo(value);
    };

    disabledDate = current => {
        if (this.props.dt_precedencia) {
            return current < moment(this.props.dt_precedencia).add(1, 'days').startOf("day") || current > moment(this.context.dt_fim).endOf("day");
        }
        else {
            return current < moment(this.context.dt_inicio).startOf("day") || current > moment(this.context.dt_fim).endOf("day");
        }
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            if (this.props.moduloid === 0) {
                this.carregarPrecedencia(this.props.proximaPosicaoModulo);
                this.props.carregarDataPrecedencia(0)
                this.setState({
                    modulo: {
                        id: 0,
                        titulo: "",
                        precedencia: undefined,
                        estado: "ATIVO",
                        inicio: null,
                        termino: null,
                        objetivos: "",
                        obrigatorio: false,
                        ordem: this.props.proximaPosicaoModulo,
                        acessoLivre: false,
                        //PARTICIANTES
                        todos: true,
                        participantes: [],
                        competencias: []
                    },
                    precedencias: [],
                    listaCompetencias: []
                });
            } else {
                this.carregarModulo(this.props.moduloid);
            }

            if (this.context.habilitar_competencias)
                this.listarCompetencias();
        }
    };

    render() {
        const {
            precedencias,
            listaCompetencias,
            iconLoading,
            //DRAWER PESQUISAR PARTICIPATES,
            visibleDrawerPesqusiar
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const {
            titulo,
            precedencia,
            competencias,
            estado,
            inicio,
            termino,
            objetivos,
            obrigatorio,
            ordem,
            //PARTICIANTES
            todos,
            participantes,
            acessoLivre
        } = this.state.modulo;

        return (
            <>
                <Drawer
                    className="drawer-from-modulo"
                    //title={this.props.moduloid > 0 ? "Editar" : "Adicionar"}
                    width={720}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-modulo" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Titulo">
                                    {getFieldDecorator("titulo", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: titulo
                                    })(<Input name="titulo" onChange={this.handlerChange} />)}
                                </Form.Item>
                                <Form.Item label="Ordem">
                                    {getFieldDecorator("ordemModulo", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: ordem
                                    })(<InputNumber className="input-25" name="ordemModulo" min={1} onChange={this.handlerChangeNumber} />)}
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <>
                                            Precedência
                                    <Tooltip className="info-icon" title="Selecione a atividade a realizar previamente. Nota: Ambas as atividades devem ter os mesmos participantes.">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </>
                                    }
                                >
                                    {getFieldDecorator("precedencia", {
                                        initialValue: precedencia
                                    })(
                                        <Select
                                            className="input-50"
                                            placeholder="Selecionar"
                                            disabled={precedencias.length === 0 ? true : false}
                                            onChange={this.handlerChangeSelectPrecedencia}
                                            allowClear={true}
                                        >
                                            {precedencias.map((precedencia, index) => (
                                                <Option key={index} value={precedencia.id}>
                                                    {precedencia.nome}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                {precedencia ? (
                                    <Form.Item>
                                        <Checkbox name="obrigatorio" checked={obrigatorio} onChange={this.handlerChangeCheck}>
                                            Obrigatória
                                </Checkbox>
                                    </Form.Item>
                                ) : null}
                                <Form.Item label="Estado">
                                    {getFieldDecorator("estado", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: estado
                                    })(
                                        <Select className="input-25" placeholder="Selecionar" onChange={this.handlerChangeSelectEstado}>
                                            <Option value="ATIVO">Ativo</Option>
                                            <Option value="FECHADO">Fechado</Option>
                                            <Option value="BLOQUEADO">Bloqueado</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label={
                                    <>
                                        Inicio
                                        <Tooltip className="info-icon" title="Período de datas recomendado para realizar as tarefas desta atividade">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </>}
                                    className="input-25"
                                >
                                    {getFieldDecorator("inicio", {
                                        initialValue: inicio
                                    })(<DatePicker
                                        name="inicio"
                                        placeholder="Selecionar"
                                        format="DD-MM-YYYY"
                                        onChange={this.handleChangeDataInicio}
                                        disabledDate={this.disabledDate}
                                    />)}
                                </Form.Item>
                                <Form.Item label="Término">
                                    {getFieldDecorator("termino", {
                                        initialValue: termino
                                    })(<DatePicker
                                        className="input-25"
                                        name="termino"
                                        placeholder="Selecionar"
                                        format="DD-MM-YYYY"
                                        onChange={this.handleChangeDataTermino}
                                        disabledDate={this.disabledDate}
                                    />)}
                                </Form.Item>
                                <Form.Item label="Objetivos">
                                    {getFieldDecorator("objetivcos", {
                                        initialValue: objetivos
                                    })(<TextArea name="objetivos" maxLength="200" onChange={this.handlerChange} rows="3" style={{ height: "100%" }} />)}
                                </Form.Item>
                                <Form.Item className="form-item-checkbox">
                                    <Checkbox name="acessoLivre" checked={acessoLivre} onChange={this.handlerChangeCheck}>
                                        Atividade com acesso livre na página pública
                            </Checkbox>
                                </Form.Item>
                            </div>
                            {this.context.habilitar_competencias &&
                                <div className="bloco">
                                    <h3 className="titulo-separador">
                                    Competências
                                    <Tooltip className="info-icon" title="Competências específicas">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </h3>
                                <Form.Item>
                                    {getFieldDecorator("selecionarCompetencia", {
                                    })(<Select placeholder="Selecionar" onChange={this.handlerChangeSelectCompetencia}>
                                        {listaCompetencias.map((competencia, index) => (
                                            <Option key={index} value={competencia.id}>{competencia.nome}</Option>
                                        ))}
                                    </Select>)}
                                </Form.Item>
                                <Form.Item className="form-item-checkbox">
                                    <div className="lista-competencias">
                                        {competencias.map((competencia, index) => (
                                            <div key={index} className="competencia">
                                                <div className="info-competencia">
                                                    <div className="info">
                                                        <span className="nome">
                                                            {competencia.nome}
                                                        </span>
                                                        <p className="numero">{JSON.parse(competencia.tipos).join(", ")}</p>
                                                    </div>
                                                </div>
                                                <div className="opcoes">
                                                    <button className="botao-excluir" onClick={() => this.setState({ modulo: { ...this.state.modulo, competencias: [...this.state.modulo.competencias.filter(x => x.id !== competencia.id)] } })}>
                                                        <Icon type="delete" />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Form.Item>
                                </div>}
                            <div className="bloco">
                                <h3 className="titulo-separador">
                                    Participantes
                                </h3>
                                <Form.Item className="form-item-checkbox">
                                    <Checkbox className="marcar-todos" checked={todos} name="todos" onChange={this.handlerChangeCheck}>
                                        Todos
                                        <Tooltip className="info-icon" title="Ao selecionar todos, os alunos que indicar serão considerados exceções.">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item className="form-item-checkbox">
                                    <div className="lista-participantes">
                                        <label className="label-participantes">
                                            {todos ? "Exceto os seguintes participantes" : "Incluidos os seguintes participantes"}
                                        <button className="botao-principal" onClick={() => this.setState({ visibleDrawerPesqusiar: true })}>
                                                <Icon type="plus" />
                                            </button>
                                        </label>
                                        {participantes.length > 0 ?
                                            participantes.map((participante, index) => (
                                                <div key={index} className="participante">
                                                    <div className="info-participante">
                                                        <div className="container-img">
                                                            <img src={participante.foto ? participante.foto : noimage} />
                                                        </div>
                                                        <div className="info">
                                                            <span className="nome">
                                                                {participante.nome}
                                                            </span>
                                                            <p className="numero">{participante.numero}</p>
                                                        </div>
                                                    </div>
                                                    <div className="opcoes">
                                                        <button className="botao-excluir" onClick={() => this.setState({ modulo: { ...this.state.modulo, participantes: [...this.state.modulo.participantes.filter(x => x.id !== participante.id)] } })}>
                                                            <Icon type="delete" />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <span className="sem-registos">Sem dados</span>
                                        }
                                    </div>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        <button className="botao-principal" type="primary" disabled={iconLoading} onClick={this.props.moduloid > 0 ? this.alterar : this.criar} style={{ padding: "0 10px" }}>
                            {iconLoading ? <Icon type="loading" /> : null}
                            Guardar
                        </button>
                        {/*{this.props.moduloid > 0 ? (*/}
                        {/*    <button className="botao-principal" type="primary" disabled={iconLoading} onClick={this.alterar} style={{ padding: "0 10px" }}>*/}
                        {/*        {iconLoading ? <Icon type="loading" /> : null}*/}
                        {/*    Guardar*/}
                        {/*    </button>*/}
                        {/*) : (*/}
                        {/*        <button className="botao-principal" type="primary" disabled={iconLoading} onClick={this.criar}>*/}
                        {/*            {iconLoading ? <Icon type="loading" /> : null}*/}
                        {/*    Guardar*/}
                        {/*        </button>*/}
                        {/*    )}*/}
                    </div>
                </Drawer>
                <DrawerPesquisar
                    onClose={() => this.setState({ visibleDrawerPesqusiar: false })}
                    participantes={participantes}
                    visibleDrawerPesqusiar={visibleDrawerPesqusiar}
                    selecionarParticipante={participante => this.setState({ modulo: { ...this.state.modulo, participantes: [...participantes, participante] } })}
                />
            </>
        );
    }
}
const FormFormModulo = Form.create({ name: "form-modulo" })(FormModulo);

export default FormFormModulo;
